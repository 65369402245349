import {
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Divider,
  Input,
  Label,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  Toaster,
  useToastController,
} from "@fluentui/react-components";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { InputComponent } from "@web/react-component-ui-user";
import { useDispatch, useSelector } from "react-redux";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import ToastComp from "../../components/Toast";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { horizontal, timeout, vertical } from "../../utils/constants";
import FtpSvg from "./../../assets/ftp.svg";
import FSSvg from "./../../assets/images/fs.svg";
import S3svg from "./../../assets/images/s3.svg";
import WebDav from "./../../assets/webdav.svg";
import { postAsync } from "./../../store/ManageStorageSlice";

const dataBread = [
  {
    key: 0,
    value: "Storages",
    path: "list-storage",
  },

  {
    key: 2,
    value: "Create",
    path: "",
  },
];
const arrResource = [
  { name: "FileSystem", icon: FSSvg },
  { name: "AmazoneS3", icon: S3svg },
  { name: "FTP", icon: FtpSvg },
  { name: "WebDAV", icon: WebDav },
];

const CreateStoragePage = () => {
  const dispatch = useDispatch();

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [state1, setState1] = useState(true);
  const [state3, setState3] = useState(false);

  const [valRadioResource, setValRadiResource] = useState("FileSystem");
  const navigate = useCustomNavigate();

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/create-storage"));
    dispatch(changeName("create-storage"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  const [path, setPath] = useState("");
  const [name, setName] = useState("");
  const [bucket, setBucket] = useState("");
  const [uri, setUri] = useState("");
  const [object, setOject] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [expiration, setExpiration] = useState("");
  const [region, setRegion] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [securityToken, setSecurityToken] = useState("");

  const [description, setDescription] = useState("");

  const handleFinish = async () => {
    dispatch(changeVisibleSpinner(true));
    let payFS = {
      description: description,
      name: name,
      resource: {
        FS: {
          path: path,
        },
      },
      // rs_type: valRadioRsType,
    };

    let payS3 = {
      description: description,
      name: name,
      resource: {
        S3: {
          uri: uri,
          bucket: bucket,
          object: object,
          credential: {
            access_key: accessKey,
            expiration: expiration,
            region: region,
            secret_key: secretKey,
            security_token: securityToken,
          },
        },
      },
      // rs_type: valRadioRsType,
    };
    try {
      const res = await dispatch(
        // @ts-ignore
        postAsync(valRadioResource === "FileSystem" ? payFS : payS3)
      ).unwrap();

      if (res?.status === 200) {
        notify("Add storage successfully", "success");
        setTimeout(() => {
          navigate(`/list-storage`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Add storage fail", "error");
        setTimeout(() => {
          // navigate(`/list-storage`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    } catch (e) {
      console.error(e);
      notify("Add storage fail", "error");
      setTimeout(() => {
        // navigate(`/list-storage`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const [errorPath, setErrorPath] = useState("");
  const [errorBucket, setErrorBucket] = useState("");
  const [errorUri, setErrorUri] = useState("");
  const [errorObject, setErrorObject] = useState("");
  const [errorDes, setErrorDes] = useState("");

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  return (
    <>
      <div className="px-6 py-6 !w-full !relative">
        <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} />
        {visibleSpinner && <LoadingGlobalComp />}
        <div
          className={
            state1 && !state3 ? "div-form-storage" : "div-form-storage-small"
          }
        >
          {state1 && !state3 && (
            <>
              <p className="div-title-form-storage">Resource</p>
              <div className="div-resource">
                <div className="flex justify-between gap-4 mt-8">
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    {arrResource?.map((item: any, index: any) => (
                      <Grid item xs={12} md={6} lg={3}>
                        <Card
                          key={index}
                          className={
                            item?.name === valRadioResource
                              ? "card-default-active"
                              : "card-default"
                          }
                          orientation="horizontal"
                          floatingAction={
                            // @ts-ignore
                            <RadioGroup value={valRadioResource}>
                              <Radio
                                disabled={
                                  item?.name === "FTP" ||
                                  item?.name === "WebDAV"
                                  // || item?.name === "AmazoneS3"
                                }
                                value={item?.name}
                                onChange={(e: any, val: any) => {
                                  setValRadiResource(val?.value);
                                  if (val?.value === "FileSystem") {
                                  } else if (val?.value === "AmazoneS3") {
                                    setPath("");
                                    setErrorPath("");
                                  }
                                }}
                              />
                            </RadioGroup>
                          }
                        >
                          <CardPreview className="div-preview-storage">
                            <div
                              className={
                                item?.name === "FTP" || item?.name === "WebDAV"
                                  ? "div-center bg-icon-card-disable"
                                  : "div-center bg-icon-card"
                              }
                            >
                              <img
                                className="img-svg"
                                src={item?.icon}
                                alt="storage"
                              />
                            </div>
                          </CardPreview>

                          <CardHeader
                            header={<Text weight="semibold">{item?.name}</Text>}
                            description={<Caption1>{item?.name}</Caption1>}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <div className="mt-6">
                  {valRadioResource === "FileSystem" && (
                    <>
                      <center>
                        <p className="mb-2 mt-8">Path</p>

                        <InputComponent
                          stylesDiv={{ display: "flex", alignItems: "center" }}
                          value={path}
                          onChange={(val: any) => {
                            if (val && val !== "") {
                              setPath(val);
                              setErrorPath("");
                            } else {
                              setPath(val);
                              setErrorPath("Requied");
                            }
                          }}
                          placeholder="Enter path..."
                          error={errorPath}
                        />
                      </center>
                    </>
                  )}
                  {valRadioResource === "AmazoneS3" && (
                    <>
                      <div className="flex">
                        <div className="!w-[48%]">
                          <div className="mb-4">
                            <p className="mb-2">Uri</p>
                            <InputComponent
                              placeholder="Enter Uri"
                              className="div-element"
                              value={uri}
                              onChange={(val: any) => {
                                if (val && val !== "") {
                                  setUri(val);
                                  setErrorUri("");
                                } else {
                                  setUri(val);
                                  setErrorUri("Requied");
                                }
                              }}
                              error={errorUri}
                            />
                          </div>
                          <div className="mb-4">
                            <p className="mb-2">Bucket</p>
                            <InputComponent
                              placeholder="Enter Bucket"
                              className="div-element"
                              value={bucket}
                              onChange={(val: any) => {
                                if (val && val !== "") {
                                  setBucket(val);
                                  setErrorBucket("");
                                } else {
                                  setBucket(val);
                                  setErrorBucket("Requied");
                                }
                              }}
                              error={errorBucket}
                            />
                          </div>
                          <div className="mb-4">
                            <p className="mb-2">Object</p>
                            <InputComponent
                              value={object}
                              placeholder="Enter Object"
                              className="div-element"
                              error={errorObject}
                              onChange={(val: any) => {
                                if (val && val !== "") {
                                  setOject(val);
                                  setErrorObject("");
                                } else {
                                  setOject(val);
                                  setErrorObject("Requied");
                                }
                              }}
                            />
                          </div>
                        </div>

                        <Divider vertical />

                        <div className="!w-[48%]">
                          <div className="mb-4">
                            <p className="mb-2">Region</p>
                            <InputComponent
                              placeholder="Enter Region"
                              className="div-element"
                              value={region}
                              onChange={(val: any) => {
                                setRegion(val);
                              }}
                            />
                          </div>
                          <div className="mb-4">
                            <p className="mb-2">Access_key</p>
                            <InputComponent
                              placeholder="Enter Access_key"
                              className="div-element"
                              value={accessKey}
                              onChange={(val: any) => {
                                setAccessKey(val);
                              }}
                            />
                          </div>

                          <div className="mb-4">
                            <p className="mb-2">Secret_key</p>
                            <InputComponent
                              placeholder="Enter Secret_key"
                              className="div-element"
                              value={secretKey}
                              onChange={(val: any) => {
                                setSecretKey(val);
                              }}
                              type="password"
                            />
                          </div>

                          <div className="mb-4">
                            <p className="mb-2">Security_token</p>
                            <InputComponent
                              placeholder="Enter Security_token"
                              className="div-element"
                              value={securityToken}
                              onChange={(val: any) => {
                                setSecurityToken(val);
                              }}
                            />
                          </div>

                          <div className="mb-4">
                            <p className="mb-2">Expiration</p>
                            <InputComponent
                              placeholder="Enter Expiration"
                              className="div-element"
                              value={expiration}
                              onChange={(val: any) => {
                                setExpiration(val);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* @ts-ignore */}

              <div className="divFooterStep">
                <div />
                <div className="divStep">1/2</div>
                {/* @ts-ignore */}
                {valRadioResource === "FileSystem" && (
                  <Button
                    className="btn-cus-primary !text-black"
                    disabled={path === "" ? true : false}
                    appearance="primary"
                    title="Next"
                    onClick={() => {
                      setState1(true);
                      // setState2(true);
                      setState3(true);
                    }}
                  />
                )}
                {valRadioResource === "AmazoneS3" && (
                  <Button
                    className="btn-cus-primary !text-black"
                    disabled={bucket === "" ? true : false}
                    appearance="primary"
                    title="Next"
                    onClick={() => {
                      setState1(true);
                      // setState2(true);
                      setState3(true);
                    }}
                  />
                )}
              </div>
            </>
          )}

          {state1 && state3 && (
            <>
              <div className="div-resource">
                <p className="div-title-form-storage !mb-8">Describe</p>
                <p className="mb-2">Name</p>
                <Input
                  value={name}
                  onChange={(e: any) => {
                    setName(e?.target?.value);

                    if (e?.target?.value && e?.target?.value !== "") {
                      setErrorDes("");
                    } else {
                      setErrorDes("Required");
                    }
                  }}
                  placeholder="Enter name..."
                  className="div-dropdown"
                />
                {errorDes !== "" && (
                  <Label className="!text-error" size="small">
                    {errorDes}
                  </Label>
                )}
                <p className="mb-2 mt-6">Description</p>
                <Textarea
                  value={description}
                  onChange={(val: any) => {
                    setDescription(val?.target?.value);
                  }}
                  //   resize="both"
                  placeholder="Enter description storage..."
                  className="div-text-area"
                />
              </div>
              <div className="divFooterStep">
                <Button
                  className="btn-back"
                  // appearance="primary"
                  title="Back"
                  onClick={() => {
                    setState1(true);
                    // setState2(true);
                    setState3(false);
                  }}
                />
                <div className="divStep">2/2</div>
                {/* @ts-ignore */}
                <Button
                  className="btn-cus-primary !text-black"
                  appearance="primary"
                  title="Add"
                  onClick={() => handleFinish()}
                  disabled={name === "" ? true : false}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateStoragePage;
