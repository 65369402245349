import {
  Dropdown,
  Option,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeSortByTask,
  changeSortOrderTask,
} from "../../store/ManageScheduler";

import { arrSortStorageBy, arrSortOrder } from "../../utils/constants";

const SortTaskComp = (props: any) => {
  const dispatch = useDispatch();

  const { sort_order_task, sort_by_task } = useSelector(
    (state: RootState) => state.managescheduler
  );

  return (
    <>
      <div>
        <p className="mb-2">Sort by</p>
        <Dropdown
          placeholder="-- Choose field --"
          className="div-dropdown"
          value={sort_by_task}
          onOptionSelect={(e: any, data: any) => {
            dispatch(changeSortByTask(data?.optionValue));
          }}
        >
          {arrSortStorageBy?.map((option: any) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>

        <p className="mb-2 mt-4">Sort order</p>
        <RadioGroup value={sort_order_task}>
          {arrSortOrder?.map((item: any, index: any) => (
            <Radio
              key={index}
              label={item}
              value={item}
              onChange={(e: any, val: any) => {
                dispatch(changeSortOrderTask(val?.value));
              }}
            />
          ))}
        </RadioGroup>
      </div>
    </>
  );
};

export default SortTaskComp;
