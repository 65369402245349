import
  {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Switch,
    Tooltip
  } from "@fluentui/react-components";
import moment from "moment";
import { useNavigate } from "react-router";
import DeleteSvg from "./../../assets/delete-dark.svg";
import EditSvg from "./../../assets/edit-dark.svg";
import TaskSvg from "./../../assets/item-task.svg";
import NextSvg from "./../../assets/next.svg";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardItemTaskComp = (props: any) => {
   const navigate = useCustomNavigate();

  const handleCheckTask = (item: any) => {
    let flag = false;
    if (
      !item?.enable ||
      item?.task_type === "EXPIRE_SCAN_SESSIONS" ||
      item?.task_type === "CLEAN_EXPIRED_DLSESSION_DATA"
    ) {
      flag = true;
    }
    if (
      //2024-04-16T14:15:00Z
      !item?.next_run_time ||
      (item?.next_run_time && moment(item?.next_run_time).isBefore(moment()))
    ) {
      flag = true;
    }
    return flag;
  };
  return (
    <>
      {props?.data?.map((item: any, index: any) => (
        <section
          key={index}
          onClick={() => {
            navigate(`${document.location.pathname}/${item?.id}/view-job`, {
              state: { item: item },
            });
          }}
        >
          <div className="bg-[#2E3240] rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview
                className="div-center div-card-preview"
               
              >
                <Tooltip
                  relationship="description"
                  content={
                    item?.next_run_time && (
                      <span>
                        Next run at:{" "}
                        {moment(item?.next_run_time).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </span>
                    )
                  }
                >
                  <div className="relative !flex flex-col items-center justify-between">
                    <img
                      className="img-card-item-catalog"
                      src={TaskSvg}
                      alt="img item"
                    />
                    {item?.schedule_id && item?.schedule_id !== "" && (
                      <div className="absolute top-[60%] left-[56%]">
                        <span className="relative flex h-3 w-3">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span>
                      </div>
                    )}
                  </div>
                </Tooltip>
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div
                    className="!w-auto divHeader relative"
                  
                  >
                    <div className="block !w-auto ">
                      <Tooltip relationship="description" content={item?.name}>
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.name}
                        </p>
                      </Tooltip>
                      <Tooltip
                        relationship="description"
                        content={item?.description}
                      >
                        <p className="text-title-card ellipsis-text-one-line mt-1 text-des">
                          {item?.description}
                        </p>
                      </Tooltip>
                    </div>

                    <div />
                  </div>
                }
               
              />

              <CardFooter className="footer-card-action">
                <div
                  className="div-action-card"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="mr-3"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      navigate(
                        `${document.location.pathname}/edit/${item?.id}`,
                        {
                          state: { item: item },
                        }
                      );
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon-filter"
                      src={EditSvg}
                      alt="Dismiss"
                    />
                  </div>

                  <div>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="mr-2 img-svg-icon-filter"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() => props?.handleDeleteTask(item?.id)}
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  {item?.task_type === "SCAN_COLLECTION" && (
                    <div className="mr-3" onClick={(e) => e.stopPropagation()}>
                      <Switch
                        checked={
                          item?.schedule_id && item?.schedule_id !== ""
                            ? true
                            : false
                        }
                        onChange={(_, data: any) => {
                          if (data?.checked) {
                            props?.handleStartTask(item?.id);
                          } else {
                            props?.handleStopTask(item?.id);
                          }
                        }}
                        disabled={handleCheckTask(item)}
                      />
                    </div>
                  )}

                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${document.location.pathname}/${item?.id}/view-job`,
                        {
                          state: { item: item },
                        }
                      );
                    }}
                  >
                    <img
                      className="img-svg-icon"
                      src={NextSvg}
                      alt="Info Circle"
                    />
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItemTaskComp;
