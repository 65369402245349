import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FluentProvider, webDarkTheme } from "@fluentui/react-components";
import { store } from "./store/store";
import { Provider } from "react-redux";

import "./i18n";
import Wrapper from "./Wrapper";

localStorage.setItem(
  "offsetUtc",
  (new Date().getTimezoneOffset() / 60).toString()
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>

  // </React.StrictMode>
  <FluentProvider
    theme={{
      ...webDarkTheme,
      colorNeutralBackground1: "rgba(28, 28, 28, 1)",
      colorCompoundBrandBackground: "rgba(90, 188, 232, 1)",
      colorCompoundBrandForeground1: "rgba(90, 188, 232, 1)",
      colorNeutralStroke1: "#383838",

      colorBrandBackground: "rgba(90, 188, 232, 1)",
      colorBrandBackgroundHover: "rgba(83, 170, 210, 1)",
      fontFamilyBase: "'Inter', sans-serif",
      colorPaletteDarkOrangeBackground3: "#EF6C00",
      // colorPaletteDarkOrangeBackground3,
    }}
  >
    <Provider store={store}>
      <Wrapper />
    </Provider>
  </FluentProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
