import React, { useEffect } from "react";
import FormAddNotiComp from "../../components/Notification/FormAddNoti";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const AddNotificationPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePath("/add-noti"));
    dispatch(changeName("add-noti"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);
  return <FormAddNotiComp />;
};

export default AddNotificationPage;
