import InfoMetadata from "./metadataCollection";

import CloudSVG from "./../../assets/cloud.svg";
import Maximize3SVG from "./../../assets/maximize-3.svg";
import MedalStar from "./../../assets/medal-star.svg";
import NoteTextSVG from "./../../assets/note-text.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import LayerSvg from "./../../assets/Layer_1.png";
import { useEffect, useState } from "react";

const IndexDrawerDataset = (props: any) => {
  const arrMetaData = [
    {
      title: "Title",
      content: props?.data?.title,
      icon: NoteTextSVG,
    },
    {
      title: "keywords",
      content: props?.data?.keywords?.[0],
      icon: MedalStar,
    },
    {
      title: "License",
      content: props?.data?.license,
      icon: CloudSVG,
    },
    {
      title: "Provider",
      content: `${props?.data?.providers?.[0]?.name} ${props?.data?.providers?.[0]?.description}`,
      icon: Maximize3SVG,
    },
  ];

  let sun = props?.data?.links?.filter(
    (item: any) => item?.rel === "thumbnail"
  );

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setHasError(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [props?.data?.id]);

  return (
    <>
      <div
        id="action_button"
        className="mb-2 bg-black rounded relative !h-[200px] flex flex-col justify-center items-center"
      >
        {props?.data?.thumbnail?.length === 0 && (
          <img
            className="w-full !h-[100px] !object-contain"
            src={LayerSvg}
            alt="layer"
            onError={(e: any) => {
              e.target.src = LayerSvg;
            }}
          />
        )}
        {props?.data?.thumbnail && props?.data?.thumbnail?.length === 1 && (
          <img
            loading="lazy"
            className={`w-full !object-contain ${
              hasError ? "!h-[100px]" : "!h-[200px]"
            }`}
            src={`${localStorage.getItem("REACT_APP_ENDPOINT")}/collections/${
              props?.data?.id
            }/thumbnails/${props?.data?.thumbnail?.[0]}/`}
            onError={(e: any) => {
              e.target.src = LayerSvg;
              setHasError(true);
              e.target.onerror = null;
            }}
          />
        )}

        {props?.data?.thumbnail && props?.data?.thumbnail?.length > 1 && (
          <Carousel>
            {props?.data?.thumbnail?.map((item: any, index: any) => (
              <div>
                <img
                  loading="lazy"
                  className={`w-full !object-contain ${
                    hasError ? "!h-[100px]" : "!h-[200px]"
                  }`}
                  src={`${localStorage.getItem(
                    "REACT_APP_ENDPOINT"
                  )}/collections/${props?.data?.id}/thumbnails/${item}/`}
                  onError={(e: any) => {
                    e.target.src = LayerSvg;
                    setHasError(true);
                    e.target.onerror = null;
                  }}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div id="info" className="mb-2">
        <b className="text-title-card !uppercase ellipsis-text">
          {props?.data?.title}
        </b>
        <p className="text-des ellipsis-text-one-line mt-1">
          {props?.data?.description}
        </p>
      </div>
      <InfoMetadata data={arrMetaData} />
    </>
  );
};

export default IndexDrawerDataset;
