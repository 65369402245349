import React from "react";
import MyQuotaSvg from "../../assets/my-quota.svg";
import { ProgressBar } from "@fluentui/react-components";
import { HASH_ROLE_USE } from "../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const MyQuotasComp = (props: any) => {
  const { currentRole } = useSelector((state: RootState) => state.manageuser);

  let total: any = 0;
  let used: any = 0;
  let unit: any = "";

  if (props?.data?.[0]?.unit === "Gigabytes") {
    total = props?.data?.[0]?.limit_value;
    used =
      props?.data?.[0]?.value?.LimitTotalOrderSize > 0
        ? props?.data?.[0]?.value?.LimitTotalOrderSize / 1000000000
        : 0;

    unit = "GB";
  } else if (props?.data?.[0]?.unit === "Tetabytes") {
    total = props?.data?.[0]?.limit_value;
    used =
      props?.data?.[0]?.value?.LimitTotalOrderSize > 0
        ? props?.data?.[0]?.value?.LimitTotalOrderSize / 100000000000
        : 0;
    unit = "TB";
  } else if (props?.data?.[0]?.unit === "Megabytes") {
    total = props?.data?.[0]?.limit_value / 1000;
    used =
      props?.data?.[0]?.value?.LimitTotalOrderSize > 0
        ? props?.data?.[0]?.value?.LimitTotalOrderSize / 1000000000
        : 0;
    unit = "GB";
  } else if (props?.data?.[0]?.unit === "Kilobytes") {
    total = props?.data?.[0]?.limit_value / 1000000;
    used =
      props?.data?.[0]?.value?.LimitTotalOrderSize > 0
        ? props?.data?.[0]?.value?.LimitTotalOrderSize / 1000000000
        : 0;
    unit = "GB";
  } else if (props?.data?.[0]?.unit === "Bytes") {
    total = props?.data?.[0]?.limit_value/100000000;
    used =
      props?.data?.[0]?.value?.LimitTotalOrderSize > 0
        ? props?.data?.[0]?.value?.LimitTotalOrderSize / 1000000000
        : 0;
    unit = "GB";
  }

  // const renderColorProgress = () =>
  // {
  //   // #EF6C00;
  //   // #60CDFF
  //   // #DD2C00
  //   let color = "#EF6C00";
  //   return color
  // }
  return currentRole === HASH_ROLE_USE || props?.type === "Admin" ? (
    <div className="div-my-quota-info w-full">
      <div
        className="flex justify-between items-center"
        style={{ fontSize: "12px" }}
      >
        <div className="flex justify-between items-center">
          <img className="img-svg-icon" src={MyQuotaSvg} />
          <span className="ml-[10px]">
            {props?.type === "Admin" ? props?.data?.[0]?.quota_name : "Storage"}
          </span>
        </div>
        <div>
          <span className="text-des">
            {used.toFixed(1)} GB of {total.toFixed(1)} {unit} used
          </span>
        </div>
      </div>
      <div className="mt-2">
        <ProgressBar
          // style={{ background: renderColorProgress() }}
          // color={() => renderColorProgress()}
          shape="rounded"
          thickness="large"
          value={used / total}
          color={
            used / total > 0.9
              ? "error"
              : used / total < 0.9 && used / total > 0.75
              ? "warning"
              : "brand"
          }
        />
      </div>
    </div>
  ) : null;
};

export default MyQuotasComp;
