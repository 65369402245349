import { Checkbox, Label } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";

import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getProductTypeAsync,
  changeStartAt,
  changeEndAt,
  changeCloudCoverFrom,
  changeCloudCoverTo,
  changeTypeVT,
  changeStateFilter,
  changeAllowIntersect,
  changeCoordinates,
} from "../../store/exploreSlice";
import { RootState } from "../../store/store";

import MultiRangeSlider from "../../components/multiRangeSlider";
import MaplibreGL from "../map/maplibregl/Map";
import { HASH_ROLE_ADMIN, MD5_TEXT_ROLE } from "../../utils/constants";

const FilterDatasetComp = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  const {
    start_at,
    end_at,
    cloudCoverFrom,
    cloudCoverTo,
    typeVT,
    allowIntersect,
    coordinates,
  } = useSelector((state: RootState) => state.exploredataset);

  const [satellites, setSatellites] = useState<Array<any>>([]);

  let r = localStorage.getItem(MD5_TEXT_ROLE);

  useEffect(() =>
  {
    
    if (props?.criteria)
    {
      
      if (props?.criteria?.query) {
        if (props?.criteria?.query["eo:cloud_cover"]) {
          dispatch(
            changeCloudCoverFrom(props?.criteria?.query["eo:cloud_cover"].gte)
          );
          dispatch(
            changeCloudCoverTo(props?.criteria?.query["eo:cloud_cover"].lte)
          );
        }
        if (props?.criteria?.query["platform"])
        {
          
          dispatch(
            changeTypeVT(
              satellites?.filter((x: any) =>
                props?.criteria?.query["platform"]["in"].includes(x?.platform)
              )
            )
          );
        } else
        {
          
          dispatch(changeTypeVT(satellites?.filter((x) => x?.platform)));
        }
      }
      if (props?.criteria?.datetime) {
        let dates = props?.criteria?.datetime.split("/");
        if (dates.length === 2) {
          try {
            dispatch(
              changeStartAt(dates[0] !== ".." ? new Date(dates[0]) : undefined)
            );
            dispatch(
              changeEndAt(dates[1] !== ".." ? new Date(dates[1]) : undefined)
            );
          } catch (error) {}
        } else {
          dispatch(changeStartAt(undefined));
          dispatch(changeEndAt(undefined));
        }
      } if (!props?.criteria?.query)
      {
        dispatch(changeTypeVT(satellites?.filter((x) => x?.platform)));
      }
    } else
    {
    
      dispatch(changeTypeVT(satellites?.filter((x) => x?.platform)));
    }
  }, [satellites]);

  useEffect(() => {
    (async function () {
      if (pageName !== "dataset-item")
        try {
          const res = await dispatch(
            // @ts-ignore
            getProductTypeAsync()
          ).unwrap();

          setSatellites(res?.data ?? []);
        } catch (e) {
          console.error(e);
        }
    })();
  }, []);

  const changeTime = (date: any, type: string) => {
    if (type === "start_at") {
      if (date) {
        dispatch(changeStartAt(date));
      } else {
        dispatch(changeStartAt(undefined));
      }
    } else if (type === "end_at" && date) {
      if (date) {
        dispatch(changeEndAt(date));
      } else if (date === null) {
        dispatch(changeEndAt(undefined));
      }
    }
  };

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = useCallback(
    (newValue: string): Date => {
      const previousValue = start_at || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [start_at]
  );

  const onParseDateFromStringEndAt = useCallback(
    (newValue: string): Date => {
      const previousValue = end_at || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [end_at]
  );

  const onChangeCloudCover = (min: number, max: number) => {
    dispatch(changeCloudCoverFrom(min));
    dispatch(changeCloudCoverTo(max));
  };

  const onSelectSatellite = (item: any) => {
    let index = typeVT?.findIndex((x) => x?.platform === item?.platform);

    let selected = typeVT?.slice();

    if (index < 0) selected.push(item);
    else {
      selected.splice(index, 1);
    }

    dispatch(changeTypeVT(selected));
  };

  const onChangeAllowExtent = () => {
    let temp = !allowIntersect;
    dispatch(changeAllowIntersect(temp));
  };

  const mapChangedBounds = (bounds: any) => {
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    //dispatch(changeCoordinates([[sw.lng, sw.lat], [sw.lng, ne.lat], [ne.lng, ne.lat], [ne.lng, sw.lat], [sw.lng, sw.lat]]));

    // search with bbox
    dispatch(changeCoordinates([sw.lng, sw.lat, ne.lng, ne.lat]));
  };

  return (
    <div className="relative h-full">
      <div id="form-filter">
        <p className="mb-2">{t("From day")}</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          parseDateFromString={onParseDateFromStringStartAt}
          value={start_at}
          onSelectDate={(date: any) => changeTime(date, "start_at")}
          maxDate={end_at}
          onChange={(e: any, data: any) => {
            if (data?.value === "") {
              dispatch(changeStartAt(null));
            }
          }}
        />

        <p className="mb-2 mt-5">{t("To day")}</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          value={end_at}
          onSelectDate={(date: any) => changeTime(date, "end_at")}
          parseDateFromString={onParseDateFromStringEndAt}
          minDate={start_at}
          onChange={(e: any, data: any) => {
            if (data?.value === "") {
              dispatch(changeEndAt(null));
            }
          }}
        />

        <p className="mb-2 mt-5">
          {t("Cloud cover")} [{cloudCoverFrom}-{cloudCoverTo}]&nbsp;(%)
        </p>
        <div className="mt-2 gap-x-1 flex items-center w-full">
          <Label className="w-6 text-center">{cloudCoverFrom}</Label>
          <div className="flex w-[calc(100%-24px-32px)]">
            <MultiRangeSlider
              min={0}
              max={100}
              currentMax={cloudCoverTo}
              currentMin={cloudCoverFrom}
              onChange={({ min, max }) => {
                onChangeCloudCover(min, max);
              }}
            />
          </div>
          <Label className="w-8 text-center">{cloudCoverTo}</Label>
        </div>

        {pageName !== "manage-dataset-item" && (
          <div className="mb-6">
            <p className="mb-2 mt-5">{t("Satellite")}</p>
            <div className="grid grid-cols-2 mt-2 w-full">
              {satellites?.map((item: any, index: number) => (
                <Checkbox
                  checked={ typeVT?.length > 0 ?
                    typeVT?.findIndex((x) => x?.platform === item?.platform) >=
                    0 : false
                  }
                  key={item.platform}
                  label={item.product}
                  onChange={() => onSelectSatellite(item)}
                />
              ))}
            </div>
          </div>
        )}

        {/* here add check role admin to show */}

        {((r && r === HASH_ROLE_ADMIN) || !r) && (
          <div className="mt-8 ">
            <Checkbox
              checked={allowIntersect}
              label={t("Extent")}
              onChange={() => onChangeAllowExtent()}
            />
            <div className="w-full h-full">
              <MaplibreGL
                heightClass="h-80"
                coordinates={coordinates}
                mapChangedBounds={(bounds: any) => mapChangedBounds(bounds)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterDatasetComp;
