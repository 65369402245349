import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingListComp from "../../components/Loading/LoadingList";
import CardHistoryScanComp from "../../components/ManageStorage/CardHistoryScan";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import EmptySvg from "./../../assets/nodata.svg";
import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  getHistoryScanAsync,
  resetFilter,
  resetSortHistoryScan,
  getListAsync as getListStorage,
} from "./../../store/ManageStorageSlice";
import { changePage, changeTotal, resetState } from "./../../store/pagingSlice";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";

const dataBread = [
  {
    key: 0,
    value: "History scan",
    path: "history-scan",
  },
];

const HistoryScanPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { page, limit } = useSelector((state: RootState) => state.paging);

  const {
    created_from,
    created_to,
    success,
    collection_id,
    storage_id,
    sort_by,
    sort_order,
    flagFilter,
    flagFresh,
    flagSort,
  } = useSelector((state: RootState) => state.managestorage);

  const [data, setData] = useState([]);

  const [arrSortStorage, setArrStorage] = useState<any>([]);

  useEffect(() => {
    dispatch(changePath("/history-scan"));
    dispatch(changeName("history-scan"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    (async function () {
      const resStorage = await dispatch(
        // @ts-ignore
        getListStorage({
          page: 0,
          limit: 10000,
        })
      ).unwrap();

      setArrStorage(resStorage?.result ?? []);
    })();
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());

      if (!window.location.pathname?.includes("history-scan")) {
        // @ts-ignore
        dispatch(resetFilter({}));
        dispatch(resetSortHistoryScan({}));
      }

      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const getData = async (props: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      getHistoryScanAsync({
        size: limit,
        page: props?.page ? props?.page : page,
        include_summary: true,
        sortby: "created",
        asc: sort_order === "asc" ? true : false,
        filter: {
          created_from: created_from,
          created_to: created_to
            ? moment(created_to).endOf("days").toISOString()
            : null,
          success:
            success === "True" ? true : success === "False" ? false : null,
          collection_id:
            collection_id && collection_id !== "" ? collection_id : null,
          storage_id: storage_id && storage_id !== "" ? storage_id : null,
        },
      })
    ).unwrap();

    dispatch(changeTotal(res?.paging?.total ?? 0));

    setData(res?.result);

    setTimeout(() => {
      dispatch(changeVisibleSpinner(false));
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    (async function () {
      if (!flagFilter || !flagFresh || !flagSort)
        try {
          getData({ page: page });
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilter)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFilter(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilter(false));
          setLoading(false);
        }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagSort(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagSort(false));
          setLoading(false);
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFresh(false));
        } catch (e) {
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFresh(false));
          setLoading(false);
          console.error(e);
        }
    })();
  }, [flagFresh]);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {visibleSpinner && !loading && <LoadingGlobalComp />}

        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div>
            <CardHistoryScanComp arrSortStorage={arrSortStorage} data={data} />
          </div>
        )}
      </div>
    </>
  );
};

export default HistoryScanPage;
