import {
  Button,
  Dropdown,
  Option,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { getProductTypeAsync } from "../../store/exploreSlice";
import { createCollectionStorageAsync } from "../../store/ManageCollectionStorageSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";
import { getListAsync as getListStorage } from "./../../store/ManageStorageSlice";

const FormAddDatasetStorageComp = () => {
  const navigate = useCustomNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [arrStorage, setArrStorage] = useState([]);

  const [storageId, setStorageId] = useState("");
  const [productType, setProductType] = useState("");

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const [satellites, setSatellites] = useState<Array<any>>([]);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListStorage({
            size: 1000,
            sortby: "modified",
            asc: false,
          })
        ).unwrap();

        setArrStorage(res?.result);
        // @ts-ignore
        const resProductType = await dispatch(getProductTypeAsync()).unwrap();
        setSatellites(resProductType?.data ?? []);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    dispatch(changeVisibleSpinner(true));

    const res = await dispatch(
      // @ts-ignore
      createCollectionStorageAsync({
        collection_id: params?.id,
        storage_id: storageId,
        product_type: productType,
      })
    ).unwrap();

    if (res?.status === 200) {
      notify("Add successfully", "success");
      setTimeout(() => {
        navigate(`/manage-dataset/setting/${params?.id}`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Add fail", "error");
      setTimeout(() => {
        // navigate(`/manage-dataset/setting/${params?.id}`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };
  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage-small ">
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            <p className="mb-2">Storage</p>
            <Dropdown
              placeholder="-- Select --"
              className="div-dropdown"
              onOptionSelect={(e: any, data: any) => {
                setStorageId(data?.optionValue);
              }}
            >
              {arrStorage?.map((item: any) => (
                <Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Dropdown>
            <p className="mb-2 mt-6">Product type</p>

            <Dropdown
              placeholder="Select product type"
              className="div-dropdown"
              selectedOptions={[productType]}
              // multiselect={true}
              onOptionSelect={(e: any, data: any) => {
                setProductType(data?.optionValue);
              }}
              value={productType}
            >
              {satellites?.map((option: any) => (
                <Option key={option} value={option?.platform}>
                  {option?.product}
                </Option>
              ))}
            </Dropdown>

            {/* <RadioGroup value={productType}>
              {satellites?.map((item: any, index: any) => (
                <Radio
                  key={index}
                  label={item?.product}
                  value={item?.platform}
                  onChange={(e: any, val: any) => {
                    setProductType(val?.value);
                  }}
                />
              ))}
            </RadioGroup> */}
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => navigate(`/manage-dataset/setting/${params?.id}`)}
          disabled={visibleSpinner}
        >
          Cancel
        </Button>

        <Button
          appearance="outline"
          className="btn-action"
          onClick={() => add()}
          disabled={visibleSpinner || !storageId || !productType}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default FormAddDatasetStorageComp;
