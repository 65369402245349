import { useRef, useEffect, useState } from "react";
import {
  Spinner,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../../../store/store";
import {
  removeFeature,
  changeActiveFeature,
  clearFeatures,
  setShowDownloadAOI,
} from "../../../../store/drawSlice";

import deleteSvg from "../../../../assets/delete.svg";
import downloadSvg from "../../../../assets/download.svg";
import eraserSvg from "../../../../assets/images/eraser.svg";

export default function FeaturesDraw(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let draw: any = useSelector((state: RootState) => state.draw?.draw);

  let featuresDraw: Array<any> = useSelector(
    (state: RootState) => state.draw?.features
  );

  let activeFeature: any = useSelector(
    (state: RootState) => state.draw?.activeFeature
  );

  let pageName: any = useSelector((state: RootState) => state.page?.name);

  const findIndexFeature = (feature: any) => {
    let index = featuresDraw?.findIndex((x) => x.id == feature.id);
    return index;
  };

  const onSelectedFeature = (feature: any) => {
    changeColor(featuresDraw, "#3bb2d0");
    dispatch(changeActiveFeature(feature));
  };

  const deleteFeature = (feature: any) => {
    dispatch(removeFeature(feature));
    if (draw) {
      draw.delete(feature.id);
    }
    setTimeout(() => {
      changeColor([activeFeature], "#ff0000");
    }, 10);
  };

  //change colors
  const changeColor = (features: Array<any>, color: string) => {
    if (features && features.length) {
      features.forEach((f) => {
        if (draw && f) {
          draw?.setFeatureProperty(f?.id, "portColor", color);
          var feat = draw.get(f?.id);
          draw?.add(feat);
        }
      });
    }
  };

  const clearAllAoi = () => {
    dispatch(clearFeatures());
    if (draw) {
      draw.deleteAll();
    }
  };

  const showDownloadAOI = () => {
    dispatch(setShowDownloadAOI(true));
  };

  return (
    <>
      {pageName &&
      pageName == "mainMap" &&
      featuresDraw &&
      featuresDraw.length > 0 ? (
        <div className="z-2 mr-2">
          <Menu>
            <MenuTrigger>
              <div className="flex gap-x-1 items-center">
                <MenuButton className="!whitespace-nowrap !self-baseline !items-baseline btn-action-drawer">
                  {t("AOI") +
                    " " +
                    (findIndexFeature(activeFeature) < 0
                      ? ""
                      : findIndexFeature(activeFeature) + 1)}
                </MenuButton>
                <MenuButton
                  className="btn-action-drawer "
                  onClick={(e) => {
                    e.stopPropagation();
                    showDownloadAOI();
                  }}
                  icon={
                    <img
                      className="img-svg-icon"
                      src={downloadSvg}
                      alt={t("Download")}
                    />
                  }
                ></MenuButton>
                <MenuButton
                  className="btn-action-drawer "
                  onClick={(e) => {
                    e.stopPropagation();
                    clearAllAoi();
                  }}
                  icon={
                    <img
                      className="img-svg-icon"
                      src={eraserSvg}
                      alt={t("Clear")}
                    />
                  }
                ></MenuButton>
              </div>
            </MenuTrigger>

            <MenuPopover
              className={
                featuresDraw && featuresDraw.length == 1
                  ? "hidden"
                  : "!bg-s-background1"
              }
            >
              <MenuList className="!bg-s-background1">
                {featuresDraw.map((f: any, index: number) => (
                  <div
                    key={index}
                    className={
                      activeFeature && f.id == activeFeature.id ? "hidden" : ""
                    }
                  >
                    <MenuItem
                      className="!bg-s-background1 hover:!bg-ink-300"
                      key={index}
                      onClick={() => onSelectedFeature(f)}
                    >
                      <div className="flex justify-between items-center gap-x-1">
                        {t("AOI") + " " + (index + 1)}
                        <Button
                          className="btn-action-drawer "
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteFeature(f);
                          }}
                          appearance="transparent"
                          size="small"
                          icon={
                            <img
                              className="img-svg-icon"
                              src={deleteSvg}
                              alt="delete"
                            />
                          }
                        />
                      </div>
                    </MenuItem>
                  </div>
                ))}
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
