import {
  Card,
  CardFooter,
  CardHeader,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
// import { ButtonComponent as Button } from "@web/react-component-ui-user";

import DeleteSvg from "./../../assets/delete.svg";

import moment from "moment";

const CardNotiDrawerComp = (props: any) => {
  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section key={index}>
            <div
              className={
                item?.seen ? "!bg-[#2E3240]" : "!bg-[#244159]" + " rounded"
              }
              style={{borderRadius: '4px'}}
            >
              <Card
                orientation="horizontal"
                className={
                  item?.readed
                    ? "!bg-[#2E3240] card-item-storage"
                    : "!bg-[#244159] card-item-storage"
                }
              >
                <CardHeader
                  onClick={() => {
                    props?.handleReadItem(item);
                  }}
                  // className="div-card-header"
                  style={{ width: "calc(100% - 30px)" }}
                  header={
                    <>
                      <div className="divHeader relative">
                        <div className="block w-full">
                          <p
                            style={{ overflowWrap: "anywhere" }}
                            className="down-line"
                          >
                            {item?.title}
                          </p>

                          <i className=" text-des" style={{ fontSize: "12px" }}>
                            {moment(item?.created)?.format(
                              "DD/MM/YYYY HH:mm:ss "
                            )}
                          </i>
                        </div>
                      </div>
                    </>
                  }
                />

                <CardFooter className="footer-card-smal-action">
                  <div>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="img-svg-icon"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() =>
                            props?.handleDelete(item?.notification_id)
                          }
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CardNotiDrawerComp;
