const InfoMetadata = (props: any) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-6">
        {props?.data?.map((item: any, index: any) => (
          <div key={index}>
            <span className="flex text-title-infor-metadata">
              <img src={item?.icon} className="img-svg-icon mr-1" />
              {item?.title}
            </span>
            <p className="text-sm ml-6 mt-1">{item?.content}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default InfoMetadata;
