import CardItem from "../../components/ManageStorage/CardItem";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { useEffect, useState } from "react";

import { useToastController } from "@fluentui/react-components";
import EmptySvg from "./../../assets/nodata.svg";
import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeSate,
  changeStateFilter,
  deleteStorageAsync,
  getListAsync,
} from "./../../store/ManageStorageSlice";
import { changePage, changeTotal, resetState } from "./../../store/pagingSlice";

import moment from "moment";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import {
  changeStateBtnCreate,
  changeVisibleSpinner,
} from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { timeout } from "../../utils/constants";

const dataBread = [
  {
    key: 1,
    value: "Storages",
    path: "list-storage",
  },
];

const ListStoragePage = () => {
  const dispatch = useDispatch();

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, limit } = useSelector((state: RootState) => state.paging);

  const {
    start_at,
    end_at,
    product_type,
    flagFilter,
    flagFresh,
    flagSort,
    sort_order,
    keyword,
  } = useSelector((state: RootState) => state.managestorage);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    dispatch(changePath("/list-storage"));
    dispatch(changeName("list-storage"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  const getData = async (props: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      getListAsync({
        size: limit,
        page: props?.page ? props?.page : page,

        sortby: "modified",
        asc: sort_order === "asc" ? true : false,
        filter: {
          product_types: product_type,
          created_from: start_at,
          created_to: end_at
            ? moment(end_at).endOf("days").toISOString()
            : null,
          keyword: keyword && keyword !== "" ? keyword : null,
        },
      })
    ).unwrap();

    dispatch(changeSate(res?.result));
    dispatch(changeTotal(res?.paging?.total ?? 0));
    setData(res?.result);
    dispatch(changeVisibleSpinner(false));
    setLoading(false);
  };

  useEffect(() => {
    (async function () {
      if (!flagFilter || !flagFresh || !flagSort)
        try {
          getData({ page: page });
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilter) {
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFilter(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilter(false));
          setLoading(false);
        }
      }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagSort(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
          dispatch(changeFlagSort(false));
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFresh(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
          dispatch(changeFlagFresh(false));
        }
    })();
  }, [flagFresh]);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());
      if (!window.location.pathname?.includes("list-storage")) {
        // @ts-ignore
        dispatch(changeStateFilter());
      }

      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: "/list-storage/create",
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
    };
  }, []);

  const deleteItem = async (id: string) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteStorageAsync(id)).unwrap();
    if (res?.status === 200) {
      const res = await dispatch(
        // @ts-ignore
        getListAsync({
          size: limit,
          page: page,

          sortby: "modified",
          asc: sort_order === "asc" ? true : false,
          filter: {
            rs_type: product_type,
            created_from: start_at,
            created_to: end_at,
          },
        })
      ).unwrap();
      dispatch(changeSate(res?.result));
      dispatch(changeTotal(res?.paging?.total ?? 0));
      setData(res?.result);
      notify("Delete successfully", "success");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Delete fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {!loading && visibleSpinner && <LoadingGlobalComp />}

        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div className="w-full">
            <CardItem data={data} deleteItem={deleteItem} />
          </div>
        )}
      </div>
    </>
  );
};
export default ListStoragePage;
