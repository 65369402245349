import {
  Button,
  Dropdown,
  Input,
  Option,
  Textarea,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import {
  getTypeNotiAsync,
  sendNotiAsync,
  updateNotiAsync,
} from "../../store/notificationsSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";
import { getListUserAsync } from "./../../store/ManageUserSlice";
import useCustomNavigate from "../../hook/useCustomNavigate";

const FormEditNotiComp = (props: any) => {
  const location = useLocation();

  const dataBread = [
    {
      key: 0,
      value: "Manage notification",
      path: "manage-notification",
    },
    {
      key: 1,
      value: `Edit ${location?.state?.item?.title}`,
      path: "edit",
    },
  ];

  const dispatch = useDispatch();
   const navigate = useCustomNavigate();

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getTypeNotiAsync({})
        ).unwrap();

        setArrType(res?.data ?? []);

        const resUser = await dispatch(
          // @ts-ignore
          getListUserAsync()
        ).unwrap();

        setArrUser(resUser);

        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  const [arrType, setArrType] = useState([]);
  const [arrUser, setArrUser] = useState([]);
  const [recipient, setRecipient] = useState<any[]>([]);
  const [recipientId, setRecipientId] = useState<any[]>([]);

  const [title, setTitle] = useState(location?.state?.item?.title);
  const [content, setContent] = useState(location?.state?.item?.content);
  const [type, setType] = useState(location?.state?.item?.noti_type);

  const onOptionSelectType = (e: any, data: any) => {
    setType(data.optionText);
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const sendTo = async () => {
    dispatch(changeVisibleSpinner(true));
    if (handleCheck()) {
      const res = await dispatch(
        // @ts-ignore
        updateNotiAsync({
          content: content,
          noti_type: type,
          title: title,
          id: location?.state?.item?.id,
        })
      ).unwrap();

      if (res?.status === 200) {
        // @ts-ignore
        const resSend = await dispatch(
          // @ts-ignore
          sendNotiAsync({
            id: location?.state?.item?.id,
            // arrRecipients: recipientId,
            arrRecipients: recipientId,
          })
        ).unwrap();

        if (resSend?.status === 200) {
          notify("Send notication successfully", "success");
          setTimeout(() => {
            navigate(`/manage-notification`);
            dispatch(changeVisibleSpinner(false));
          }, 2000);
        } else {
          notify("Send notication fail", "error");
          setTimeout(() => {
            // navigate(`/manage-notification`);
            dispatch(changeVisibleSpinner(false));
          }, 2000);
        }
      } else {
        notify("Update fail", "error");
        setTimeout(() => {
          // navigate(`/manage-notification`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    } else {
      const resSend = await dispatch(
        // @ts-ignore
        sendNotiAsync({
          id: location?.state?.item?.id,
          arrRecipients: recipientId,
        })
      ).unwrap();

      if (resSend?.status === 200) {
        notify("Send notication successfully", "success");
        setTimeout(() => {
          navigate(`/manage-notification`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Send notication fail", "error");
        setTimeout(() => {
          // navigate(`/manage-notification`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    }
  };

  const update = async () => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      updateNotiAsync({
        content: content,
        noti_type: type,
        title: title,
        id: location?.state?.item?.id,
      })
    ).unwrap();

    if (res?.status === 200) {
      notify("Update successfully", "success");
      setTimeout(() => {
        navigate(`/manage-notification`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Update fail", "error");
      setTimeout(() => {
        // navigate(`/manage-notification`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const handleCheck = () => {
    let flag = false;

    if (
      title !== location?.state?.item?.title ||
      content !== location?.state?.item?.content ||
      type !== location?.state?.item?.noti_type
    ) {
      flag = true;
    }
    return flag;
  };

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage-small">
        <div className="w-full">
          <p className="mb-2">Title</p>
          <Input
            value={title}
            onChange={(e: any) => {
              setTitle(e?.target?.value);
            }}
            placeholder="Enter title..."
            className="div-dropdown"
          />
          <p className="mb-2 mt-6">Content</p>
          <Textarea
            value={content}
            onChange={(e: any) => setContent(e?.target?.value)}
            placeholder="Enter content..."
            className="div-dropdown"
            resize="both"
          />

          <p className="mb-2 mt-6">Type</p>
          <Dropdown
            placeholder="Select quota type"
            className="div-dropdown"
            value={type}
            onOptionSelect={onOptionSelectType}
          >
            {arrType?.map((option: string) => (
              <Option key={option}>{option}</Option>
            ))}
          </Dropdown>

          <p className="mb-2 mt-6">Recipient</p>
          <Dropdown
            placeholder="Select recipient"
            className="div-dropdown"
            multiselect
            // @ts-ignore
            value={recipient}
            onOptionSelect={(e: any, data: any) => {
              let a = data?.selectedOptions;
              let b: any[] = [];
              let sun: any[] = [];
              a?.map((item: any, index: any) => {
                let c = a?.length > 1 && index !== a?.length - 1 ? " ," : "";
                if (item?.traits?.name?.first && item?.traits?.name?.last) {
                  b.push(
                    item?.traits?.name?.first +
                      " " +
                      item?.traits?.name?.last +
                      c
                  );
                } else {
                  b.push(item?.traits?.email + c);
                }

                sun?.push(item?.id);
              });
              setRecipient(b);
              setRecipientId(sun);
            }}
          >
            {arrUser?.map((user: any) => {
              return user?.traits?.name?.first && user?.traits?.name?.last ? (
                // @ts-ignore
                <Option key={user} value={user}>
                  {user?.traits?.name?.first} {user?.traits?.name?.last}
                </Option>
              ) : (
                <Option key={user} value={user}>
                  {user?.traits?.email}
                </Option>
              );
            })}
          </Dropdown>
        </div>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => navigate(`/manage-notification`)}
          disabled={visibleSpinner}
        >
          Cancel
        </Button>

        <Button
          appearance="outline"
          className="btn-action !mr-4"
          onClick={() => update()}
          disabled={visibleSpinner || !handleCheck()}
        >
          Update
        </Button>

        <Button
          appearance="outline"
          className="btn-action"
          onClick={() => sendTo()}
          disabled={visibleSpinner || recipientId?.length === 0}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default FormEditNotiComp;
