import axios from "../../utils/axios";

export async function initSessionDownload(payload: any) {
  
  // Create a DownloadSession with `order_id` and one or many `order_item_ids`
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/download_sessions/`,
    payload?.ids,
    payload?.configHeader
  );
}

export async function getSessionDownload(payload: any) {
  
  //This api only get download session of current logged in user.
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/download_sessions/`,
    {
      // params: {
      //   page: 0,
      //   limit: 1,
      //   filter: {
      //     processed: true,
      //     expired: false,
      //   },
      // },
    }
  );
}

export async function getDownload(payload: any) {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/download_sessions/${payload?.downloadSessions}/download/`,
    {
      responseType: payload?.responseType,
      headers: payload?.headers,
      signal: payload?.signal,
    }
  );
}

export async function headDownload(payload: any) {
  
  return axios.head(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/download_sessions/${payload?.downloadSessions}/download/`
  );
}

export async function getDownloadStorage(payload: any) {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/download_sessions/storage_configs/`,
    {
      params: { ...payload },
    }
  );
}

export async function addDownloadStorage(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/download_sessions/storage_configs/`,
    payload
  );
}

export async function updDownloadStorage(payload: any) {
  
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/download_sessions/storage_configs/${payload?.id}/`,
    payload
  );
}

export async function delDownloadStorage(payload: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/download_sessions/storage_configs/${payload?.id}/`
  );
}

export async function expireSession(payload: any) {
  
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/download_sessions/expire/`
  );
}
