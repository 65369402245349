import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeFlagReloadCart,
  getThumbnailItemDatasetAsync as getThumbnail,
} from "../../store/exploreSlice";
import { toggleDrawerCart } from "./../../store/DrawerSlice";
import {
  addItemOderAsync,
  changeTotalCart,
  createOderAsync,
  deleteItemInOrderAsync,
  getItemInOrderAsync,
  submitOrderAsync,
} from "./../../store/ManageOrderSlice";
import { updateAlert } from "./../../store/notificationsSlice";

import { getItemCollectionByIdAsync } from "../../store/itemCollectionSlice";
import {
  changeCartId,
  changeCartItems,
  changeItemsMapSelected,
} from "./../../store/ManageOrderSlice";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";

import { useTranslation } from "react-i18next";

import DeleteSvg from "./../../assets/delete.svg";
import CheckSVG from "./../../assets/images/checked.svg";

import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeVisibleSpinner } from "../../store/NavSlice";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import CartItem from "./CartItem";

const timeout = 3000;
const DrawerCart = (props: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useCustomNavigate();

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  let mapItemsCart: any = useSelector(
    (state: RootState) => state.manageorder?.itemsMapSelected
  );

  const { cartId, total_cart } = useSelector(
    (state: RootState) => state.manageorder
  );

  const { flagReloadCart } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const { dispatchToast } = useToastController(toasterId);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);

  const [selected, setSelected] = useState<Array<any>>([]);
  const [showOrderConfirm, setShowOrderConfirm] = useState<boolean>(false);

  const [count, setCount] = useState(0);

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (props?.visibleCart) {
      getCartAndItems({ type: "get_selected" });
    }
  }, [props?.visibleCart]);

  useEffect(() => {
    if (total_cart > 0 && flagReloadCart) {
      getCartAndItems({ type: "get_selected" });
    }
  }, [total_cart]);

  const onSelectedItem = (item: any) => {
    let items = selected.slice(); // copy
    let index = items?.findIndex((x) => x.id == item.id);
    if (index < 0) {
      items.push(item);
      // onAddItem(item);
    } else {
      items.splice(index, 1);

      // onDeleteItem(item);
    }

    // dispatch(changeCartItems(items));
    setSelected(items);
  };

  const onChangeSelectAll = (val: any) => {
    if (val.checked) {
      setSelected(data);
      // if (selected?.length < count) {
      //   let a = data.filter(
      //     (objA: any) => !selected.some((objB: any) => objB.id === objA.id)
      //   );

      //   for (let i = 0; i < a?.length; i++) {
      //     onAddItem(a[i]);
      //   }
      // }
    } else {
      setSelected([]);

      // for (let i = 0; i < selected?.length; i++) {
      //   onDeleteItem(selected[i]);
      // }
    }
  };

  const onAdd2Map = async (item: any) => {
    let items: any = mapItemsCart.slice(); // copy
    let itemDetail: any = null;
    //@ts-ignore
    let index = data?.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      //@ts-ignore
      itemDetail = data[index]?.item_detail ? data[index]?.item_detail : null;
    }

    //@ts-ignore
    let index2 = items?.findIndex((x) => x.id == item.item_id);
    if (index2 >= 0) items.splice(index2, 1);
    else {
      if (!itemDetail) {
        itemDetail = await getItemCollectionById(
          item.collection_id,
          item.item_id
        );
        if (itemDetail != null) {
          items.push(itemDetail);
          setData(
            //@ts-ignore
            data.map((t: any) => {
              if (t.id === item.id) {
                return {
                  ...item,
                  item_detail: itemDetail,
                };
              } else {
                return t;
              }
            })
          );
        }
      } else {
        items.push(itemDetail);
      }
    }

    dispatch(changeItemsMapSelected(items));
  };

  const onDeleteItem = async (item: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));

    dispatch(changeFlagReloadCart(false));

    const res = await dispatch(
      // @ts-ignore
      deleteItemInOrderAsync({
        idOrder: cartId,
        item_id: item?.stac_item_id,
        collection_id: item?.collection_id,
      })
    ).unwrap();

    if (res.status === 200) {
      // @ts-ignore
      let resItems = await dispatch(getItemInOrderAsync(cartId)).unwrap();

      dispatch(changeCartItems(resItems?.result?.[0]?.items));
      dispatch(changeTotalCart(resItems?.result?.[0]?.items?.length));

      notify(t("Delete item from cart successfully"), false, "success");
    } else {
      let msg = res?.message;
      if (msg) {
        try {
          let msgJson = JSON.parse(msg);
          msg = msgJson?.message ?? null;
        } catch (error) {
          msg = null;
        }
      }
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: msg ?? t("Delete item from cart error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
    setLoading(false);
    dispatch(changeVisibleSpinner(false));
  };

  const onAddItem = async (item: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    dispatch(changeFlagReloadCart(false));

    const res = await dispatch(
      // @ts-ignore
      addItemOderAsync({
        order_id: cartId,
        item_id: item?.stac_item_id,
        collection_id: item?.collection_id,
      })
    ).unwrap();

    if (res.status === 200) {
      // @ts-ignore
      let resItems = await dispatch(getItemInOrderAsync(cartId)).unwrap();

      dispatch(changeCartItems(resItems?.result?.[0]?.items));
      dispatch(changeTotalCart(resItems?.result?.[0]?.items?.length));

      notify(t("Add item into cart successfully"), false, "success");
    } else {
      let msg = res?.message;
      if (msg) {
        try {
          let msgJson = JSON.parse(msg);
          msg = msgJson?.message ?? null;
        } catch (error) {
          msg = null;
        }
      }
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: msg ?? t("Add item into cart error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
    setLoading(false);
    dispatch(changeVisibleSpinner(false));
  };

  const onShowDialogOrderConfirm = () => {
    setShowOrderConfirm(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      submitOrderAsync({
        orderId: cartId,
      })
    ).unwrap();
    if (res?.status === 200) {
      notify(
        t(
          "Submit order successfully, we redirect you into invoice page in a few seconds"
        ),
        true,
        "success"
      );
      // @ts-ignore
      setTimeout(() => {
        navigate(`/invoice`);
        // @ts-ignore
        dispatch(toggleDrawerCart(false));
      }, 1000);

      dispatch(changeTotalCart(0));
      // @ts-ignore
      const res = await dispatch(createOderAsync()).unwrap();

      if (res?.status === 200) {
        dispatch(changeCartId(res?.data?.id));
        dispatch(changeCartItems(res?.data?.items ?? []));
      }

      setShowOrderConfirm(false);
      setLoading(false);
    } else {
      setLoading(false);
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: t("Submit order error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };

  const getCartAndItems = async (props: any) => {
    try {
      setLoading(true);
      dispatch(changeVisibleSpinner(true));
      // @ts-ignore
      let resItems = await dispatch(getItemInOrderAsync(cartId)).unwrap();

      dispatch(changeCartItems(resItems?.result?.[0]?.items));
      dispatch(changeTotalCart(resItems?.result?.[0]?.items?.length));

      let a: any = [];
      let b = resItems?.result?.[0]?.items;

      for (let i = 0; i < b?.length; i++) {
        let res;
        res = await dispatch(
          // @ts-ignore
          getThumbnail({
            collection_id: b?.[i]?.collection_id,
            item_id: b?.[i]?.stac_item_id,
          })
        ).unwrap();

        a.push({
          ...b?.[i],
          thumbnail: res,
        });
      }

      setData(a);

      // setData(resItems?.result?.[0]?.items);
      setCount(resItems?.result?.[0]?.items?.length);
      if (props?.type === "get_selected") {
        setSelected(resItems?.result?.[0]?.items);
      }

      setLoading(false);
      dispatch(changeVisibleSpinner(false));
    } catch (e) {
      setLoading(false);
      dispatch(changeVisibleSpinner(false));
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: t("Can't get Items Cart"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };

  const getItemCollectionById = async (
    collectionId: string,
    itemId: String
  ) => {
    try {
      setLoading(true);
      const item = await dispatch(
        // @ts-ignore
        getItemCollectionByIdAsync({ collectionId, itemId })
      ).unwrap();
      setLoading(false);
      return item;
    } catch (err: any) {}

    setLoading(false);
    return null;
  };

  const handleRemoveSelectedItem = async () => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    let items = selected.slice(); // copy
    for (let i = 0; i < selected?.length; i++) {
      let res = await dispatch(
        // @ts-ignore
        deleteItemInOrderAsync({
          idOrder: cartId,
          item_id: selected?.[i]?.stac_item_id,
          collection_id: selected?.[i]?.collection_id,
        })
      ).unwrap();

      if (res?.status === 200) {
        let index = items?.findIndex((x) => x.id == selected?.[i].id);
        items.splice(index, 1);
        setSelected(items);
      }
    }

    // @ts-ignore
    // let resItems = await dispatch(getItemInOrderAsync(cartId)).unwrap();

    // dispatch(changeCartItems(resItems?.result?.[0]?.items));
    // dispatch(changeTotalCart(resItems?.result?.[0]?.items?.length));

    await getCartAndItems({ type: "get_no_selected" });
    notify(t("Delete items from cart successfully"), false, "success");
    setLoading(false);
    dispatch(changeVisibleSpinner(false));
  };

  const notify = (message: any, showTimeout: any, type?: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle media={showTimeout ? <Spinner size="tiny" /> : null}>
          {message}
        </ToastTitle>
      </Toast>,
      { position: "bottom", timeout: timeout, intent: type }
    );
  };

  const renderDialogConfirm = () => {
    return (
      <Dialog
        open={showOrderConfirm}
        onOpenChange={(event, data) => {
          setShowOrderConfirm(false);
        }}
        modalType="alert"
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{t("Order confirmation")}</DialogTitle>
            <DialogContent>
              {`Are you sure to order ${data?.length} items?`}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button autoFocus appearance="secondary">
                  {t("Close")}
                </Button>
              </DialogTrigger>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                appearance="primary"
              >
                {t("Confirm")}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  };

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  return (
    <>
      {visibleSpinner && !loading && <LoadingGlobalComp />}
      {loading && <LoadingGlobalComp />}

      {count > 0 && (
        <div
          className="flex justify-between items-baseline seft-center pt-1 pb-3 h-12 sticky top-0 bg-s-background1 z-1"
          style={{
            opacity: loading ? 0.3 : 1,
            cursor: loading ? "none" : "initial",
          }}
        >
          <Checkbox
            className="py-0 !h-8"
            checked={selected.length == count}
            label={
              t("Selected") +
              " " +
              selected.length +
              " " +
              t("of") +
              " " +
              count
            }
            onChange={(e: any, data: any) => onChangeSelectAll(data)}
          />
          <div className="flex gap-x-2.5">
            <Button
              className="!flex !self-center !border-none !bg-ink-300 !text-white w-24 hover:!bg-ink-400 font-normal"
              disabled={selected?.length > 0 ? false : true}
              onClick={() => handleRemoveSelectedItem()}
              icon={<img src={DeleteSvg} />}
            ></Button>
            <Button
              className="!flex !self-center !border-none !bg-ink-300 !text-white w-24 hover:!bg-ink-400 font-normal"
              // disabled={selected?.length > 0 ? false : true}
              onClick={async () => {
                setSelected(data);
                onShowDialogOrderConfirm();
              }}
              icon={<img src={CheckSVG} />}
            >
              {t("Order")}
            </Button>
          </div>
        </div>
      )}
      <CartItem
        selectedIds={
          selected && selected.length ? selected.map((x) => x.id) : []
        }
        pageName={props?.pageName}
        data={data}
        add2Map={(item: any) => onAdd2Map(item)}
        deleteItem={(item: any) => onDeleteItem(item)}
        onSelectedItem={(item: any) => onSelectedItem(item)}
      />

      {renderDialogConfirm()}
    </>
  );
};

export default DrawerCart;
