import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAllStateDrawer } from "../store/DrawerSlice";
import {
  deleteCollectionsAsync,
  getListAsync,
  getThumbnailDatasetAsync,
} from "../store/exploreSlice";
import { changePage } from "../store/pagingSlice";
import { RootState } from "../store/store";

import LoadingGlobalComp from "../components/Loading/LoadingGlobal";
import LoadingListComp from "../components/Loading/LoadingList";
import CardDatasetAdmin from "../components/ManageDataset/CardDatasetAdmin";
import ToastComp from "../components/Toast";
import { changeDataBreadcrumb } from "../store/BreadcrumbSlice";
import { changeStateBtnCreate, changeVisibleSpinner } from "../store/NavSlice";
import { changeName, changePath } from "../store/pageSlice";
import { resetState } from "../store/pagingSlice";
import { timeout } from "../utils/constants";
import EmptySvg from "./../assets/nodata.svg";
import { scanThumbnailAsync } from "../store/ManageCollectionStorageSlice";

const ExploreDatasetPage = () => {
  const dispatch = useDispatch();

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListAsync({
            size: limit,
            sortby: "modified",
            asc: false,
            page: page,
          })
        ).unwrap();

        
        let b: any = [];

        if (res?.collections?.length > 0) {
          for (let i = 0; i < res?.collections?.length; i++) {
            let resThumbnail;
            resThumbnail = await dispatch(
              // @ts-ignore
              getThumbnailDatasetAsync({
                collection_id: res?.collections?.[i]?.id,
              })
            ).unwrap();

            b.push({
              ...res?.collections?.[i],
              thumbnail: resThumbnail,
            });
          }
        }

        setData(b ?? []);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, [page]);

  useEffect(() => {
    dispatch(changePath("/manage-dataset"));
    dispatch(changeName("manage-dataset"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(changePage(0));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "Dataset",
          path: "manage-dataset",
        },
      ])
    );
    return () =>
    {
      
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: "/manage-dataset/create",
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
    };
  }, []);

  const [data, setData] = useState([]);

  const reloadData = async () => {
    try {
      const res = await dispatch(
        // @ts-ignore
        getListAsync({
          size: limit,
          sortby: "modified",
          asc: false,
          page: page,
        })
      ).unwrap();

      let b: any = [];

      if (res?.collections?.length > 0) {
        for (let i = 0; i < res?.collections?.length; i++) {
          let resThumbnail;
          resThumbnail = await dispatch(
            // @ts-ignore
            getThumbnailDatasetAsync({
              collection_id: res?.collections?.[i]?.id,
            })
          ).unwrap();

          b.push({
            ...res?.collections?.[i],
            thumbnail: resThumbnail,
          });
        }
      }

      setData(b ?? []);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteDataset = async (id: string) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteCollectionsAsync(id)).unwrap();
    if (res?.status === 200) {
      notify("Delete successfully", "success");
      setTimeout(() => {
        reloadData();

        dispatch(changeVisibleSpinner(false));
      }, 1500);
    } else {
      notify("Delete fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 1500);
    }
  };

  const scanThumbnail = async (item: any) =>
  {

   
      dispatch(changeVisibleSpinner(true));
      const res = await dispatch(
        // @ts-ignore
        scanThumbnailAsync({
          collection_id: item?.id,
        })
      ).unwrap();
    


    if (res?.status === 200)
    {
        notify("Scan thumbnail successfully", "success");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Scan thumbnail fail", "error");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (result: any, type?: any) => {
    return dispatchToast(
      ToastComp({
        message: result,
      }),
      {
        timeout: timeout, 
        position:'bottom',
        intent: type,
      }
    );
  };

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {visibleSpinner && !loading && <LoadingGlobalComp />}

        {data?.length === 0 && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && (
          <div className="w-full">
            <CardDatasetAdmin
              loading={loading}
              data={data}
              deleteDataset={deleteDataset}
              scanThumbnail={scanThumbnail}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ExploreDatasetPage;
