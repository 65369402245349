import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Text,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { updateAlert } from "../../store/notificationsSlice";
export default function Alert({
  onClickNo,
  onClickYes,
}: {
  onClickNo?: () => void;
  onClickYes?: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // @ts-ignore
  const alert = useSelector((state: RootState) => state.notifications?.alert);

  const onClose = () => {
    dispatch(
      updateAlert({
        show: false,
        title: null,
        message: null,
        buttons: {
          close: false,
          yes: false,
          no: false,
        },
      })
    );
  };
  return alert.show ? (
    <Dialog modalType="alert" open={alert.show}>
      <DialogSurface className="!bg-s-background1">
        <DialogBody>
          <DialogTitle>{t("" + alert.title + "")}</DialogTitle>
          <DialogContent>
            <Text>{t("" + alert.message + "")}</Text>
          </DialogContent>
          <DialogActions>
            {alert.buttons.close && (
              <Button
                onClick={() => onClose()}
                className="!bg-s-background1"
                appearance="secondary"
              >
                {t("Close")}
              </Button>
            )}
            {alert.buttons.no && (
              <Button
                className="!bg-s-background1"
                appearance="secondary"
                onClick={() => {
                  if (onClickNo) onClickNo();
                }}
              >
                {t("No")}
              </Button>
            )}
            {alert.buttons.yes && (
              <Button
                appearance="primary"
                onClick={() => {
                  if (onClickYes) onClickYes();
                }}
              >
                {t("Yes")}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  ) : null;
}
