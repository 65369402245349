import { Text } from "@fluentui/react-components";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ParsedIcon from "./../../assets/ellipse-parsed.svg";
import ScanIcon from "./../../assets/ellipse-scan.svg";

const PieComp = (props: any) => {
  return (
    <div className="flex flex-col items-center w-full  !justify-center">
      {/* <div className="mb-4">
        {props?.data?.labels?.map((item: any) => (
          <>
            <span className="flex">
              <img
                src={item === "Parsed" ? ScanIcon : ParsedIcon}
                className="mr-2"
              />
              {item}

              <Text
                weight="semibold"
                className="ml-5"
                style={{ overflowWrap: "anywhere" }}
              >
                {item === "Parsed"
                  ? props?.data?.datasets?.[0]?.data?.[1]
                  : props?.data?.datasets?.[0]?.data?.[0]}
              </Text>
            </span>
          </>
        ))}
      </div> */}
      <div>
        <Pie
          data={props?.data}
          //@ts-ignore
          plugins={[ChartDataLabels]}
          options={{
            plugins: {
              legend: {
                display: false,
                // position: "bottom",
              },
              title: {
                display: false,
              },
              // @ts-ignore
              datalabels: {
                color: "black",
                // display: false,
                formatter: (value: any, ctx: any) => {
                  return ctx.chart.data.labels[ctx.dataIndex] + ": " + value;
                },
              },
            },
          }}
          height={259}
          width={259}
          className="pie-hc"
        />
      </div>
    </div>
  );
};

export default PieComp;
