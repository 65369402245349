import { useState } from "react";
import { Button } from "@fluentui/react-components";
import positionSvg from "../../../../assets/images/position.svg";
import plusSvg from "../../../../assets/images/plus.svg";
import minusSvg from "../../../../assets/images/minus.svg";
import mapMarkerSvg from "../../../../assets/images/map-marker.svg";

import { RootState } from "../../../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { changeCenter, changeZoomLevel } from "../../../../store/maplibreGLSlice";

import CoordinateEditor from "./navigationbar/CoordinateEditor";

export default function NavigationBar() {
  const dispatch = useDispatch();
  // @ts-ignore
  const maplibreGL: maplibregl.Map = useSelector(
    (state: RootState) => state.map?.maplibreGL
  );

  let zoomLevel: any = useSelector((state: RootState) => state.map?.zoomLevel);

  const [showCoordinateEditor, setShowCoordinateEditor] = useState(false);

  const pan2Coors = () => { };

  const zoom2Level = (scale: number) => {
    maplibreGL.setZoom(zoomLevel + scale);
    dispatch(changeZoomLevel(zoomLevel + scale));
  };

  // rotate bearing
  const rotate = (angle: number) => {
    maplibreGL.setPitch(0);
    //maplibreGL.setBearing(0);
    dispatch(
      changeCenter({ lng: 108.22717737212409, lat: 16.061300184261228 })
    );
    setTimeout(() => {
      maplibreGL.rotateTo(angle, { duration: 1000 });
      maplibreGL.flyTo({
        center: { lng: 108.22717737212409, lat: 16.061300184261228 },
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    }, 5);
  };

  const onClose = () => {
    setShowCoordinateEditor(false);
  };

  return (
    <ul className="flex gap-2">
      <li key={1}>
        {showCoordinateEditor && <CoordinateEditor onClose={onClose} />}
        <Button
          appearance="transparent"
          className="btn-action-drawer"
          onClick={() => setShowCoordinateEditor(true)}
          icon={
            <img
              className="position-svg-icon"
              src={positionSvg}
              alt="position"
            />
          }
        ></Button>
      </li>
      <li key={2}>
        <Button
          appearance="transparent"
          className="btn-action-drawer"
          onClick={() => zoom2Level(1)}
          icon={<img className="minus-svg-icon" src={plusSvg} alt="plus" />}
        ></Button>
      </li>
      <li key={3}>
        <Button
          appearance="transparent"
          className="btn-action-drawer"
          onClick={() => zoom2Level(-1)}
          icon={<img className="minus-svg-icon" src={minusSvg} alt="minus" />}
        ></Button>
      </li>
      <li key={4}>
        <Button
          appearance="transparent"
          className="btn-action-drawer"
          onClick={() => rotate(0)}
          icon={
            <img
              className="map-marker-svg-icon"
              src={mapMarkerSvg}
              alt="map-marker"
            />
          }
        ></Button>
      </li>
    </ul>
  );
}
