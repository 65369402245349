import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  applyAdminRoles,
  applyUserRoles,
  getInfor,
  getListUser,
  getMyRoles,
  getUserGroups,
  getUserRoles,
  setUserAsAdmin,
  setUserAsUser,
} from "./../services/ManageUser/ManageUser";

export interface ManageUserState {
  page: number;
  limit: number;
  total: number;
  danhSach: Array<any>;
  status: string;
  inforUser: any;
  roles: any;
  roleLogin: string;
  currentRole: string;
}

const initialState: ManageUserState = {
  page: 0,
  limit: 10,
  total: 0,
  danhSach: [],
  status: "loading",
  inforUser: {},
  roles: [],
  roleLogin: "", // admin or user when login
  currentRole: "",
};

export const getInforAsync = createAsyncThunk(
  "manageuser/getInfor",
  async () => {
    try {
      const response = await getInfor();
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getMyRolesAsync = createAsyncThunk(
  "manageuser/getMyRoles",
  async () => {
    try {
      const response = await getMyRoles();
      return response?.data;
    } catch (e) {
      return {};
    }
  }
);

export const getListUserAsync = createAsyncThunk(
  "manageuser/getListUser",
  async (payload) => {
    try {
      const response = await getListUser(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getUserGroupsAsync = createAsyncThunk(
  "manageuser/getUserGroups",
  async (id: string) => {
    try {
      const response = await getUserGroups(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getUserRolesAsync = createAsyncThunk(
  "manageuser/getUserRoles",
  async (id: string) => {
    try {
      const response = await getUserRoles(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const setUserAsAdminAsync = createAsyncThunk(
  "manageuser/setUserAsAdmin",
  async (id: string) => {
    try {
      const response = await setUserAsAdmin(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const setUserAsUserAsync = createAsyncThunk(
  "manageuser/setUserAsUser",
  async (id: string) => {
    try {
      const response = await setUserAsUser(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const applyAdminRolesAsync = createAsyncThunk(
  "manageuser/applyAdminRoles",
  async (id: string) => {
    try {
      const response = await applyAdminRoles(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const applyUserRolesAsync = createAsyncThunk(
  "manageuser/applyUserRoles",
  async (id: string) => {
    try {
      const response = await applyUserRoles(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const ManageUserSlice = createSlice({
  name: "manageuser",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      // handle change state

      state.danhSach = action?.payload;
    },
    changeRoleLogin: (state, action) => {
      state.roleLogin = action?.payload;
    },
    changeCurrentRole: (state, action) => {
      state.currentRole = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListUserAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.danhSach = action.payload?.result ?? [];
      })

      .addCase(getInforAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInforAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.inforUser = action.payload;
      })

      .addCase(getMyRolesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyRolesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.roles = action.payload;
      });
  },
});

export const { changeSate, changeRoleLogin, changeCurrentRole } =
  ManageUserSlice.actions;

export default ManageUserSlice.reducer;
