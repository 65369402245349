import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  acceptOrder,
  addItemOder,
  cancelOrder,
  cancelOrderAdmin,
  createOder,
  deleteItemInOrder,
  getByIdAdmin,
  getItemInOrder,
  getItemInOrderAdmin,
  getList,
  getListAdmin,
  getListUser,
  rejectOrder,
  submitOrder,
} from "../services/ManageOder/ManageOrderServices";

export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
}

var error: SerializedError = {};

export interface ManageOrderState {
  danhSach: Array<any>;
  statusSubmit: string;
  danhSachItemOrder: any;
  danhSachAdmin: Array<any>;
  danhSachItemOrderAdmin: Array<any>;
  start_at: undefined;
  end_at: undefined;
  idUser: any;
  textUser: string;
  sort_by: string;
  sort_order: string;
  stateStatus: string;
  stateStatusUser: string;
  total_cart: number;
  cartId: any;
  cartItems: any;
  currentRequestId: undefined;
  error: any;
  loading: string;
  // for map
  itemsMapSelected: Array<any>;

  flagFilter: boolean;
  flagSort: boolean;
  flagFresh: boolean;
}
const initialState: ManageOrderState & { [key: string]: any } = {
  danhSach: [],
  statusSubmit: "idle",
  danhSachItemOrder: [],
  danhSachAdmin: [],
  danhSachItemOrderAdmin: [],
  start_at: undefined,
  end_at: undefined,
  idUser: undefined,
  stateStatus: "Submit",

  stateStatusUser: "All",
  textUser: "-- Choose user --",
  sort_by: "modified",
  sort_order: "desc",
  total_cart: 0,
  cartId: "",
  cartItems: [],

  currentRequestId: undefined,
  error: error,
  loading: "idle",
  // for map
  itemsMapSelected: [],
  flagFilter: false,
  flagSort: false,
  flagFresh: false,
};

export const getByIdAdminAsync = createAsyncThunk(
  "manageorder/getByIdAdmin",
  async (params) => {
    let response;
    try {
      response = await getByIdAdmin(params);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListUserAsync = createAsyncThunk(
  "manageorder/getListUser",
  async (params) => {
    let response;
    try {
      response = await getListUser(params);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListAdminAsync = createAsyncThunk(
  "manageorder/getListAdmin",
  async (params) => {
    let response;
    try {
      response = await getListAdmin(params);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListAsync = createAsyncThunk(
  "manageorder/getList",
  async (params) => {
    let response;
    try {
      response = await getList(params);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getItemInOrderAsync = createAsyncThunk<
  null, // Returned,
  string,
  {
    state: {
      manageorder: { loading: string; currentRequestId: string };
      page: { name: string };
    };
  }
>("manageorder/getItemInOrder", async (id: any, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().manageorder;
  const { name } = getState().page;

  if (
    loading !== "pending" ||
    (requestId !== currentRequestId && name !== "invoiceItem")
  ) {
    return;
  }
  const response = await getItemInOrder(id);
  return response.data;
});

export const getItemInOrderAdminAsync = createAsyncThunk(
  "manageorder/getItemInOrderAdmin",
  async (id) => {
    let response;
    try {
      response = await getItemInOrderAdmin(id);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const acceptOrderAsync = createAsyncThunk(
  "manageorder/acceptOrder",
  async (payload) => {
    let response;
    try {
      response = await acceptOrder(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const rejectOrderAsync = createAsyncThunk(
  "manageorder/rejectOrder",
  async (payload) => {
    let response;
    try {
      response = await rejectOrder(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const cancelOrderAsync = createAsyncThunk(
  "manageorder/cancelOrder",
  async (payload) => {
    let response;
    try {
      response = await cancelOrder(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const cancelOrderAdminAsync = createAsyncThunk(
  "manageorder/cancelOrderAdmin",
  async (payload) => {
    let response;
    try {
      response = await cancelOrderAdmin(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

// item in collection action by user

export const createOderAsync = createAsyncThunk(
  "manageorder/createOder",
  async (params) => {
    try {
      const response = await createOder(params);
      // await (new Promise(resolve => setTimeout(resolve, 3000)))
      return response;
    } catch (e: any) {}
  }
);

export const addItemOderAsync = createAsyncThunk(
  "manageorder/addItemOder",
  async (payload) => {
    let response;
    try {
      response = await addItemOder(payload);
      return response;
    } catch (e: any) {
      return {
        message: e?.request?.responseText ?? "Add fail",
      };
    }
  }
);

export const deleteItemInOrderAsync = createAsyncThunk(
  "manageorder/deleteItemInOrder",
  async (payload) => {
    try {
      const response = await deleteItemInOrder(payload);

      // Doi api delete xong, ket qua thi cha ve ngay nhung tren server van dang xoa
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e: any) {
      return {
        message: e?.request?.responseText,
      };
    }
  }
);

export const submitOrderAsync = createAsyncThunk(
  "manageorder/submitOrder",
  async (payload) => {
    const response = await submitOrder(payload);

    return response;
  }
);

export const ManageOrderSlice = createSlice({
  name: "manageorder",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      // handle change state
    },

    changeStateFilter: (state, action) => {
      state.start_at = undefined;
      state.end_at = undefined;
      state.idUser = undefined;
      state.textUser = "-- Choose user --";
      state.stateStatus = "Submit";

      state.sort_by = "modified";
      state.sort_order = "desc";
    },

    resetStateFilter: (state, action) => {
      // in admin
      state.start_at = undefined;
      state.end_at = undefined;
      state.idUser = undefined;
      state.textUser = "-- Choose user --";
      state.stateStatus = "Submit";
    },

    resetStateFilterUser: (state, action) => {
      // in admin
      state.start_at = undefined;
      state.end_at = undefined;
      state.idUser = undefined;
      state.textUser = "-- Choose user --";
      state.stateStatusUser = "All";
    },

    resetStateSort: (state, action) => {
      state.sort_by = "modified";
      state.sort_order = "desc";
    },

    resetStateOrderAdmin: (state, action) => {
      // in admin
      state.start_at = undefined;
      state.end_at = undefined;
      state.idUser = undefined;
      state.textUser = "-- Choose user --";
      state.stateStatus = "Submit";

      state.sort_by = "modified";
      state.sort_order = "desc";
    },
    changeStateStatusUser: (state, action) => {
      state.stateStatusUser = action.payload;
    },

    changeStateStatus: (state, action) => {
      state.stateStatus = action.payload;
    },

    changeStartAt: (state, action) => {
      state.start_at = action.payload;
    },

    changeEndAt: (state, action) => {
      state.end_at = action.payload;
    },

    changeUser: (state, action) => {
      if (action.payload) {
        let a =
          action.payload?.traits?.name?.first +
          action.payload?.traits?.name?.last;
        state.idUser = action.payload?.id;
        state.textUser = a;
      } else {
        state.idUser = undefined;
        state.textUser = "-- Choose user --";
      }
    },

    changeSortBy: (state, action) => {
      state.sort_by = action.payload;
    },

    changeSortOrder: (state, action) => {
      state.sort_order = action.payload;
    },

    changeTotalCart: (state, action) => {
      state.total_cart = action.payload;
    },

    changeCartId: (state, action) => {
      state.cartId = action.payload;
    },

    changeCartItems: (state, action) => {
      state.cartItems = action.payload;
    },

    changeItemsMapSelected: (state, action) => {
      state.itemsMapSelected = action?.payload;
    },

    clearItemsMapSelected: (state) => {
      state.itemsMapSelected = [];
    },
    changeFlagFilter: (state, action) => {
      state.flagFilter = action?.payload;
    },
    changeFlagSort: (state, action) => {
      state.flagSort = action?.payload;
    },
    changeFlagFresh: (state, action) => {
      state.flagFresh = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.danhSach = action.payload?.result ?? [];
          state.currentRequestId = undefined;
        }
      })
      .addCase(getListAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      // get list admin
      .addCase(getListAdminAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getListAdminAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.danhSachAdmin = action.payload?.result ?? [];
          state.currentRequestId = undefined;
        }
      })
      .addCase(getListAdminAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      //accept
      .addCase(acceptOrderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(acceptOrderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(acceptOrderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // reject
      .addCase(rejectOrderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(rejectOrderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(rejectOrderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // cancel
      .addCase(cancelOrderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(cancelOrderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(cancelOrderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // cancel admin
      .addCase(cancelOrderAdminAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(cancelOrderAdminAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(cancelOrderAdminAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // create order

      .addCase(createOderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createOderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(createOderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      // get item in order by id order admin

      .addCase(getItemInOrderAdminAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getItemInOrderAdminAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.danhSachItemOrderAdmin = action.payload ?? [];
          state.currentRequestId = undefined;
        }
      })
      .addCase(getItemInOrderAdminAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      // get item in order by id order

      .addCase(getItemInOrderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action?.meta?.requestId;
        }
      })
      .addCase(getItemInOrderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
          // @ts-ignore
          state.danhSachItemOrder = action?.payload?.result?.[0]?.items ?? [];
          // @ts-ignore
          // state.cartItems = action?.payload?.result?.[0]?.items ?? [];
          // @ts-ignore
          // state.total_cart = action?.payload?.result?.[0]?.items?.length || 0;
        }
      })
      .addCase(getItemInOrderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      // add item into order

      .addCase(addItemOderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(addItemOderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(addItemOderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // delet item in order by id order

      .addCase(deleteItemInOrderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteItemInOrderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteItemInOrderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // submit order by id order

      .addCase(submitOrderAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(submitOrderAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(submitOrderAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {
  changeSate,
  changeStateFilter,
  changeStartAt,
  changeEndAt,
  changeUser,
  changeSortBy,
  changeSortOrder,
  changeTotalCart,
  changeCartId,
  changeItemsMapSelected,
  clearItemsMapSelected,
  changeCartItems,
  changeFlagFilter,
  changeFlagSort,
  changeStateStatus,
  changeFlagFresh,
  resetStateFilter,
  resetStateSort,
  resetStateFilterUser,
  changeStateStatusUser,
  resetStateOrderAdmin,
} = ManageOrderSlice.actions;

export default ManageOrderSlice.reducer;
