import React, { useEffect } from "react";
import FormEditQuotaComp from "../../components/ManageQuota/FormEdit";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const EditedQuotaPage = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dataBread = [
    {
      key: 1,
      value: "Quotas",
      path: "manage-quota",
    },
    {
      key: 2,
      value: `Edit ${location?.state?.item?.name}`,
      path: "edit",
    },
  ];

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);
  useEffect(() => {
    dispatch(changePath("/manage-quota-edit"));
    dispatch(changeName("manage-quota-edit"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));
    };
  });

  return <FormEditQuotaComp itemEdit={location?.state?.item} />;
};
export default EditedQuotaPage;
