import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getList,
  add,
  addExpire,
  deleteQuota,
  getExpireQuota,
  updateQuota,
  deleteQuotaExpire,
  getUserQuota,
  addUserQuota,
  delUserQuota,
  getQuotaTye,
  getTyeUnit,
  getExpireTypes,
  delExpire,
  getMyQuotas,
  getUserQuotas,
} from "../services/ManageQuota/ManageQuotaServices";

export interface ManageQuotaState {
  status: string;
  danhSach: Array<any>;
  statusScan: string;
  danhSachHistoryScan: Array<any>;
  type_quota: string;
  start_at: any;
  end_at: any;
  sort_by: string;
  sort_order: string;
  flagFilter: boolean;
  flagSort: boolean;
  flagFresh: boolean;
  keyword?: any;

  itemUserQuota?: any

}
const initialState: ManageQuotaState = {
  status: "ilde",
  danhSach: [],
  statusScan: "",
  danhSachHistoryScan: [],
  type_quota: "All",
  start_at: null,
  end_at: null,
  sort_by: "modified",
  sort_order: "desc",
  keyword: "",

  flagFilter: false,
  flagSort: false,
  flagFresh: false,
  itemUserQuota: {}
};

export const getUserQuotasAsync = createAsyncThunk(
  "ManageQuota/getUserQuotas",
  async (idUser: string) => {
    try {
      const response = await getUserQuotas(idUser);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getMyQuotasAsync = createAsyncThunk(
  "ManageQuota/getMyQuotas",
  async () => {
    try {
      const response = await getMyQuotas();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListAsync = createAsyncThunk(
  "ManageQuota/getList",
  async (payload) => {
    try {
      const response = await getList(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const addAsync = createAsyncThunk("ManageQuota/add", async (payload) => {
  try {
    const response = await add(payload);
    return response;
  } catch (e) {
    return [];
  }
});

export const addExpireAsync = createAsyncThunk(
  "ManageQuota/addExpire",
  async (payExpire: any) => {
    try {
      const response = await addExpire(payExpire);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const delExpireAsync = createAsyncThunk(
  "ManageQuota/delExpire",
  async (payExpire: any) => {
    try {
      const response = await delExpire(payExpire);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const deleteQuotaAsync = createAsyncThunk(
  "ManageQuota/deleteQuota",
  async (payExpire: any) => {
    try {
      const response = await deleteQuota(payExpire);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const getExpireQuotaAsync = createAsyncThunk(
  "ManageQuota/getExpireQuota",
  async (id) => {
    try {
      const response = await getExpireQuota(id);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const updateQuotaAsync = createAsyncThunk(
  "ManageQuota/updateQuota",
  async (payload) => {
    try {
      const response = await updateQuota(payload);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const deleteQuotaExpireAsync = createAsyncThunk(
  "ManageQuota/deleteQuotaExpire",
  async (id: any) => {
    try {
      const response = await deleteQuotaExpire(id);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const getUserQuotaAsync = createAsyncThunk(
  "ManageQuota/getUserQuata",
  async (userId: string) => {
    try {
      const response = await getUserQuota(userId);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const addUserQuotaAsync = createAsyncThunk(
  "ManageQuota/addUserQuota",
  // @ts-ignore
  async (payload: any) => {
    try {
      const response = await addUserQuota({
        userId: payload?.userId,
        quotaId: payload?.quotaId,
      });
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const delUserQuotaAsync = createAsyncThunk(
  "ManageQuota/delUserQuota",
  // @ts-ignore
  async (payload: any) => {
    try {
      const response = await delUserQuota({
        userId: payload?.userId,
        quotaId: payload?.quotaId,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const getQuotaTyeAsync = createAsyncThunk(
  "ManageQuota/getQuotaTye",
  async () => {
    try {
      const response = await getQuotaTye();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getTyeUnitAsync = createAsyncThunk(
  "ManageQuota/getTyeUnit",
  async () => {
    try {
      const response = await getTyeUnit();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getExpireTypesAsync = createAsyncThunk(
  "ManageQuota/getExpireTypes",
  async () => {
    try {
      const response = await getExpireTypes();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const ManageQuotaSlice = createSlice({
  name: "ManageQuota",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      // handle change state
    },

    changeStateFilter: (state, action) => {
      state.start_at = undefined;
      state.end_at = undefined;
      state.type_quota = "All";
      state.keyword = "";
      state.sort_by = "modified";
      state.sort_order = "desc";
    },

    resetStateFilter: (state, action) => {
      state.start_at = undefined;
      state.end_at = undefined;
      state.type_quota = "All";
      state.keyword = "";
    },

    resetStateSort: (state, action) => {
      state.sort_by = "modified";
      state.sort_order = "desc";
    },
    changeStateFilterDrawer: (state, action) => {
      state.start_at = undefined;
      state.end_at = undefined;
      state.type_quota = "All";
      state.keyword = "";
    },
    changeKeyword: (state, action) => {
      state.keyword = action.payload;
    },

    changeStartAt: (state, action) => {
      state.start_at = action.payload;
    },

    changeEndAt: (state, action) => {
      state.end_at = action.payload;
    },

    changeTypeQuota: (state, action) => {
      state.type_quota = action.payload;
    },

    changeSortBy: (state, action) => {
      state.sort_by = action.payload;
    },

    changeSortOrder: (state, action) => {
      state.sort_order = action.payload;
    },
    changeFlagFilter: (state, action) => {
      state.flagFilter = action?.payload;
    },
    changeFlagSort: (state, action) => {
      state.flagSort = action?.payload;
    },
    changeFlagFresh: (state, action) => {
      state.flagFresh = action?.payload;
    },
    changeItemUserQuota: (state, action) => {
      state.itemUserQuota = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.danhSach = action.payload?.result ?? [];
      })
      // add
      .addCase(addAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      // add expires
      .addCase(addExpireAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addExpireAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // delete quota
      .addCase(deleteQuotaAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteQuotaAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      // delete quota
      .addCase(getExpireQuotaAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getExpireQuotaAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // update quota
      .addCase(updateQuotaAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQuotaAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // delete quota expire
      .addCase(deleteQuotaExpireAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteQuotaExpireAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // get User Quota
      .addCase(getUserQuotaAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserQuotaAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // add User Quota
      .addCase(addUserQuotaAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUserQuotaAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // del User Quota
      .addCase(delUserQuotaAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(delUserQuotaAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const {
  changeSate,
  changeEndAt,
  changeStartAt,
  changeStateFilter,
  changeTypeQuota,
  changeSortBy,
  changeSortOrder,
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  resetStateFilter,
  resetStateSort,
  changeStateFilterDrawer,
  changeKeyword,
  changeItemUserQuota,
} = ManageQuotaSlice.actions;

export default ManageQuotaSlice.reducer;
