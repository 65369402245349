import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardInvoiceComp from "../components/Invoice/CardInvoice";
import LoadingGlobalComp from "../components/Loading/LoadingGlobal";
import LoadingListComp from "../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../store/DrawerSlice";
import { changeVisibleSpinner } from "../store/NavSlice";
import { changeName, changePath } from "../store/pageSlice";
import { RootState } from "../store/store";
import EmptySvg from "./../assets/nodata.svg";
import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeStateFilter,
  getListAsync,
} from "./../store/ManageOrderSlice";
import { changePage, changeTotal, resetState } from "./../store/pagingSlice";

const InvoicePage = () => {
  const dispatch = useDispatch();

  const { page, limit } = useSelector((state: RootState) => state.paging);

  const {
    start_at,
    end_at,
    idUser,
    sort_order,
    stateStatusUser,
    flagFilter,
    flagSort,
    flagFresh,
  } = useSelector((state: RootState) => state.manageorder);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(changePath("/invoice"));
    dispatch(changeName("invoice"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    (async function () {
      if (!flagFilter || !flagFresh || !flagSort)
        try {
          getData({ page: page });
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilter)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFilter(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagSort(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFresh(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagFresh]);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
      if (!window.location.pathname?.includes('invoice'))
      {
        dispatch(changeStateFilter({}));
      }
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "My order",
          path: "invoice",
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  const getData = async (props: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      getListAsync({
        size: limit,
        sortby: "modified",
        asc: sort_order === "asc" ? true : false,
        page: props?.page ? props?.page : page,

        filter: {
          created_from: start_at,
          created_to: end_at
            ? moment(end_at).endOf("days").toISOString()
            : null,
          user_id: idUser,
          states:
            stateStatusUser !== "All"
              ? [stateStatusUser]
              : ["Accept", "Submit", "Reject", "Cancel"],
        },
      })
    ).unwrap();

    dispatch(changeTotal(res?.paging?.total ?? 0));

    let sun: any = [];
    sun = res?.result?.filter(
      (item: any) =>
        item?.states?.[item?.states?.length - 1]?.state !== "Create"
    );
    setData(sun ?? []);
    dispatch(changeVisibleSpinner(false));
    setLoading(false);
  };
  return (
    <>
      <div className="px-6 py-6 !w-full !relative">
        {loading && <LoadingListComp />}

        {!loading && visibleSpinner && <LoadingGlobalComp />}

        {data?.length === 0 && !loading && (
          <center className="mt-3">
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}

        {data?.length !== 0 && !loading && (
          <div className="div-list">
            <CardInvoiceComp
              data={data}
              getData={getData}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default InvoicePage;
