import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../store/store";
import { useTranslation } from "react-i18next";

import { convertCoorProjections } from "../../../../utils/map/CoordinatesUtils";

export default function MouseCoordinates() {

    const { t } = useTranslation();

    let mousePointer: any = useSelector((state: RootState) => state.map?.mousePointer?.lnglat);
    let crs: any = useSelector((state: RootState) => state.map?.crs);

    const [coordinates, setCoordinates] = useState(mousePointer);

    useEffect(() => {
        if (crs == 'EPSG:4326')
            setCoordinates({ lng: mousePointer?.lng, lat: mousePointer?.lat });
        else setCoordinates(convertCoordinate(mousePointer));
    }, [mousePointer]);



    const convertCoordinate = (coordinate: any) => {
        if (!coordinate) return;
        return convertCoorProjections("EPSG:4326", crs, { lng: coordinate.lng, lat: coordinate.lat });
    }

    return (
        <>
            {coordinates && coordinates.lng && coordinates.lat && <span className='flex'>
                <div className='w-28 text-left'> {t('lng')}:&nbsp;{(coordinates?.lng).toFixed(6)}</div >
                <div className='w-[6.5rem] text-left'>{t('lat')}:&nbsp;{(coordinates?.lat).toFixed(6)}</div>
            </span>
            }
        </>
    );
}