import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getTaskType,
  getListTask,
  createTask,
  getDay,
  getFreqType,
  getRepeatType,
  getTypeDay,
  getDayRelative,
  startTask,
  stopTask,
  deleteTask,
  updateTask,
  getListJob,
  getTaskJobs,
} from "../services/ManageScheduler/TaskServices";

export interface ManageTaskState {
  sort_by_task: string;
  sort_order_task: string;

  flagFilter: boolean;
  flagSort: boolean;
  flagFresh: boolean;

  flagFilterJob: boolean;
  flagSortJob: boolean;
  flagFreshJob: boolean;

  sort_by_job: string;
  sort_order_job: string;

  selectedTimeFrom: any;
  selectedDateFrom: any;
  timePickerValueFrom: any;
  selectedTimeTo: any;
  selectedDateTo: any;
  timePickerValueTo: any;
  keywordFilter?: any;
  taskType?: any;
  enable?: any;
  oneTimeOnly?: any;

  timePickerValueScheduledJobFrom?: any;
  selectedTimeScheduledJobFrom?: any;
  selectedDateScheduledJobFrom?: any;
  timePickerValueScheduledJobTo?: any;
  selectedTimeScheduledJobTo?: any;
  selectedDateScheduledJobTo?: any;
}
const initialState: ManageTaskState = {
  sort_by_task: "modified",
  sort_order_task: "desc",

  flagFilter: false,
  flagSort: false,
  flagFresh: false,

  sort_by_job: "scheduled",
  sort_order_job: "desc",

  selectedTimeFrom: null,
  selectedDateFrom: null,
  timePickerValueFrom: "",
  selectedTimeTo: null,
  selectedDateTo: null,
  timePickerValueTo: "",
  keywordFilter: "",
  taskType: [
    "SCAN_COLLECTION",
    "CLEAN_EXPIRED_DLSESSION_DATA",
    "EXPIRE_SCAN_SESSIONS",
  ],
  enable: null,
  oneTimeOnly: null,

  flagFilterJob: false,
  flagSortJob: false,
  flagFreshJob: false,

  timePickerValueScheduledJobFrom: null,
  selectedTimeScheduledJobFrom: null,
  selectedDateScheduledJobFrom: null,
  timePickerValueScheduledJobTo: null,
  selectedTimeScheduledJobTo: null,
  selectedDateScheduledJobTo: null,
};

export const getDayAsync = createAsyncThunk(
  "ManageScheduer/getDay",
  async () => {
    try {
      const response = await getDay();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getTaskJobsAsync = createAsyncThunk(
  "ManageScheduer/getTaskJobs",
  async (payload) => {
    try {
      const response = await getTaskJobs(payload);

      return response?.data;
    } catch (e) {
      return {};
    }
  }
);

export const getDayRelativeAsync = createAsyncThunk(
  "ManageScheduer/getDayRelative",
  async () => {
    try {
      const response = await getDayRelative();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getFreqTypeAsync = createAsyncThunk(
  "ManageScheduer/getFreqType",
  async () => {
    try {
      const response = await getFreqType();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getRepeatTypeAsync = createAsyncThunk(
  "ManageScheduer/getRepeatType",
  async () => {
    try {
      const response = await getRepeatType();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getTypeDayAsync = createAsyncThunk(
  "ManageScheduer/getTypeDay",
  async () => {
    try {
      const response = await getTypeDay();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getTaskTypeAsync = createAsyncThunk(
  "ManageScheduer/getTaskType",
  async () => {
    try {
      const response = await getTaskType();

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListTaskAsync = createAsyncThunk(
  "ManageScheduer/getListTask",
  async (payload) => {
    try {
      const response = await getListTask(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListJobAsync = createAsyncThunk(
  "ManageScheduer/getListJob",
  async (payload) => {
    try {
      const response = await getListJob(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const updateTaskAsync = createAsyncThunk(
  "ManageScheduer/updateTask",
  async (payload) => {
    try {
      const response = await updateTask(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const createTaskAsync = createAsyncThunk(
  "ManageScheduer/createTask",
  async (payload) => {
    try {
      const response = await createTask(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const startTaskAsync = createAsyncThunk(
  "ManageScheduer/startTask",
  async (payload) => {
    try {
      const response = await startTask(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const stopTaskAsync = createAsyncThunk(
  "ManageScheduer/stopTask",
  async (payload) => {
    try {
      const response = await stopTask(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const deleteTaskAsync = createAsyncThunk(
  "ManageScheduer/deleteTask",
  async (id) => {
    try {
      const response = await deleteTask(id);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e: any) {
      return e?.response;
    }
  }
);
export const ManageScheduerSlice = createSlice({
  name: "ManageScheduer",
  initialState: initialState,
  reducers: {
    changeSelectedTimeFrom: (state, action) => {
      state.selectedTimeFrom = action.payload;
    },
    changeSelectedTimeTo: (state, action) => {
      state.selectedTimeTo = action.payload;
    },

    changeSelectedDateFrom: (state, action) => {
      state.selectedDateFrom = action.payload;
    },
    changeSelectedDateTo: (state, action) => {
      state.selectedDateTo = action.payload;
    },

    changeTimePickerValueFrom: (state, action) => {
      state.timePickerValueFrom = action.payload;
    },
    changeTimePickerValueTo: (state, action) => {
      state.timePickerValueTo = action.payload;
    },

    changeKeywordFilter: (state, action) => {
      state.keywordFilter = action.payload;
    },

    changeTaskType: (state, action) => {
      state.taskType = action.payload;
    },

    changeEnable: (state, action) => {
      state.enable = action.payload;
    },

    changeOneTimeOnly: (state, action) => {
      state.oneTimeOnly = action.payload;
    },

    resetStateFilterTask: (state, action) => {
      state.selectedTimeFrom = null;
      state.selectedDateFrom = null;
      state.timePickerValueFrom = "";
      state.selectedTimeTo = null;
      state.selectedDateTo = null;
      state.timePickerValueTo = "";
      state.keywordFilter = "";
      state.taskType = [
        "SCAN_COLLECTION",
        "CLEAN_EXPIRED_DLSESSION_DATA",
        "EXPIRE_SCAN_SESSIONS",
      ];
      state.enable = null;
      state.oneTimeOnly = null;
    },

    resetStateFilterJob: (state, action) => {
      state.timePickerValueScheduledJobFrom = "";
      state.selectedTimeScheduledJobFrom = null;
      state.selectedDateScheduledJobFrom = null;
      state.timePickerValueScheduledJobTo = "";
      state.selectedTimeScheduledJobTo = null;
      state.selectedDateScheduledJobTo = null;
      state.taskType = [
        "SCAN_COLLECTION",
        "CLEAN_EXPIRED_DLSESSION_DATA",
        "EXPIRE_SCAN_SESSIONS",
      ];
    },

    resetStateSortTask: (state, action) => {
      state.sort_by_task = "modified";
      state.sort_order_task = "desc";
    },

    resetStateSortJob: (state, action) => {
      state.sort_by_job = "scheduled";
      state.sort_order_job = "desc";
    },
    changeSortByTask: (state, action) => {
      state.sort_by_task = action.payload;
    },
    changeSortOrderTask: (state, action) => {
      state.sort_order_task = action.payload;
    },

    changeSortByJob: (state, action) => {
      state.sort_by_job = action.payload;
    },
    changeSortOrderJob: (state, action) => {
      state.sort_order_job = action.payload;
    },

    changeFlagFilter: (state, action) => {
      state.flagFilter = action?.payload;
    },
    changeFlagSort: (state, action) => {
      state.flagSort = action?.payload;
    },
    changeFlagFresh: (state, action) => {
      state.flagFresh = action?.payload;
    },

    changeFlagFilterJob: (state, action) => {
      state.flagFilterJob = action?.payload;
    },
    changeFlagSortJob: (state, action) => {
      state.flagSortJob = action?.payload;
    },
    changeFlagFreshJob: (state, action) => {
      state.flagFreshJob = action?.payload;
    },

    changeSelectedTimeScheduledJobFrom: (state, action) => {
      state.selectedTimeScheduledJobFrom = action?.payload;
    },

    changeTimePickerValueScheduledJobFrom: (state, action) => {
      state.timePickerValueScheduledJobFrom = action?.payload;
    },

    changeSelectedTimeScheduledJobTo: (state, action) => {
      state.selectedTimeScheduledJobTo = action?.payload;
    },

    changeTimePickerValueScheduledJobTo: (state, action) => {
      state.timePickerValueScheduledJobTo = action?.payload;
    },

    changeSelectedDateScheduledJobTo: (state, action) => {
      state.selectedDateScheduledJobTo = action?.payload;
    },

    changeSelectedDateScheduledJobFrom: (state, action) => {
      state.selectedDateScheduledJobFrom = action?.payload;
    },
  },
});

export const {
  resetStateFilterJob,
  changeSelectedDateScheduledJobFrom,
  changeSelectedDateScheduledJobTo,
  changeSelectedTimeScheduledJobFrom,
  changeTimePickerValueScheduledJobFrom,
  changeSelectedTimeScheduledJobTo,
  changeTimePickerValueScheduledJobTo,
  changeFlagFilterJob,
  changeFlagSortJob,
  changeFlagFreshJob,
  resetStateSortTask,
  changeSortByTask,
  changeSortOrderTask,
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeSelectedDateFrom,
  changeSelectedDateTo,
  changeSelectedTimeFrom,
  changeSelectedTimeTo,
  changeTimePickerValueFrom,
  changeTimePickerValueTo,
  changeKeywordFilter,
  changeTaskType,
  changeEnable,
  changeOneTimeOnly,
  resetStateFilterTask,
  changeSortByJob,
  changeSortOrderJob,
  resetStateSortJob,
} = ManageScheduerSlice.actions;

export default ManageScheduerSlice.reducer;
