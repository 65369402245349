import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import { useEffect, useState } from "react";

import CartItemBlackSvg from "./../../assets/cart-item-black.svg";
import InfoCircle from "./../../assets/info-circle.svg";
import Map from "./../../assets/map.svg";

import { useSelector } from "react-redux";
import { RootState } from "./../../store/store";

import { useParams } from "react-router-dom";
// import { ip } from "../../services/ip";

import useCustomNavigate from "../../hook/useCustomNavigate";
import LayerSvg from "./../../assets/images/item-collection-default.png";

const CardItem = (props: any) => {
  let { id } = useParams();

  const { cartItems, total_cart } = useSelector(
    (state: RootState) => state.manageorder
  );

  const [ip, setIp] = useState<any>("");

  const [arrDataConvert, setArrDataConvert] = useState([]);

  const navigate = useCustomNavigate();

  const handleCheck = async () => {
    // tai vi item co id giong nhau phai check ca collection cuar no
    let a: any = [];
    props?.data?.map((itemProps: any) => {
      let flag = false;
      cartItems?.map((itemCart: any) => {
        if (
          itemCart?.stac_item_id === itemProps?.id &&
          itemCart?.collection_id === itemProps?.collection
        ) {
          flag = true;
        }
      });

      a?.push({
        ...itemProps,
        checkInCart: flag,
      });
    });

    setIp(localStorage.getItem("REACT_APP_ENDPOINT"));

    setArrDataConvert(a);
  };

  useEffect(() => {
    handleCheck();
  }, [total_cart]);

  useEffect(() => {
    handleCheck();
  }, [props?.data]);

  return (
    <>
      {arrDataConvert?.map((item: any) => (
        <div
          className="div-card"
          key={item.id}
          onClick={async () => {
            props?.ViewInforItem(item);
          }}
        >
          <Card
            orientation="horizontal"
            className="card-item-storage !bg-[#2E3240]"
          >
            <CardPreview
              className="div-center div-card-preview"
              onClick={async () => {
                props?.ViewInforItem(item);
              }}
            >
              <img
                loading="lazy"
                className="rounded-sm img-card-item-catalog-search-drawer"
                src={
                  item?.thumbnail?.[0]
                    ? `${ip}/collections/${item?.collection}/items/${item?.id}/thumbnails/${item?.thumbnail?.[0]}/`
                    : LayerSvg
                }
                alt="thumbnail"
                onError={(e: any) => {
                  e.target.src = LayerSvg;
                }}
              />
            </CardPreview>
            <CardHeader
              className="w-auto div-card-header"
              header={
                <div className="divHeader relative">
                  <div className="block w-full">
                    <Tooltip
                      relationship="description"
                      content={item?.properties?.title}
                    >
                      <p className="!uppercase text-title-card ellipsis-text-one-line">
                        {item?.properties?.title}
                      </p>
                    </Tooltip>

                    <p className="mt-1 text-des ellipsis-text-one-line">
                      Cloud cover: {item?.properties?.["eo:cloud_cover"]}{" "}
                      <span className="ml-2">GSD: {item?.properties?.gsd}</span>
                    </p>
                  </div>
                </div>
              }
              onClick={async () => {
                props?.ViewInforItem(item);
              }}
            />
            <CardFooter className="footer-card-action">
              <div
                className="div-action-card"
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  className="btn-action-card"
                  size="medium"
                  iconPosition="before"
                  onClick={async () => {
                    props?.ViewInforItem(item);
                  }}
                  icon={
                    <img
                      className="img-svg-icon"
                      src={InfoCircle}
                      alt="Info Circle"
                    />
                  }
                />
                {item?.type === "Feature" ? (
                  <Button
                    className="btn-action-card"
                    size="medium"
                    iconPosition="before"
                    onClick={(e: any) => {
                      e.preventDefault();
                      navigate(`/dataset/${id}/${item?.id}/map`);
                    }}
                    icon={<img className="img-svg-icon" src={Map} alt="Map" />}
                  />
                ) : (
                  ""
                )}

                <Button
                  className={
                    "w-full " +
                    (item?.checkInCart
                      ? "!bg-primary-500 !border-none"
                      : "!bg-[none] !border-none")
                  }
                  size="medium"
                  iconPosition="before"
                  onClick={async (e: any) => {
                    e.preventDefault();
                    item?.checkInCart
                      ? props?.handleDelete(item)
                      : props?.handleAddIntoCart(item);
                  }}
                  icon={
                    <img
                      className="img-svg-icon"
                      src={CartItemBlackSvg}
                      alt="Delete"
                    />
                  }
                />
              </div>
            </CardFooter>
          </Card>
        </div>
      ))}
    </>
  );
};

export default CardItem;
