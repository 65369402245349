import { Button, Divider } from "@fluentui/react-components";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
} from "@fluentui/react-components/unstable";
import DismissSvg from "./../../assets/Dismiss.svg";

export default function DrawerComponent(props: any) {
  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    if (typeof props.onScroll !== "undefined") {
      props.onScroll(e);
    }
  };

  return (
    <Drawer
      className={
        "!bg-s-background1 drawer-cus " +
        (props?.showDrawer
          ? " !w-[504px] !min-w-[504px] "
          : " !w-[0px] !min-w-[0px] ") +
        props?.className
      }
      modalType={props?.modalType}
      type="inline"
      separator
      open={true}
      // open={props?.showDrawer}
      onOpenChange={props?.toggleDrawer}
      position={props?.position}
      style={{
        ...props?.style,
      }}
    >
      <DrawerHeader className="!bg-s-background1 !bg-none !px-6 !pt-3  z-10">
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<img src={DismissSvg} alt="Dismiss" />}
              onClick={props?.toggleDrawer}
              className="btn-action-drawer"
            />
          }
        >
          {props?.header}
        </DrawerHeaderTitle>
        <Divider></Divider>
      </DrawerHeader>

      <DrawerBody
        className="!bg-s-background1 !bg-none div-drawer-body"
        onScroll={(e) => onScroll(e)}
      >
        {props?.children}
      </DrawerBody>

      {props?.componentsFooter && (
        <DrawerFooter>{props?.componentsFooter}</DrawerFooter>
      )}
    </Drawer>
  );

  // return props?.showDrawer ? (
  //   <Drawer
  //     className={
  //       "!bg-s-background1 drawer-cus " +
  //       // (props?.width ? props?.width : " !w-[35rem] ") +
  //       props?.className
  //     }
  //     modalType={props?.modalType}
  //     type="inline"
  //     separator
  //     open={props?.showDrawer}
  //     onOpenChange={props?.toggleDrawer}
  //     position={props?.position}
  //     style={{
  //       ...props?.style,
  //     }}
  //   >
  //     <DrawerHeader className="!bg-s-background1!bg-none !px-6 !pt-3  z-10">
  //       <DrawerHeaderTitle
  //         action={
  //           <Button
  //             appearance="subtle"
  //             aria-label="Close"
  //             icon={<img src={DismissSvg} alt="Dismiss" />}
  //             onClick={props?.toggleDrawer}
  //             className="btn-action-drawer"
  //           />
  //         }
  //       >
  //         {props?.header}
  //       </DrawerHeaderTitle>
  //       <Divider></Divider>
  //     </DrawerHeader>

  //     <DrawerBody
  //       className="!bg-s-background1 !bg-none div-drawer-body"
  //       onScroll={(e) => onScroll(e)}
  //     >
  //       {props?.children}
  //     </DrawerBody>

  //     {props?.componentsFooter && (
  //       <DrawerFooter>{props?.componentsFooter}</DrawerFooter>
  //     )}
  //   </Drawer>
  // ) : null;
}
