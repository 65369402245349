import React, { useEffect, useState } from "react";
import LoadingListComp from "../../components/Loading/LoadingList";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import EmptySvg from "./../../assets/nodata.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  getListUserAsync,
  getUserGroupsAsync,
  getUserRolesAsync,
} from "../../store/ManageUserSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import CardItemUserComp from "../../components/ManageUser/CardItemUser";
import { changeName, changePath } from "../../store/pageSlice";
import { changeTotal, resetState } from "../../store/pagingSlice";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const ListUserPage = () => {
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state: RootState) => state.paging);
  const [data, setData] = useState([]); // data da duocc phan trang
  const [allData, setAllData] = useState([]); // ta ca data de phan trang
  const [loading, setLoading] = useState(true);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const { inforUser } = useSelector((state: RootState) => state.manageuser);

  const dataBread = [
    {
      key: 1,
      value: "Manage user",
      path: "manage-user",
    },
  ];

  useEffect(() => {
    dispatch(changePath("/manage-user"));
    dispatch(changeName("manage-user"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      // dispatch(resetState());
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListUserAsync()
        ).unwrap();

        const arrUserNotMe = res?.filter(
          (item: any) => item?.id !== inforUser?.data?.id
        );
        let arrUserWithGroupRole: any = [];
        for (let i = 0; i < arrUserNotMe?.length; i++) {
          let groups = await dispatch(
            // @ts-ignore
            getUserGroupsAsync(arrUserNotMe[i]?.id)
          ).unwrap();
          let roles = await dispatch(
            // @ts-ignore
            getUserRolesAsync(arrUserNotMe[i]?.id)
          ).unwrap();

          arrUserWithGroupRole.push({
            ...arrUserNotMe?.[i],
            groups: groups?.data ?? [],
            roles: roles?.data ?? [],
            index: i,
          });
        }

        let arrUserPaging = arrUserWithGroupRole?.filter(
          (item: any) =>
            page * limit <= item.index && item.index < (page + 1) * limit
        );

        setData(arrUserPaging);
        setAllData(arrUserWithGroupRole);

        dispatch(changeTotal(arrUserWithGroupRole?.length));

        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListUserAsync()
        ).unwrap();

        const arrUserNotMe = res?.filter(
          (item: any) => item?.id !== inforUser?.data?.id
        );
        let arrUserWithGroupRole: any = [];
        for (let i = 0; i < arrUserNotMe?.length; i++) {
          let groups = await dispatch(
            // @ts-ignore
            getUserGroupsAsync(arrUserNotMe[i]?.id)
          ).unwrap();
          let roles = await dispatch(
            // @ts-ignore
            getUserRolesAsync(arrUserNotMe[i]?.id)
          ).unwrap();

          arrUserWithGroupRole.push({
            ...arrUserNotMe?.[i],
            groups: groups?.data ?? [],
            roles: roles?.data ?? [],
            index: i,
          });
        }

        let arrUserPaging = arrUserWithGroupRole?.filter(
          (item: any) =>
            page * limit <= item.index && item.index < (page + 1) * limit
        );

        setData(arrUserPaging);
        setAllData(arrUserWithGroupRole);

        dispatch(changeTotal(arrUserWithGroupRole?.length));

        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      }
    })();
  }, [page]);
  return (
    <>
      <div className="px-6 py-6 !w-full div-list relative">
        {loading && <LoadingListComp />}
        {visibleSpinner && !loading && <LoadingGlobalComp />}
        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div className="!w-full">
            <CardItemUserComp data={data} />
          </div>
        )}
      </div>
    </>
  );
};

export default ListUserPage;
