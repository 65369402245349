import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import DeleteSvg from "./../../assets/delete-dark.svg";
import EditSvg from "./../../assets/edit-dark.svg";

import LayerSvg from "./../../assets/Layer_1.svg";
import SettingSvg from "./../../assets/setting-storage.svg";
import ScanSvg from "./../../assets/auto-scan.svg";

import { useParams } from "react-router-dom";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardDatasetStorageComp = (props: any) => {
  const navigate = useCustomNavigate();
  const params = useParams();

  return (
    <>
      {props?.data?.map((item: any, index: any) => (
        <section key={index}>
          <div className="bg-[#2E3240] rounded">
            <Card
              className="card-item-storage !bg-[#2E3240] cursor-pointer shadow-[none]"
              orientation="horizontal"
            >
              <CardPreview
                className="div-center div-card-preview"
              >
                <img
                  className="img-card-item-catalog"
                  src={LayerSvg}
                  alt="img item"
                />
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <>
                    <div className="divHeader relative">
                      <div className="block w-full">
                        <Tooltip
                          relationship="description"
                          content={item?.storage?.name}
                        >
                          <p className="!uppercase text-title-card ellipsis-text-one-line">
                            {item?.storage?.name}
                          </p>
                        </Tooltip>

                        <p className="mt-1 text-des">{item?.product_type}</p>
                      </div>

                      <div />
                    </div>
                  </>
                }
              />

              <CardFooter className="footer-card-action">
                <div className="div-action-card">
                  <div
                    className="mr-3"
                    onClick={() => {
                      navigate(
                        `/manage-dataset/setting/${params?.id}/edit/${item?.id}/`,
                        {
                          state: { item: item },
                        }
                      );
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon"
                      src={EditSvg}
                      alt="Info Circle"
                    />
                  </div>

                  <div className="mr-3">
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="img-svg-icon mr-2"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList onClick={() => props?.delete(item?.id)}>
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>

                  <Tooltip relationship="description" content="Scan">
                    <div className="mr-3">
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <img
                            className="img-svg-icon mr-2"
                            src={ScanSvg}
                            alt="Dismiss"
                          />
                        </MenuTrigger>

                        <MenuPopover>
                          <MenuList onClick={() => props?.handleScan(item, 'skip')}>
                            <MenuItem>Skip </MenuItem>
                          </MenuList>
                          <MenuList onClick={() => props?.handleScan(item, 'overwrite')}>
                            <MenuItem>Overwrite </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    </div>
                  </Tooltip>

                  <div
                    onClick={(e: any) => {
                      e.preventDefault();
                      navigate(
                        `/manage-dataset/setting/${item?.collection_id}/storage/${item?.id}`,
                        {
                          state: { item: item },
                        }
                      );
                    }}
                  >
                    <Tooltip
                      relationship="description"
                      content="Setting auto scan"
                    >
                      <img
                        className="mr-2 img-svg-icon"
                        src={SettingSvg}
                        alt="Dismiss"
                      />
                    </Tooltip>
                  </div>
                </div>
              </CardFooter>
            </Card>
            {/* </Link> */}
          </div>
        </section>
      ))}
    </>
  );
};

export default CardDatasetStorageComp;
