import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getList,
  postStorage,
  scanStorage,
  editStorage,
  getHistoryScan,
  deleteStorage,
  getHistoryScanById,
  configureStorage,
  browseStorage,
  getScannedResource,
} from "../services/ManageStorage/ManageStorageServices";

export interface ManageStorageState {
  status: string;
  danhSach: Array<any>;
  statusScan: string;
  danhSachHistoryScan: Array<any>;
  start_at: any;
  end_at: any;
  product_type: Array<any>;
  asc: any;
  success: any;
  created_from: any;
  created_to: any;
  collection_id: any;
  storage_id: any;
  collection_title: any;
  storage_des: any;
  sort_by: any;
  sort_by_history_scan: any;

  keyword?: any;

  sort_order: any;

  flagFilter: boolean;
  flagSort: boolean;
  flagFresh: boolean;

  pathBrowse?: any;
}
const initialState: ManageStorageState = {
  status: "ilde",
  danhSach: [],
  statusScan: "",
  danhSachHistoryScan: [],
  start_at: null,
  end_at: null,
  product_type: [],
  asc: false,
  success: null,
  created_from: null,
  created_to: null,
  collection_id: "",
  storage_id: "",
  sort_by: "modified",
  sort_by_history_scan: "created",
  sort_order: "desc",
  collection_title: "",
  storage_des: "",
  keyword: "",

  flagFilter: false,
  flagSort: false,
  flagFresh: false,
  pathBrowse: null,
};

export const browseStorageAsync = createAsyncThunk(
  "managestorage/browseStorage",
  async (payload) => {
    try {
      const response = await browseStorage(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getListAsync = createAsyncThunk(
  "managestorage/getList",
  async (payload) => {
    try {
      const response = await getList(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const postAsync = createAsyncThunk(
  "managestorage/postStorage",
  async (payload: any) => {
    const response = await postStorage(payload);

    return response;
  }
);

export const editAsync = createAsyncThunk(
  "managestorage/editStorage",
  async (payload: any) => {
    const response = await editStorage(payload);

    return response;
  }
);

export const configureStorageAsync = createAsyncThunk(
  "managestorage/configureStorage",
  async (payload: any) => {
    const response = await configureStorage(payload);

    return response;
  }
);

export const scanAsync = createAsyncThunk(
  "managestorage/scanStorage",
  async (payload: any) => {
    const response = await scanStorage(payload);

    return response;
  }
);

export const getHistoryScanByIdAsync = createAsyncThunk(
  "managestorage/getHistoryScanById",
  async (id: string) => {
    try {
      const response = await getHistoryScanById(id);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getScannedResourceAsync = createAsyncThunk(
  "managestorage/getScannedResource",
  async (payload: any) => {
    try {
      const response = await getScannedResource(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getHistoryScanAsync = createAsyncThunk(
  "managestorage/getHistoryScan",
  async (payload) => {
    try {
      const response = await getHistoryScan(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const deleteStorageAsync = createAsyncThunk(
  "managestorage/deleteStorage",
  async (id: string) => {
    const response = await deleteStorage(id);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return response;
  }
);

export const ManageStorageSlice = createSlice({
  name: "managestorage",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      state.danhSach = action?.payload;
    },
    changePathBrowse: (state, action) => {
      state.pathBrowse = action?.payload;
    },
    changeStateFilter: (state, action) => {
      state.start_at = undefined;
      state.end_at = undefined;
      state.product_type = [];
      state.keyword = "";
    },

    changeStateSort: (state, action) => {
      state.sort_order = "desc";
      state.sort_by = "modified";
    },

    resetFilter: (state, action) => {
      state.storage_id = "";
      state.storage_des = "";
      state.collection_id = "";
      state.collection_title = "";
      state.created_to = null;
      state.created_from = null;
      state.success = null;
      state.keyword = "";
    },

    resetSortHistoryScan: (state, action) => {
      state.sort_by_history_scan = "created";
      state.sort_order = "desc";
    },

    changeKeyword: (state, action) => {
      state.keyword = action.payload;
    },

    changeStartAt: (state, action) => {
      state.start_at = action.payload;
    },

    changeEndAt: (state, action) => {
      state.end_at = action.payload;
    },

    changeProductType: (state, action) => {
      state.product_type = action.payload;
    },

    changeAsc: (state, action) => {
      state.asc = action.payload;
    },

    changeSuccess: (state, action) => {
      state.success = action.payload;
    },

    changeCreatedFrom: (state, action) => {
      state.created_from = action.payload;
    },

    changeCreatedTo: (state, action) => {
      state.created_to = action.payload;
    },
    changeCollectionId: (state, action) => {
      state.collection_id = action.payload?.id;
      state.collection_title = action.payload?.title;
    },
    changeStorageId: (state, action) => {
      state.storage_id = action.payload?.id;
      state.storage_des = action.payload?.name;
    },

    changeSortBy: (state, action) => {
      state.sort_by = action.payload;
    },

    changeSortByHistoryScan: (state, action) => {
      state.sort_by_history_scan = action.payload;
    },

    changeSortOrder: (state, action) => {
      state.sort_order = action.payload;
    },
    resetSortOption: (state, action) => {
      state.sort_by = "created";
      state.sort_order = "desc";
    },

    changeFlagFilter: (state, action) => {
      state.flagFilter = action?.payload;
    },
    changeFlagSort: (state, action) => {
      state.flagSort = action?.payload;
    },
    changeFlagFresh: (state, action) => {
      state.flagFresh = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // configure storage
      .addCase(configureStorageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(configureStorageAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      .addCase(getListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.danhSach = action.payload?.result ?? [];
      })
      // post item

      .addCase(postAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.danhSach = action.payload;
      })

      //scan

      .addCase(scanAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(scanAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.statusScan = action.payload?.data?.message;
        // state.danhSach = action.payload;
      })
      //edit
      .addCase(editAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.danhSach = action.payload;
      })

      //get history scan
      .addCase(getHistoryScanAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHistoryScanAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.danhSachHistoryScan = action.payload?.result ?? [];
      })

      //get history scan by id
      .addCase(getHistoryScanByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHistoryScanByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      //delete storage

      .addCase(deleteStorageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteStorageAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const {
  changeSate,
  changeEndAt,
  changeStartAt,
  changeProductType,
  changeStateFilter,
  changeAsc,
  changeCollectionId,
  changeCreatedFrom,
  changeCreatedTo,
  changeStorageId,
  changeSuccess,
  changeSortBy,
  changeSortOrder,
  resetSortOption,
  changeSortByHistoryScan,
  resetFilter,
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeStateSort,
  resetSortHistoryScan,
  changeKeyword,
  changePathBrowse
} = ManageStorageSlice.actions;

export default ManageStorageSlice.reducer;
