import React, { useState } from "react";
import BreadcrumbComp from "../components/Breadcrumb";
import { Spinner } from "@fluentui/react-components";
import FormChangeRoleComp from "../components/ChangeRole/FormChange";

const dataBread = [
  {
    key: 0,
    value: "Change role",
    path: "change-role",
  },
];

const ChangeRolePage = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div
      style={{
        opacity: loading ? 0.3 : 1,
        cursor: loading ? "none" : "initial",
      }}
      className="px-6 py-6 !w-full"
    >
      {loading && (
        <center>
          <Spinner className="absolute top-1/2 left-1/2 z-[9999]" />
        </center>
      )}
      <div className="div-breadcrumb">
        <BreadcrumbComp data={dataBread} className="!mt-1" />
      </div>

      <FormChangeRoleComp />
    </div>
  );
};

export default ChangeRolePage;
