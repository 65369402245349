import React, { useEffect } from "react";
import FormAddQuotaComp from "../../components/ManageQuota/FormAdd";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const AddQuotaPage = (props: any) => {
  const dispatch = useDispatch();
  const dataBread = [
    {
      key: 1,
      value: "Quotas",
      path: "manage-quota",
    },
    {
      key: 2,
      value: "Add",
      path: "add",
    },
  ];

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);
  useEffect(() => {
    dispatch(changePath("/manage-quota-add"));
    dispatch(changeName("manage-quota-add"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));
    };
  });
  return <FormAddQuotaComp />;
};
export default AddQuotaPage;
