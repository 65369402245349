import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getThumbnailItemCollectionAsync } from "../../../store/exploreSlice";
import { updateThumbnailItem } from "./../../../store/filterSlice";
import LayerSvg from "../../../assets/images/item-collection-default.png";

import { Spinner } from "@fluentui/react-components";

export default function PreviewItem(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [thumbnailImg, setThumbnailImg] = useState<String | any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getThumbnailItem();
  }, [props?.item.id]);

  const getThumbnailItem = async () => {
    if (props && props.item.thumbnail && props.item.thumbnail.length > 0)
      setThumbnailImg(props.items.thumbnail[0]);
    else {
      setLoading(true);
      try {
        const res = await dispatch(
          // @ts-ignore
          getThumbnailItemCollectionAsync({
            collection_id: props.item?.collection,
            item_id: props.item?.id,
          })
        ).unwrap();
        if (res && res.status == 200) {
          if (res.data && res.data.length > 0) {
            setThumbnailImg(res?.data?.[0]);
            props.setThumbnailItem(thumbnailImg);

            dispatch(updateThumbnailItem(res.data));
          }
        }
      } catch (error) {}
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <img
          className="rounded-sm img-card-item-catalog-search-drawer"
          src={
            thumbnailImg
              ? `${localStorage.getItem("REACT_APP_ENDPOINT")}/collections/${
                  props.item?.collection
                }/items/${props.item?.id}/thumbnails/${thumbnailImg}/`
              : LayerSvg
          }
          alt="Thumbnail image"
          onError={(e: any) => {
            e.target.src = LayerSvg;
          }}
        />
      )}
    </>
  );
}
