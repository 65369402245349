import {
  Card,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";

import { Checkbox, Text } from "@fluentui/react-components";

import { truncateString } from "../../utils/utils";
import LayerSvg from "../../assets/images/item-collection-default.png";
import { useEffect, useState } from "react";

export default function CardItemCart(props: any) {

  const renderCardPreview = (item: any) => {
    return (
      <div className="!flex !flex-row self-center items-center justify-items-center gap-x-1.5">
        <Checkbox
          checked={props.selectedIds.includes(item.id)}
          onChange={(e: any, data: any) => props?.onSelectedItem(item)}
        />
        <div className="bg-black w-16 h-16 rounded">
          <img
            loading="lazy"
            className="!w-16 !h-16 max-w-[4rem] rounded"
            src={
              item?.thumbnail?.[0]
                ? `${localStorage.getItem("REACT_APP_ENDPOINT")}/collections/${
                    item?.collection_id
                  }/items/${item?.stac_item_id}/thumbnails/${
                    item?.thumbnail?.[0]
                  }/`
                : LayerSvg
            }
            onError={(e: any) => {
              e.target.src = LayerSvg;
            }}
            alt="img item"
          />
        </div>
      </div>
    );
  };

  const renderCardheaderDesc = (item: any) => {
    return (
      <>
        {item ? (
          <span className="grid grid-cols-1">
            <span style={{ fontSize: "12px" }}>
              <code>{item.collection_id}</code>
            </span>
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-y-3 mb-2">
      {props?.data?.map((item: any, index: number) => (
        <div
          key={index}
          className="bg-ink-400 px-2 py-1 hover:bg-white/25 rounded-sm hover:cursor-pointer"
        >
          <Card orientation="horizontal" appearance="subtle">
            <CardPreview>{renderCardPreview(item)}</CardPreview>

            <CardHeader
              header={
                <Tooltip
                  relationship="description"
                  content={item?.stac_item_id}
                >
                  <Text
                    className="!font-semibold !leading-6"
                    style={{ fontSize: "12px" }}
                  >
                    {truncateString(item?.stac_item_id, 38)}
                  </Text>
                </Tooltip>
              }
              description={
                <div className="!font-normal !leading-5 w-full">
                  {renderCardheaderDesc(item)}
                </div>
              }
            />
          </Card>
        </div>
      ))}
    </div>
  );
}
