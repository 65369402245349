import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { Select, useId } from "@fluentui/react-components";

import {
  changeMapScale,
  changeZoomLevel,
} from "../../../../store/maplibreGLSlice";
import { getGoogleMercatorScales } from "../../../../utils/map/MapUtils";

export default function ScaleBox() {
  const dispatch = useDispatch();
  // @ts-ignore
  const maplibreGL: maplibregl.Map = useSelector(
    (state: RootState) => state.map?.maplibreGL
  );
  // @ts-ignore
  let scale: any = useSelector((state: RootState) => state.map?.scale);
  const minZoom = 0;
  const scales = getGoogleMercatorScales(0, 28);

  const getOptions = () => {
    return scales
      .map((item, index) => {
        return (
          <option value={index} key={index}>
            {template(item)}
          </option>
        );
      })
      .filter((element, index) => index >= minZoom);
  };
  const template = (scale: number) => {
    return scale < 1
      ? Math.round(1 / scale) + " : 1"
      : "1 : " + Math.round(scale);
  };

  const selectId = useId();

  const onChangeScale = (ev: any, data: any) => {
    var selectedZoomLvl = parseInt(data.value, 10);
    maplibreGL.setZoom(selectedZoomLvl);
    dispatch(changeMapScale(data.value));
    dispatch(changeZoomLevel(selectedZoomLvl));
  };

  return (
    <Select
      className="[&>*]:!bg-ink-300"
      value={scale}
      id={"scale-" + selectId}
      onChange={(ev, data) => onChangeScale(ev, data)}
      size="medium"
      appearance="filled-lighter"
    >
      {getOptions()}
    </Select>
  );
}
