import axios from "../../utils/axios";

export async function getList(payload: any) {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.collection_id}/storages/`,
    { params: { ...payload } }
  );
}

export async function createCollectionStorage(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.collection_id}/storages/${payload?.storage_id}/`,
    { product_type: payload?.product_type }
  );
}

export async function updateCollectionStorage(payload: any) {
  
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/storages/${payload?.collection_storage_id}/`,
    {
      product_type: payload?.product_type,
      // storage_id: payload?.storage_id,
    }
  );
}

export async function deleteCollectionStorage(payload: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/storages/${payload?.collection_storage_id}/`
  );
}

export async function scanCollectionStorage(payload: any) {
  
  return axios.post(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/admin/collections/${
      payload?.collection_id
    }/storages/${payload?.storage_id}/start_scan/?overwrite=${payload?.type ==='skip' ? false : true}`
  );
}

export async function scanThumbnail(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.collection_id}/items/thumbnails/scan/`
  );
}
