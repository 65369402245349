import React, { useState } from "react";
import { Card, CardHeader, CardFooter, Dropdown } from "@fluentui/react-components";
import { Select, useId } from "@fluentui/react-components";
import {
  Button,
  Field,
  Input,
  useToastController,
  ToastBody,
  ToastTitle,
  Toast,
  Option,
} from "@fluentui/react-components";

import dismissSvg from "../../../../../assets/Dismiss.svg";

import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

import {
  convertDDToDMS,
  convertDMSToDD,
} from "../../../../../utils/map/CoordinatesUtils";

export default function CoordinateEditor(props: any) {
  const { t } = useTranslation();
  const selectId = useId();
  const toasterId: string = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  // @ts-ignore
  const maplibreGL: maplibregl.Map = useSelector(
    (state: RootState) => state.map?.maplibreGL
  );
  const { dispatchToast } = useToastController(toasterId);

  const [kind, setkind] = useState("dd");

  const [lngDD, setLngDD] = useState(undefined);
  const [latDD, setLatDD] = useState(undefined);
  const [msgLngDD, setMsgLngDD] = useState("");
  const [msgLatDD, setMsgLatDD] = useState("");

  const [lng1DMS, setLng1DMS] = useState(undefined);
  const [lng2DMS, setLng2DMS] = useState(undefined);
  const [lng3DMS, setLng3DMS] = useState(undefined);
  const [lat1DMS, setLat1DMS] = useState(undefined);
  const [lat2DMS, setLat2DMS] = useState(undefined);
  const [lat3DMS, setLat3DMS] = useState(undefined);

  const [msgLngDMS, setMsgLngDMS] = useState("");
  const [msgLatDMS, setMsgLatDMS] = useState("");

  const onKindChange = (ev: any, data: any) => {
    setkind(data);
  };

  const onClose = () => {
    props.onClose();
  };

  function renderOptions() {
    return (
      <div className="flex gap-x-2">
        <Dropdown
          placeholder="Select unit"
          className="div-dropdown !w-20 !min-w-20"
          onOptionSelect={(e: any, data: any) => {
            onKindChange(e, data.optionText);
          }}
          id={selectId}
          value={kind}
          size="small"
          appearance="filled-lighter"
        >
          <Option key={1} value={"dd"}>
            {t("dd")}
          </Option>
          <Option key={2} value={"dms"}>
            {t("dms")}
          </Option>
        </Dropdown>
        {/* <Select
          className="div-dropdown"
          id={selectId}
          value={kind}
          onChange={(ev, data) => onKindChange(ev, data)}
          size="small"
          appearance="filled-lighter"
        >
          <option key={1} value={"dd"}>
            {t("dd")}
          </option>
          <option key={2} value={"dms"}>
            {t("dms")}
          </option>
        </Select> */}
        <h6 className="text-sm self-center">{t("Enter coordinate")}</h6>
      </div>
    );
  }
  const onLngDDChange = (value: any) => {
    try {
      value = parseFloat(value);
      let convert = convertDDToDMS(value, true, 4);
      if (convert) {
        // @ts-ignore
        setLng1DMS(convert.degrees ? convert.degrees : undefined);
        // @ts-ignore
        setLng2DMS(convert.minutes ? convert.minutes : undefined);
        // @ts-ignore
        setLng3DMS(convert.seconds ? convert.minutes : undefined);
      }
      setLngDD(value);
    } catch (error) {
      setMsgLngDD(t("Invalid longitude"));
    }
  };

  const onLatDDChange = (value: any) => {
    try {
      value = parseFloat(value);
      let convert = convertDDToDMS(value, false, 4);
      if (convert) {
        // @ts-ignore
        setLat1DMS(convert.degrees ? convert.degrees : undefined);
        // @ts-ignore
        setLat2DMS(convert.minutes ? convert.minutes : undefined);
        // @ts-ignore
        setLat3DMS(convert.seconds ? convert.minutes : undefined);
      }
      setLatDD(value);
    } catch (error) {
      setMsgLatDD(t("Invalid latitude"));
    }
  };
  const onLng1DMSChange = (value: any) => {
    try {
      value = parseFloat(value);
      onLngDMSChange(value, lng2DMS, lng3DMS);
      setLng1DMS(value);
    } catch (error) {
      setMsgLngDMS(t("Invalid longitude"));
    }
  };
  const onLng2DMSChange = (value: any) => {
    try {
      value = parseFloat(value);
      onLngDMSChange(lng1DMS, value, lng3DMS);
      setLng2DMS(value);
    } catch (error) {
      setMsgLngDMS(t("Invalid longitude"));
    }
  };
  const onLng3DMSChange = (value: any) => {
    try {
      value = parseFloat(value);
      onLngDMSChange(lng1DMS, lng2DMS, value);
      setLng3DMS(value);
    } catch (error) {
      setMsgLngDMS(t("Invalid longitude"));
    }
  };
  const onLat1DMSChange = (value: any) => {
    try {
      value = parseFloat(value);
      onLatDMSChange(value, lat2DMS, lat3DMS);
      setLat1DMS(value);
    } catch (error) {
      setMsgLatDMS(t("Invalid latitude"));
    }
  };
  const onLat2DMSChange = (value: any) => {
    try {
      value = parseFloat(value);
      onLatDMSChange(lat1DMS, value, lat3DMS);
      setLat2DMS(value);
    } catch (error) {
      setMsgLatDMS(t("Invalid latitude"));
    }
  };
  const onLat3DMSChange = (value: any) => {
    try {
      value = parseFloat(value);
      onLatDMSChange(lat1DMS, lat2DMS, value);
      setLat3DMS(value);
    } catch (error) {
      setMsgLatDMS(t("Invalid latitude"));
    }
  };

  const onLngDMSChange = (degrees: any, minutes: any, seconds: any) => {
    try {
      let convert = convertDMSToDD(degrees, minutes, seconds, null);
      if (convert) {
        // @ts-ignore
        setLngDD(convert);
      }
    } catch (error) {
      setMsgLngDMS(t("Invalid longitude"));
    }
  };
  const onLatDMSChange = (degrees: any, minutes: any, seconds: any) => {
    try {
      let convert = convertDMSToDD(degrees, minutes, seconds, null);
      if (convert) {
        // @ts-ignore
        setLatDD(convert);
      }
    } catch (error) {
      setMsgLngDMS(t("Invalid latitude"));
    }
  };

  const onApply = () => {
    // validate value
    let invalid = false;
    if (lngDD == undefined || latDD == undefined) invalid = true;
    if (invalid) {
      return dispatchToast(
        <Toast>
          <ToastTitle>{t("Message")}</ToastTitle>
          <ToastBody>
            <p>{t("Invalid coordinate")}</p>
          </ToastBody>
        </Toast>,
        { position: "bottom", timeout: 5000, intent: "error" }
      );
    }
    maplibreGL.panTo([!lngDD ? 0 : lngDD, !latDD ? 0 : latDD], {
      duration: 1000,
    });
    props.onClose();
  };

  function renderCoordinateDD() {
    return (
      <fieldset className="text-left grid grid-cols-1 gap-y-2">
        <Field
          className="text-xs self-center"
          orientation="horizontal"
          label={t("Longitude")}
          validationState={msgLngDD ? "error" : "none"}
          validationMessage={msgLngDD}
        >
          <Input
            className="div-dropdown"
            autoFocus
            type="number"
            defaultValue={lngDD}
            min={-180}
            max={180}
            maxLength={3}
            onChange={(e) => onLngDDChange(e.target.value)}
            appearance="filled-darker"
            contentAfter={<span>&deg;</span>}
          />
        </Field>
        <Field
          className="text-xs self-center"
          orientation="horizontal"
          label={t("Latitude")}
          validationState={msgLatDD ? "error" : "none"}
          validationMessage={msgLatDD}
        >
          <Input
            className="div-dropdown"
            type="number"
            defaultValue={latDD}
            min={-90}
            max={90}
            maxLength={2}
            onChange={(e) => onLatDDChange(e.target.value)}
            appearance="filled-darker"
            contentAfter={<span>&deg;</span>}
          />
        </Field>
      </fieldset>
    );
  }

  function renderCoordinateDMS() {
    return (
      <fieldset className="text-left grid grid-cols-1 gap-y-2">
        <Field
          className="text-xs self-center"
          orientation="horizontal"
          label={t("Longitude")}
          validationState={msgLngDMS ? "error" : "none"}
          validationMessage={msgLngDMS}
        >
          <div className="grid grid-cols-3 gap-x-1">
            <Input
              
              type="number"
              defaultValue={lng1DMS}
              className="w-20 div-dropdown"
              min={-180}
              max={180}
              maxLength={2}
              onChange={(e) => onLng1DMSChange(e.target.value)}
              appearance="filled-darker"
              contentAfter={<span>&deg;</span>}
            />
            <Input
              type="number"
              defaultValue={lng2DMS}
              className="w-20 div-dropdown"
              min={0}
              max={60}
              maxLength={2}
              onChange={(e) => onLng2DMSChange(e.target.value)}
              appearance="filled-darker"
              contentAfter={<span>'</span>}
            />
            <Input
              type="number"
              defaultValue={lng3DMS}
              className="w-20 div-dropdown"
              min={0}
              max={60}
              maxLength={2}
              onChange={(e) => onLng3DMSChange(e.target.value)}
              appearance="filled-darker"
              contentAfter={<span>"</span>}
            />
          </div>
        </Field>
        <Field
          className="text-xs self-center"
          orientation="horizontal"
          label={t("Latitude")}
          validationState={msgLatDMS ? "error" : "none"}
          validationMessage={msgLatDMS}
        >
          <div className="grid grid-cols-3 gap-x-1">
            <Input
              type="number"
              defaultValue={lat1DMS}
              className="w-20 div-dropdown"
              min={-90}
              max={90}
              maxLength={2}
              onChange={(e) => onLat1DMSChange(e.target.value)}
              appearance="filled-darker"
              contentAfter={<span>&deg;</span>}
            />
            <Input
              type="number"
              defaultValue={lat2DMS}
              className="w-20 div-dropdown"
              min={0}
              max={60}
              maxLength={2}
              onChange={(e) => onLat2DMSChange(e.target.value)}
              appearance="filled-darker"
              contentAfter={<span>'</span>}
            />
            <Input
              type="number"
              defaultValue={lat3DMS}
              className="w-20 div-dropdown"
              min={0}
              max={60}
              maxLength={2}
              onChange={(e) => onLat3DMSChange(e.target.value)}
              appearance="filled-darker"
              contentAfter={<span>"</span>}
            />
          </div>
        </Field>
      </fieldset>
    );
  }

  return (
    <div className="min-w-[24rem] fixed right-4 bottom-2">
      <Card className="!bg-s-background1">
        <CardHeader
          className="flex"
          header={renderOptions()}
          action={
            <Button
              onClick={() => onClose()}
              appearance="transparent"
              icon={
                <img
                  className="dismiss-svg-icon"
                  src={dismissSvg}
                  alt="dismiss"
                />
              }
              aria-label={t("Close")}
            />
          }
        />

        <div>
          {kind == "dd" && renderCoordinateDD()}
          {kind == "dms" && renderCoordinateDMS()}
        </div>
        <CardFooter>
          <Button className="!bg-s-background1" onClick={() => onApply()}>
            {t("Apply")}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
