// xuanvt add
import
  {
    AvatarGroup,
    AvatarGroupItem,
    Divider,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
  } from "@fluentui/react-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import downSvg from "./../../assets/down-circle-userinfor.svg";

import useCustomNavigate from "../../hook/useCustomNavigate";
import { ipKratos } from "../../services/ip";
import { HASH_ROLE_ADMIN } from "../../utils/constants";

const UserInforButton = (props: any) => {
  const navigate = useCustomNavigate();
  let tun =
    props?.inforUser?.data?.traits?.name?.first +
    " " +
    props?.inforUser?.data?.traits?.name?.last;

  const { total_cart } = useSelector((state: RootState) => state.manageorder);
  const { roleLogin, currentRole } = useSelector(
    (state: RootState) => state.manageuser
  );

  return (
    <Menu>
      <MenuTrigger>
        <AvatarGroup size={24} className="relative">
          <AvatarGroupItem
            // size="32"
            active="active"
            activeAppearance="ring"
            color="red"
            name={tun}
            key="sun"
            style={{ marginLeft: total_cart !== 0 ? "4px" : "0px" }}
          />
          <img src={downSvg} className="absolute top-4 left-4" />
        </AvatarGroup>
      </MenuTrigger>

      <MenuPopover className="menu-popover">
        <MenuList>
          <MenuItem style={{ cursor: "none" }}>
            <div className="flex">
              {props?.inforUser?.data?.traits?.name?.first +
                " " +
                props?.inforUser?.data?.traits?.name?.last}
            </div>
          </MenuItem>
          <Divider />
          <MenuItem>
            <div className="flex">Setting</div>
          </MenuItem>
          <Divider />
          {roleLogin === HASH_ROLE_ADMIN && (
            <>
              <MenuItem onClick={() => navigate(`/change-role`)}>
                <div className="flex" onClick={() => navigate(`/change-role`)}>
                  {currentRole === HASH_ROLE_ADMIN
                    ? "Switch to user"
                    : "Switch to admin"}
                </div>
              </MenuItem>
              <Divider />
            </>
          )}

          <MenuItem>
            <div
              className="flex"
              onClick={() => window.location.replace(`${ipKratos}/auth/login`)}
            >
              Logout
            </div>
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default UserInforButton;
