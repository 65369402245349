import { useToastController } from "@fluentui/react-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CardItemDetailOrderAdmin from "../../components/CardItemOrderImg/CardItemDetailOrderAdmin";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import ToastComp from "../../components/Toast";
import MaplibreGL from "../../components/map/maplibregl/Map";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import
  {
    changeStateVisibleDrawerInforItemDataset,
    resetAllStateDrawer
  } from "../../store/DrawerSlice";
import { getByIdAdminAsync as getOrderByIdAdmin } from "../../store/ManageOrderSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { setDraw } from "../../store/drawSlice";
import
  {
    changeAllowIntersect,
    changeCoordinates,
    changeItemHover,
    changeItemViewInfor,
    getItemCollectionByIdAsync,
    getThumbnailItemDatasetAsync as getThumbnail,
  } from "../../store/exploreSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import EmptySvg from "./../../assets/nodata.svg";
import
  {
    acceptOrderAsync,
    rejectOrderAsync,
  } from "./../../store/ManageOrderSlice";

const ManageOderDetail = () => {
  const param = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});
  const [dataOrder, setDataOrder] = useState<any>([]);
  const [selected, setSelected] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { visibleDrawerInforDataset } = useSelector(
    (state: RootState) => state.drawer
  );

  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  useEffect(() => {
    dispatch(changePath("/manage-order/item"));
    dispatch(changeName("manage-order/item"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    return () => {
      // detroy search intersect
      dispatch(changeAllowIntersect(false));
      dispatch(changeCoordinates([]));
      dispatch(changeItemHover(null));
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setDraw(undefined));
    };
  }, []);

  const mapChangedBounds = (bounds: any) => {
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    //dispatch(changeCoordinates([[sw.lng, sw.lat], [sw.lng, ne.lat], [ne.lng, ne.lat], [ne.lng, sw.lat], [sw.lng, sw.lat]]));

    // search with bbox
    dispatch(changeCoordinates([sw.lng, sw.lat, ne.lng, ne.lat]));
  };

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getOrderByIdAdmin({
            id: param?.id,
          })
        ).unwrap();

        let b: any = [];
        let a: any = res?.items;

        let ids: any = [];

        for (let i = 0; i < a?.length; i++) {
          let res;
          res = await dispatch(
            // @ts-ignore
            getThumbnail({
              collection_id: a?.[i]?.collection_id,
              item_id: a?.[i]?.stac_item_id,
            })
          ).unwrap();

          let resItem = await dispatch(
            // @ts-ignore
            getItemCollectionByIdAsync({
              collectionId: a?.[i]?.collection_id,
              itemId: a?.[i]?.stac_item_id,
            })
          ).unwrap();

          ids.push(resItem?.data);

          b.push({
            ...a?.[i],
            thumbnail: res,
          });
        }

        setSelected(ids);

        setDataOrder(b);

        setData(res);

        dispatch(
          changeDataBreadcrumb([
            {
              key: 0,
              value: "Orders",
              path: "manage-order",
            },
            {
              key: 1,
              value: moment(res?.modified).format("DD/MM/YYYY HH:mm:ss"),
              path: "",
            },
          ])
        );

        // dispatch(changeTotal(res?.paging?.total ?? 0));
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
      }
    })();
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const handleAccpet = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      acceptOrderAsync({
        // owner_id: item?.user?.id,
        orderId: item?.id,
        owner_id: item?.user_id,
      })
    ).unwrap();

    if (res.status === 200) {
      notify("Accept successfully", "success");
      setTimeout(() => {
        getData();

        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Accept fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const handleReject = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      rejectOrderAsync({
        // owner_id: item?.user?.id,
        orderId: item?.id,
        owner_id: item?.user_id,
      })
    ).unwrap();

    if (res.status === 200) {
      notify("Reject successfully", "success");
      setTimeout(() => {
        getData();

        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Reject fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const getData = async () => {
    const res = await dispatch(
      // @ts-ignore
      getOrderByIdAdmin({
        id: param?.id,
      })
    ).unwrap();

    let b: any = [];
    let a: any = res?.items;
    let ids: any = [];

    for (let i = 0; i < a?.length; i++) {
      let res;
      res = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: a?.[i]?.collection_id,
          item_id: a?.[i]?.stac_item_id,
        })
      ).unwrap();

      let resItem = await dispatch(
        // @ts-ignore
        getItemCollectionByIdAsync({
          collectionId: a?.[i]?.collection_id,
          itemId: a?.[i]?.stac_item_id,
        })
      ).unwrap();

      ids.push(resItem?.data);

      b.push({
        ...a?.[i],
        thumbnail: res,
      });
    }

    setSelected(ids);
    setDataOrder(b);
    setData(res);
  };

  const onSelectedItem = (item: any) => {
    let selectedCopy: Array<any> =
      selected && selected.length ? selected.slice() : [];
    let index = selectedCopy?.findIndex((x) => x?.id === item?.id);
    if (index < 0) selectedCopy.push(item);
    else selectedCopy.splice(index, 1);

    setSelected(selectedCopy);
  };

  const handleViewInfo = async (collectionId: any, idItem: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      getItemCollectionByIdAsync({
        collectionId: collectionId,
        itemId: idItem,
      })
    ).unwrap();

    const resThumnail = await dispatch(
      // @ts-ignore
      getThumbnail({
        collection_id: collectionId,
        item_id: idItem,
      })
    ).unwrap();

    let item = {
      ...res?.data,
      thumbnail: resThumnail,
    };

    dispatch(changeItemViewInfor(item));
    dispatch(
      changeStateVisibleDrawerInforItemDataset(!visibleDrawerInforDataset)
    );

    dispatch(changeVisibleSpinner(false));
  };

  const onSelectFeatureChange = async (features: Array<any>) => {
    handleViewInfo(
      features?.[0]?.properties?.collection,
      features?.[0]?.properties?.id
    );
  };

  const onMouseEnterItem = async (item: any) => {
    const res = await dispatch(
      // @ts-ignore
      getItemCollectionByIdAsync({
        collectionId: item?.collection_id,
        itemId: item?.stac_item_id,
      })
    ).unwrap();

    dispatch(changeItemHover(res?.data));
  };
  const onMouseLeaveItem = (item: any) => {
    dispatch(changeItemHover(null));
  };

  return (
    <>
      <div
        className="px-6 py-6 !w-full !relative overflow-hidden"
        style={{ height: "calc(100vh - 58px -57px)" }}
      >
        {loading && <LoadingListComp />}
        {visibleSpinner && !loading && <LoadingGlobalComp />}
        {!loading && (
          <div className="header-order-item-page">
            <p>Total items: {dataOrder?.length}</p>
            <div className="flex items-center">
              <p className="mr-8">
                {moment(data?.modified).format("DD/MM/YYYY HH:mm:ss")}
              </p>
              {data?.states?.[data?.states?.length - 1]?.state === "Submit" && (
                <>
                  <span
                    className="btn-action-page-item-order mr-4"
                    onClick={() => handleAccpet(data)}
                  >
                    Accept
                  </span>
                  <span
                    className="btn-action-page-item-order"
                    onClick={() => handleReject(data)}
                  >
                    Reject
                  </span>
                </>
              )}
            </div>
          </div>
        )}

        {dataOrder?.length === 0 && !loading && (
          <center className="mt-4">
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {dataOrder?.length !== 0 && !loading && (
          <div className="w-full">
            <div className="mb-2">
              <div className="w-full h-full">
                <MaplibreGL
                  heightClass="h-80"
                  // coordinates={coordinates}
                  mapChangedBounds={(bounds: any) => mapChangedBounds(bounds)}
                  // intersects={intersects}
                  items={selected && selected.length ? selected : []}
                  onSelectFeatureChange={(features: Array<any>) =>
                    onSelectFeatureChange(features)
                  }
                />
              </div>
            </div>
            <div className="div-list-detail-order-admin mt-4">
              <CardItemDetailOrderAdmin
                data={dataOrder ?? []}
                onSelectedItem={onSelectedItem}
                notify={notify}
                selectedIds={
                  selected && selected.length
                    ? selected.map((x: any) => x?.id)
                    : []
                }
                onMouseMoveItem={(item: any) => onMouseEnterItem(item)}
                onMouseLeaveItem={(item: any) => onMouseLeaveItem(item)}
                handleViewInfo={handleViewInfo}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ManageOderDetail;
