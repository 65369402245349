import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardItemOrderImg from "../components/CardItemOrderImg";
import { RootState } from "../store/store";
import EmptySvg from "./../assets/nodata.svg";
import {
  acceptOrderAsync,
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeStateFilter,
  getListAdminAsync,
  rejectOrderAsync,
} from "./../store/ManageOrderSlice";
import { changePage, changeTotal, resetState } from "./../store/pagingSlice";

import LoadingGlobalComp from "../components/Loading/LoadingGlobal";
import LoadingListComp from "../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../store/DrawerSlice";
import { getListUserAsync } from "../store/ManageUserSlice";
import { changeVisibleSpinner } from "../store/NavSlice";
import { changeName, changePath } from "../store/pageSlice";
import { timeout } from "../utils/constants";

import ToastComp from "../components/Toast";
import moment from "moment";

const dataBread = [
  {
    key: 1,
    value: "Orders",
    path: "manage-order",
  },
];

const ManageOderPage = () => {
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state: RootState) => state.paging);

  const {
    start_at,
    end_at,
    idUser,
    sort_by,
    sort_order,
    stateStatus,
    flagFilter,
    flagSort,
    flagFresh,
  } = useSelector((state: RootState) => state.manageorder);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(changePath("/manage-order"));
    dispatch(changeName("manage-order"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);
  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());
      // @ts-ignore
      
      if (!window.location.pathname?.includes("manage-order"))
      {
        // @ts-ignore
        dispatch(changeStateFilter());
      }
        // console.log(window.location.pathname, "window.location.pathname");
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    (async function () {
      if (!flagFresh || !flagFilter || !flagSort)
        try {
          getData({ page: page });
        } catch (e) {
          console.error(e);
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFresh) {
        try {
          getData({ page: 0 });

          dispatch(changeFlagFresh(false));
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [flagFresh]);

  useEffect(() => {
    (async function () {
      if (flagFilter) {
        try {
          getData({ page: 0 });

          dispatch(changeFlagFilter(false));
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          getData({ page: 0 });

          dispatch(changeFlagSort(false));
        } catch (e) {
          console.error(e);
        }
    })();
  }, [flagSort]);

  const getData = async (props: any) => {
    try {
      setLoading(true);
      // dispatch(changeTotal(0));
      
      dispatch(changeVisibleSpinner(true));
      const resUser = await dispatch(
        // @ts-ignore
        getListUserAsync()
      ).unwrap();
      const res = await dispatch(
        // @ts-ignore
        getListAdminAsync({
          size: limit,
          sortby: sort_by,
          asc: sort_order === "asc" ? true : false,
          page: props?.page ?? page,

          filter: {
            created_from: start_at,
            created_to: end_at
              ? moment(end_at).endOf("days").toISOString()
              : null,
            user_id: idUser,
            states:
              stateStatus !== "All"
                ? [stateStatus]
                : ["Submit", "Accept", "Reject"],
          },
        })
      ).unwrap();

      let a = res?.result?.filter(
        (item: any) =>
          item?.states?.[item?.states?.length - 1]?.state !== "Create"
      );

      let b: any = [];

      for (let i = 0; i < a?.length; i++) {
        // let sun = resUser?.filter((item: any) => {
        //   item?.id === a?.[i]?.user_id;
        // });
        // b.pus
        let flag = false;
        for (let j = 0; j < resUser.length; j++) {
          if (resUser[j].id === a?.[i]?.user_id) {
            b = b.concat({
              ...a[i],
              user: resUser[j],
            });
            flag = true;
          }
        }
        if (!flag) {
          b = b.concat({
            ...a[i],
            user: {},
          });
        }
      }

      setData(b);
      dispatch(changePage(props?.page));
      dispatch(changeTotal(res?.paging?.total ?? 0));
      setLoading(false);
      dispatch(changeVisibleSpinner(false));
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(changeVisibleSpinner(false));
    }
  };

  const handleAccpet = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      acceptOrderAsync({
        // owner_id: item?.user?.id,
        orderId: item?.id,
        owner_id: item?.user_id,
      })
    ).unwrap();

    if (res.status === 200) {
      notify("Accept successfully", "success");
      setTimeout(() => {
        getData({ page: page });
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Accept false", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const handleReject = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      rejectOrderAsync({
        // owner_id: item?.user?.id,
        orderId: item?.id,
        owner_id: item?.user_id,
      })
    ).unwrap();

    if (res.status === 200) {
      notify("Reject successfully", "success");
      setTimeout(() => {
        getData({ page: page });
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Reject false", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {visibleSpinner && !loading && <LoadingGlobalComp />}

        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div className="w-full">
            <CardItemOrderImg
              data={data}
              getData={getData}
              setLoading={setLoading}
              handleAccpet={handleAccpet}
              handleReject={handleReject}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ManageOderPage;
