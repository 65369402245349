import { Tab, TabList, useToastController } from "@fluentui/react-components";
import { ButtonComponent } from "@web/react-component-ui-user";
import { useEffect, useState } from "react";
import styled from "styled-components";
import InfoMetadata from "./metadata";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThumbnailItemCollectionAsync } from "../../store/exploreSlice";
import CartItemSvg from "./../../assets/cart-item.svg";
import CheckSVG from "./../../assets/check.svg";
import CloudSVG from "./../../assets/cloud.svg";
import LayerSvg from "./../../assets/Layer_1.png";
import MapItemSvg from "./../../assets/map-item.svg";
import Maximize3SVG from "./../../assets/maximize-3.svg";
import MedalStar from "./../../assets/medal-star.svg";
import NoteTextSVG from "./../../assets/note-text.svg";
import SunFogSVG from "./../../assets/sun-fog.svg";
import SunSVG from "./../../assets/sun.svg";
import DateTimeSvg from '../../assets/datetime.svg'

import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  addItemOderAsync,
  changeCartItems,
  changeTotalCart,
  deleteItemInOrderAsync,
  getItemInOrderAsync,
} from "../../store/ManageOrderSlice";
import { updateAlert } from "../../store/notificationsSlice";
import { RootState } from "../../store/store";
import { HASH_ROLE_USE, timeout } from "../../utils/constants";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";
import AssetsComp from "./assets";
import useCustomNavigate from "../../hook/useCustomNavigate";

const StyledDivTablist = styled(Tab)`
  height: 100%;
  text-align: center;
  align-items: center;
  width: 50%;
  justify-content: center;
  &:after {
    height: 1px;
  }
`;

const IndexDrawerDataset = (props: any) => {
  let Instruments;
  let Shape;

  if (typeof props?.data?.properties?.instruments === "object") {
    Instruments = props?.data?.properties?.instruments?.join(" ");
  } else {
    Instruments = props?.data?.properties?.instruments;
  }

  if (typeof props?.data?.properties?.["proj:shape"] === "object") {
    Shape = props?.data?.properties?.["proj:shape"]?.join(" ");
  } else {
    Shape = props?.data?.properties?.["proj:shape"];
  }

  const [type, setType] = useState("Metadata");
  const arrMetaData = [
    {
      title: "Datetime",
      content: moment(props?.data?.properties?.datetime).format("DD/MM/YYYY"),
      icon: DateTimeSvg,
    },
    {
      title: "Created",
      content: moment(props?.data?.properties?.created).format("DD/MM/YYYY"),
      icon: NoteTextSVG,
    },
    {
      title: "Platform",
      content: props?.data?.properties?.platform,
      icon: MedalStar,
    },
    {
      title: "Cloud",
      content: props?.data?.properties?.["eo:cloud_cover"],
      icon: CloudSVG,
    },
    { title: "Shape", content: Shape, icon: Maximize3SVG },
    { title: "Instruments", content: Instruments, icon: CheckSVG },
    {
      title: "Sun Azimuth",
      content: props?.data?.properties?.["view:sun_azimuth"],
      icon: SunSVG,
    },
    {
      title: "Sun Elevation",
      content: props?.data?.properties?.["view:sun_elevation"],
      icon: SunFogSVG,
    },
  ];

  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const [flag, setFlag] = useState(props?.data?.checkInCart);
  const [loading, setLoading] = useState(false);

  const [pathImg, setPathImg] = useState([]);
  const [hasError, setHasError] = useState(false);

  const cartItems: Array<any> = useSelector(
    (state: RootState) => state.manageorder?.cartItems
  );

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const res = await dispatch(
          // @ts-ignore
          getThumbnailItemCollectionAsync({
            collection_id: props?.data?.collection,
            item_id: props?.data?.id,
          })
        ).unwrap();

        let check = false;
        cartItems?.map((itemCart: any) => {
          if (
            itemCart?.stac_item_id === props?.data?.id &&
            itemCart?.collection_id === props?.data?.collection
          ) {
            check = true;
          }
        });

        setFlag(check);

        setPathImg(res?.data);
        setHasError(false)

        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [props?.data?.id || props?.data?.collection]);

  const { cartId } = useSelector((state: RootState) => state.manageorder);
  const { t } = useTranslation();
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const handleDelete = async (item: any) => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      deleteItemInOrderAsync({
        idOrder: cartId,
        item_id: item?.id,
        collection_id: item?.collection,
      })
    ).unwrap();
    if (res?.status === 200) {
      const response = await dispatch(
        // @ts-ignore
        getItemInOrderAsync(cartId)
      ).unwrap();
      dispatch(changeCartItems(response?.result?.[0]?.items));
      dispatch(changeTotalCart(response?.result?.[0]?.items?.length));
      notify(t("Delete item from cart successfully"), "success");
    } else {
      let msg = res?.message;
      if (msg) {
        try {
          let msgJson = JSON.parse(msg);
          msg = msgJson?.message ?? null;
        } catch (error) {
          msg = null;
        }
      }
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: msg ?? t("Delete item from cart error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
    setLoading(false);
  };

  const handleAddIntoCart = async (item: any) => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      addItemOderAsync({
        order_id: cartId,
        item_id: item?.id,
        collection_id: item?.collection,
      })
    ).unwrap();

    if (res?.status === 200) {
      // @ts-ignore
      const response = await dispatch(getItemInOrderAsync(cartId)).unwrap();

      dispatch(changeCartItems(response?.result?.[0]?.items));
      dispatch(changeTotalCart(response?.result?.[0]?.items?.length));

      notify(t("Add item to cart successfully"), "success");
    } else {
      let a: any = JSON.parse(res?.message);

      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: res?.message ? a?.message : t("Can not add item into cart"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
    setLoading(false);
  };

  const handleCart = () => {
    setFlag(!flag);
    flag ? handleDelete(props?.data) : handleAddIntoCart(props?.data);
  };

  const { currentRole } = useSelector((state: RootState) => state.manageuser);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  console.log(pageName, 'pageName in infometada');

  return (
    <>
      {visibleSpinner && !props?.loading && <LoadingGlobalComp />}
      {loading && <LoadingGlobalComp />}
      <div
        style={{
          opacity: props?.loading ? 0.3 : 1,
          cursor: props?.loading ? "none" : "initial",
        }}
      >
        <div
          id="action_button"
          className="relative rounded mb-2 bg-black !h-[200px] flex flex-col justify-center items-center"
        >
          <div
            className="absolute right-2 flex"
            style={{ zIndex: 99999, marginTop: "-132px" }}
          >
            <ButtonComponent
              appearance="outline"
              icon={
                <img className="img-svg-icon" src={MapItemSvg} alt="Dismiss" />
              }
              className={
                "btn-action-drawer " +
                (pageName === "view-item-in-map" ||
                (pageName === "mainMap" &&
                  props?.selectedIds?.includes(props?.data?.id))
                  ? "!bg-primary-500 !border-primary-500 "
                  : "")
              }
              onClick={(e: any) => {
                e.preventDefault();

                navigate(
                  `/dataset/${props?.data?.collection}/${props?.data?.id}/map`
                );
              }}
            />

            {currentRole === HASH_ROLE_USE && (
              <ButtonComponent
                icon={
                  <img
                    className="img-svg-icon"
                    src={CartItemSvg}
                    alt="Dismiss"
                  />
                }
                className={
                  "btn-action-drawer-info !ml-2 " +
                  (flag
                    ? "!bg-primary-500 !border-primary-500 "
                    : "!bg-[#272D3C] hover:!bg-[#2D3342]")
                }
                size="medium"
                iconPosition="before"
                onClick={handleCart}
              />
            )}
          </div>

          {pathImg?.length === 0 && (
            <img
              className="w-full !h-[100px] !object-contain"
              src={LayerSvg}
              alt="layer"
              onError={(e: any) => {
                e.target.src = LayerSvg;
              }}
            />
          )}

          {pathImg && pathImg?.length === 1 && (
            <img
              loading="lazy"
              className={`w-full !object-contain ${
                hasError ? "!h-[100px]" : "!h-[200px]"
              }`}
              src={`${localStorage.getItem("REACT_APP_ENDPOINT")}/collections/${
                props?.data?.collection
              }/items/${props?.data?.id}/thumbnails/${pathImg?.[0]}/`}
              onError={(e: any) => {
                e.target.src = LayerSvg;
                setHasError(true);
                e.target.onerror = null;
              }}
            />
          )}

          {pathImg && pathImg?.length > 1 && (
            <Carousel>
              {props?.data?.thumbnail?.map((item: any, index: any) => (
                <div>
                  <img
                    loading="lazy"
                    className={`w-full !object-contain ${
                      hasError ? "!h-[100px]" : "!h-[200px]"
                    }`}
                    src={`${localStorage.getItem(
                      "REACT_APP_ENDPOINT"
                    )}/collections/${props?.data?.collection}/items/${
                      props?.data?.id
                    }/thumbnails/${item}/`}
                    onError={(e: any) => {
                      e.target.src = LayerSvg;
                      setHasError(true);
                      e.target.onerror = null;
                    }}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>

        <div id="info" className="mb-3">
          <p
            className="down-line text-xs ellipsis-text"
            style={{ margin: "8px 0 6px 0" }}
          >
            {props?.data?.properties?.title}
          </p>
          <b className="mt-1 down-line text-title-card !uppercase text-des ellipsis-text-one-line">
            {props?.data?.description}
          </b>
        </div>
        <TabList
          className="flex w-full justify-around h-10 items-center mb-5 cursor-pointer"
          defaultSelectedValue="Metadata"
        >
          <StyledDivTablist
            value="Metadata"
            onClick={() => {
              setType("Metadata");
            }}
          >
            Metadata
          </StyledDivTablist>
          <StyledDivTablist
            value="Assets"
            onClick={() => {
              setType("Assets");
            }}
          >
            Assets
          </StyledDivTablist>
        </TabList>
        <div>
          {type === "Metadata" && (
            <InfoMetadata
              data={arrMetaData}
              dataProperties={props?.data?.properties}
            />
          )}
          {type === "Assets" && <AssetsComp data={props?.data?.assets} />}
        </div>
      </div>
    </>
  );
};
export default IndexDrawerDataset;
