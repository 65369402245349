import { createSlice } from '@reduxjs/toolkit'

export const maplibreGLSlice = createSlice({
    name: 'maplibreGL',
    initialState: {
        maplibreGL: undefined,
        mousePointer: {
            lnglat: null,
            point: null
        },
        zoomLevel: 5,
        center: { lng: 108.22717737212409, lat: 16.061300184261228 },
        crs: "EPSG:4326",
        scale: 5
    },
    reducers: {
        setMap: (state, action) => {
            state.maplibreGL = action?.payload;
        },
        changeMousePointer: (state, action) => {
            state.mousePointer = action?.payload;
        },
        changeCenter: (state, action) => {
            state.center = action?.payload;
        },
        changeZoomLevel: (state, action) => {
            state.zoomLevel = action?.payload;
            state.scale = Math.round(state.zoomLevel);
        },
        changeMapCrs: (state, action) => {
            state.crs = action?.payload;
        },
        changeMapScale: (state, action) => {
            state.scale = action?.payload;
        }

    },
})
export const { setMap, changeMousePointer, changeCenter, changeZoomLevel, changeMapCrs, changeMapScale } = maplibreGLSlice.actions

export default maplibreGLSlice.reducer