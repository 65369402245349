import
  {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip
  } from "@fluentui/react-components";

import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserQuotaSvg from "../../assets/user-quota-item.svg";
import DeleteSvg from "./../../assets/delete.svg";

const CardItemUserQuota = (props: any) => {
  const handleDeleteItem = (e: any, item: any) => {
    e.stopPropagation();

    props?.deleteItem(item);
  };

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section key={index}>
            <div
              className="bg-[#2E3240] rounded"
              onClick={() => props?.handleViewQuotaUser(item)}
            >
              <Card
                orientation="horizontal"
                className="card-item-storage !bg-[#2E3240]"
              >
                <CardPreview
                  className="div-center div-card-preview"
                  onClick={() => props?.handleViewQuotaUser(item)}
                >
                  <img
                    src={UserQuotaSvg}
                    alt="img item"
                    className="img-card-item-catalog"
                  />
                </CardPreview>
                <CardHeader
                  className="w-auto div-card-header"
                  onClick={() => props?.handleViewQuotaUser(item)}
                  header={
                    <>
                      <div className="divHeader relative">
                        <div className="block w-full">
                          <div style={{ width: "100%" }}>
                            {item?.user?.traits?.name?.first &&
                            item?.user?.traits?.name?.last ? (
                              <Tooltip
                                relationship="description"
                                content={
                                  item?.user?.traits?.name?.first +
                                  " " +
                                  item?.user?.traits?.name?.last
                                }
                              >
                                <p className="!uppercase text-title-card ellipsis-text-one-line">
                                  {item?.user?.traits?.name?.first}{" "}
                                  {item?.user?.traits?.name?.last}
                                </p>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                relationship="description"
                                content={item?.user?.traits?.email}
                              >
                                <p className="!uppercase text-title-card ellipsis-text-one-line">
                                  {item?.user?.traits?.email}
                                </p>
                              </Tooltip>
                            )}

                            {item?.totalQ > 1 ? (
                              <p className="text-des">
                                Total quotas: {item?.totalQ}
                              </p>
                            ) : (
                              <Tooltip
                                relationship="description"
                                content={
                                  item?.name +
                                  " - " +
                                  item?.quota_type +
                                  "-" +
                                  item?.limit_value +
                                  "-" +
                                  item?.unit
                                }
                              >
                                <p className="!mt-1  ellipsis-text text-des ellipsis-text-one-line">
                                  <span>
                                    {item?.quota_type} | {' '}
                                    {item?.limit_value} {item?.unit}
                                  </span>
                                </p>
                              </Tooltip>
                            )}
                          </div>
                        </div>

                        <div />
                      </div>
                    </>
                  }
                />

                <CardFooter className="footer-card-action !w-[80px] !max-w-[80px]">
                  {item?.totalQ === 1 && (
                    <div className="div-action-card" onClick={(e) => e.stopPropagation()}>
                      <div>
                        <Menu
                        >
                          <MenuTrigger disableButtonEnhancement>
                            <img
                              className="mr-2 img-svg-icon"
                              src={DeleteSvg}
                              alt="Dismiss"
                            />
                          </MenuTrigger>

                          <MenuPopover>
                            <MenuList
                              onClick={(e) => handleDeleteItem(e, item)}
                            >
                              <MenuItem>Delete </MenuItem>
                            </MenuList>
                          </MenuPopover>
                        </Menu>
                      </div>
                    </div>
                  )}

                  <Tooltip
                    content={item?.percent * 100 + `%`}
                    relationship={"label"}
                  >
                    <div
                      className="w-8 h-8"
                      onClick={() => props?.handleViewQuotaUser(item)}
                    >
                      <CircularProgressbar
                        value={item?.percent}
                        maxValue={1}
                        text={`${item?.percent * 100}%`}
                        strokeWidth={12}
                        styles={buildStyles({
                          textColor:
                            item?.percent > 0.9
                              ? "#DD2C00"
                              : item?.percent > 0.75 && item?.percent < 0.9
                              ? "#EF6C00"
                              : "#60CDFF",
                          pathColor:
                            item?.percent > 0.9
                              ? "#DD2C00"
                              : item?.percent > 0.75 && item?.percent < 0.9
                              ? "#EF6C00"
                              : "#60CDFF",
                          trailColor: "#565656",
                        })}
                      />
                    </div>
                  </Tooltip>
                </CardFooter>
              </Card>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CardItemUserQuota;
