import {
  Button,
  Dropdown,
  Option,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { getProductTypeAsync } from "../../store/exploreSlice";
import { updateCollectionStorageAsync } from "../../store/ManageCollectionStorageSlice";
import { getListAsync as getListStorage } from "../../store/ManageStorageSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";

const FormEditDatasetStorageComp = () => {
  const navigate = useCustomNavigate();
  const location = useLocation();
  const itemEdit = location?.state?.item;
  const params = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [arrStorage, setArrStorage] = useState([]);

  const [storageId, setStorageId] = useState(itemEdit?.storage_id);
  const [storageDes, setStorageDes] = useState(itemEdit?.storage?.name);
  const [productType, setProductType] = useState(itemEdit?.product_type);
  const [satellites, setSatellites] = useState<Array<any>>([]);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListStorage({
            size: 1000,
            sortby: "modified",
            asc: false,
          })
        ).unwrap();

        setArrStorage(res?.result);

        // @ts-ignore
        const resProductType = await dispatch(getProductTypeAsync()).unwrap();
        setSatellites(resProductType?.data ?? []);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };
  const update = async () => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      updateCollectionStorageAsync({
        product_type: productType,
        collection_storage_id: itemEdit?.id ?? params?.idCollectionStorage,
        // storage_id: storageId,
      })
    ).unwrap();

    if (res?.status === 200) {
      setTimeout(() => {
        notify("Update successfully", "success");
        navigate(`/manage-dataset/setting/${params?.id}`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Update fail", "error");
      setTimeout(() => {
        // navigate(`/manage-dataset/setting/${params?.id}`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };
  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage-small ">
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            {/* <p className="mb-2">Storage</p>
            <Dropdown
              placeholder="-- Select --"
              className="div-dropdown"
              onOptionSelect={(e: any, data: any) => {
                setStorageId(data?.optionValue);
                setStorageDes(data?.optionText);
              }}
              value={storageDes}
            >
              {arrStorage?.map((item: any) => (
                <Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Dropdown> */}
            <p className="mb-2">Product type</p>

            <Dropdown
              placeholder="Select product type"
              className="div-dropdown"
              selectedOptions={[productType]}
              // multiselect={true}
              onOptionSelect={(e: any, data: any) => {
                setProductType(data?.optionValue);
              }}
              value={productType}
            >
              {satellites?.map((option: any) => (
                <Option key={option} value={option?.platform}>
                  {option?.product}
                </Option>
              ))}
            </Dropdown>

            {/* <RadioGroup value={productType}>
              {arrRsType?.map((item: any, index: any) => (
                <Radio
                  key={index}
                  label={item}
                  value={item}
                  onChange={(e: any, val: any) => {
                    setProductType(val?.value);
                  }}
                />
              ))}
            </RadioGroup> */}
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => navigate(`/manage-dataset/setting/${params?.id}`)}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          appearance="outline"
          className="btn-action"
          onClick={() => update()}
          disabled={loading || productType === itemEdit?.product_type}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default FormEditDatasetStorageComp;
