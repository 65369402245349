import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
// import { ButtonComponent as Button } from "@web/react-component-ui-user";

import NextSvg from "./../../assets/next.svg";
import SuccessIcon from "./../../assets/user-active.svg";
import ErrorIcon from "./../../assets/user-inactive.svg";

import useCustomNavigate from "../../hook/useCustomNavigate";

const CardItemUserComp = (props: any) => {
  const navigate = useCustomNavigate();

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section
            key={index}
            onClick={
              item?.state === "active"
                ? () =>
                    navigate(`${document.location.pathname}/${item?.id}`, {
                      state: {
                        item: item,
                      },
                    })
                : () => {}
            }
          >
            <div className="bg-[#2E3240] rounded">
              <Card
                orientation="horizontal"
                className="card-item-storage !bg-[#2E3240]"
              >
                <CardPreview className="div-center div-card-preview">
                  {item?.state && item?.state === "active" ? (
                    <Tooltip relationship="description" content={"active"}>
                      <img
                        className="img-card-item-catalog"
                        src={SuccessIcon}
                        alt="img item"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip relationship="description" content={"inactive"}>
                      <img
                        className="img-card-item-catalog"
                        src={ErrorIcon}
                        alt="img item"
                      />
                    </Tooltip>
                  )}
                </CardPreview>
                <CardHeader
                  className="w-auto div-card-header"
                  // style={{ width: "calc(100% - 105px)" }}
                  header={
                    <div className="divHeader relative">
                      <div className="block w-full">
                        {item?.traits?.name ? (
                          <Tooltip
                            relationship="description"
                            content={
                              item?.traits?.name?.last +
                              " " +
                              item?.traits?.name?.first
                            }
                          >
                            <p className="!uppercase text-title-card ellipsis-text-one-line">
                              {item?.traits?.name?.last}{" "}
                              {item?.traits?.name?.first}
                            </p>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            relationship="description"
                            content={item?.traits?.email}
                          >
                            <p className="!uppercase text-title-card ellipsis-text-one-line">
                              {item?.traits?.email}
                            </p>
                          </Tooltip>
                        )}

                        <Tooltip
                          relationship="description"
                          content={` Group:
                              ${
                                item?.groups?.length === 0
                                  ? " User"
                                  : item?.groups?.join(", ")
                              }`}
                        >
                          <p className="mt-1 text-des ellipsis-text-one-line">
                            <span>
                              Group:{" "}
                              {item?.groups?.length === 0
                                ? "User"
                                : item?.groups?.join(", ")}
                            </span>
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  }
                />

                {item?.state === "active" && (
                  <CardFooter className="footer-card-action">
                    <Button
                      className="btn-action-card !mr-3"
                      appearance="subtle"
                      size="medium"
                      icon={
                        <img
                          className="img-svg-icon mr-2"
                          src={NextSvg}
                          alt="Info Circle"
                        />
                      }
                    />
                  </CardFooter>
                )}
              </Card>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CardItemUserComp;
