import { Text } from "@fluentui/react-components";

const CardInforHistoryScan = (props: any) => {
  return (
    <div
      className={`flex gap-4 justify-between my-8 columns-1 sm:columns-2 md:columns-2 lg:columns-4 xl:columns-4 `}
    >
      {props?.data?.map((item: any, index: any) => (
        <div
          key={index}
          className="flex card-info-hc mb-4"
          style={{ height: "unset", minHeight: "74px" }}
        >
          {item?.icon && (
            <img className="img-svg-icon-add mr-3" src={item?.icon} />
          )}
          <div className="block">
            <p className="text-color-des !mb-3"> {item?.title}</p>

            <Text weight="semibold" style={{ overflowWrap: "anywhere" }}>
              {item?.value}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardInforHistoryScan;
