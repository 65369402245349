import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  Spinner,
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import {
  changeFlagFilter,
  changeTotalNoti,
  changeTotalNotiNotRead,
  getNotiUserAsync,
} from "../../store/notificationsSlice";

import { useTranslation } from "react-i18next";

import { changeSateVisibleReadNoti } from "../../store/DrawerSlice";
import {
  changeSateItemRead,
  userUpdateNotiAsync,
} from "../../store/notificationsSlice";

import { changeStateArrNoti } from "../../store/pagingSlice";
import { timeout } from "../../utils/constants";
import CardNotiDrawerComp from "./CardNotiDrawer";

const DrawerNotification = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const [loading, setLoading] = useState<boolean>(false);

  const { arrNoti } = useSelector((state: RootState) => state.paging);
  // const [arrUnRead, setArrUnRead] = useState(
  //   arrNoti?.filter((item: any) => !item?.seen)
  // );
  const [dataNoti, setDataNoti] = useState([]);

  const { flagFilter } = useSelector((state: RootState) => state.notifications);
  useEffect(() => {
    (async function () {
      if (props?.visibleNoti) {
        try {
          //   setLoading(true);
          const res = await dispatch(
            // @ts-ignore
            getNotiUserAsync({
              sortby: "created",
              asc: false,
              page: 0,
              size: 1000,
            })
          ).unwrap();

          // setArrUnRead(res?.result?.filter((item: any) => !item?.seen));
          setDataNoti(res?.result?.filter((item: any) => !item?.deleted));
          dispatch(changeStateArrNoti(res?.result ?? []));
          let b = res?.result?.filter(
            (item: any) => !item?.seen && !item?.deleted
          );

          dispatch(changeTotalNoti(b?.length));

          //   setLoading(false);
        } catch (e) {
          console.error(e);
          //   setLoading(false);
        }
      }
    })();
  }, [props?.visibleNoti]);

  useEffect(() => {
    (async function () {
      if (flagFilter) {
        try {
          //   setLoading(true);
          const res = await dispatch(
            // @ts-ignore
            getNotiUserAsync({
              sortby: "created",
              asc: false,
              page: 0,
              size: 1000,
            })
          ).unwrap();

          // setArrUnRead(res?.result?.filter((item: any) => !item?.seen));
          setDataNoti(res?.result?.filter((item: any) => !item?.deleted));
          dispatch(changeStateArrNoti(res?.result ?? []));
          let b = res?.result?.filter((item: any) => !item?.deleted);

          dispatch(
            changeTotalNoti(
              res?.result?.filter((item: any) => !item?.seen)?.length
            )
          );
          dispatch(changeFlagFilter(false));

          //   setLoading(false);
        } catch (e) {
          console.error(e);
          //   setLoading(false);
        }
      }
    })();
  }, [flagFilter]);

  const notify = (result: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>{result}</ToastTitle>
      </Toast>,
      { timeout: timeout, position: "bottom", intent: type }
    );
  };

  const reloadData = async () => {
    const res = await dispatch(
      // @ts-ignore
      getNotiUserAsync({
        sortby: "created",
        asc: false,
        page: 0,
        size: 1000,
      })
    ).unwrap();

    dispatch(changeStateArrNoti(res?.result ?? []));

    let a = res?.result?.filter((item: any) => !item?.deleted); // tat ca cac noti chua bi xoa de hien thi o list drawer
    let b = res?.result?.filter((item: any) => !item?.seen && !item?.deleted); // tat ca noti chua duoc seen va ko bi xoa
    let c = res?.result?.filter((item: any) => !item?.deleted && !item?.readed); // tat ca cac item da seen chua doc va chua xoa
    dispatch(changeTotalNoti(b?.length));

    dispatch(changeTotalNotiNotRead(c?.length));

    setDataNoti(a ?? []);
    // setArrUnRead(b ?? []);
  };

  const handleReadItem = async (item: any) => {
    if (item?.readed) {
      dispatch(changeSateVisibleReadNoti(true));
      dispatch(changeSateItemRead(item));
    } else {
      setLoading(true);
      const res = await dispatch(
        // @ts-ignore
        userUpdateNotiAsync({ id: item?.notification_id, pay: { read: true } })
      ).unwrap();

      if (res?.status === 200) {
        reloadData();

        dispatch(changeSateVisibleReadNoti(true));
        dispatch(changeSateItemRead(item));
        setLoading(false);
      } else {
        dispatch(changeSateVisibleReadNoti(true));
        dispatch(changeSateItemRead(item));
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      userUpdateNotiAsync({ id: id, pay: { delete: true } })
    ).unwrap();

    if (res?.status === 200) {
      reloadData();
      notify("Delete notication successfully", "success");
      setLoading(false);
    } else {
      notify("Delete notication fail", "error");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full z-2 flex items-center justify-center !bg-black/25">
          <center>
            <Spinner className="absolute top-1/2 left-1/2" />
          </center>
        </div>
      )}

      <CardNotiDrawerComp
        data={dataNoti}
        handleReadItem={handleReadItem}
        handleDelete={handleDelete}
      />
    </>
  );
};
export default DrawerNotification;
