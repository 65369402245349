import {
    MenuItem
} from "@fluentui/react-components";
import SearchMapSvg from "../../../assets/search_map.svg";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../store/store";
import { useSelector, useDispatch } from 'react-redux'
import { setIsDrawing, setDrawingType } from '../../../store/drawSlice';

export default function DrawingMenuItem() {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    let draw: MapboxDraw | undefined = useSelector((state: RootState) => state.draw?.draw);

    const onClickDrawing = () => {
        if (draw) {
            dispatch(setIsDrawing(true));
            dispatch(setDrawingType('rectangle'));
            draw.changeMode("draw_rectangle_drag");
        }
    }

    return (
        <MenuItem onClick={(e) => onClickDrawing()} className="!bg-s-background1 hover:!bg-ink-300">
            <div className="flex gap-x-2">
                <img src={SearchMapSvg} alt="Draw" />
                <span>{t('Draw')}</span>
            </div>
        </MenuItem>
    );
}