import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import { useState } from "react";
import { truncateString } from "../../utils/utils";
import FSSvg from "./../../assets/images/fs.svg";

import EditSvg from "./../../assets/edit-light.svg";

import NextSvg from "./../../assets/next.svg";

import DeleteSvg from "./../../assets/delete.svg";

import { useDispatch, useSelector } from "react-redux";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { changePathBrowse } from "../../store/ManageStorageSlice";
import { RootState } from "../../store/store";
import S3svg from "./../../assets/images/s3.svg";

const CardItemOrderImg = (props: any) => {
  const navigate = useCustomNavigate();
  const [value, setValue] = useState(58);
  const dispatch = useDispatch();

  const { dataBreadcrumb } = useSelector(
    (state: RootState) => state.breadcrumn
  );

  const handleBrowserItem = (item: any) =>
  {
     let sun = [...dataBreadcrumb];
     sun.push({
       value: item?.name ?? item?.resource?.S3?.object,
       key: dataBreadcrumb?.[dataBreadcrumb?.length - 1]?.key + 1,
       url: null,
     });

     dispatch(changeDataBreadcrumb(sun));
     dispatch(changePathBrowse(null));
     navigate(`./${item?.id}/browser`, {
       state: {
         item: item,
       },
     });
  }

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section
            key={index}
            onClick={() => {
             handleBrowserItem(item)
            }}
          >
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview
                className="div-center div-card-preview flex !flex-row"
              >
                <img
                  src={item?.resource?.FS?.path ? FSSvg : S3svg}
                  alt="img item"
                  className="img-card-item-catalog"
                />
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <Tooltip relationship="description" content={item?.name}>
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.name}
                        </p>
                      </Tooltip>

                      <Tooltip
                        relationship="description"
                        content={item?.description}
                      >
                        <p className="mt-1 ellipsis-text-one-line text-des">
                          {truncateString(item?.description, 100)}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <div
                  className="div-action-card"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${document.location.pathname}/edit/${item?.id}`,
                        {
                          state: {
                            item: item,
                          },
                        }
                      );
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon"
                      src={EditSvg}
                      alt="Dismiss"
                    />
                  </div>

                  <div>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="mr-2 img-svg-icon"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() => {
                            props?.deleteItem(item?.id);
                          }}
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                </div>
                <div className="flex items-center">
                  <div
                    x-data="scrollProgress"
                    className="flex text-center items-center justify-center overflow-hidden rounded-full mr-6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx={14}
                        cy="13.9999"
                        r={12}
                        transform="rotate(45 14 13.9999)"
                        stroke="#565656"
                        strokeWidth={4}
                      />
                      <path
                        d="M22.4852 22.4852C17.7989 27.1715 10.2009 27.1715 5.51465 22.4852C0.828357 17.7989 0.828357 10.2009 5.51465 5.51465"
                        stroke="#60CDFF"
                        strokeWidth={4}
                        strokeLinecap="round"
                        strokeDasharray={value}
                        strokeDashoffset={100}
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                     handleBrowserItem(item)
                    }}
                  >
                    <img
                      className="img-svg-icon"
                      src={NextSvg}
                      alt="Info Circle"
                    />
                  </div>
                </div>
              </CardFooter>
            </Card>
          </section>
        );
      })}
    </>
  );
};

export default CardItemOrderImg;
