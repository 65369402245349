import {
  Spinner,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import Editor from "@monaco-editor/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import {
  changeStateStringValidate,
  editCollectionsAsync,
  validateItemCollectionsAsync,
} from "./../../store/exploreSlice";

const CreateItemDatasetPage = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const { statusValidate, status } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const [value, setValue] = useState(
    JSON.stringify(location?.state?.item, null, 2)
  );
  const [state, setState] = useState(!statusValidate ? "validate" : "post");
  const customTheme = {
    base: "vs-dark",
    inherit: true,
    rules: [
      {
        token: "comment",
        foreground: "#5d7988",
        fontStyle: "italic",
      },
      { token: "constant", foreground: "#e06c75" },
    ],
    colors: {
      "editor.background": "#21252b",
    },
  };
  useEffect(() => {
    // @ts-ignore
    monaco.editor.defineTheme("my-custom-theme", customTheme);
    monaco.editor.setTheme("my-custom-theme");
  }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "Dataset",
          path: "manage-dataset",
        },
        {
          key: 1,
          value: location?.state?.parent?.title,
          path: `manage-dataset/${location?.state?.parent?.id}`,
        },
        {
          key: 2,
          value: "Add Item",
          path: "",
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(changeStateStringValidate());
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-item-dataset/add"));
    dispatch(changeName("manage-item-dataset/add"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  const handleEditorChange = (value: any, event: any) => {
    setValue(value);
    setState("validate");
    // @ts-ignore
    dispatch(changeStateStringValidate());
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (result: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>
          {state === "validate" && (
            <p>
              {result?.status === 200
                ? "Validate successfully"
                : "Validate fail"}
            </p>
          )}
          {state === "post" && (
            <p>{result === 200 ? "Update successfully" : "Update fail"}</p>
          )}
        </ToastBody>
      </Toast>,

      { timeout: timeout, position: "bottom", intent: type }
    );
  };

  const handleValidate = () => {
    setLoading(true);
    // @ts-ignore
    dispatch(validateItemCollectionsAsync(value))
      .then(unwrapResult)
      .then((result: any) => {
        setState(result?.status === 200 ? "post" : "validate");
        notify(result, result?.status === 200 ? "success" : "error");
        setLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleFinish = () => {
    setLoading(true);
    // @ts-ignore
    dispatch(editCollectionsAsync({ id: location?.state?.item?.id, value }))
      .then(unwrapResult)
      .then((result: any) => {
        notify(result?.status, result?.status === 200 ? "success" : "error");
        // @ts-ignore
        setValue(undefined);
        setState("validate");
        setLoading(false);
        // @ts-ignore
        dispatch(changeStateStringValidate());
      })
      .catch((error: any) => {
        setLoading(false);
        console.error(error);
      });
  };
  return (
    <>
      <div
        style={{
          opacity: loading ? 0.3 : 1,
          cursor: loading ? "none" : "initial",
        }}
        className="px-6 py-6 !w-full"
      >
        {loading && (
          <center>
            <Spinner className="absolute top-1/2 left-1/2 z-[9999]" />
          </center>
        )}

        {/* <BreadcrumbComp data={dataBread} /> */}

        {/* <p className="mb-4">Enter information item</p> */}
        <Editor
          height="calc(100vh - 80px - 102px - 90px)"
          defaultLanguage="json"
          defaultValue={value}
          theme="my-custom-theme"
          onChange={handleEditorChange}
        />

        <div className="mt-4 float-right">
          <Button
            className="btn-back"
            title="Cancel"
            onClick={() => navigate(`/manage-dataset`)}
          />
          <Button
            appearance="primary"
            title={state === "post" ? "Add" : "Validate"}
            className="text-black !ml-3"
            onClick={state === "post" ? handleFinish : handleValidate}
          />
        </div>
      </div>
    </>
  );
};

export default CreateItemDatasetPage;
