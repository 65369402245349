import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { HASH_ROLE_ADMIN, HASH_ROLE_USE } from "../utils/constants";
import PageNotFound from "../pages/404";

import {
  arrPathAdmin,
  arrPathAdminLoginUser,
  arrPathUser,
} from "../utils/data";
import {
  CSSTransition,
  SwitchTransition,
  TransitionGroup,
} from "react-transition-group";
import { Route, Routes, useLocation } from "react-router-dom";
import { changeAnimationPage, changeHref } from "../store/pagingSlice";
import useLocationChange from "../hook/useLocationChange";

const EoRouter = () => {
  const dispatch = useDispatch();
  const { animationPage, arrHref } = useSelector(
    (state: RootState) => state.paging
  );
  const location = useLocation();
  const { currentRole, roleLogin } = useSelector(
    (state: RootState) => state.manageuser
  );
  const [userPaths, setUserPaths] = useState<Array<any>>([]);
  useEffect(() => {
    findPaths(currentRole);
  }, [currentRole]);
  const findPaths = (r: string) => {
    let paths =
      r === HASH_ROLE_ADMIN && roleLogin === HASH_ROLE_ADMIN
        ? arrPathAdmin
        : r === HASH_ROLE_USE && roleLogin === HASH_ROLE_ADMIN
        ? arrPathAdminLoginUser
        : arrPathUser;

    paths = [
      ...paths,
      {
        path: "*",
        element: <PageNotFound />,
      },
    ];

    setUserPaths(paths);
  };

  const handleData = (arrVal: any, path: any) => {
    if (arrVal.indexOf(path) === -1) {
      dispatch(changeAnimationPage(true));

      dispatch(changeHref(arrVal.concat(path)));
    } else {
      if (arrVal.indexOf(path) !== arrVal.length - 1) {
        if (arrVal[arrVal.length - 1].search(path) === 0) {
          dispatch(changeAnimationPage(false));
        }
        dispatch(
          changeHref(
            arrVal.filter(
              (_item: any, index: number) => index <= arrVal.indexOf(path)
            )
          )
        );
      }
    }
  };

  useEffect(() => {
  
    dispatch(changeHref(arrHref.concat(location.pathname)));
  }, []);

  useEffect(() => {
    console.log("mount");
    handleData(
      arrHref,
      location.pathname.charAt(0) === "/"
        ? location.pathname.replace("/", "")
        : location.pathname
    );
  }, [location.pathname]);

  console.log(location.pathname, "location.pathname");

  return (
    <TransitionGroup
      className="!w-full"
      component="div"
      exit={false}
      in={false}
    >
      <SwitchTransition mode="out-in">
        {/* @ts-ignore */}
        <CSSTransition
          key={window.location.href}
          classNames={animationPage ? "fade-in" : "fade-out"}
          timeout={500}
          in={false}
          exit={false}
          unmountOnExit
        >
          <Routes>
            {userPaths.map((item: any) => (
              <Route
                path={item?.path}
                element={item?.element}
                key={item?.path}
              />
            ))}
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </TransitionGroup>
    // <Routes>
    //   {userPaths.map((item: any) => (
    //     <Route path={item?.path} element={item?.element} key={item?.path} />
    //   ))}
    // </Routes>
  );
};

export default EoRouter;
