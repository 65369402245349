import HisSearchSvg from "../assets/his-search.svg";
import JobSvg from "../assets/job.svg";
import ManageNotiSvg from "../assets/manage-noti.svg";
import ManageUserSvg from "../assets/manage-user.svg";
import QuotasSvg from "../assets/quotas.svg";
import SchedulerSvg from "../assets/scheduler.svg";
import TaskSvg from "../assets/task.svg";
import UserQuotaSvg from "../assets/user-quota.svg";
import AlertSvg from "./../assets/Alert.svg";
import SettingSvg from "./../assets/Setting.svg";
import AccountSvg from "./../assets/account.svg";
import DatasetAdminSvg from "./../assets/dataset-admin.svg";
import DropdownSvg from "./../assets/dropdown.svg";
import ExploreIconSvg from "./../assets/explore.svg";
import MyOrderSvg from "./../assets/my-order.svg";
import QuotaSvg from "./../assets/quota-icon-menu.svg";
import ScanSvg from "./../assets/scan.svg";
import ShoppingCartSvg from "./../assets/shopping-cart.svg";
import StorageSvg from "./../assets/storage.svg";

import Logo from "./../assets/logo.png";

import { Navigate } from "react-router-dom";

import MainMap from "./../pages/MainMap";
import DatasetUserIdPage from "./../pages/[datasetUserId]";
import DatasetUserPage from "./../pages/datasetuser";
import InvoicePage from "./../pages/invoice";

import ViewItemMap from "../pages/[datasetUserId]/ViewMap";
import InvoiceItemPage from "../pages/[invoiceId]";

import HistorySearchPage from "./../pages/HistorySearch";

import AddCollectionStoragePage from "../pages/ManageDataset/AddCollectionStorage";
import AutoScanPage from "../pages/ManageDataset/AutoScan";
import EditCollectionStoragePage from "../pages/ManageDataset/EditCollectionStorage";
import SettingDatasetPage from "../pages/ManageDataset/SettingDataset";
import CreateDatasetPage from "./../pages/ManageDataset/CreateDataset";
import EditDatasetPage from "./../pages/ManageDataset/EditDataset";

import CreateItemDatasetPage from "./../pages/ManageDataset/CreateItemDataset";
import EditItemDatasetPage from "./../pages/ManageDataset/EditItemDataset";

import AddQuotaPage from "./../pages/ManageQuota/AddQuota";
import EditedQuotaPage from "./../pages/ManageQuota/EditQuota";
import ManageQuotasPage from "./../pages/ManageQuota/ManageQuota";

import AddUserQuotaPage from "./../pages/ManageQuota/AddUserQuota";
import UserQuotaPage from "./../pages/ManageQuota/UserQuota";

import DatasetId from "./../pages/[datasetId]";
import DatasetPage from "./../pages/dataset";

import HistoryScanViewDetailPage from "../pages/[historyScanId]";
import ManageOderPage from "./../pages/ManageOder";
import CreateStoragePage from "./../pages/ManageStorage/CreateStorage";
import EditStorage from "./../pages/ManageStorage/EditStorage";
import HistoryScanPage from "./../pages/ManageStorage/HistoryScan";
import ListStoragePage from "./../pages/ManageStorage/ListStorage";
import ManageOderDetail from "./../pages/[manageOderId]/index";

import AddNotificationPage from "../pages/ManageNotification/AddNotification";
import EditNotificationPage from "../pages/ManageNotification/EditNotification";
import ManageNotificationPage from "../pages/ManageNotification/ManageNotification";

import AddStorageDownloadPage from "../pages/SettingDownload/AddSettingStorageDownload";
import EditStorageDownloadPage from "../pages/SettingDownload/EditSettingStorageDownload";

import AddTaskPage from "../pages/ManageScheduler/AddTask";
import EditTaskPage from "../pages/ManageScheduler/EditTask";
import ListJobPage from "../pages/ManageScheduler/ListJob";
import ListTaskPage from "../pages/ManageScheduler/ListTask";

import ChangeRolePage from "../pages/ChangeRole";
import DetailSystemKeyPage from "../pages/ManageSettingSystem/DetailSystemKeyPage";
import SettingSystemPage from "../pages/ManageSettingSystem/ListSettingSystem";

import ViewJobInTask from "../pages/ManageScheduler/[viewJobInTaskId]";
import ListSearchHisPage from "../pages/ManageSearchHistory/ListSearchHis";
import ViewBrowseStoragePage from "../pages/ManageStorage/[viewBrowseStorageId]/ViewBrowseStorage";

import ManageUserPage from "../pages/ManageUser/ListUser";

import ManageUserDetailPage from "../pages/[manageUserId]";

export const arrCheckHealth = ["Down", "down", "invalid", "disconnect"];

export const arrPathVisibleSeachBar = [
  "mainMap",
  "manage-quota",
  "scheduler/task",
  "list-storage",
  // "setting-system",
  "manage-notification",
  "manage-dataset-item",
  "dataset-item",
];

export const arrPathDisablePagingFliter = [
  "dataset",
  "manage-dataset",
  // "manage-dataset-item",
  "manage-user-quota",
  "setting-download",
  // "dataset-item",
  "setting",
  "account",
  "manage-order/item",
  "invoiceItem",
  "history-scan/id-item",
  "scheduler/job/add",
  "scheduler/job/edit",
  "manage-dataset/auto-scan",
  "setting-system",
  "manage-quota-edit",
  "manage-quota-add",
  "add-user-quota",
  "add-noti",
  "edit-noti",
  "upd-setting-system",
  "view-browse-storage",
  "create-storage",
  "edit-storage",
  "404-page",
  "manage-dataset-item",
  "dataset-item",
  "manage-user",
  "manage-user/id-user",
  "manage-dataset/setting/idCollection/edit",
  "manage-dataset/setting/idCollection/create",
  "manage-dataset/setting/idCollection",
  "manage-item-dataset/add",
  "manage-item-dataset/edit",
  "manage-dataset/add",
  "manage-dataset/edit",
  "view-item-in-map",
];

export const arrPathDisablePaing = [
  "create-storage",
  "edit-storage",
  "manage-dataset",
  "manage-order/item",
  "manage-quota-edit",
  "manage-quota-add",
  "add-user-quota",
  "add-noti",
  "edit-noti",
  "upd-setting-system",
  "invoiceItem",
  // "history-scan/id-item",
  "scheduler/job/add",
  "scheduler/job/edit",
  "setting-system",
  "account",
  "404-page",
  "manage-dataset-item",
  "dataset-item",
  // "manage-user",
  "manage-user/id-user",
  "manage-dataset/setting/idCollection/edit",
  "manage-dataset/setting/idCollection/create",
  "manage-dataset/auto-scan",
  // "manage-dataset/setting/idCollection",
  "manage-item-dataset/add",
  "manage-item-dataset/edit",
  "manage-dataset/add",
  "manage-dataset/edit",
  "view-item-in-map",
];

export const arrMenuUser = [
  {
    title: "Explore",
    path: "/main-map",
    icon: <img className="img-svg-icon" src={ExploreIconSvg} alt="main-map" />,
  },
  {
    title: "Catalog",
    path: "/dataset",
    icon: <img className="img-svg-icon" src={DatasetAdminSvg} alt="dataset" />,
  },

  {
    title: "My order",
    path: "/invoice",
    icon: <img className="img-svg-icon" src={MyOrderSvg} alt="my-order" />,
  },
  {
    title: "Search history",
    path: "/search-history",
    icon: <img className="img-svg-icon" src={HisSearchSvg} alt="my-order" />,
  },
];

export const arrMenuAdmin = [
  {
    title: "Dataset",
    path: "/manage-dataset",
    icon: <img className="img-svg-icon" src={DatasetAdminSvg} alt="dataset" />,
  },
  {
    title: "Order",
    path: "/manage-order",
    icon: (
      <img className="img-svg-icon" src={ShoppingCartSvg} alt="manage-order" />
    ),
  },
  {
    title: "Storage",
    path: "/list-storage",
    icon: (
      <img className="img-svg-icon" src={StorageSvg} alt="manage-storage" />
    ),
  },
  {
    title: "Quota",
    path: "/quota",
    icon: <img className="img-svg-icon" src={QuotaSvg} alt="manage-storage" />,
    menuicon: (
      <img
        className="img-svg-icon-menu"
        src={DropdownSvg}
        alt="manage-storage"
      />
    ),
    children: [
      {
        title: "Quotas",
        path: "/manage-quota",
        icon: <img className="img-svg-icon" src={QuotasSvg} alt="dataset" />,
      },
      {
        title: "User quota",
        path: "/manage-user-quota",
        icon: <img className="img-svg-icon" src={UserQuotaSvg} alt="dataset" />,
      },
    ],
  },
  {
    title: "Scheduler",
    path: "/scheduler",
    icon: (
      <img className="img-svg-icon" src={SchedulerSvg} alt="manage-order" />
    ),

    menuicon: (
      <img
        className="img-svg-icon-menu"
        src={DropdownSvg}
        alt="manage-storage"
      />
    ),
    children: [
      {
        title: "Task",
        path: "/scheduler/task",
        icon: <img className="img-svg-icon" src={TaskSvg} alt="dataset" />,
      },
      {
        title: "Job",
        path: "/scheduler/job",
        icon: <img className="img-svg-icon" src={JobSvg} alt="dataset" />,
      },
    ],
  },
  {
    title: "History scan",
    path: "/history-scan",
    icon: <img className="img-svg-icon" src={ScanSvg} alt="manage-storage" />,
  },
  {
    title: "Manage user",
    path: "/manage-user",
    icon: (
      <img className="img-svg-icon" src={ManageUserSvg} alt="manage-user" />
    ),
  },
  {
    title: "Manage notification",
    path: "/manage-notification",
    icon: (
      <img
        className="img-svg-icon"
        src={ManageNotiSvg}
        alt="manage-notification"
      />
    ),
  },
];

export const arrSubMenuUser = (props: any) => [
  {
    title: "Cart",
    type: "action",
    icon: (
      <img
        className="img-svg-icon"
        src={ShoppingCartSvg}
        alt="ShoppingCartSvg"
      />
    ),
  },
  {
    title: "Notification",
    type: "action",
    icon: <img className="img-svg-icon" src={AlertSvg} alt="AlertSvg" />,
  },
  {
    title: "Account",
    type: "path",
    icon: <img className="img-svg-icon" src={AccountSvg} alt="AccountSvg" />,
    path: props?.REACT_APP_ENDPOINT_ACCOUNT,
  },
  // {
  //   title: "Setting",
  //   type: "path",
  //   icon: <img className="img-svg-icon" src={SettingSvg} alt="SettingSvg" />,
  //   path: "/setting",
  // },
];

export const arrSubMenuAdmin = (props: any) => [
  {
    title: "Notification",
    type: "action",
    icon: (
      <img className="img-svg-icon" src={AlertSvg} alt="manage-notification" />
    ),
  },
  {
    title: "Account",
    type: "path",
    icon: <img className="img-svg-icon" src={AccountSvg} alt="AccountSvg" />,
    path: props?.REACT_APP_ENDPOINT_ACCOUNT,
  },
  {
    title: "Setting",
    type: "path",
    icon: <img className="img-svg-icon" src={SettingSvg} alt="SettingSvg" />,
    path: "/setting-system",
  },
];

export const arrNavUser = [
  {
    // title: <img src={Logo} alt="logo-website" />,
    icon: <img src={Logo} className="!w-[56px] ml-4" alt="logo-website" />,
  },
  {
    title: "shopping-cart",
    icon: (
      <img
        className="cursor-pointer"
        src={ShoppingCartSvg}
        alt="shopping-cart"
        // onClick={() => {
        //   // @ts-ignore
        //   dispatch(toggleDrawerCart());
        // }}
      />
    ),
    total: 4,
  },

  // {
  //   title: "Alert",
  //   icon: <img src={AlertSvg} alt="Alert" />,
  // },
];

export const arrNavAdmin = [
  {
    icon: <img src={Logo} className="!w-[56px] ml-4" alt="logo-website" />,
    // icon: <img src={MenuButtonSvg} alt="menu_button" />,
  },

  // {
  //   title: "Alert",
  //   icon: <img src={AlertSvg} alt="Alert" />,
  // },
];

export const arrPathUser = [
  {
    path: "/search-history",
    element: <ListSearchHisPage />,
  },
  {
    path: "/",
    element: <Navigate to="/main-map" />,
  },
  {
    path: "/main-map",
    element: <MainMap />,
  },

  {
    path: "/dataset/:id",
    element: <DatasetUserIdPage />,
  },
  {
    path: "/dataset/:collectionid/:itemid/map",
    element: <ViewItemMap />,
  },
  {
    path: "/dataset",
    element: <DatasetUserPage />,
  },
  {
    path: "/invoice",
    element: <InvoicePage />,
  },
  {
    path: "/invoice/:id",
    element: <InvoiceItemPage />,
  },
];

export const arrPathAdminLoginUser = arrPathUser?.concat({
  path: "/change-role",
  element: <ChangeRolePage />,
});

export const arrPathAdmin = [
  {
    path: "/manage-user/:id",
    element: <ManageUserDetailPage />,
  },
  {
    path: "/manage-user",
    element: <ManageUserPage />,
  },
  {
    path: "/",
    element: <Navigate to="/manage-dataset" />,
  },
  {
    path: "/change-role",
    element: <ChangeRolePage />,
  },
  {
    path: "/setting-system",
    element: <SettingSystemPage />,
  },
  {
    path: "/setting-system/:idKey",
    element: <DetailSystemKeyPage />,
  },
  {
    path: "/setting-download/add",
    element: <AddStorageDownloadPage />,
  },
  {
    path: "/setting-download/edit/:id",
    element: <EditStorageDownloadPage />,
  },
  {
    path: "/manage-notification",
    element: <ManageNotificationPage />,
  },
  {
    path: "/manage-notification/add",
    element: <AddNotificationPage />,
  },
  {
    path: "/manage-notification/edit/:id",
    element: <EditNotificationPage />,
  },
  {
    path: "/manage-dataset",
    element: <DatasetPage />,
  },
  {
    path: "/manage-dataset/:id",
    element: <DatasetId />,
  },
  {
    path: "/manage-dataset/setting/:id/storage/:idStorage",
    element: <AutoScanPage />,
  },
  {
    path: "/manage-dataset/create",
    element: <CreateDatasetPage />,
  },
  {
    path: "/manage-dataset/edit/:id",
    element: <EditDatasetPage />,
  },
  {
    path: "/manage-dataset/setting/:id/create",
    element: <AddCollectionStoragePage />,
  },
  {
    path: "/manage-dataset/setting/:id/edit/:idCollectionStorage",
    element: <EditCollectionStoragePage />,
  },
  {
    path: "/manage-dataset/setting/:id",
    element: <SettingDatasetPage />,
  },
  {
    path: "/manage-dataset/:iddataset/edit/:id",
    element: <EditItemDatasetPage />,
  },
  {
    path: "/manage-dataset/:iddataset/create-item",
    element: <CreateItemDatasetPage />,
  },
  {
    path: "/dataset/:collectionid/:itemid/map",
    element: <ViewItemMap />,
  },
  {
    path: "/manage-order",
    element: <ManageOderPage />,
  },
  {
    path: "/manage-order/:id",
    element: <ManageOderDetail />,
  },
  {
    path: "/list-storage",
    element: <ListStoragePage />,
  },
  {
    path: "/list-storage/create",
    element: <CreateStoragePage />,
  },
  {
    path: "/list-storage/edit/:id",
    element: <EditStorage />,
  },
  {
    path: "/list-storage/:id/browser",
    element: <ViewBrowseStoragePage />,
  },
  {
    path: "/history-scan",
    element: <HistoryScanPage />,
  },
  {
    path: "/history-scan/:id",
    element: <HistoryScanViewDetailPage />,
  },
  {
    path: "/histories",
    element: <HistorySearchPage />,
  },
  {
    path: "/manage-quota",
    element: <ManageQuotasPage />,
  },
  {
    path: "/manage-quota/edit/:id",
    element: <EditedQuotaPage />,
  },
  {
    path: "/manage-quota/add",
    element: <AddQuotaPage />,
  },
  {
    path: "/manage-user-quota",
    element: <UserQuotaPage />,
  },
  {
    path: "/manage-user-quota/add",
    element: <AddUserQuotaPage />,
  },
  {
    path: "/scheduler/task",
    element: <ListTaskPage />,
  },
  {
    path: "/scheduler/task/add",
    element: <AddTaskPage />,
  },
  {
    path: "/scheduler/task/:idTask/view-job",
    element: <ViewJobInTask />,
  },
  {
    path: "/scheduler/task/edit/:id",
    element: <EditTaskPage />,
  },
  {
    path: "/scheduler/job",
    element: <ListJobPage />,
  },
];
