import
  {
    Breadcrumb,
    BreadcrumbButton,
    BreadcrumbDivider,
    BreadcrumbItem,
  } from "@fluentui/react-breadcrumb-preview";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { changePathBrowse } from "../../store/ManageStorageSlice";

const BreadcrumbStorageComp = (props: any) => {
  const dispatch = useDispatch();

  const navigate = useCustomNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const changePathParam = (path: string) => {
    navigate(`${document.location.pathname}?${path}`);
  }; 

  console.log(props?.data, 'props?.data')


  return (
    <>
      <Breadcrumb
        aria-label="Small Breadcrumb"
        size="large"
        style={{
          ...props?.style,
          display: "flex",
          alignItems: "center",
        }}
      >
        {props?.data.map((item: any, index: any) => (
          <React.Fragment key={`item-${item.key}`}>
            <BreadcrumbItem>
              {item?.path ? (
                <Link to={`/${item?.path}`}>
                  <BreadcrumbButton
                    className={index == 0 ? "!pl-0" : ""}
                    icon={item.icon}
                    current={props?.data.length - 1 === index}
                    style={{
                      fontSize: props?.data?.length > 4 ? "12px" : "14px",
                    }}
                  >
                    {item.value}
                  </BreadcrumbButton>
                </Link>
              ) : (
                <BreadcrumbButton
                  style={{
                    fontSize: props?.data?.length > 4 ? "12px" : "14px",
                  }}
                  className={index == 0 ? "!pl-0" : ""}
                  icon={item.icon}
                  current={props?.data.length - 1 === index}
                  onClick={(e: any) => {
                    let a = props?.data?.filter(
                      (itemFliter: any) => itemFliter?.key < item?.key + 1
                    );

                    const dataBreadString = a.map((obj: any) => {
                      const newObj: any = {};
                      for (const key in obj) {
                        // Check if the property value is an object
                        if (typeof obj[key] === "object" && obj[key] !== null) {
                          // Convert nested object to string
                          newObj[key] = JSON.stringify(obj[key]);
                        } else {
                          newObj[key] = obj[key];
                        }
                      }
                      return JSON.stringify(newObj);
                    });

                    localStorage.setItem(
                      "databread",
                      dataBreadString?.join(";")
                    );

                    dispatch(
                      changePathBrowse(item?.url ? `${item?.url}` : null)
                    );

                    changePathParam(item?.url ? `path=${item?.url}` : "");

                    dispatch(changeDataBreadcrumb(a));
                  }}
                >
                  {item?.value}
                </BreadcrumbButton>
              )}
            </BreadcrumbItem>
            {!(props?.data.length - 1 === index) && <BreadcrumbDivider />}
          </React.Fragment>
        ))}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbStorageComp;
