// const ip = "http://127.0.0.1:8822";

// const ipLogin = "http://127.0.0.1:8822";

// @ts-ignore
const ip = process.env.REACT_APP_ENDPOINT;

const ipKratos = process.env.REACT_APP_ENDPOINT_KRATOS;
const ipAccount = process.env.REACT_APP_ENDPOINT_ACCOUNT;

export { ip, ipKratos, ipAccount };
