import { CounterBadge } from "@fluentui/react-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotiUserAsync } from "../../store/notificationsSlice";
import ShoppingCartSvg from "./../../assets/Alert.svg";

import {
  changeSateItemRead,
  userUpdateNotiAsync,
} from "../../store/notificationsSlice";
import {
  changeSateVisibleReadNoti,
  toggleDrawerNoti,
} from "../../store/DrawerSlice";
import { changeStateArrNoti } from "../../store/pagingSlice";
import { RootState } from "../../store/store";

const NotificationNav = (props: any) => {
  const dispatch = useDispatch();
  const { arrNoti } = useSelector((state: RootState) => state.paging);
  const [arrUnRead, setArrUnRead] = useState(
    arrNoti?.filter((item: any) => !item?.seen)
  );
  const [dataNoti, setDataNoti] = useState([]);
  useEffect(() => {
    (async function () {
      try {
        //   setLoading(true);
        const res = await dispatch(
          // @ts-ignore
          getNotiUserAsync({})
        ).unwrap();

        setDataNoti(res?.result ?? []);
        dispatch(changeStateArrNoti(res?.result ?? []));

        //   setLoading(false);
      } catch (e) {
        console.error(e);
        //   setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        setArrUnRead(arrNoti?.filter((item: any) => !item?.seen));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [arrNoti]);
  const renderItemNoti = (item: any) => {
    return (
      <span>
        <p>{item?.title}</p>
        <i className="text-color-des !float-right !text-[12px]">
          {moment(item?.sent).format("HH:mm:ss DD/MM/YYYY")}
        </i>
      </span>
    );
  };

  const reloadData = async () => {
    //   setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      getNotiUserAsync({})
    ).unwrap();

    setDataNoti(res?.result ?? []);

    dispatch(changeStateArrNoti(res?.result ?? []));

    //   setLoading(false);
  };

  const handleReadItem = (item: any) => {
    dispatch(changeSateVisibleReadNoti(true));
    dispatch(changeSateItemRead(item));

    dispatch(
      // @ts-ignore
      userUpdateNotiAsync({ id: item?.notification_id, pay: { read: true } })
    ).unwrap();
  };

  const handleDrawerNoti = async () => {
    await reloadData();
    dispatch(toggleDrawerNoti(true));
  };
  return (
    <span className="group-count-badge" onClick={() => handleDrawerNoti()}>
      <img
        className="cursor-pointer"
        src={ShoppingCartSvg}
        alt="shopping-cart"
        onClick={() => {
          handleDrawerNoti();
        }}
      />

      {arrUnRead?.length !== 0 && (
        <CounterBadge
          className="count-badge"
          count={arrUnRead?.length}
          color="danger"
        />
      )}
    </span>
  );
};

export default NotificationNav;
