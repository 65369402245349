import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { changeItems } from "../../store/filterSlice";
import
  {
    getItemCollectionMapByIdAsync
  } from "../../store/itemCollectionSlice";
import { updateAlert } from "../../store/notificationsSlice";
import { changeName, changePath } from "../../store/pageSlice";

import MapPlugins from "../../components/map/Map";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
export default function ViewItemMap() {
  let { collectionid } = useParams();
  let { itemid } = useParams();

  const dispatch = useDispatch();

  const [dataset, setDataset] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(changePath("/view-item-in-map"));
    dispatch(changeName("view-item-in-map"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      // dispatch(resetState({}));
      // dispatch(changePage(0));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    (async function () {
      await getItemCollectionById();
    })();
  }, [itemid]);

  const getItemCollectionById = async () => {
    try {
      setLoading(true);
      const item = await dispatch(
        // @ts-ignore
        getItemCollectionMapByIdAsync({
          collectionId: collectionid,
          itemId: itemid,
        })
      ).unwrap();

      if (item && Object.keys(item).length) {
        dispatch(changeItems([item]));
        setDataset(item);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      // show toast or alert
      dispatch(
        updateAlert({
          show: true,
          title: "Error",
          message: err?.message,
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };


  return (
    <>
      {loading && (
        <center>
          <Spinner className="absolute top-1/2 left-1/2 z-[9999]" />
        </center>
      )}
      <MapPlugins items={dataset ? [dataset] : []} />
    </>
  );
}
