import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getItemCollectionById } from "./../services/explore/exploreServices";

export const getItemCollectionMapByIdAsync = createAsyncThunk(
  "health/getHealthCheck",
  async (payload) => {
    try {
       const response = await getItemCollectionById(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getItemCollectionByIdAsync = createAsyncThunk<
  null, // Returned,
  { collectionId: any; itemId: any },
  {
    state: { itemCollection: { loading: string; currentRequestId: string } };
  }
>(
  "itemCollection/getItemCollectionById",
  async (
    payload: { collectionId: any; itemId: any },
    { getState, requestId }
  ) => {
    const { currentRequestId, loading } = getState().itemCollection;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const response = await getItemCollectionById(payload);
    return response.data;
  }
);

export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
}

var error: SerializedError = {};

export const pageSlice = createSlice({
  name: "itemCollection",
  initialState: {
    item: null,
    loading: "idle",
    currentRequestId: undefined,
    error: error,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // getItemCollectionById
      .addCase(getItemCollectionByIdAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action?.meta?.requestId;
        }
      })
      .addCase(getItemCollectionByIdAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.item = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getItemCollectionByIdAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {} = pageSlice.actions;

export default pageSlice.reducer;
