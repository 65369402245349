import {
  Button,
  Dropdown,
  Input,
  Option,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeSateKeywordFilter,
  changeSateSentAtFromFilter,
  changeSateSentAtToFilter,
  changeSateTypeFilter,
  getTypeNotiAsync,
} from "../../store/notificationsSlice";

const useStyles = makeStyles({
  listbox: {
    maxHeight: "200px",
  },
  // these styles wrap the value text within the dropdown button and cause it to truncate
  truncatedText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const FilterNotificationsComp = (props: any) => {
  const styles = useStyles();
  const { typeFilter, sentAtToFilter, sentAtFromFilter, keywordFilter } =
    useSelector((state: RootState) => state.notifications);

  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      try {
        const res = await dispatch(
          // @ts-ignore
          getTypeNotiAsync({})
        ).unwrap();

        setArrType(res?.data ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const [arrType, setArrType] = useState([]);

  const changeTime = (date: any, type: string) => {
    if (type === "start_at") {
      if (date) {
        dispatch(changeSateSentAtFromFilter(date));
      } else {
        dispatch(changeSateSentAtFromFilter(null));
      }
    }
    if (type === "end_at") {
      {
        if (date) {
          dispatch(changeSateSentAtToFilter(date));
        } else {
          dispatch(changeSateSentAtToFilter(null));
        }
      }
    }
  };

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = useCallback(
    (newValue: string): Date => {
      const previousValue = sentAtFromFilter || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [sentAtFromFilter]
  );

  const onParseDateFromStringEndAt = useCallback(
    (newValue: string): Date => {
      const previousValue = sentAtToFilter || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [sentAtToFilter]
  );

  const onOptionSelectType = (e: any, data: any) => {
    dispatch(changeSateTypeFilter(data?.selectedOptions));
  };

  return (
    <>
      <div>
        {/* <p className="mb-2">Keyword</p>
        <Input
          className="div-dropdown"
          placeholder="-- Enter keyword --"
          value={keywordFilter}
          onChange={(e: any) => {
            dispatch(changeSateKeywordFilter(e?.target?.value));
          }}
        /> */}
        <p className="mb-2 mt-5">Type</p>
        <Dropdown
          placeholder="Select type"
          className="div-dropdown"
          multiselect
          value={typeFilter?.length > 0 ? typeFilter?.join(", ") : undefined}
          onOptionSelect={onOptionSelectType}
          selectedOptions={typeFilter ?? []}
          // button={<span className={styles.truncatedText}>{typeFilter}</span>}
          listbox={{ className: styles.listbox }}
        >
          {arrType?.map((option: string) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>
        <p className="mb-2 mt-5">Sent from</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          parseDateFromString={onParseDateFromStringStartAt}
          value={sentAtFromFilter}
          onSelectDate={(date: any) => changeTime(date, "start_at")}
          maxDate={sentAtToFilter}
        />
        <p className="mb-2 mt-5">Sent to</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          value={sentAtToFilter}
          onSelectDate={(date: any) => changeTime(date, "end_at")}
          parseDateFromString={onParseDateFromStringEndAt}
          minDate={sentAtFromFilter}
        />
      </div>
    </>
  );
};

export default FilterNotificationsComp;
