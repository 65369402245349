import {
  Button,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";

import DrawerNotification from "../Notification/DrawerNotification";
import SortHistoryScanComp from "../SortComponent/SortHistoryScan";
import SortNotiComp from "../SortComponent/SortNoti";
import SortQuotaComp from "../SortComponent/SortQuota";
import SortStorageComp from "../SortComponent/SortStorage";
import DrawerCart from "./../../components/Cart/DrawerCart";
import DrawerComp from "./../../components/Drawer/Drawer";
import InforNotiComp from "./../../components/Notification/InforNoti";
import SortOrderComp from "./../../components/SortComponent/SortOrder";

import DrawerDowload from "../../components/CardItemDowload/DrawerDownload";
import FilterDatasetComp from "../../components/FilterComponent/FilterDataset";
import FilterHistoryScanComp from "../../components/FilterComponent/FilterHistoryScan";
import FilterNotificationsComp from "../../components/FilterComponent/FilterNotifications";
import FilterQuotaImg from "../../components/FilterComponent/FilterQuotas";
import FilterStorageComp from "../../components/FilterComponent/FilterStorage";
import ScanCollectionComp from "../../components/ManageDataset/Scan";
import FilterOrderImg from "./../../components/FilterComponent/FilterOrder";
import InfoMetadataItemDataSet from "./../../components/InfoMetadata/index";
import InfoMetadata from "./../../components/InfoMetadata/indexCollection";

import AlertSvg from "../../assets/Alert.svg";
import FilterTaskComp from "../FilterComponent/FliterTask";

import infoCircleDrawerSvg from "../../assets/info-circle-drawer.svg";
import ShoppingCartSvg from "../../assets/shopping-cart.svg";
import ApplySvg from "./../../assets/accept.svg";
import FilterSvg from "./../../assets/filter-footer.svg";
import RefreshSvg from "./../../assets/refresh.svg";
import SortSvg from "./../../assets/sortby.svg";
import UserQuotaSvg from "../../assets/user-quota.svg";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  resetStateFilterUser as resetFilterOrderUser,
  resetStateOrderAdmin,
  resetStateSort as resetStateSortOrder,
} from "../../store/ManageOrderSlice";

import {
  changeFlagFilter as changeFlagFilterStorage,
  changeFlagFresh as changeFlagFreshStorage,
  changeFlagSort as changeFlagSortStorage,
  changeStateFilter as changeStateFilterStorage,
  changeStateSort,
  resetFilter,
  resetSortHistoryScan,
} from "./../../store/ManageStorageSlice";

import {
  changeFlagFilter as changeFlagFilterQuota,
  changeFlagFresh as changeFlagFreshQuota,
  changeFlagSort as changeFlagSortQuota,
  changeItemUserQuota,
  resetStateFilter as resetStateFilterQuota,
  resetStateSort as resetStateSortQuota,
} from "./../../store/ManageQuotaSlice";

import {
  //dataset
  changeStateVisibleDrawerInforDataset,
  changeStateVisibleDrawerScan,
  resetAllStateDrawer,
} from "../../store/DrawerSlice";
import { scanCollectionStorageAsync } from "../../store/ManageCollectionStorageSlice";
import {
  changeFlagFilterJob,
  changeFlagFilter as changeFlagFilterTask,
  changeFlagFreshJob,
  changeFlagFresh as changeFlagFreshTask,
  changeFlagSortJob,
  changeFlagSort as changeFlagSortTask,
  resetStateFilterJob,
  resetStateFilterTask,
  resetStateSortJob,
  resetStateSortTask,
} from "../../store/ManageScheduler";
import {
  changeFlagFilterItem,
  changeFlagRefeshItem as changeFlagRefeshItemDataset,
  changeIdCollection,
  changeStateFilter as changeStateFilterItemDataset,
} from "../../store/exploreSlice";
import {
  changeFlagFilter as changeFlagFilterNoti,
  changeFlagFresh as changeFlagFreshNoti,
  changeFlagSort as changeFlagSortNoti,
  changeTotalNoti,
  seenAllNotiAsync,
  resetFilter as resetFilterNoti,
  resetSortOption as resetSortOptionNoti,
  changeTotalNotiNotRead,
} from "../../store/notificationsSlice";
import { timeout } from "../../utils/constants";
import FilterJobComp from "../FilterComponent/FliterJob";
import FliterTaskJobsComp from "../FilterComponent/FliterTaskJobs";
import SortJobComp from "../SortComponent/SortJob";
import SortTaskComp from "../SortComponent/SortTask";
import ScanSvg from "./../../assets/scan.svg";
import CheckSvg from "../../assets/check-noti.svg";

import {
  changeFlagFilter as changeFlagFilterSearchHis,
  changeFlagFresh as changeFlagFreshSearchHis,
  changeFlagSort as changeFlagSortSearchHis,
  resetFilterState as resetFilterStateSearchHis,
  resetSortState as resetSortStateSearchHis,
} from "../../store/ManageSearchHis";
import FilterSearchHistoryComp from "../FilterComponent/FilterSearchHistory";
import SortSearchHisComp from "../SortComponent/SortSearchHis";
import ToastComp from "../Toast";
import { changeVisibleSpinner } from "../../store/NavSlice";
import DrawerUserQuota from "../ManageQuota/DrawerUserQuota";

const GlobalDrawer = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { itemRead, totalNotiNotRead } = useSelector(
    (state: RootState) => state.notifications
  );

  const { itemViewInfor, idCollection, idCollectionStorage } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const { itemUserQuota } = useSelector(
    (state: RootState) => state.managequota
  );

  const {
    visibleDrawerInforDataset,
    visibleDrawerScan,
    visibleDrawerInforItemDataset,

    visibleCart,
    visibleDrawerDownload,
    visibleNoti,
    visible,
    visibleSort,
    visibleReadNoti,
    visibleShowQuotaUser,
  } = useSelector((state: RootState) => state.drawer);

  const handleFilter = (type: string) => {
    if (props?.pageName === "manage-order") {
      if (type === "filter") {
        dispatch(changeFlagFilter(true));
      } else {
        // @ts-ignore
        // dispatch(resetFilterOrder({}));
        dispatch(resetStateOrderAdmin({}));
        dispatch(changeFlagFresh(true));
      }
    } else if (props?.pageName === "manage-quota") {
      if (type === "filter") {
        dispatch(changeFlagFilterQuota(true));
      } else {
        // @ts-ignore
        dispatch(resetStateFilterQuota({}));
        dispatch(changeFlagFreshQuota(true));
      }
    } else if (props?.pageName === "history-scan") {
      if (type === "filter") {
        dispatch(changeFlagFilterStorage(true));
      } else {
        // @ts-ignore
        dispatch(resetFilter({}));
        dispatch(changeFlagFreshStorage(true));
      }
    } else if (props?.pageName === "manage-notification") {
      if (type === "filter") {
        dispatch(changeFlagFilterNoti(true));
      } else {
        // @ts-ignore
        dispatch(resetFilterNoti());
        dispatch(changeFlagFreshNoti(true));
      }
    } else if (props?.pageName === "list-storage") {
      if (type === "filter") {
        dispatch(changeFlagFilterStorage(true));
      } else {
        // @ts-ignore
        dispatch(changeStateFilterStorage({}));
        dispatch(changeFlagFreshStorage(true));
      }
    } else if (props?.pageName === "invoice") {
      if (type === "filter") {
        dispatch(changeFlagFilter(true));
      } else {
        // @ts-ignore
        dispatch(resetFilterOrderUser({}));
        dispatch(changeFlagFresh(true));
      }
    } else if (props?.pageName === "scheduler/task") {
      if (type === "filter") {
        dispatch(changeFlagFilterTask(true));
      } else {
        // @ts-ignore
        dispatch(resetStateFilterTask({}));
        dispatch(changeFlagFreshTask(true));
      }
    } else if (props?.pageName === "scheduler/job") {
      if (type === "filter") {
        dispatch(changeFlagFilterJob(true));
      } else {
        // @ts-ignore
        dispatch(resetStateFilterJob({}));
        dispatch(changeFlagFreshJob(true));
      }
    } else if (props?.pageName === "view-task-jobs") {
      if (type === "filter") {
        dispatch(changeFlagFilterJob(true));
      } else {
        // @ts-ignore
        dispatch(resetStateFilterJob({}));
        dispatch(changeFlagFreshJob(true));
      }
    } else if (
      // props?.pageName === "dataset-item" ||
      props?.pageName === "manage-dataset-item"
    ) {
      if (type === "filter") {
        // dispatch(changeFlagFilter(true));\
        dispatch(changeFlagFilterItem(true));
        
      } else {
        // @ts-ignore
        // dispatch(resetFilterOrder({}));
        dispatch(changeStateFilterItemDataset({}));
        dispatch(changeFlagRefeshItemDataset(true));
      }
    } else if (props?.pageName === "search-history") {
      if (type === "filter") {
        dispatch(changeFlagFilterSearchHis(true));
      } else {
        // @ts-ignore
        dispatch(resetFilterStateSearchHis({}));
        dispatch(changeFlagFreshSearchHis(true));
      }
    }
  };

  const handleSort = (type: string) => {
    if (props?.pageName === "manage-order") {
      if (type === "sort") {
        dispatch(changeFlagSort(true));
      } else {
        // @ts-ignore
        // dispatch(resetStateSortOrder({}));
        dispatch(resetStateOrderAdmin({}));
        dispatch(changeFlagFresh(true));
      }
    } else if (props?.pageName === "manage-quota") {
      if (type === "sort") {
        dispatch(changeFlagSortQuota(true));
      } else {
        // @ts-ignore
        dispatch(resetStateSortQuota());
        dispatch(changeFlagFreshQuota(true));
      }
    } else if (props?.pageName === "history-scan") {
      if (type === "sort") {
        dispatch(changeFlagSortStorage(true));
      } else {
        // @ts-ignore
        dispatch(resetSortHistoryScan({}));
        dispatch(changeFlagFreshStorage(true));
      }
    } else if (props?.pageName === "manage-notification") {
      if (type === "sort") {
        dispatch(changeFlagSortNoti(true));
      } else {
        // @ts-ignore
        dispatch(resetSortOptionNoti({}));
        dispatch(changeFlagFreshNoti(true));
      }
    } else if (props?.pageName === "dataset-item") {
    } else if (props?.pageName === "list-storage") {
      if (type === "sort") {
        dispatch(changeFlagSortStorage(true));
      } else {
        // @ts-ignore
        dispatch(changeStateSort({}));
        dispatch(changeFlagFreshStorage(true));
      }
    } else if (props?.pageName === "invoice") {
      if (type === "sort") {
        dispatch(changeFlagSort(true));
      } else {
        // @ts-ignore
        dispatch(resetStateSortOrder({}));
        dispatch(changeFlagFresh(true));
      }
    } else if (props?.pageName === "scheduler/task") {
      if (type === "sort") {
        dispatch(changeFlagSortTask(true));
      } else {
        // @ts-ignore
        dispatch(resetStateSortTask({}));
        dispatch(changeFlagFreshTask(true));
      }
    } else if (props?.pageName === "scheduler/job") {
      if (type === "sort") {
        dispatch(changeFlagSortJob(true));
      } else {
        // @ts-ignore
        dispatch(resetStateSortJob({}));
        dispatch(changeFlagFreshJob(true));
      }
    } else if (props?.pageName === "view-task-jobs") {
      if (type === "sort") {
        dispatch(changeFlagSortJob(true));
      } else {
        // @ts-ignore
        dispatch(resetStateSortJob({}));
        dispatch(changeFlagFreshJob(true));
      }
    } else if (props?.pageName === "search-history") {
      if (type === "sort") {
        dispatch(changeFlagSortSearchHis(true));
      } else {
        // @ts-ignore
        dispatch(resetSortStateSearchHis({}));
        dispatch(changeFlagFreshSearchHis(true));
      }
    }
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const scan = async () => {
    const res = await dispatch(
      // @ts-ignore
      scanCollectionStorageAsync({
        storage_id: idCollectionStorage,
        collection_id: idCollection,
      })
    ).unwrap();

    if (res.status === 200) {
      notify(res?.message ?? `Starting scan ${idCollection}`, "success");
      dispatch(changeFlagFilterNoti(true));
      dispatch(changeStateVisibleDrawerScan(false));
    } else {
      notify("Scan fail", "error");
      dispatch(changeStateVisibleDrawerScan(false));
    }
  };

  const handleReadAllNoti = async () => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      seenAllNotiAsync({
        read: true,
      })
    ).unwrap();

    if (res?.status === 200) {
      notify(
        res?.message ?? `Mark read all notifications successfully`,
        "success"
      );
      dispatch(changeVisibleSpinner(false));
      dispatch(changeTotalNotiNotRead(0));
      dispatch(changeFlagFilterNoti(true));
    } else {
      notify(res?.message ?? `Mark read all notifications fail`, "error");
      dispatch(changeVisibleSpinner(false));
    }
  };

  const renderHeaderComp = () => {
    if (visible) {
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img src={FilterSvg} alt="Dismiss" />
            <span className="ml-1 font-thin"> Filter</span>
          </div>

          <div className="flex">
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={ApplySvg}
                  alt="Info Circle"
                />
              }
              onClick={() => handleFilter("filter")}
              // appearance="transparent"
              className="btn-action-drawer !mr-2"
            >
              Apply
            </Button>
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={RefreshSvg}
                  alt="Info Circle"
                />
              }
              className="btn-action-drawer"
              onClick={() => handleFilter("refresh")}
            />
          </div>
        </div>
      );
    } else if (visibleSort) {
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img src={SortSvg} alt="Dismiss" />
            <span className="ml-1 font-thin"> Sort</span>
          </div>

          <div className="flex">
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={ApplySvg}
                  alt="Info Circle"
                />
              }
              onClick={() => handleSort("sort")}
              // appearance="transparent"
              className="btn-action-drawer !mr-2"
            >
              Apply
            </Button>
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={RefreshSvg}
                  alt="Info Circle"
                />
              }
              className="btn-action-drawer"
              onClick={() => handleSort("refresh")}
            />
          </div>
        </div>
      );
    } else if (visibleCart) {
      return (
        <div className="flex text-sm items-center">
          <img src={ShoppingCartSvg} alt="cart" />
          <span className="ml-1">&nbsp;{t("Cart")}</span>
        </div>
      );
    } else if (visibleDrawerDownload) {
      return (
        <div className="flex text-sm items-center">
          <img src={ShoppingCartSvg} alt="Download" />
          <span className="ml-1">&nbsp;{t("Download")}</span>
        </div>
      );
    } else if (visibleNoti) {
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img src={AlertSvg} alt="AlertSvg" />
            <span style={{ marginLeft: "4px" }}> &nbsp;Notification</span>
          </div>
          <div>
            <Button
              icon={
                <img className="img-svg-icon" src={CheckSvg} alt="CheckSvg" />
              }
              disabled={totalNotiNotRead === 0}
              onClick={() => handleReadAllNoti()}
              className="btn-action-drawer !mr-2"
            >
              Read all
            </Button>
          </div>
        </div>
      );
    } else if (visibleReadNoti) {
      return (
        <div className="flex text-sm items-center">
          <img src={infoCircleDrawerSvg} alt="Dismiss" />
          <span style={{ marginLeft: "4px" }}>Notification</span>
        </div>
      );
    } else if (visibleDrawerInforDataset) {
      return (
        <div className="flex text-sm items-center">
          <img src={infoCircleDrawerSvg} alt="Dismiss" />
          <span style={{ marginLeft: "4px" }}> Information</span>
        </div>
      );
    } else if (visibleDrawerScan) {
      return (
        <>
          <div className="flex justify-between text-sm items-center">
            <div className="flex">
              <img src={ScanSvg} alt="ScanSvg" />
              <span style={{ marginLeft: "4px" }}> Scan {idCollection}</span>
            </div>
            <div>
              <Button
                disabled={!idCollectionStorage}
                onClick={() => scan()}
                className="btn-action-drawer !mr-2"
              >
                Scan
              </Button>
            </div>
          </div>
        </>
      );
    } else if (visibleDrawerInforItemDataset) {
      return (
        <div className="flex text-sm items-center">
          <img src={infoCircleDrawerSvg} alt="Dismiss" />
          <span style={{ marginLeft: "4px" }}> Information</span>
        </div>
      );
    } else if (visibleShowQuotaUser) {
      return (
        <div className="flex text-sm items-center">
          <img src={UserQuotaSvg} alt="UserQuotaSvg" />
          <span style={{ marginLeft: "4px" }}>
           
            {itemUserQuota?.user?.traits?.name ? (
              <span>
                {itemUserQuota?.user?.traits?.name?.first}{" "}
                {itemUserQuota?.user?.traits?.name?.last}
              </span>
            ) : (
              itemUserQuota?.user?.traits?.email
            )} quota
          </span>
        </div>
      );
    }
  };

  const renderCompDrawer = () => {
    if (visible)
    {
      return props?.pageName === "manage-order" ||
        props?.pageName === "invoice" ? (
        <FilterOrderImg />
      ) : props?.pageName === "manage-quota" ? (
        <FilterQuotaImg />
      ) : props?.pageName === "history-scan" ? (
        <FilterHistoryScanComp />
      ) : props?.pageName === "manage-notification" ? (
        <FilterNotificationsComp />
      ) : props?.pageName === "manage-dataset-item" ? (
        <FilterDatasetComp />
      ) : props?.pageName === "scheduler/task" ? (
        <FilterTaskComp />
      ) : props?.pageName === "scheduler/job" ? (
        <FilterJobComp />
      ) : props?.pageName === "list-storage" ? (
        <FilterStorageComp />
      ) : props?.pageName === "view-task-jobs" ? (
        <FliterTaskJobsComp />
      ) : props?.pageName === "search-history" ? (
        <FilterSearchHistoryComp />
      ) : null;
    } else if (visibleSort)
    {
      return props?.pageName === "manage-order" ||
        props?.pageName === "invoice" ? (
        <SortOrderComp />
      ) : props?.pageName === "manage-quota" ? (
        <SortQuotaComp />
      ) : props?.pageName === "history-scan" ? (
        <SortHistoryScanComp />
      ) : props?.pageName === "manage-notification" ? (
        <SortNotiComp />
      ) : props?.pageName === "list-storage" ? (
        <SortStorageComp />
      ) : props?.pageName === "scheduler/task" ? (
        <SortTaskComp />
      ) : props?.pageName === "scheduler/job" ? (
        <SortJobComp />
      ) : props?.pageName === "view-task-jobs" ? (
        <SortJobComp />
      ) : props?.pageName === "search-history" ? (
        <SortSearchHisComp />
      ) : null;
    } else if (visibleCart)
    {
      return (
        <DrawerCart visibleCart={visibleCart} pageName={props?.pageName} />
      );
    } else if (visibleDrawerDownload)
    {
      return (
        <DrawerDowload
          visibleDrawerDownload={visibleDrawerDownload}
          pageName={props?.pageName}
        />
      );
    } else if (visibleNoti)
    {
      return <DrawerNotification visibleNoti={visibleNoti} />;
    } else if (visibleReadNoti)
    {
      return <InforNotiComp data={itemRead} />;
    } else if (visibleDrawerInforDataset)
    {
      return <InfoMetadata data={itemViewInfor} />;
    } else if (visibleDrawerScan)
    {
      return <ScanCollectionComp idCollection={idCollection} />;
    } else if (visibleDrawerInforItemDataset)
    {
      return (
        <InfoMetadataItemDataSet hasButtonCart={true} data={itemViewInfor} />
      );
    } else if (visibleShowQuotaUser)
    {
      return (
        <DrawerUserQuota />
      )
    }
  };

  const toggleDrawerScan = () => {
    dispatch(changeIdCollection(undefined));
    dispatch(changeStateVisibleDrawerScan(false));
    dispatch(changeStateVisibleDrawerInforDataset(false));
  };
  return (
    <DrawerComp
      showDrawer={
        visible ||
        visibleSort ||
        visibleCart ||
        visibleNoti ||
        visibleDrawerDownload ||
        visibleReadNoti ||
        visibleDrawerInforDataset ||
        visibleDrawerScan ||
        visibleDrawerInforItemDataset ||
        visibleShowQuotaUser
      }
      toggleDrawer={() => {
        toggleDrawerScan();
        dispatch(resetAllStateDrawer({}));
        if (visibleShowQuotaUser)
        {
          dispatch(changeItemUserQuota({}));
        }
      }}
      type="inline"
      position="end"
      header={renderHeaderComp()}
      modalType={props?.pageName === "mainMap" ? "non-modal" : ""}
      // style={{
      //   width: "504px",
      //   // visibleCart || visibleDrawerInforItemDataset || visibleDrawerDownload
      //   //   ? "650px"
      //   //   : "initial",
      // }}
      // className={
      //   "!w-[504px]"
      //   // visibleCart || visibleDrawerInforItemDataset || visibleDrawerDownload
      //   //   ? "!w-[650px]"
      //   //   : ""
      // }
      // width="!w-[278px]"
    >
      {renderCompDrawer()}
    </DrawerComp>
  );
};

export default GlobalDrawer;
