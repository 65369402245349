import {
    MenuItem,
    MenuList,
    MenuPopover,
} from "@fluentui/react-components";
import DrawingMenuItem from "./DrawingMenuItem";
import UploadMenuItem from "./UploadMenuItem";
export default function AOI() {
    return (
        <MenuList className="!bg-s-background1">
            <DrawingMenuItem />
            <UploadMenuItem />
        </MenuList>
    );

}