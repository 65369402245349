import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import CardItemUserQuota from "../../components/ManageQuota/CardItemUserQuota";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  resetAllStateDrawer,
  toggleShowQuotaUser,
} from "../../store/DrawerSlice";
import {
  changeStateBtnCreate,
  changeVisibleSpinner,
} from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import EmptySvg from "./../../assets/nodata.svg";
import {
  changeFlagFresh,
  changeItemUserQuota,
  delUserQuotaAsync,
  getUserQuotaAsync,
  getUserQuotasAsync,
} from "./../../store/ManageQuotaSlice";
import { getListUserAsync } from "./../../store/ManageUserSlice";
import { renderInfoUserQuota } from "../../utils/utils";

const dataBread = [
  {
    key: 0,
    value: "Quotas",
    path: "manage-quota",
  },
  {
    key: 1,
    value: "User quota",
    path: "manage-user-quota",
  },
];

const UserQuotaPage = () => {
  const dispatch = useDispatch();
  const { itemUserQuota, flagFresh } = useSelector(
    (state: RootState) => state.managequota
  );
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const { visibleShowQuotaUser } = useSelector(
    (state: RootState) => state.drawer
  );

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(changePath("/manage-user-quota"));
    dispatch(changeName("manage-user-quota"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });
  useEffect(() => {
    (async function () {
      try {
        // @ts-ignore
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        let sun: any[] = [];
        const res = await dispatch(
          // @ts-ignore
          getListUserAsync()
        ).unwrap();

        for (let i = 0; i < res?.length; i++) {
          const resQ = await dispatch(
            // @ts-ignore
            getUserQuotasAsync(res?.[i]?.id)
          ).unwrap();

          if (resQ?.data?.length > 0)
            sun = sun.concat({
              ...resQ?.data?.[0],
              user: res[i],
              totalQ: resQ?.data?.length,
              percent: await renderInfoUserQuota({
                unit: resQ?.data?.[0]?.unit,
                limit_value: resQ?.data?.[0]?.limit_value,
                value: {
                  LimitTotalOrderSize:
                    resQ?.data?.[0]?.value?.LimitTotalOrderSize,
                },
              }),
            });
        }
        setData(sun);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (flagFresh)
      (async function () {
        try {
          // @ts-ignore
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          let sun: any[] = [];
          const res = await dispatch(
            // @ts-ignore
            getListUserAsync()
          ).unwrap();

          for (let i = 0; i < res?.length; i++) {
            const resQ = await dispatch(
              // @ts-ignore
              getUserQuotasAsync(res?.[i]?.id)
            ).unwrap();

          

            if (resQ?.data?.length > 0)
              sun = sun.concat({
                ...resQ?.data?.[0],
                user: res[i],
                totalQ: resQ?.data?.length,
                percent: await renderInfoUserQuota({
                  unit: resQ?.data?.[0]?.unit,
                  limit_value: resQ?.data?.[0]?.limit_value,
                  value: {
                    LimitTotalOrderSize:
                      resQ?.data?.[0]?.value?.LimitTotalOrderSize,
                  },
                }),
              });
          }

       
          setData(sun);
          setLoading(false);
          dispatch(changeFlagFresh(false));
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          setLoading(false);
          dispatch(changeFlagFresh(false));
          dispatch(changeVisibleSpinner(false));
          console.error(e);
        }
      })();
  }, [flagFresh]);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: "/manage-user-quota/add",
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
    };
  }, []);

  const deleteItem = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      delUserQuotaAsync({ userId: item?.user?.id, quotaId: item?.id })
    ).unwrap();

    if (res?.status === 200) {
      reloadData();
      if (visibleShowQuotaUser && itemUserQuota?.user?.id === item?.user?.id) {
        dispatch(toggleShowQuotaUser(false));
        dispatch(changeItemUserQuota({}));
      }
      dispatch(changeVisibleSpinner(false));
      notify("Delete successfully", "success");
    } else {
      dispatch(changeVisibleSpinner(false));
      notify("Delete fail", "error");
    }
  };

  const reloadData = async () => {
    let sun: any[] = [];
    const res = await dispatch(
      // @ts-ignore
      getListUserAsync()
    ).unwrap();

    for (let i = 0; i < res?.length; i++) {
      const resQ = await dispatch(
        // @ts-ignore
        getUserQuotaAsync(res?.[i]?.id)
      ).unwrap();
      if (resQ?.length > 0)
        sun = sun.concat({
          ...resQ?.[0],
          user: res[i],
          totalQ: resQ?.length,
        });
    }

    setData(sun);
  };
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const handleViewQuotaUser = async (item: any) => {
    // dispatch(changeVisibleSpinner(true));

    if (item?.user?.id === itemUserQuota?.user?.id) {
      dispatch(toggleShowQuotaUser(false));
      dispatch(changeItemUserQuota({}))
      // dispatch(changeVisibleSpinner(false));
    } else {
      dispatch(toggleShowQuotaUser(true));
      let a: any;
      // @ts-ignore
      const res = await dispatch(getUserQuotasAsync(item?.user?.id)).unwrap();
      if (res?.status === 200) {
        a = {
          user: { ...item?.user },
          data: res?.data,
        };

        dispatch(changeItemUserQuota(a));
        // dispatch(changeVisibleSpinner(false));
      } else {
        a = {
          user: { ...item?.user },
          data: [],
        };
        dispatch(changeItemUserQuota(a));
        // dispatch(changeVisibleSpinner(false));
      }
    }

    
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {loading && <LoadingListComp />}

      {visibleSpinner && !loading && <LoadingGlobalComp />}

      {data?.length === 0 && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}
      {data?.length !== 0 && !loading && (
        <div className="div-list">
          <CardItemUserQuota
            data={data}
            deleteItem={deleteItem}
            handleViewQuotaUser={handleViewQuotaUser}
          />
        </div>
      )}
    </div>
  );
};

export default UserQuotaPage;
