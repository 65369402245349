const addToIndexedDb = (db: IDBDatabase, collectionName: string, data: any) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(collectionName, "readwrite");
    const objectStore = transaction.objectStore(collectionName);
    const request = objectStore.add(data);
    request.onsuccess = () => {
      resolve(data);
    };
    request.onerror = (err) => {
      console.log(err, "indexedDb err add");
      reject(false);
    };
  });
};

export default addToIndexedDb;
