import React from "react";

const cleanPercentage = (percentage: any) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }: any) => {
  const r = 12;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx="18"
      cy="18"
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"3px"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage, textX, textY }: any) => {
  return (
    <text
      x={percentage !== 100 ? "70%" : "50%"} // text x,y in firefox 80,90 in gg chrome 50,50
      y={percentage !== 100 ? "70%" : "60%"}
      textAnchor="middle"
      alignmentBaseline="middle"
      fontSize={"10px"}
      className="text-white"
      dy="-0.9em" // Adjust this value as needed
      dx="-0.9em"
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const Pie = ({ percentage, colour, textX, textY }: any) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg height="30" width="30" className="ml-4">
      <Circle colour="rgba(86, 86, 86, 1)" />
      <Circle colour={colour} pct={pct} />

      {/* text x,y in firefox 80,90 in gg chrome 50,50 */}
      <Text
        percentage={pct}
        x="80%"
        y="97%"
        textAnchor="middle"
        alignmentBaseline="middle"
      />
    </svg>
  );
};

export default Pie;
