// xuanvt add
import React, { useState } from "react";
import {
  Input,
  Label,
  makeStyles,
  shorthands,
  InputProps,
  ButtonProps,
  Button,
  Menu,
  MenuTrigger,
  MenuPopover,
} from "@fluentui/react-components";
import styled from "styled-components";

import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setOpenDialog } from "../../store/uploadAoiSlice";
import {
  changeCallSearch,
  changeSearchText,
  changeShowCriteria,
  changeShowResultSearch,
} from "../../store/filterSlice";

import SearchMapSvg from "./../../assets/search_map.svg";
import DrawerComp from "./../../components/Drawer";
import LayerSvg from "./../../assets/layer_drawer.svg";
import ArrowSvg from "./../../assets/images/arrow-down.svg";
import criteriaWhiteSvg from "../../assets/images/criteria-white.svg";
import criteriaBlackSvg from "../../assets/images/criteria-black.svg";
import searchSvg from "../../assets/images/search.svg";
import DismissSvg from "./../../assets/Dismiss.svg";
import ViewResultSearch from "../../assets/search-intersect.svg";

import CardItemSearch from "./CardItemSearch";
import AOIComp from "./aoi/AOI";
import UploadDialog from "./aoi/UploadDialog";

import FeaturesDraw from "../map/maplibregl/header/FeaturesDraw";
import { arrPathVisibleSeachBar } from "../../utils/data";

import {
  changeFlagFilter as changeFlagFilterStorage,
  changeKeyword as changeKeywordStorage,
} from "../../store/ManageStorageSlice";

import {
  changeKeyword as changeKeywordQuota,
  changeFlagFilter as changeFlagFilterQuota,
} from "../../store/ManageQuotaSlice";

import {
  changeKeywordFilter as changeKeywordTask,
  changeFlagFilter as changeFlagFilterTask,
} from "../../store/ManageScheduler";

import {
  changeSateKeywordFilter as changeKeywordNoti,
  changeFlagFilter as changeFlagFilterNoti,
} from "../../store/notificationsSlice";
import {
  toggleDrawerCart,
  toggleDrawerDownload,
  toggleDrawerNoti,
  changeSateVisibleReadNoti,
  resetAllStateDrawer,
  changeShowDrawerMainMap,
} from "../../store/DrawerSlice";
import { changeIdSearch, changeFlagFilterItem } from "../../store/exploreSlice";

interface IStyledInput extends InputProps {
  err?: string;
}

// @ts-ignore
interface IButtonCus extends ButtonProps {
  visible?: boolean;
  onChangeVal?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    marginRight: "24px",
  },

  input: {
    ...shorthands.borderRadius("3px"),
    backgroundColor: "272D3C",
    ...shorthands.borderColor("none"),
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "rgb(57, 57, 57, 1)",
    // },

    // "&:focus-within": {
    //   backgroundColor: "rgba(28, 28, 28, 1)",
    // },
  },
});

const StyledFluentInput: React.FC<IStyledInput> = styled(Input)`
  width: 30rem;
  background-color: #272d3c;
  padding-left: 1px;
  padding-right: 1px;
  border: none !important;
  border-bottom-color: ${(props) =>
    props?.err && props?.err !== "" ? "rgba(221, 44, 0, 1)" : "none"};

  &:focus-within {
    border-bottom-color: transparent !important;
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(96, 205, 255, 1)"};

    &:after {
      border-bottom-color: transparent !important;
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(96, 205, 255, 1)"};
    }
  }

  &:hover {
    border-bottom-color: transparent !important;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(149, 149, 149, 1)"};

    &:after {
      border-bottom-color: transparent !important;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(149, 149, 149, 1)"};
    }
  }
`;

const ClearButton = (props: IButtonCus) => {
  return props.visible ? (
    <Button
      {...props}
      appearance="transparent"
      icon={<img className="dismiss-svg-icon" src={DismissSvg} alt="dismiss" />}
      size="small"
      onClick={props?.onChangeVal}
    />
  ) : null;
};

const SearchButton = (props: IButtonCus) => {
  return (
    <Button
      className="!bg-ink-300 !h-8 !w-8 !max-w-[32px]"
      {...props}
      appearance="transparent"
      icon={<img className="img-svg-icon" src={searchSvg} alt="search" />}
      size="small"
      onClick={props?.onChangeVal}
    />
  );
};

const DrawMenuButton = (props: IButtonCus) => {
  return (
    <>
      <Menu>
        <MenuTrigger>
          <div className="flex !bg-ink-300 !p-[5px] !ml-[2px] rounded-sm rounded-sm text-center hover:!border-ink-300 hover:!bg-ink-300">
            <img
              className="img-svg-icon "
              src={SearchMapSvg}
              alt="Info Circle"
            />
            <img src={ArrowSvg} alt="Info Circle" />
          </div>
        </MenuTrigger>

        <MenuPopover className="!top-2.5 !bg-s-background1">
          <AOIComp />
        </MenuPopover>
      </Menu>
    </>
  );
};

const CriteriaButton = (props: any) => {
  return (
    <>
      

      <Button
        as="button"
        key="buttonFliterSearh"
        className={
          "!ml-[2px] !h-8 !w-8 !min-w-[2rem] text-center " +
          (props.active ? "!bg-primary-500" : "!bg-ink-300")
        }
        {...props}
        icon={
          <img
            className="img-svg-icon"
            src={props.active ? criteriaBlackSvg : criteriaWhiteSvg}
            alt="search"
          />
        }
        size="small"
        onClick={props?.openCriteria}
        appearance="transparent"
      />
    </>
  );
};

const SearchComponent = (props: any) => {
  const styles: any = useStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  let pageName: any = useSelector((state: RootState) => state.page?.name);
  let open: boolean = useSelector((state: RootState) => state.uploadAoi?.open);

  const storeStorage = useSelector((state: RootState) => state.managestorage);
  const storeQuota = useSelector((state: RootState) => state.managequota);
  const storeTask = useSelector((state: RootState) => state.managescheduler);
  const storeNoti = useSelector((state: RootState) => state.notifications);
  const storeExplore = useSelector((state: RootState) => state.exploredataset);
  const { showDrawerMainMap } = useSelector((state: RootState) => state.drawer);
  let showCriteria: boolean = useSelector(
    (state: RootState) => state.filter?.showCriteria
  );

  const { flagFilterItem } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const { showResultSearch } = useSelector((state: RootState) => state.filter);

  let keyword =
    pageName === "list-storage"
      ? storeStorage?.keyword
      : pageName === "manage-quota"
      ? storeQuota?.keyword
      : pageName === "scheduler/task"
      ? storeTask?.keywordFilter
      : pageName === "dataset-item"
      ? storeExplore?.idSearch
      : pageName === "manage-dataset-item"
      ? storeExplore?.idSearch
      : storeNoti?.keywordFilter;

  const [showDrawerItem, setShowDrawerItem] = useState(false);
  const [searchText, setSearchText] = useState<string | any>("");

  const openCriteria = () => {
    dispatch(changeShowCriteria(!showCriteria));
    dispatch(changeFlagFilterItem(false));

    dispatch(resetAllStateDrawer({}));
  };

  const handleViewResultSearch = () => {
    dispatch(changeShowDrawerMainMap(showCriteria ? true : !showDrawerMainMap));

    dispatch(changeFlagFilterItem(!flagFilterItem));
    dispatch(changeShowCriteria(false));
  };

  const toggleDrawerItem = () => {
    setShowDrawerItem(!showDrawerItem);
  };

  const onCloseDialogUploadAoi = () => {
    dispatch(setOpenDialog(false));
  };

  const onSearchTextChange = (val: string | any) => {
    setSearchText(val ? val.trim() : val);
    if (pageName === "main-map") {
      dispatch(changeSearchText(val ? val.trim() : val));
    } else if (pageName === "list-storage") {
      dispatch(changeKeywordStorage(val ? val.trim() : val));
    } else if (pageName === "manage-quota") {
      dispatch(changeKeywordQuota(val ? val.trim() : val));
    } else if (pageName === "scheduler/task") {
      dispatch(changeKeywordTask(val ? val.trim() : val));
    } else if (pageName === "manage-notification") {
      dispatch(changeKeywordNoti(val ? val.trim() : val));
    } else if (
      pageName === "dataset-item" ||
      pageName === "manage-dataset-item"
    ) {
      dispatch(changeIdSearch(val ? val.trim() : val));
    }
  };

  const handleSearch = () => {
    if (pageName === "main-map") {
      dispatch(changeCallSearch(true));
    } else if (pageName === "list-storage") {
      dispatch(changeFlagFilterStorage(true));
    } else if (pageName === "manage-quota") {
      dispatch(changeFlagFilterQuota(true));
    } else if (pageName === "scheduler/task") {
      dispatch(changeFlagFilterTask(true));
    } else if (pageName === "manage-notification") {
      dispatch(changeFlagFilterNoti(true));
    } else if (
      pageName === "dataset-item" ||
      pageName === "manage-dataset-item"
    ) {
      dispatch(changeFlagFilterItem(true));
    }
  };

  const handleCancelSearch = async () => {
    if (pageName === "main-map") {
      onSearchTextChange("");
    } else if (pageName === "list-storage") {
      dispatch(changeKeywordStorage(""));
      dispatch(changeFlagFilterStorage(true));
    } else if (pageName === "manage-quota") {
      dispatch(changeKeywordQuota(""));
      dispatch(changeFlagFilterQuota(true));
    } else if (pageName === "scheduler/task") {
      dispatch(changeKeywordTask(""));
      dispatch(changeFlagFilterTask(true));
    } else if (pageName === "manage-notification") {
      dispatch(changeKeywordNoti(""));
      dispatch(changeFlagFilterNoti(true));
    } else if (
      pageName === "dataset-item" ||
      pageName === "manage-dataset-item"
    ) {
      dispatch(changeIdSearch(""));
      dispatch(changeFlagFilterItem(false));
    }
  };

  return (
    <>
      {open ? (
        <UploadDialog open={open} onClose={() => onCloseDialogUploadAoi()} />
      ) : (
        ""
      )}

      {/* For map */}
      <FeaturesDraw />
      {/* End for map */}

      {arrPathVisibleSeachBar?.includes(pageName) && (
        <div style={props.stylesDiv} className={styles?.root} id="input-fluent">
          {props?.label && <Label size="medium">{props?.label}</Label>}

          <StyledFluentInput
            key={"StyledFluentInput"}
            type="text"
            id={props?.id}
            className={styles?.input + " px-2"}
            appearance="filled-lighter"
            value={pageName === "main-map" ? searchText : keyword}
            onChange={(_e: any, data: any) => {
              onSearchTextChange(data.value);
            }}
            disabled={props?.disabled}
            contentAfter={
              <div className="flex">
                <ClearButton
                  aria-label="Clear text"
                  visible={searchText && searchText.trim() ? true : false}
                  onChangeVal={() => {
                    handleCancelSearch();
                  }}
                />

                {pageName == "mainMap" ? (
                  <>
                    {showResultSearch && (
                      <Button
                        as="button"
                        key="buttonShowResultSearch"
                        className={
                          "!ml-[2px] !h-8 !w-8 !min-w-[2rem] text-center " +
                          (flagFilterItem ? "!bg-primary-500" : "!bg-ink-300")
                        }
                        {...props}
                        icon={
                          <img
                            className="img-svg-icon"
                            src={ViewResultSearch}
                            alt="search"
                          />
                        }
                        size="small"
                        onClick={() => {
                          handleViewResultSearch();
                        }}
                        appearance="transparent"
                      />
                    )}
                    <CriteriaButton
                      active={showCriteria}
                      openCriteria={openCriteria}
                      handleViewResultSearch={handleViewResultSearch}
                      
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            }
            contentBefore={
              <>
                {pageName === "mainMap" ? (
                  <DrawMenuButton />
                ) : (
                  <SearchButton
                    aria-label="Search text"
                    onChangeVal={() => {
                      handleSearch();
                    }}
                  />
                )}
              </>
            }
            placeholder={t("Search")}
            onKeyPress={(e: any) => {
              if (e.charCode === 13 && pageName !== "mainMap") {
                handleSearch();
              }
            }}
          />
        </div>
      )}

      {/* <DrawerComp
        showDrawer={showDrawerItem}
        toggleDrawer={toggleDrawerItem}
        type="overlay"
        position="end"
        style={{ color: "white", width: "650px" }}
        title="Information"
        header={
          <div className="flex text-sm items-center">
            <img src={LayerSvg} alt="layer" />
            <span style={{ marginLeft: "4px" }}>{t("Item")}</span>
          </div>
        }
      >
        <CardItemSearch />
      </DrawerComp> */}
    </>
  );
};

export default SearchComponent;
