import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
import moment from "moment";
import NextSvg from "../../assets/next.svg";
import ErrorIcon from "../../assets/scan-error.svg";
import SuccessIcon from "../../assets/scan-success.svg";
import useCustomNavigate from "../../hook/useCustomNavigate";
import TaskSvg from "./../../assets/item-task.svg";

const CardHistoryScanComp = (props: any) => {
  const navigate = useCustomNavigate();

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section key={index}>
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240] cursor-pointer shadow-[none]"
              onClick={() => {
                if (item?.summary?.finished)
                  navigate(`${document.location.pathname}/${item?.id}`);
              }}
            >
              <CardPreview className="div-center div-card-preview">
                {item?.summary?.finished ? (
                  item?.summary?.success ? (
                    <Tooltip relationship="description" content={"Success"}>
                      <img
                        className="img-card-item-catalog"
                        src={SuccessIcon}
                        alt="img item"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip relationship="description" content={"Error"}>
                      <img
                        className="img-card-item-catalog"
                        src={ErrorIcon}
                        alt="img item"
                      />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip relationship="description" content={"Scanning"}>
                    {/* <img
                      className="img-card-item-catalog"
                      src={ScanningIcon}
                      alt="img item"
                    /> */}

                    <div className="relative !flex justify-around">
                      <img
                        className="img-card-item-catalog"
                        src={TaskSvg}
                        alt="img item"
                      />

                      <div className="absolute top-[46%] left-[52%]">
                        <svg
                          className="animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="20"
                          viewBox="0 0 23 24"
                          fill="none"
                        >
                          <circle cx="11.5" cy="12" r="8" fill="#4CC2FF" />
                          <path
                            d="M1.5 12C1.5 16.4776 4.44289 20.2679 8.5 21.5422"
                            stroke="#4CC2FF"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </svg>

                        {/* <svg
                            className="animate-spin"
                          width="18"
                          height="18"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="14"
                            cy="13.9999"
                            r="12"
                            transform="rotate(45 14 13.9999)"
                            stroke="#565656"
                            stroke-width="4"
                          ></circle>
                          <path
                            d="M22.4852 22.4852C17.7989 27.1715 10.2009 27.1715 5.51465 22.4852C0.828357 17.7989 0.828357 10.2009 5.51465 5.51465"
                            stroke="#60CDFF"
                            stroke-width="4"
                            stroke-linecap="round"
                            stroke-dasharray="58"
                            stroke-dashoffset="100"
                          ></path>
                        </svg> */}
                      </div>
                    </div>
                  </Tooltip>
                )}
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <Tooltip
                        relationship="description"
                        content={item?.collection_id}
                      >
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.collection_id}
                        </p>
                      </Tooltip>

                      <Tooltip
                        relationship="description"
                        content={
                          item?.created
                            ? moment(item?.created).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "---"
                        }
                      >
                        <p className="!mt-1 text-des ellipsis-text-one-line">
                          {/* <span className="text-color-des">Created at: </span> */}
                          {/* {item?.created
                            ? moment(item?.created).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "---"} */}
                          {
                            props?.arrSortStorage?.filter(
                              (itemS: any) => item?.storage_id === itemS?.id
                            )?.[0]?.name
                          }
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                {item?.summary?.finished && (
                  <div>
                    <img
                      className="img-svg-icon"
                      src={NextSvg}
                      alt="Info Circle"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </section>
        );
      })}
    </>
  );
};

export default CardHistoryScanComp;
