import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getList,
  getTypeNoti,
  addNoti,
  updateNoti,
  deleteNoti,
  getNotiUser,
  sendNoti,
  userUpdateNoti,
  seenAllNoti,
} from "../services/ManageNotification";

export interface ManageNotificationState {
  visibleReadNoti: boolean;
  itemRead: any;
  totalNoti: any;

  keywordFilter: string;
  typeFilter: any;
  sentAtFromFilter: any;
  sentAtToFilter: any;
  sort_by: string;
  sort_order: string;
  flagFilter: false;
  flagSort: false;
  flagFresh: false;
  totalNotiNotRead?: any
}
const initialState: ManageNotificationState = {
  visibleReadNoti: false,
  itemRead: {},
  totalNoti: 0,
  totalNotiNotRead: 0,
  keywordFilter: "",
  typeFilter: [],
  sentAtFromFilter: "",
  sentAtToFilter: "",
  sort_by: "modified",
  sort_order: "desc",

  flagFilter: false,
  flagSort: false,
  flagFresh: false,
};

export const seenAllNotiAsync = createAsyncThunk(
  "ManageNotification/seenAllNoti",
  async (payload) => {
    try {
      const response = await seenAllNoti(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getListAsync = createAsyncThunk(
  "ManageNotification/getList",
  async (payload) => {
    try {
      const response = await getList(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getTypeNotiAsync = createAsyncThunk(
  "ManageNotification/getTypeNoti",
  async (payload) => {
    try {
      const response = await getTypeNoti(payload);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const addNotiAsync = createAsyncThunk(
  "ManageNotification/addNoti",
  async (payload) => {
    try {
      const response = await addNoti(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const sendNotiAsync = createAsyncThunk(
  "ManageNotification/sendNoti",
  async (payload) => {
    try {
      const response = await sendNoti(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const updateNotiAsync = createAsyncThunk(
  "ManageNotification/updateNoti",
  async (payload) => {
    try {
      const response = await updateNoti(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getNotiUserAsync = createAsyncThunk(
  "ManageNotification/getNotiUser",
  async (payload) => {
    try {
      const response = await getNotiUser(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const userUpdateNotiAsync = createAsyncThunk(
  "ManageNotification/userUpdateNoti",
  async (payload) => {
    try {
      const response = await userUpdateNoti(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    toasterId: new Date().getTime() + "",
    alert: {
      show: false,
      title: null,
      message: null,
      buttons: {
        close: false,
        yes: false,
        no: false,
      },
    },
    ...initialState,
  },
  reducers: {
    changeTotalNoti: (state, action) => {
      state.totalNoti = action.payload;
    },

    changeTotalNotiNotRead: (state, action) => {
      state.totalNotiNotRead = action.payload;
    },
    changeToasterId: (state, action) => {
      state.toasterId = action?.payload;
    },
    updateAlert: (state, action) => {
      state.alert = action?.payload;
    },

    changeSateItemRead: (state, action) => {
      state.itemRead = action.payload;
    },

    changeSateKeywordFilter: (state, action) => {
      state.keywordFilter = action.payload;
    },

    changeSateTypeFilter: (state, action) => {
      state.typeFilter = action.payload;
    },

    changeSateSentAtFromFilter: (state, action) => {
      state.sentAtFromFilter = action.payload;
    },

    changeSateSentAtToFilter: (state, action) => {
      state.sentAtToFilter = action.payload;
    },

    changeSortBy: (state, action) => {
      state.sort_by = action.payload;
    },

    changeSortOrder: (state, action) => {
      state.sort_order = action.payload;
    },

    resetState: (state, action) => {
      state.visibleReadNoti = false;
      state.itemRead = {};
    },

    resetFilter: (state, action) => {
      state.keywordFilter = "";
      state.typeFilter = [];
      state.sentAtFromFilter = "";
      state.sentAtToFilter = "";
    },
    resetSortOption: (state, action) => {
      state.sort_by = "modified";
      state.sort_order = "desc";
    },

    changeFlagFilter: (state, action) => {
      state.flagFilter = action?.payload;
    },
    changeFlagSort: (state, action) => {
      state.flagSort = action?.payload;
    },
    changeFlagFresh: (state, action) => {
      state.flagFresh = action?.payload;
    },
  },
});

export const deleteNotiAsync = createAsyncThunk(
  "managestorage/deleteNoti",
  async (payload) => {
    const response = await deleteNoti(payload);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return response;
  }
);

export const {
  changeToasterId,
  updateAlert,
  changeSateItemRead,
  resetState,
  changeSateTypeFilter,
  changeSateKeywordFilter,
  changeSateSentAtFromFilter,
  changeSateSentAtToFilter,
  resetFilter,
  resetSortOption,
  changeSortOrder,
  changeSortBy,
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeTotalNoti,
  changeTotalNotiNotRead,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
