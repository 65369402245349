import axios from "../../utils/axios";

export async function getProductType() {
  // return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/stac/collections`);
 

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/dictionaries/stac/product_types/`
  );
}

export async function getThumbnailDataset(payload: any) {
 
  // return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/stac/collections`);

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${payload?.collection_id}/thumbnails/`
  );
}

export async function getThumbnailItemDataset(payload: any) {
 
  // return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/stac/collections`);

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${payload?.collection_id}/items/${payload?.item_id}/thumbnails/`
  );
}

export async function getList(payload: any) {
  

  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/`, {
    params: { ...payload },
  });
}

export async function getFeaturedById(id: string) {
 
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${id}/`);
}

export async function getItemsByCollectionId(payload: any) {
 
  // get all item in collection by colletion id

  const { id, ...newPay } = payload;

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${payload?.id}/items/`,
    {
      params: { ...newPay },
    }
  );
}

export async function getItemsCollectionByPath(payload: any) {
  // const { path, ...newPay } = payload;

  // return axios.get(`${payload?.path}`, {
  //   params: { ...newPay },
  // });

  return axios.get(`${payload?.path}`);
}

export async function getItemsByCollectionIdFilter(payload: any) {
 
  // get all item in collection by colletion id

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${payload?.id}/items/`,
    {
      params: { ...payload },
    }
  );
}

export async function search(payload: any) {
 
  const { from_history, next, ...newPay } = payload;
  let url = payload?.from_history
    ? `${localStorage.getItem('REACT_APP_ENDPOINT')}/search/?from_history=true` +
      (next ? "&" + next : "")
    : `${localStorage.getItem('REACT_APP_ENDPOINT')}/search/` + (next ? "?" + next : "");
  return axios.post(url, newPay);
}

export async function validateCollections(payload: any) {
 
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/validate/collections/`,
    payload
  );
}

export async function uploadImgCollections(payload: any) {
 
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.id}/thumbnails/upload/`,
    payload?.formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

export async function postCollections(payload: any) {
 
  return axios.post(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/`, payload);
}

export async function editCollections(payload: any) {
 
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.id}/`,
    payload?.value
  );
}

export async function deleteCollections(id: any) {
 
  return axios.delete(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${id}/`);
}

// item collection

export async function getItemCollectionById(payload: any) {
 
  // get item by id item &&  id collection

  return await axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${payload?.collectionId}/items/${payload?.itemId}/`
    // {
    //   params: { ...payload },
    // }
  );
}

export async function validateItemCollections(payload: any) {
 
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/validate/items/`,
    payload
  );
}

export async function editItemCollections(payload: any) {
 
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.idCollection}/items/${payload?.idItem}/`,
    payload?.dataItem
  );
}

export async function deleteItemCollections(payload: any) {
 
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.idCollection}/items/${payload?.idItem}/`
  );
}

export async function addItemCollections(payload: any) {
 
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.idCollection}/items/`,
    payload?.dataItem
  );
}

export async function getThumbnailItemCollection(payload: any) {
 
  // get thumbnails item collection by id collection & id item

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/collections/${payload?.collection_id}/items/${payload?.item_id}/thumbnails/`
  );
}
