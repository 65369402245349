import { createSlice } from '@reduxjs/toolkit'

export const uploadAoiSlice = createSlice({
    name: 'uploadAoi',
    initialState: {
        open: false,
    },
    reducers: {
        setOpenDialog: (state, action) => {
            state.open = action?.payload;
        },
    }
});

export const { setOpenDialog } = uploadAoiSlice.actions

export default uploadAoiSlice.reducer