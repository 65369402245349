import { Dropdown, Option } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeEndAt,
  changeStartAt,
  changeTypeQuota,
  getQuotaTyeAsync,
} from "../../store/ManageQuotaSlice";

const FilterQuotaComp = (props: any) => {
  const { type_quota, start_at, end_at } = useSelector(
    (state: RootState) => state.managequota
  );

  const [arrQuotaType, setArrQuotaType] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const resQuotaType = await dispatch(
          // @ts-ignore
          getQuotaTyeAsync({})
        ).unwrap();

        setArrQuotaType(resQuotaType ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const dispatch = useDispatch();

  const changeTime = (date: any, type: string) => {
    if (type === "start_at") {
      if (date) {
        dispatch(changeStartAt(date));
      } else {
        dispatch(changeStartAt(null));
      }
    }
    if (type === "end_at") {
      {
        if (date) {
          dispatch(changeEndAt(date));
        } else {
          dispatch(changeEndAt(null));
        }
      }
    }
  };

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = useCallback(
    (newValue: string): Date => {
      const previousValue = start_at || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [start_at]
  );

  const onParseDateFromStringEndAt = useCallback(
    (newValue: string): Date => {
      const previousValue = end_at || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [end_at]
  );

  return (
    <>
      <div>
        <p className="mb-2">Create from</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          parseDateFromString={onParseDateFromStringStartAt}
          value={start_at}
          onSelectDate={(date: any) => changeTime(date, "start_at")}
          maxDate={end_at}
        />
        <p className="mb-2 mt-4">Create to</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          value={end_at}
          onSelectDate={(date: any) => changeTime(date, "end_at")}
          parseDateFromString={onParseDateFromStringEndAt}
          minDate={start_at}
        />

        <p className="mb-2 mt-4">Type quota</p>
        <Dropdown
          placeholder="Select type quota"
          className="div-dropdown"
          value={type_quota}
          onOptionSelect={(e: any, data: any) => {
            dispatch(changeTypeQuota(data?.optionValue));
          }}
          selectedOptions={[type_quota]}
        >
          <Option key={"All"} value="All">
            All
          </Option>
          {arrQuotaType.map((option: any) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>
      </div>
    </>
  );
};

export default FilterQuotaComp;
