import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
// import { ButtonComponent as Button } from "@web/react-component-ui-user";

import DirectNoti from "../../assets/direct-noti.svg";
import NotiSvg from "../../assets/noti.svg";
import DeleteSvg from "./../../assets/delete.svg";
import EditSvg from "./../../assets/edit-light.svg";
import InforSvg from "./../../assets/info-circle.svg";

import moment from "moment";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardNotiComp = (props: any) => {
  const navigate = useCustomNavigate();

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section key={index}>
            <div className="bg-[#2E3240] rounded">
              <Card
                orientation="horizontal"
                className="card-item-storage !bg-[#2E3240]"
              >
                <CardPreview className="div-center div-card-preview">
                  <img
                    src={NotiSvg}
                    alt="img item"
                    className="img-card-item-catalog"
                  />
                </CardPreview>
                <CardHeader
                  className="w-auto div-card-header"
                  header={
                    <div className="divHeader relative">
                      <div className="block w-full">
                        <Tooltip
                          relationship="description"
                          content={item?.title}
                        >
                          <p className="!uppercase text-title-card ellipsis-text-one-line">
                            {item?.title}
                          </p>
                        </Tooltip>

                        {item?.sent && (
                          <Tooltip
                            relationship="description"
                            content={moment(item?.sent)?.format(
                              "hh:mm:ss DD/MM/YYYY"
                            )}
                          >
                            <p className="mt-1 text-des ellipsis-text-one-line">
                              {/* <span className="text-color-des">Sent at: </span> */}
                              {moment(item?.sent)?.format(
                                "hh:mm:ss DD/MM/YYYY"
                              )}
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  }
                />

                <CardFooter className="footer-card-action">
                  <div className="div-action-card">
                    {item?.sent ? (
                      <>
                        <Button
                          className="btn-action-card !cursor-pointer"
                          size="medium"
                          iconPosition="before"
                          onClick={() => {
                            props?.handleReadItem(item);
                          }}
                          icon={
                            <img
                              className="img-svg-icon"
                              src={InforSvg}
                              alt="Info Circle"
                            />
                          }
                        />
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <Button
                            className="btn-action-card cursor-pointer !mr-4"
                            appearance="subtle"
                            size="medium"
                            icon={
                              <img
                                className="img-svg-icon mr-2"
                                src={EditSvg}
                                alt="Info Circle"
                              />
                            }
                            onClick={() =>
                              navigate(
                                `/manage-notification/edit/${item?.id}`,
                                {
                                  state: {
                                    item: item,
                                  },
                                }
                              )
                            }
                          />
                          <Menu>
                            <MenuTrigger disableButtonEnhancement>
                              <img
                                className="img-svg-icon mr-4 cursor-pointer"
                                src={DirectNoti}
                                alt="Info Circle"
                              />
                            </MenuTrigger>

                            <MenuPopover>
                              <MenuList onClick={() => props?.sendTo(item?.id)}>
                                <MenuItem>Sent </MenuItem>
                              </MenuList>
                            </MenuPopover>
                          </Menu>

                          <Menu>
                            <MenuTrigger disableButtonEnhancement>
                              <img
                                className="img-svg-icon cursor-pointer"
                                src={DeleteSvg}
                                alt="delete"
                              />
                            </MenuTrigger>

                            <MenuPopover>
                              <MenuList
                                onClick={() => props?.deleNoti(item?.id)}
                              >
                                <MenuItem>Delete </MenuItem>
                              </MenuList>
                            </MenuPopover>
                          </Menu>
                        </div>
                      </>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CardNotiComp;
