import React, { useState } from "react";
import { useTranslation } from "react-i18next";
export default function GeojsonUpload(props: any) {
    const { t } = useTranslation();
    const [file, setFile] = useState<File | null>(props.file);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            props.onChange(e.target.files[0]);
        }
    };

    return (
        <div className="py-6 px-2">
            <div>
                <label htmlFor="file" className="sr-only">
                    {t('Choose a file')}
                </label>
                <input id="file" type="file" accept=".geojson" onChange={handleFileChange}
                    className="block w-full text-sm text-white
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-white/80 file:text-ink-100
                                hover:file:bg-white/70 hover:file:cursor-pointer"/>
            </div>
            {file && (
                <section className="py-4">
                    <h6 className="text-sm after:content-[''] after:block after:w-full after:border-b after:border-dashed after:border-[#444] after:mt-1">{t('File details')}:</h6>
                    <ul className="mt-2 gap-y-2">
                        <li key={1}>{t('Name')}: {file.name}</li>
                        <li key={2}>{t('Type')}: {file.type}</li>
                        <li key={3}>{t('Size')}: {file.size} {t('bytes')}</li>
                    </ul>
                </section>
            )}
        </div>
    )
}