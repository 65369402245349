import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Dropdown,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Option,
  Switch,
  Textarea,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  addConfigAsync,
  cleanImmeDlsessionExpiredAsync,
  configAutoTaskAsync,
  expireScansessionAsync,
  updateConfigAsync,
} from "../../store/ManageSetting";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { RootState } from "../../store/store";
import {
  dataSysAutoTask,
  dataSysManualTask,
  timeout,
} from "../../utils/constants";
import StyledFluentInput from "../InputCus";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import { getListAsync as getListStorage } from "./../../store/ManageStorageSlice";

import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { getListTaskAsync } from "../../store/ManageScheduler";
import { resetState } from "../../store/pagingSlice";
import ToastComp from "../Toast";

import RunSvg from "../../assets/play.svg";
import useCustomNavigate from "../../hook/useCustomNavigate";

const DetailSystemKeyComp = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const param = useParams();
  const location = useLocation();

  const [flagExpScanSession, setFlagExpScanSession] = useState(false);
  const [flagCleanOrderDownloadExp, setFlagCleanOrderDownloadExp] =
    useState(false);

  const { item } = location?.state ?? {};

  const dataBread = [
    {
      key: 0,
      value: "System Setting",
      path: "setting-system",
    },
    {
      key: 1,
      value: `${param?.idKey}`,
    },
  ];

  useEffect(() => {
    (async function () {
      if (
        param?.idKey !== "SYSTEM_AUTOMATIC_TASKS" &&
        param?.idKey !== "SYSTEM_MANUAL_TASKS"
      ) {
        try {
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getListStorage({
              size: 1000,
              page: 0,
            })
          ).unwrap();
          res?.result?.map((item: any) => {
            if (item?.id === location?.state?.item?.detail?.value?.storage_id) {
              setStorageName(item?.name);
            }
          });

          setArrStorage(res?.result ?? []);
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (param?.idKey === "SYSTEM_AUTOMATIC_TASKS") {
        try {
          dispatch(changeVisibleSpinner(true));
          let res;
          res = await dispatch(
            // @ts-ignore
            getListTaskAsync({
              size: 1,
              page: 0,
              filter: {
                enable: true,
                task_types: ["EXPIRE_SCAN_SESSIONS"],
              },
            })
          ).unwrap();

          if (res?.result?.length !== 0) {
            setFlagExpScanSession(true);
          }

          res = await dispatch(
            // @ts-ignore
            getListTaskAsync({
              size: 1,
              page: 0,
              filter: {
                enable: true,
                task_types: ["CLEAN_EXPIRED_DLSESSION_DATA"],
              },
            })
          ).unwrap();

          if (res?.result?.length !== 0) {
            setFlagCleanOrderDownloadExp(true);
          }

          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const [arrStorage, setArrStorage] = useState<any[]>([]);
  const [des, setDes] = useState("");
  const [enable, setEnable] = useState(item?.detail?.enable);
  const [storageId, setStorageId] = useState(item?.detail?.value?.storage_id);
  const [storageName, setStorageName] = useState("");

  const [minus, setMinus] = useState(item?.detail?.value?.value);
  const [errorMinus, setErrorMinus] = useState<any>();

  const onOptionSelectType = (e: any, data: any) => {
    setStorageName(data?.optionText);
    setStorageId(data?.optionValue);
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    dispatch(changeVisibleSpinner(true));
    let res;

    if (Object.keys(item?.detail)?.length === 0) {
      res = await dispatch(
        // @ts-ignore
        addConfigAsync({
          key: item?.key,
          description: item?.detail?.description,
          enable: enable,
          value:
            item?.detail?.key !== "SCAN_SESSION_EXPIRE_TIME_IN_MINS"
              ? {
                  storage_id: storageId,
                  enable: true,
                }
              : {
                  value: minus,
                  enable: true,
                },
        })
      ).unwrap();
    } else {
      res = await dispatch(
        // @ts-ignore
        updateConfigAsync({
          key: item?.detail?.id,
          description: item?.detail?.description,
          enable: enable,
          value:
            item?.detail?.key !== "SCAN_SESSION_EXPIRE_TIME_IN_MINS"
              ? {
                  storage_id: storageId,
                  enable: true,
                }
              : {
                  value: minus,
                  enable: true,
                },
        })
      ).unwrap();
    }

    if (res?.status === 200) {
      notify(
        Object.keys(item?.detail)?.length === 0
          ? "Add successfully"
          : "Update successfully",
        "success"
      );
      setTimeout(() => {
        navigate(`/setting-system`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify(
        Object.keys(item?.detail)?.length === 0 ? "Add fail" : "Update fail",
        "error"
      );

      dispatch(changeVisibleSpinner(false));
    }
  };

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const handleInputChange = (event: any, type: any) => {
    if (type === "minus") {
      const regex = /^[0-9\b]+$/; // Allows only digits
      const inputValue = event.target.value;
      let a = regex.test(inputValue);

      if (a || inputValue === "") {
        setErrorMinus("");
        setMinus(inputValue);
      } else {
        setErrorMinus("Ener only digit");
      }
    }
  };

  const handleConfig = async (val: any, key: any) => {
    const res = await dispatch(
      // @ts-ignore
      configAutoTaskAsync({
        taskType: key,
        action: val ? "START" : "STOP",
      })
    ).unwrap();

    if (res?.status === 200) {
      if (key === "CLEAN_EXPIRED_DLSESSION_DATA") {
        setFlagCleanOrderDownloadExp(val);
      } else {
        setFlagExpScanSession(val);
      }
      notify(`${val ? "Start " : "Stop "} setting successfully`, "success");
    } else {
      notify(`${val ? "Start " : "Stop "} setting faild`, "error");
    }
  };

  const handleRun = async (key: string) => {
    dispatch(changeVisibleSpinner(true));
    if (key === "EXPIRE_SCAN_SESSIONS") {
      // @ts-ignore
      const res = await dispatch(expireScansessionAsync({})).unwrap();

      if (res?.status === 200) {
        notify(`Expire scansession successfully`, "success");
        dispatch(changeVisibleSpinner(false));
      } else {
        notify(`Expire scansession fail`, "error");
        dispatch(changeVisibleSpinner(false));
      }
    } else if (key === "CLEAN_EXPIRED_DLSESSION_DATA") {
      // @ts-ignore
      const res = await dispatch(cleanImmeDlsessionExpiredAsync({})).unwrap();

      if (res?.status === 200) {
        notify(`Clean download expired data successfully`, "success");
        dispatch(changeVisibleSpinner(false));
      } else {
        notify(`Clean download expired data fail`, "error");
        dispatch(changeVisibleSpinner(false));
      }
    }
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      {param?.idKey !== "SYSTEM_AUTOMATIC_TASKS" &&
        param?.idKey !== "SYSTEM_MANUAL_TASKS" && (
          <>
            <div className="div-form-storage-small">
              <p className="!uppercase text-title-case">
                {item?.detail?.description}
              </p>
              {Object.keys(item?.detail)?.length === 0 && (
                <>
                  <p className="mb-2 mt-6">Description</p>
                  <Textarea
                    value={des}
                    onChange={(e: any) => setDes(e?.target?.value)}
                    placeholder="Enter description..."
                    className="div-dropdown"
                    resize="both"
                  />
                </>
              )}

              <div className="w-full">
                {item?.key !== "SCAN_SESSION_EXPIRE_TIME_IN_MINS" && (
                  <>
                    <p className="mb-2 mt-6">Storage</p>
                    <Dropdown
                      placeholder="Select storage"
                      className="div-dropdown"
                      value={storageName}
                      onOptionSelect={onOptionSelectType}
                    >
                      {arrStorage?.map((option: any) => (
                        <Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Dropdown>
                  </>
                )}

                {item?.key === "SCAN_SESSION_EXPIRE_TIME_IN_MINS" && (
                  <>
                    <p className="mb-2 mt-6">Minutes</p>
                    {/* <Input className="div-dropdown" placeholder="Enter minutes" /> */}
                    <div className="block !w-full">
                      <StyledFluentInput
                        placeholder="00"
                        className="div-dropdown"
                        onChange={(e) => handleInputChange(e, "minus")}
                        value={minus}
                        err={errorMinus}
                      />
                      {errorMinus}
                    </div>
                  </>
                )}

                <p className="mb-2 mt-6">Enable</p>
                <Switch
                  checked={enable}
                  onChange={(_, data: any) => {
                    setEnable(data?.checked);
                  }}
                />
              </div>
            </div>

            <div className="flex absolute bottom-18 right-12">
              <Button
                appearance="outline"
                className="btn-action"
                onClick={() => add()}
                disabled={loading}
              >
                Update
              </Button>
            </div>
          </>
        )}

      {param?.idKey === "SYSTEM_AUTOMATIC_TASKS" &&
        dataSysAutoTask?.map((item: any, ind: number) => (
          <>
            <section key={ind}>
              <div className="bg-[#2E3240] rounded">
                <Card
                  // onClick={() => navigate(`./SYSTEM_AUTOMATIC_TASKS`)}
                  orientation="horizontal"
                  className="card-item-storage !bg-[#2E3240]"
                >
                  <CardHeader
                    style={{ width: "calc(100% - 150px)" }}
                    header={
                      <div className="divHeader relative">
                        <div className="block w-full">
                          <Tooltip
                            relationship="description"
                            // @ts-ignore
                            content="System automatic tasks"
                          >
                            <b
                              className="!uppercase text-title-card ellipsis-text-one-line"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {item?.des}
                            </b>
                          </Tooltip>

                          {/* <p className="mt-1 text-des">
                      {Object.keys(item?.detail)?.length === 0 && "No config"}
                    </p> */}
                        </div>
                      </div>
                    }
                  />

                  <CardFooter className="footer-card-action">
                    <Switch
                      checked={
                        item?.key === "EXPIRE_SCAN_SESSIONS"
                          ? flagExpScanSession
                          : flagCleanOrderDownloadExp
                      }
                      onChange={(_, data: any) => {
                        handleConfig(data?.checked, item?.key);
                      }}
                    />
                  </CardFooter>
                </Card>
              </div>
            </section>
          </>
        ))}
      {param?.idKey === "SYSTEM_MANUAL_TASKS" &&
        dataSysManualTask?.map((item: any, ind: number) => (
          <>
            <section key={ind}>
              <div className="bg-[#2E3240] rounded">
                <Card
                  // onClick={() => navigate(`./SYSTEM_AUTOMATIC_TASKS`)}
                  orientation="horizontal"
                  className="card-item-storage !bg-[#2E3240]"
                >
                  <CardHeader
                    style={{ width: "calc(100% - 150px)" }}
                    header={
                      <div className="divHeader relative">
                        <div className="block w-full">
                          <Tooltip
                            relationship="description"
                            // @ts-ignore
                            content="System automatic tasks"
                          >
                            <b
                              className="!uppercase text-title-card ellipsis-text-one-line"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {item?.des}
                            </b>
                          </Tooltip>

                          {/* <p className="mt-1 text-des">
                      {Object.keys(item?.detail)?.length === 0 && "No config"}
                    </p> */}
                        </div>
                      </div>
                    }
                  />

                  <CardFooter className="footer-card-action">
                    {/* <div onClick={() => handleRun(item?.key)} className="mr-3">
                      <img
                        className="img-svg-icon"
                        src={RunSvg}
                        alt="run setting"
                      />
                    </div> */}
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="img-svg-icon mr-2"
                          src={RunSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList onClick={() => handleRun(item?.key)}>
                          <MenuItem>Run </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </CardFooter>
                </Card>
              </div>
            </section>
          </>
        ))}
    </div>
  );
};

export default DetailSystemKeyComp;
