import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSystemKey,
  addConfig,
  getDetailConfig,
  updateConfig,
  getTaskType,
  configAutoTask,
  cleanImmeDlsessionExpired,
  expireScansession
} from "../services/ManageSetting";

export interface ManageSettingState {}

const initialState: ManageSettingState = {};

export const cleanImmeDlsessionExpiredAsync = createAsyncThunk(
  "managestorage/cleanImmeDlsessionExpired",
  async () => {
    try {
      const response = await cleanImmeDlsessionExpired();
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const expireScansessionAsync = createAsyncThunk(
  "managestorage/expireScansession",
  async () => {
    try {
      const response = await expireScansession();
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getTaskTypeAsync = createAsyncThunk(
  "managestorage/getTaskType",
  async () => {
    try {
      const response = await getTaskType();
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const configAutoTaskAsync = createAsyncThunk(
  "managestorage/configAutoTask",
  async (payload) => {
    try {
      const response = await configAutoTask(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const updateConfigAsync = createAsyncThunk(
  "managestorage/updateConfig",
  async (payload) => {
    try {
      const response = await updateConfig(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getDetailConfigAsync = createAsyncThunk(
  "managestorage/getDetailConfig",
  async (payload) => {
    try {
      const response = await getDetailConfig(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getSystemKeyAsync = createAsyncThunk(
  "managestorage/getSystemKey",
  async (payload) => {
    try {
      const response = await getSystemKey(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const addConfigAsync = createAsyncThunk(
  "managestorage/addConfig",
  async (payload) => {
    try {
      const response = await addConfig(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const ManageSettingSlice = createSlice({
  name: "managesetting",
  initialState,
  reducers: {
    changeStateBtnCreate: (state, action) => {},
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const { changeStateBtnCreate } = ManageSettingSlice.actions;

export default ManageSettingSlice.reducer;
