/**
 * Copyright 2016, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import shp from 'shpjs';
//@ts-ignore
import JSZip from 'jszip';
const parser = new DOMParser();
// eslint-disable-next-line import/first
import tj from '@mapbox/togeojson';

const cleanStyleFromKml = (xml) =>
{
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("StyleMap")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("Style")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("LineStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("PointStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("PolyStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("IconStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("LabelStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("ListStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("BallonStyle")).map(el => el.parentNode.removeChild(el));
    //@ts-ignore
    [].slice.call(xml.documentElement.getElementsByTagName("styleUrl")).map(el => el.parentNode.removeChild(el));
    return xml;
};
export const MIME_LOOKUPS = {
    'avi': 'video/avi',
    'gpx': 'application/gpx+xml',
    'kmz': 'application/vnd.google-earth.kmz',
    'kml': 'application/vnd.google-earth.kml+xml',
    'zip': 'application/zip',
    'json': 'application/json',
    'geojson': 'application/json',
    'wmc': 'application/vnd.wmc'
};
export const recognizeExt = function (fileName)
{
    return fileName.split('.').slice(-1)[0];
};
export const shpToGeoJSON = function (zipBuffer)
{
    //@ts-ignore
    return [].concat(shp.parseZip(zipBuffer));
};
export const kmlToGeoJSON = function (xml)
{
    const pureKml = cleanStyleFromKml(xml);
    //@ts-ignore
    const geoJSON = [].concat(tj.kml(pureKml)).map(item => Object.assign({}, item, { fileName: pureKml.getElementsByTagName('name')[0] ? pureKml.getElementsByTagName('name')[0].innerHTML : "" }));
    return geoJSON;
};
export const gpxToGeoJSON = function (xml, fileName)
{
    //@ts-ignore
    const geoJSON = [].concat(tj.gpx(xml)).map(item => Object.assign({}, item, {
        fileName: xml.getElementsByTagName('name')[0] && xml.getElementsByTagName('name')[0].innerHTML || fileName
    }));
    return geoJSON;
};

export const readZip = function (file)
{
    return new Promise((resolve, reject) =>
    {
        let reader = new FileReader();
        reader.onload = function () { resolve(reader.result); };
        reader.onerror = function () { reject(reader.error?.name); };
        reader.readAsArrayBuffer(file);
    });
};
export const readKml = function (file)
{
    return new Promise((resolve, reject) =>
    {
        let reader = new FileReader();
        reader.onload = function ()
        {
            resolve(parser.parseFromString(reader.result ? reader.result + "" : "", "text/xml"));
        };
        reader.onerror = function ()
        {
            reject(reader.error?.name);
        };
        reader.readAsText(file);
    });
};
export const readJson = function (file)
{
    return new Promise((resolve, reject) =>
    {
        let reader = new FileReader();
        reader.onload = function ()
        {
            try
            {
                resolve(JSON.parse(reader.result ? reader.result + "" : ""));
            } catch (e)
            {
                reject(e);
            }
        };
        reader.onerror = function ()
        {
            reject(reader.error?.name);
        };
        reader.readAsText(file);
    });
};
export const readKmz = function (file)
{
    const zip = new JSZip();
    return new Promise((resolve, reject) =>
    {
        zip.loadAsync(file).then(files =>
        {
            files.filter(elem =>
            {
                return elem.indexOf('kml') !== -1;
            }).forEach(elem =>
            {
                return elem.async("string").then((response) =>
                {
                    resolve(parser.parseFromString(response, "text/xml"));
                }).catch((e) =>
                {
                    reject(e.message);
                });
            });
        });
    });
};
export const readGeoJson = function (file, warnings = false)
{
    return new Promise((resolve, reject) =>
    {
        let reader = new FileReader();
        reader.onload = function ()
        {
            try
            {
                if (reader.result)
                {
                    const geoJsonObj = JSON.parse(reader.result ? reader.result + "" : "");
                    //import { hint as geojsonhint } from '@mapbox/geojsonhint/lib/object';
                    resolve({ geoJSON: geoJsonObj, errors: []/*geojsonhint(geoJsonObj, {}).filter((e: any) => warnings || e.level !== 'message')*/ });
                }
                else reject("No content");
            } catch (e)
            {
                reject(e);
            }
        };
        reader.onerror = function ()
        {
            reject(reader.error?.name);
        };
        reader.readAsText(file);
    });
};
/* Checks if the zip contains .prj file */
export const checkShapePrj = function (buffer)
{
    const zip = new JSZip();
    return new Promise((resolve) =>
    {
        zip.loadAsync(buffer).then(({ files = {} }) =>
        {
            const shapeNames = Object.keys(files).filter(k => !files[k].dir && k.indexOf("__MACOSX") !== 0 && k.indexOf(".shp") === k.length - 4).map(n => n.slice(0, -4));
            const warnings = shapeNames.reduce((p, c) =>
            {
                return p.concat(!files[`${ c }.prj`] && c || []);
            }, []);
            resolve(warnings);
        });
    });
};
