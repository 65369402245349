import { Spinner } from "@fluentui/react-components";
import { Baselayout as BaseLayout } from "@web/reactjs-layout";
import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { RootState, store } from "./store/store";

import Alert from "./components/Notification/Alert";

import SearchBarComp from "./components/Searchbar/index";
import UserInforButton from "./components/UserInfor/index";
import Footer from "./components/footer/Footer";

import {
  changeCartId,
  changeCartItems,
  changeTotalCart,
  createOderAsync,
  getItemInOrderAsync,
  getListAsync,
} from "./store/ManageOrderSlice";
import {
  changeCurrentRole,
  changeRoleLogin,
  getInforAsync,
  getMyRolesAsync,
} from "./store/ManageUserSlice";

import NotificationNav from "./components/Notification/NotificationList";
import {
  changeTotalNoti,
  changeTotalNotiNotRead,
  getNotiUserAsync,
  seenAllNotiAsync,
} from "./store/notificationsSlice";
import {
  HASH_ROLE_ADMIN,
  HASH_ROLE_USE,
  MD5_TEXT_ROLE,
  MD5_TEXT_ROLE_LOGIN,
} from "./utils/constants";
import {
  arrMenuAdmin,
  arrMenuUser,
  arrNavAdmin,
  arrNavUser,
  arrPathAdmin,
  arrPathUser,
  arrSubMenuAdmin,
  arrSubMenuUser,
} from "./utils/data";

import ButtonCreateComp from "./components/ButtonCreate";

import GlobalDrawer from "./components/Drawer/GlobalDrawer";
import { toggleDrawerCart, toggleDrawerNoti } from "./store/DrawerSlice";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import EoRouter from "./router/Router";
import { ip } from "./services/ip";
import { getHealthCheckAsync } from "./store/HealthSlice";
import { changePageReloaded } from "./store/dowloadSlice";
import { changeHref, changeStateArrCheckHealth } from "./store/pagingSlice";
import { loadConfig } from "./utils/utils";
import { getMyQuotasAsync } from "./store/ManageQuotaSlice";
import MyQuotasComp from "./components/ManageQuota/MyQuota";
import { changeFlagFilterItem } from "./store/exploreSlice";
import { changeShowCriteria } from "./store/filterSlice";
import { AxiosInterceptor } from "./utils/axios";
import WrapperRouter from "./router/WrapperRouter";
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

ChartJS.register(ArcElement, Tooltip, Legend);

// // config axios for app
// axios.defaults.timeout = 60000000;
// axios.defaults.withCredentials = true;
// // axios.defaults.headers.["Access-Control-Allow-Origin"]

// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },

//   function (error) {
//     if (error?.response?.status === 401 || error?.code === 401) {
//       console.log("401");
//       console.log("error", error);
//       console.log("error?.response", error?.response);
//       // @ts-ignore
//       window.location.replace(ipAccount ?? "https://dev-account.geohub.ai");
//     }
//     return error;
//   }
// );

function App() {
  const dispatch = useDispatch();

  const { total_cart } = useSelector((state: RootState) => state.manageorder);
  const { totalNoti } = useSelector((state: RootState) => state.notifications);
  const { inforUser, currentRole, roleLogin } = useSelector(
    (state: RootState) => state.manageuser
  );

  const {
    visibleDrawerInforDataset,
    visibleDrawerScan,
    visibleDrawerInforItemDataset,

    visibleCart,
    visibleDrawerDownload,
    visibleNoti,
    visible,
    visibleSort,
    visibleReadNoti,
    visibleShowQuotaUser,
  } = useSelector((state: RootState) => state.drawer);

  const [dataNoti, setDataNoti] = useState([]);

  const [loading, setLoading] = useState(false);

  const [myQuotas, setMyQuota] = useState<any>([]);

  const [config, setConfig] = useState<any>();

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );
  const search = (val: any) => {
    //handle call api here
  };

  useEffect(() => {
    getDataConfig();
    // @ts-ignore
    dispatch(getInforAsync());

    getRoles();

    getNotifications();

    getMyQuotas();
  }, []); // get role if role === 0 (user) get cart

  const getDataConfig = async () => {
    const data = await loadConfig(process.env.REACT_APP_CFG_URL);

    localStorage.setItem("REACT_APP_ENDPOINT", data?.REACT_APP_ENDPOINT);
    localStorage.setItem(
      "REACT_APP_ENDPOINT_KRATOS",
      data?.REACT_APP_ENDPOINT_KRATOS
    );
    localStorage.setItem(
      "REACT_APP_ENDPOINT_ACCOUNT",
      data?.REACT_APP_ENDPOINT_ACCOUNT
    );
    localStorage.setItem("REACT_APP_GLYPHS", data?.REACT_APP_GLYPHS);
    localStorage.setItem("REACT_APP_BASE_MAP", data?.REACT_APP_BASE_MAP);
    localStorage.setItem(
      "REACT_APP_CONFIG_DIRECT_DOWNLOAD",
      data?.REACT_APP_CONFIG_DIRECT_DOWNLOAD
    );

    setConfig(data);
  };

  const getRoles = async () => {
    setLoading(true);

    let r = localStorage.getItem(MD5_TEXT_ROLE);

    let isAdmin = false;

    const resRole = await dispatch(
      // @ts-ignore
      getMyRolesAsync()
    ).unwrap();

    if (
      resRole &&
      resRole.length > 0 &&
      (resRole[resRole.length - 1] === "admin" ||
        resRole[resRole.length - 1] === "Admin")
    ) {
      // admin
      dispatch(changeRoleLogin(HASH_ROLE_ADMIN));

      dispatch(changeCurrentRole(HASH_ROLE_ADMIN));
      localStorage.setItem(MD5_TEXT_ROLE, HASH_ROLE_ADMIN);
      localStorage.setItem(MD5_TEXT_ROLE_LOGIN, HASH_ROLE_ADMIN);

      if ((r && r === HASH_ROLE_ADMIN) || !r) {
        isAdmin = true;
        // dispatch(changeCurrentRole(HASH_ROLE_ADMIN));
        // localStorage.setItem(MD5_TEXT_ROLE, HASH_ROLE_ADMIN);
        // localStorage.setItem(MD5_TEXT_ROLE_LOGIN, HASH_ROLE_ADMIN);

        // handleHealthCheck();
      }
    } else {
      dispatch(changeRoleLogin(HASH_ROLE_USE));
      dispatch(changeCurrentRole(HASH_ROLE_USE));
      localStorage.setItem(MD5_TEXT_ROLE, HASH_ROLE_USE);
      localStorage.setItem(MD5_TEXT_ROLE_LOGIN, HASH_ROLE_USE);
    }

    // if (!isAdmin) {
    //   // default role is user
    //   dispatch(changeRoleLogin(HASH_ROLE_USE));
    //   dispatch(changeRoleLogin(HASH_ROLE_USE));
    //   localStorage.setItem(MD5_TEXT_ROLE, HASH_ROLE_USE);
    //   localStorage.setItem(MD5_TEXT_ROLE_LOGIN, HASH_ROLE_USE);
    // }
    getCarts();
    setLoading(false);
  };

  const getMyQuotas = async () => {
    const res = await dispatch(
      // @ts-ignore
      getMyQuotasAsync({})
    ).unwrap();

    if (res?.length > 1) {
      const currentDate: any = new Date();
      let nearestElement: any = null;
      let nearestDiff: any = Infinity;
      res?.forEach((element: any) => {
        const endDate: any = new Date(element?.ended);
        const timeDiff = endDate - currentDate;

        if (timeDiff > 0 && timeDiff < nearestDiff) {
          nearestDiff = timeDiff;
          nearestElement = element;
        }
      })

      setMyQuota([nearestElement] ?? [] );
    } else {
      setMyQuota(res ?? []);
    }
  };

  const getNotifications = async () => {
    const res = await dispatch(
      // @ts-ignore
      getNotiUserAsync({})
    ).unwrap();

    setDataNoti(res?.result ?? []);
  };

  const getCarts = async () => {
    let cart = await dispatch(
      // @ts-ignore
      getListAsync({
        size: 1,
        sortby: "modified",
        asc: false,
      })
    ).unwrap();

    if (
      cart &&
      cart?.result?.length > 0 &&
      cart?.result?.[0]?.states?.length === 1 &&
      cart?.result?.[0]?.states?.[0]?.state === "Create"
    ) {
      dispatch(changeCartId(cart.result?.[0]?.id));
      getCartItems(cart.result?.[0]?.id);
    } else {
      // @ts-ignore
      const res = await dispatch(createOderAsync()).unwrap();

      if (res?.status === 200) {
        dispatch(changeCartId(res?.data?.id));
        getCartItems(res?.data?.id);
      }
    }

    let resNoti = await dispatch(
      // @ts-ignore
      getNotiUserAsync({
        sortby: "created",
        asc: false,
        page: 0,
        size: 1000,
      })
    ).unwrap();
    let b = resNoti?.result?.filter(
      (item: any) => !item?.seen && !item?.deleted
    );
    dispatch(changeTotalNoti(b?.length));

    let c = resNoti?.result?.filter(
      (item: any) => !item?.deleted && !item?.readed
    ); // tat ca cac item da seen chua doc va chua xoa
    dispatch(changeTotalNotiNotRead(c?.length));
  };

  const getCartItems = async (id: string) => {
    // @ts-ignore
    let res = await dispatch(getItemInOrderAsync(id)).unwrap();

    dispatch(changeCartItems(res?.result?.[0]?.items ?? []));
    dispatch(changeTotalCart(res?.result?.[0]?.items?.length));
  };

  const onToggleCart = () => {
    // @ts-ignore
    dispatch(toggleDrawerCart(!visibleCart));
    dispatch(changeFlagFilterItem(false));
    dispatch(changeShowCriteria(false));
  };

  const onToggleNoti = async () => {
    // @ts-ignore
    dispatch(toggleDrawerNoti(!visibleNoti));
    dispatch(changeFlagFilterItem(false));
     dispatch(changeShowCriteria(false));

    if (!visibleNoti) {
      try {
        const res = await dispatch(
          // @ts-ignore
          seenAllNotiAsync({
            seen: true,
          })
        ).unwrap();

        if (res?.status === 200) {
          dispatch(changeTotalNoti(0));
        } else {
          return;
        }
      } catch (e) {}
    }
  };

  const handleHealthCheck = async () => {
    const res = await dispatch(
      // @ts-ignore
      getHealthCheckAsync()
    ).unwrap();

    if (res?.checks?.length > 0) {
      let a, b, c, d;
      let arrCheckFail: any = [];
      for (let i = 0; i < res?.checks?.length; i++) {
        a = JSON.stringify(res?.checks?.[i]).search("Down");
        b = JSON.stringify(res?.checks?.[i]).search("down");
        c = JSON.stringify(res?.checks?.[i]).search("invalid");
        d = JSON.stringify(res?.checks?.[i]).search("disconnect");

        if (a !== -1 || b !== -1 || c !== -1 || d !== -1) {
          arrCheckFail = arrCheckFail.concat(res?.checks?.[i]);
        }
      }

      if (arrCheckFail?.length > 0) {
        dispatch(changeStateArrCheckHealth(arrCheckFail));
      }
    }
  };

  useEffect(() => {
    const navigationEntries: any =
      window.performance.getEntriesByType("navigation");
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0]?.type === "reload"
    ) {
      dispatch(changePageReloaded(true));

      let a: any = localStorage.getItem("arrHref")?.split(";");

      dispatch(changeHref(a))
    }
  }, []);

  useEffect(() => {
    if (currentRole === HASH_ROLE_ADMIN) {
      handleHealthCheck();
    }
  }, [currentRole]);

  return (
    <>
      {loading && (
        <center>
          <Spinner className="absolute top-1/2 left-1/2 z-[9999]" />
        </center>
      )}

      {!loading && (
        <Provider store={store}>
          <AxiosInterceptor>
            <WrapperRouter history={history}>
              <BaseLayout
                handleSearch={search}
                dataMenu={
                  currentRole === HASH_ROLE_ADMIN
                    ? arrMenuAdmin
                    : currentRole === HASH_ROLE_USE
                    ? arrMenuUser
                    : []
                }
                dataNav={
                  currentRole === HASH_ROLE_ADMIN
                    ? arrNavAdmin
                    : currentRole === HASH_ROLE_USE
                    ? arrNavUser
                    : []
                }
                dataSubMenu={
                  currentRole === HASH_ROLE_ADMIN
                    ? arrSubMenuAdmin(config)
                    : currentRole === HASH_ROLE_USE
                    ? arrSubMenuUser(config)
                    : []
                }
                componentButtonCreate={<ButtonCreateComp />}
                componentSearch={<SearchBarComp />}
                componentFooter={<Footer config={config} />}
                componentUserInfor={<UserInforButton inforUser={inforUser} />}
                totalCart={total_cart}
                onToggleCart={onToggleCart}
                onToggleNoti={onToggleNoti}
                totalNoti={totalNoti}
                componentNotiList={<NotificationNav data={dataNoti} />}
                userPaths={
                  currentRole === HASH_ROLE_ADMIN
                    ? arrPathAdmin
                    : currentRole === HASH_ROLE_USE
                    ? arrPathUser
                    : []
                }
                componentMyQuota={<MyQuotasComp data={myQuotas} />}
                currentRole={currentRole}
              >
                <EoRouter />
                <GlobalDrawer pageName={pageName} />

                {/* {(visible ||
                  visibleSort ||
                  visibleCart ||
                  visibleNoti ||
                  visibleDrawerDownload ||
                  visibleReadNoti ||
                  visibleDrawerInforDataset ||
                  visibleDrawerScan ||
                  visibleDrawerInforItemDataset ||
                  visibleShowQuotaUser) && <GlobalDrawer pageName={pageName} />} */}

                <Alert />
              </BaseLayout>
            </WrapperRouter>
          </AxiosInterceptor>
        </Provider>
      )}
    </>
  );
}

export default App;
