const getFromIndexedDb = (
  db: IDBDatabase,
  collectionName: string,
  key: string
): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(collectionName, "readonly");
    const objectStore = transaction.objectStore(collectionName);
    const request = objectStore.get(key);
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = (err) => {
      console.log(err, "indexedDb err get");
      reject(false);
    };
  });
};

export default getFromIndexedDb;
