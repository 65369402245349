import axios from "../../utils/axios";

export async function getList(params?: any) {
  //user

  

  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/`, {
    params: { ...params },
  });
}

export async function getById(id: any) {
  //user
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${id}/`);
}

// export async function addItemOder(payload: any) { //user
//   return axios.post(`${localStorage.getItem('REACT_APP_ENDPOINT')}/order/orders/${payload?.order_id}/item`, payload?.data);
// }

export async function getItemInOrder(id: any) {
  //user+ admin
  // return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${id}/items/`);
  
  const newPar = {
    filter: {
      ids: [id],
    },
  };
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/`, {
    params: { ...newPar },
  });
}

export async function acceptOrder(payload: any) {
  
  //admin
  const newParams = {
    user_id: payload?.owner_id,
  };
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/orders/${payload?.orderId}/states/accept/`,
    null,
    {
      params: { ...newParams },
    }
  );
}

export async function cancelOrder(payload: any) {
  
  //user + admin
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/states/cancel/`
  );
}

export async function cancelOrderAdmin(payload: any) {
  
  //user + admin
  const newParams = {
    user_id: payload?.owner_id,
  };
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/states/cancel/`,
    {
      params: { ...newParams },
    }
  );
}

export async function rejectOrder(payload: any) {
  
  // admin
  const newParams = {
    user_id: payload?.owner_id,
  };
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/orders/${payload?.orderId}/states/reject/`,
    null,
    {
      params: { ...newParams },
    }
  );
}

export async function createOder(payload: any) {
  
  //user call it if no order state "Create"
  return axios.post(`${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/`, payload);
}

export async function deleteItemInOrder(payload: any) {
  //user
  
  const newParams = {
    collection_id: payload?.collection_id,
    item_id: payload?.item_id,
  };

  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.idOrder}/items/`,
    {
      data: { ...newParams },
    }
  );
}

export async function submitOrder(payload: any) {
  
  //user

  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.orderId}/states/submit/`
  );
}

export async function addItemOder(payload: any) {
  
  //user
  const newParams = {
    collection_id: payload?.collection_id,
    item_id: payload?.item_id,
  };
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/orders/${payload?.order_id}/items/`,
    newParams
  );
}

export async function getListAdmin(params?: any) {
  
  //admin
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/orders/`, {
    params: { ...params },
  });
}

export async function getByIdAdmin(params: any) {
  
  //admin
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/orders/${params?.id}/`);
}

export async function getItemInOrderAdmin(id: any) {
  
  //user+ admin
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/orders/${id}/items/`);
}

export async function getListUser(params?: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT_KRATOS')}/oa/admin/identities`, {
    params: { ...params },
  });
}
