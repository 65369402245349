import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import MouseCoordinates from "./footer/MouseCoordinates"
import CrsSelector from "./footer/CrsSelector"
import ScaleBox from "./footer/ScaleBox"
import NavigationBar from "./footer/NavigationBar"
import TimeSlider from "./footer/TimeSlider";
import RangeSlider from "./footer/RangeSlider";
import { Select } from "@fluentui/react-components";

import { RootState } from "../../../store/store";
import { changeTimeSlider } from "./../../../store/filterSlice";

export default function MapFooterMaplibreGL() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    let mousePointer: any = useSelector((state: RootState) => state.map?.mousePointer?.lnglat);

    const [timeType, setTimeType] = useState<String>("range"); // day or range
    const [showTimeType, setShowTimeType] = useState<boolean>(false);

    const onChangeTimeType = (data: any) => {
        setTimeType(data?.value);
        dispatch(changeTimeSlider([]));
    }

    return (
        <div className="flex justify-between w-full">
            <ul className="left flex gap-2">
                {mousePointer ? <li className="self-center">
                    <MouseCoordinates />
                </li> : ""}
                {mousePointer ? <li className="divider border-r border-r-white/20 "></li> : ""}
                {mousePointer ? <li className="self-center">
                    <CrsSelector />
                </li> : ""}
                {mousePointer ? <li className="divider border-r border-r-white/20 "></li> : ""}
                <li className="self-center">
                    <ScaleBox />
                </li>
            </ul>
            <div className="center self-center block" style={{display:'none'}}>
                <div className="flex gap-x-2">
                    {showTimeType && <Select
                        className="[&>*]:!bg-ink-300"
                        onChange={(ev, data) => onChangeTimeType(data)}
                        size="medium"
                        appearance="filled-lighter"
                    >
                        <option key='range' value='range'>
                            {t('Phạm vi')}
                        </option>
                        <option key='day' value='day'>
                            {t('Ngày')}
                        </option>
                    </Select>}
                    {timeType == "day" ? <TimeSlider setShowTimeType={(val: boolean) => setShowTimeType(val)} /> : <RangeSlider setShowTimeType={(val: boolean) => setShowTimeType(val)} />}
                </div>
            </div>
            <div className="right">
                <NavigationBar />
            </div>
        </div >
    )
}