import { createSlice } from '@reduxjs/toolkit'

export const pageSlice = createSlice({
    name: 'page',
    initialState: {
        title: undefined,
        path: undefined,
        name: undefined
    },
    reducers: {
        changeTitle: (state, action) => {
            state.title = action?.payload;
        },
        changePath: (state, action) => {
            state.path = action?.payload;
        },
        changeName: (state, action) => {
            state.name = action?.payload;
        }
    }
});

export const { changeTitle, changePath, changeName } = pageSlice.actions

export default pageSlice.reducer