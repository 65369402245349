import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getList,
  createCollectionStorage,
  updateCollectionStorage,
  deleteCollectionStorage,
  scanCollectionStorage,
  scanThumbnail,
} from "../services/ManageCollectionStorage/index";

export interface ManageCollectionStorage {
  status: string;
  danhSach: Array<any>;
}
const initialState: ManageCollectionStorage = {
  status: "ilde",
  danhSach: [],
};

export const getListAsync = createAsyncThunk(
  "managecollectionstorage/getList",
  async (payload) => {
    try {
      const response = await getList(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const createCollectionStorageAsync = createAsyncThunk(
  "manageorder/createCollectionStorage",
  async (params) => {
    try {
      const response = await createCollectionStorage(params);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const updateCollectionStorageAsync = createAsyncThunk(
  "manageorder/updatecollectionstorage",
  async (params) => {
    try {
      const response = await updateCollectionStorage(params);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const deleteCollectionStorageAsync = createAsyncThunk(
  "ManageQuota/deleteCollectionStorage",
  // @ts-ignore
  async (payload: any) => {
    try {
      const response = await deleteCollectionStorage(payload);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const scanCollectionStorageAsync = createAsyncThunk(
  "manageorder/scanCollectionStorage",
  async (params) => {
    try {
      const response = await scanCollectionStorage(params);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const scanThumbnailAsync = createAsyncThunk(
  "manageorder/scanThumbnail",
  async (params) => {
    try {
      const response = await scanThumbnail(params);
      return response;
    } catch (e) {
      return {};
    }
  }
);
export const ManageCollectionStorageSlice = createSlice({
  name: "managecollectionstorage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get list
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.danhSach = action.payload?.result ?? [];
      });
  },
});

export default ManageCollectionStorageSlice.reducer;
