import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import {
  changePathBrowse,
  changeStateFilter,
} from "../../../store/ManageStorageSlice";
import {
  changePage,
  changeTotal,
  resetState,
} from "../../../store/pagingSlice";
import EmptySvg from "./../../../assets/nodata.svg";

import { changeDataBreadcrumb } from "../../../store/BreadcrumbSlice";
import { browseStorageAsync } from "../../../store/ManageStorageSlice";
import { changeName, changePath } from "../../../store/pageSlice";

import LoadingListComp from "../../../components/Loading/LoadingList";
import CardItemBrowse from "../../../components/ManageStorage/CardItemBrowse";
import useCustomNavigate from "../../../hook/useCustomNavigate";
import { resetAllStateDrawer } from "../../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../../store/NavSlice";
import { RootState } from "../../../store/store";

const ViewBrowseStoragePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const param = useParams();

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const { pathBrowse } = useSelector((state: RootState) => state.managestorage);

  const [data, setData] = useState([]);

  const { dataBreadcrumb } = useSelector(
    (state: RootState) => state.breadcrumn
  );
  const navigate = useCustomNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const changePathParam = (path: string) => {
    navigate(`/list-storage/${param.id}/browser?${path}`);
  };

  const dataBread = [
    {
      key: 1,
      value: "Storages",
      path: "list-storage",
    },
    {
      key: 2,
      value: `${location?.state?.item?.name}`,
      searchParams: "",
    },
  ];

  useEffect(() => {
    dispatch(changePath("/view-browse-storage"));
    dispatch(changeName("view-browse-storage"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(changeStateFilter());
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    if (location?.state) {
      const dataBreadString = dataBread.map((obj: any) => {
        const newObj: any = {};
        for (const key in obj) {
          // Check if the property value is an object
          if (typeof obj[key] === "object" && obj[key] !== null) {
            // Convert nested object to string
            newObj[key] = JSON.stringify(obj[key]);
          } else {
            newObj[key] = obj[key];
          }
        }
        return JSON.stringify(newObj);
      });
      dispatch(changeDataBreadcrumb(dataBread));
      localStorage.setItem("databread", dataBreadString?.join(";"));
    } else {
      if (localStorage.getItem("databread")) {
        let a = localStorage.getItem("databread")?.split(";");
        const dataArray = a?.map((str: any) => JSON.parse(str));

        if (localStorage.getItem("databread")?.includes(pathBrowse)) {
          const filteredArray = dataArray?.filter((item, index, arr) => {
            const targetIndex = arr.findIndex((el) => el.url === pathBrowse);
            return index <= targetIndex;
          });

          dispatch(changeDataBreadcrumb(filteredArray));
          dispatch(changePathBrowse(dataArray?.[dataArray?.length - 1]?.url));
          const dataBreadString = filteredArray?.map((obj: any) => {
            const newObj: any = {};
            for (const key in obj) {
              // Check if the property value is an object
              if (typeof obj[key] === "object" && obj[key] !== null) {
                // Convert nested object to string
                newObj[key] = JSON.stringify(obj[key]);
              } else {
                newObj[key] = obj[key];
              }
            }
            return JSON.stringify(newObj);
          });
          localStorage.setItem("databread", dataBreadString?.join(";") ?? "");
        } else {
          dispatch(changeDataBreadcrumb(dataArray));
          dispatch(changePathBrowse(dataArray?.[dataArray?.length - 1]?.url));

          const dataBreadString = dataArray?.map((obj: any) => {
            const newObj: any = {};
            for (const key in obj) {
              // Check if the property value is an object
              if (typeof obj[key] === "object" && obj[key] !== null) {
                // Convert nested object to string
                newObj[key] = JSON.stringify(obj[key]);
              } else {
                newObj[key] = obj[key];
              }
            }
            return JSON.stringify(newObj);
          });
          localStorage.setItem("databread", dataBreadString?.join(";") ?? "");
        }
      }
    }
    return () => {
      // console.log('return')
      // dispatch(changeDataBreadcrumb([]));
      // localStorage.removeItem("databread");
      // dispatch(changePathBrowse(null));
    };
  }, [searchParams.toString()]);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));

        const res = await dispatch(
          // @ts-ignore
          browseStorageAsync({
            id: location?.state?.item?.id ?? param?.id,
            path: searchParams.get("path"),
          })
        ).unwrap();

        let sun: any = [];
        let sunFolder: any = [];
        sunFolder = res?.filter((item: any) => item?.is_dir);
        let sunFile: any = [];
        sunFile = res?.filter((item: any) => item?.is_file);

        sun = sunFolder?.concat(sunFile);

        let a: any = [];

        sun?.map((item: any, ind: any) => {
          a.push({
            ...item,
            index: ind + 0 * 10 + 1,
          });
        });

        setData(
          a?.filter(
            (item: any) =>
              item?.index <= limit * (page + 1) && item?.index > limit * page
          )
        );

        dispatch(changeTotal(res?.length ?? 0));
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [searchParams.toString(), page]);


  

  useEffect(() => {
    const path = searchParams.get("path");
    dispatch(changePathBrowse(path));
  }, [searchParams.toString()]);

  return (
    <>
      <div className="px-6 py-6 !w-full !relative div-list">
        {visibleSpinner && <LoadingListComp />}

        {data?.length === 0 && !visibleSpinner && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !visibleSpinner && (
          <div className="w-full">
            <CardItemBrowse
              data={data}
              handleClick={(item: any) => {
                if (item?.is_dir) {
                  if (item?.resource?.FS?.path) {
                    dispatch(changePage(0));
                    changePathParam(`path=${item?.resource?.FS?.path}`);
                    dispatch(changePathBrowse(`${item?.resource?.FS?.path}`));

                    let a: any = [
                      ...dataBreadcrumb,
                      {
                        key:
                          dataBreadcrumb?.[dataBreadcrumb?.length - 1]?.key + 1,
                        value:
                          item?.resource?.FS?.path?.split("/")?.[
                            item?.resource?.FS?.path?.split("/")?.length - 1
                          ],
                        url: item?.resource?.FS?.path,
                      },
                    ];

                    const dataBreadString = a.map((obj: any) => {
                      const newObj: any = {};
                      for (const key in obj) {
                        // Check if the property value is an object
                        if (typeof obj[key] === "object" && obj[key] !== null) {
                          // Convert nested object to string
                          newObj[key] = JSON.stringify(obj[key]);
                        } else {
                          newObj[key] = obj[key];
                        }
                      }
                      return JSON.stringify(newObj);
                    });
                    localStorage.setItem(
                      "databread",
                      dataBreadString?.join(";")
                    );

                    dispatch(changeDataBreadcrumb(a));
                  } else if (item?.resource?.S3?.object) {
                    let arrSplit = item?.resource?.S3?.object?.split("/");
                    dispatch(changePage(0));
                    changePathParam(`path=${item?.resource?.S3?.object}`);
                    dispatch(changePathBrowse(`${item?.resource?.S3?.object}`));

                    let a: any = [
                      ...dataBreadcrumb,
                      {
                        key:
                          dataBreadcrumb?.[dataBreadcrumb?.length - 1]?.key + 1,
                        value:
                          arrSplit[arrSplit?.length - 1] !== ""
                            ? arrSplit[arrSplit?.length - 1]
                            : arrSplit[arrSplit?.length - 2],
                        url: item?.resource?.S3?.object,
                      },
                    ];

                    const dataBreadString = a.map((obj: any) => {
                      const newObj: any = {};
                      for (const key in obj) {
                        // Check if the property value is an object
                        if (typeof obj[key] === "object" && obj[key] !== null) {
                          // Convert nested object to string
                          newObj[key] = JSON.stringify(obj[key]);
                        } else {
                          newObj[key] = obj[key];
                        }
                      }
                      return JSON.stringify(newObj);
                    });
                    localStorage.setItem(
                      "databread",
                      dataBreadString?.join(";")
                    );

                    dispatch(changeDataBreadcrumb(a));
                  } else if (item?.resource?.S3Archive?.object) {
                    dispatch(changePage(0));
                    changePathParam(
                      `path=${item?.resource?.S3Archive?.object}`
                    );
                    dispatch(
                      changePathBrowse(`${item?.resource?.S3Archive?.object}`)
                    );
                    const splitName =
                      item?.resource?.S3Archive?.object?.split("/");

                    let a: any = [
                      ...dataBreadcrumb,
                      {
                        key:
                          dataBreadcrumb?.[dataBreadcrumb?.length - 1]?.key + 1,
                        value:
                          splitName.length === 1
                            ? splitName[0]
                            : splitName?.[splitName.length - 1] === ""
                            ? splitName?.[splitName.length - 2]
                            : splitName?.[splitName.length - 1],
                        url: item?.resource?.S3Archive?.object,
                      },
                    ];

                    const dataBreadString = a.map((obj: any) => {
                      const newObj: any = {};
                      for (const key in obj) {
                        // Check if the property value is an object
                        if (typeof obj[key] === "object" && obj[key] !== null) {
                          // Convert nested object to string
                          newObj[key] = JSON.stringify(obj[key]);
                        } else {
                          newObj[key] = obj[key];
                        }
                      }
                      return JSON.stringify(newObj);
                    });
                    localStorage.setItem(
                      "databread",
                      dataBreadString?.join(";")
                    );

                    dispatch(changeDataBreadcrumb(a));
                  }
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ViewBrowseStoragePage;
