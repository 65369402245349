import { Card, CardHeader, CardPreview } from "@fluentui/react-card";
import { Tooltip } from "@fluentui/react-components";
import moment from "moment";

import ErrorIcon from "./../../assets/error-icon.svg";
import SuccessIcon from "./../../assets/success-icon.svg";

import FtpSvg from "./../../assets/ftp.svg";
import FSSvg from "./../../assets/images/fs.svg";
import S3svg from "./../../assets/images/s3.svg";
import WebDav from "./../../assets/webdav.svg";

const CardScanedResourceComp = (props: any) => {
  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section key={index}>
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240] cursor-pointer shadow-[none]"
            >
              <CardPreview className="div-center div-card-preview-item-scan !flex-row">
                <div className="relative">
                  <img
                    className="img-icon-state"
                    src={
                      item?.resource?.resource_type === "FSArchive"
                        ? FSSvg
                        : item?.resource?.resource_type === "S3"
                        ? S3svg
                        : item?.resource?.resource_type === "FPT"
                        ? FtpSvg
                        : WebDav
                    }
                    alt="img item"
                  />

                  <span className="absolute !top-[10px] !w-[14px]">
                    {item?.parse_result?.success ? (
                      <img
                        className="img-icon-state"
                        src={SuccessIcon}
                        alt="img item"
                      />
                    ) : (
                      <Tooltip
                        relationship="description"
                        content={item?.parse_result?.err_msg}
                      >
                        <img
                          className="img-icon-state"
                          src={ErrorIcon}
                          alt="img item"
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </CardPreview>

              <CardHeader
                className="w-4/5"
                header={
                  <div className="divHeader relative">
                    <div className="block w-10/12">
                      <Tooltip relationship="description" content={item?.path}>
                        <p
                          className=" !uppercase text-title-card ellipsis-text-one-line"
                          style={{ overflowWrap: "anywhere" }}
                        >
                          {item?.path}
                        </p>
                      </Tooltip>

                      <Tooltip
                        relationship="description"
                        content={
                          item?.created
                            ? moment(item?.created).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "---"
                        }
                      >
                        <p className="!mt-1 text-des ellipsis-text-one-line">
                          {item?.created
                            ? moment(item?.created).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "---"}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </Card>
          </section>
        );
      })}
    </>
  );
};

export default CardScanedResourceComp;
