export const horizontal = 20;
export const vertical = 65;

export const timeout = 1000;

export const arrRsType = [
  `SPOT6`,
  `SPOT7`,
  `PLEIADES_1A`,
  `PLEIADES_1B`,
  `SENTINEL_1A`,
  "SPOT1",
  "SPOT2",
  "SPOT3",
  "SPOT4",
  "SPOT5",
];

// SHA1 text admin, user, staff
export const HASH_ROLE_ADMIN = "d033e22ae348aeb5660fc2140aec35850c4da997";
export const HASH_ROLE_USE = "12dea96fec20593566ab75692c9949596833adc9";
export const HASH_ROLE_STAFF = "6ccb4b7c39a6e77f76ecfa935a855c6c46ad5611";

export const MD5_TEXT_ROLE = "29a7e96467b69a9f5a93332e29e9b0de";
export const MD5_TEXT_ROLE_LOGIN = "751bf0ef215d2c978e4a2db23fb50262";

export const arrSortOrder = ["asc", "desc"];
export const arrSortStorageBy = ["modified"];
export const arrSortQuotaBy = ["modified", "created", "name", "limit_value"];
export const arrSortNotiBy = ["modified", "created", "sent"];
export const arrSortHistoryScan = ["created"];
export const arrSortJob = ["scheduled"];

export const arrRole = [
  { value: HASH_ROLE_ADMIN, label: "Admin" },
  { value: HASH_ROLE_USE, label: "User" },
];

export const settingKeys = [
  "Storage for contains user download data",
  "Storage config for stac item thumbnails",
  "Storage config for stac collection thumbnails",
  "Storage config for web map client",
  "Scan session expire time in minutes",
];

export const dataSysAutoTask = [
  { des: "Setting expiration scan sessions", key: "EXPIRE_SCAN_SESSIONS" },
  {
    des: "Setting clean order download expired",
    key: "CLEAN_EXPIRED_DLSESSION_DATA",
  },
];


export const dataSysManualTask = [
  { des: "Expiration scan sessions", key: "EXPIRE_SCAN_SESSIONS" },
  {
    des: "Clean order download expired",
    key: "CLEAN_EXPIRED_DLSESSION_DATA",
  },
];

export const dataUnitType = [
  "Bytes",
  "Kilobytes",
  "Megabytes",
  "Gigabytes",
  "Tetabytes",
];
