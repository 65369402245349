import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  addItemOderAsync,
  changeCartItems,
  changeTotalCart,
  deleteItemInOrderAsync,
  getItemInOrderAsync,
} from "./../../store/ManageOrderSlice";

import { updateAlert } from "../../store/notificationsSlice";

import { RootState } from "../../store/store";

import { timeout } from "../../utils/constants";
import CartItemBlackSvg from "./../../assets/cart-item-black.svg";
import InfoCircleSvg from "./../../assets/info-circle.svg";
import MapSvg from "./../../assets/map.svg";

import moment from "moment";

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Checkbox,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import { getItemCollectionByIdAsync } from "../../store/exploreSlice";
import ToastComp from "../Toast";
import PreviewItem from "./itemscollection/Preview";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import { useState } from "react";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { HASH_ROLE_USE } from "../../utils/constants";
import LoadingListComp from "../Loading/LoadingList";

export default function ItemsCollection(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const items: Array<any> = useSelector(
    (state: RootState) => state.filter?.items
  );

  const timeSlider: Array<any> = useSelector(
    (state: RootState) => state.filter?.timeSlider
  );

  const itemsView =
    timeSlider && timeSlider.length
      ? items.filter(function (x) {
          let date = new Date(x.properties.datetime);
          return date >= timeSlider[0] && date <= timeSlider[1];
        })
      : items;

  const cartItems: Array<any> = useSelector(
    (state: RootState) => state.manageorder?.cartItems
  );

  let cartItemsIds = cartItems?.map((x) => x?.stac_item_id);

  const { cartId } = useSelector((state: RootState) => state.manageorder);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const onShowInfoMetadata = async (item: any) => {
    props.onShowInfoMetadata(await getFullInforItem(item));
  };

  const getFullInforItem = async (item: any) => {
    /// here

    const res = await dispatch(
      // @ts-ignore
      getItemCollectionByIdAsync({
        collectionId: item?.collection,
        itemId: item?.id,
      })
    ).unwrap();

    return res?.data;
  };

  const onSelectedItem = async (item: any) =>
  {
    // const res = await dispatch(
    //   // @ts-ignore
    //   getItemCollectionByIdAsync({
    //     collectionId: item?.collection,
    //     itemId: item?.id,
    //   })
    // ).unwrap();

    // props.onSelectedItem(res?.data);
    if (!props?.selectedIds?.includes(item?.stac_item_id)) {
      const res = await dispatch(
        // @ts-ignore
        getItemCollectionByIdAsync({
          collectionId: item?.collection,
          itemId: item?.id,
        })
      ).unwrap();

      props.onSelectedItem(res?.data);
    } else {
      props.onSelectedItem({ id: item?.id });
    }
  };

  const onMouseEnterItem = async (item: any) => {
    const res = await dispatch(
      // @ts-ignore
      getItemCollectionByIdAsync({
        collectionId: item?.collection,
        itemId: item?.id,
      })
    ).unwrap();
    props.onMouseEnterItem(res?.data);
  };
  const onMouseLeaveItem = async (item: any) => {
    // const res = await dispatch(
    //   // @ts-ignore
    //   getItemCollectionByIdAsync({
    //     collectionId: item?.collection,
    //     itemId: item?.id,
    //   })
    // ).unwrap();
    props.onMouseLeaveItem(item);
  };

  const setThumbnailItem = (obj: any) => {
    let index = itemsView?.findIndex(
      (x) => x.collection == obj.collection_id && x.id == obj.item_id
    );
    if (index >= 0) {
      itemsView[index] = {
        ...itemsView[index],
        thumbnail: [obj.thumbnail_img],
      };
    }
  };

  const renderCardPreview = (item: any) => {
    return (
      <PreviewItem
        item={item}
        setThumbnailItem={(thumbnailImg: String) =>
          setThumbnailItem({
            item_id: props.item?.id,
            collection_id: props.item?.collection,
            thumbnail_img: thumbnailImg,
          })
        }
      />
    );
  };

  const checkItemInCart = (item: any) => {
    let flag = false;
    for (let i = 0; i < cartItems?.length; i++) {
      if (
        cartItems?.[i]?.stac_item_id === item?.id &&
        cartItems?.[i]?.collection_id === item?.collection
      ) {
        flag = true;
      }
    }

    return flag;
  };
  const renderCardFooter = (item: any) => {
    return (
      <div className="flex gap-x-2">
        <Tooltip
          relationship="description"
          content={t("View information item")}
        >
          <Button
            className="!w-[26px] !min-w-[26px] !h-[26px] !min-h-[26px] !bg-transparent !border-none"
            iconPosition="before"
            icon={
              <img className="img-svg-icon" src={InfoCircleSvg} alt="info" />
            }
            onClick={() => onShowInfoMetadata(item)}
          />
        </Tooltip>

        <Tooltip relationship="description" content={t("View item in map")}>
          <Button
            className={
              "!w-[26px] !min-w-[26px] !h-[26px] !min-h-[26px] " +
              (props.selectedIds.includes(item?.id)
                ? "!bg-primary-500 !border-primary-500"
                : "!bg-transparent !border-none")
            }
            iconPosition="before"
            icon={<img className="img-svg-icon" src={MapSvg} alt="selected" />}
            onClick={() => onSelectedItem(item)}
          />
        </Tooltip>

        {currentRole === HASH_ROLE_USE && (
          <Tooltip
            relationship="description"
            content={
              checkItemInCart(item)
                ? t("Remove item from cart")
                : t("Add item to cart")
            }
          >
            <Button
              className={
                "!w-[26px] !min-w-[26px] !h-[26px] !min-h-[26px] " +
                (checkItemInCart(item)
                  ? "!bg-primary-500 !border-primary-500"
                  : "!bg-transparent !border-none")
              }
              iconPosition="before"
              icon={
                <img
                  className="img-svg-icon"
                  src={CartItemBlackSvg}
                  alt="order"
                />
              }
              onClick={async () => {
                checkItemInCart(item)
                  ? handleDeleteItemCart(item)
                  : handleAddItemCart(item);
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const handleAddItemCart = async (item: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));

    const res = await dispatch(
      // @ts-ignore
      addItemOderAsync({
        order_id: cartId,
        item_id: item?.id,
        collection_id: item?.collection,
      })
    ).unwrap();

    if (res?.status === 200) {
      const response = await dispatch(
        // @ts-ignore
        getItemInOrderAsync(cartId)
      ).unwrap();
      dispatch(changeCartItems(response?.result?.[0]?.items));
      dispatch(changeTotalCart(response?.result?.[0]?.items?.length));
      setLoading(false);
      dispatch(changeVisibleSpinner(false));
      notify(t("Add item to cart successfully"), "success");
    } else {
      let msg = res?.message;
      if (msg) {
        try {
          let msgJson = JSON.parse(msg);
          msg = msgJson?.message ?? null;
        } catch (error) {
          msg = null;
        }
      }
      setLoading(false);
      dispatch(changeVisibleSpinner(false));
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: msg ?? t("Add item to cart error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };

  const handleDeleteItemCart = async (item: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      deleteItemInOrderAsync({
        idOrder: cartId,
        item_id: item?.id,
        collection_id: item?.collection,
      })
    ).unwrap();
    if (res?.status === 200) {
      const response = await dispatch(
        // @ts-ignore
        getItemInOrderAsync(cartId)
      ).unwrap();
      dispatch(changeCartItems(response?.result?.[0]?.items));
      dispatch(changeTotalCart(response?.result?.[0]?.items?.length));
      setLoading(false);
      dispatch(changeVisibleSpinner(false));
      notify(t("Delete item from cart successfully"), "success");
    } else {
      let msg = res?.message;
      if (msg) {
        try {
          let msgJson = JSON.parse(msg);
          msg = msgJson?.message ?? null;
        } catch (error) {
          msg = null;
        }
      }

      setLoading(false);
      dispatch(changeVisibleSpinner(false));
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: msg ?? t("Delete item from cart error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentRole } = useSelector((state: RootState) => state.manageuser);
  const renderCard = () => {
    return itemsView?.map(function (item: any, index: number) {
      return (
        <>
          <div
            className="div-card !h-[90px] !mb-0"
            key={index}
            onMouseEnter={() =>
            {
              onMouseEnterItem(item);
              // if (!props.selectedIds.includes(item?.id)) {
              //   onMouseEnterItem(item);
              // }
            }}
            onMouseLeave={() =>
            {
              onMouseLeaveItem(item);
              // if (!props.selectedIds.includes(item?.id)) {
              //   onMouseLeaveItem(item);
              // }
            }}
          >
            <Card
              orientation="horizontal"
              className="card-item-collection-search-drawer !bg-[#2E3240] !h-[90px]"
            >
              <CardPreview
                className="div-card-preview-item-drawer-search"
                style={{ margin: "auto" }}
              >
                {renderCardPreview(item)}
              </CardPreview>

              <div className="div-card-header-item-collection-search-drawer">
                <CardHeader
                  className="!block w-full"
                  header={
                    <div className="block w-full">
                      <Tooltip
                        relationship="description"
                        content={item?.properties?.title}
                      >
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.properties?.title}
                        </p>
                      </Tooltip>

                      <p className="mt-1">
                        {renderCardheaderDesc(item?.properties)}
                      </p>
                    </div>
                  }
                />
                <CardFooter className="mt-1">
                  {renderCardFooter(item)}
                </CardFooter>
              </div>
            </Card>
          </div>
        </>
      );
    });
  };

  const renderCardheaderDesc = (properties: any) => {
    return (
      <>
        {properties ? (
          <span className="flex gap-x-6 text-xs text-des">
            <span>
              {t("Mission")}:&nbsp;<code>{properties.mission}</code>
            </span>
            <span>
              {t("Platform")}:&nbsp;<code>{properties.platform}</code>
            </span>
            <span>
              {t("Date")}:&nbsp;
              <code>
                {moment(new Date(properties?.datetime)).format("DD/MM/YYYY")}
              </code>
            </span>
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderNodata = () => {
    return <span>{t("No data")}</span>;
  };

  const handleViewAllInMap = async (data: any) => {
    // console.log(props?.selectedIds, "props?.selectedIds");
    // console.log(items, "items");

    dispatch(changeVisibleSpinner(true));
    if (data?.checked) {
      let temp = [];
      for (let i = 0; i < items?.length; i++) {
        const res = await dispatch(
          // @ts-ignore
          getItemCollectionByIdAsync({
            collectionId: items[i]?.collection,
            itemId: items[i]?.id,
          })
        ).unwrap();
        temp.push(res?.data);
      }
      props.onSelectedViewAll(temp);
    } else {
      props.onSelectedViewAll([]);
    }

    dispatch(changeVisibleSpinner(false));
  };

  return (
    <>
      {items.length ? (
        <>
          <div className="flex flex-col gap-y-3 mb-2">{renderCard()}</div>

          {loading && <LoadingGlobalComp />}
          {/* {visibleSpinner && !loading && <LoadingListComp />} */}
        </>
      ) : (
        renderNodata()
      )}
    </>
  );
}
