import
  {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Tooltip
  } from "@fluentui/react-components";

import NextSvg from "../../assets/next.svg";

import { useNavigate } from "react-router-dom";

import StorageSvg from '../../assets/storage.svg';
import TaskSvg from '../../assets/task.svg';
import ScanSvg from '../../assets/scan.svg'
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardItemDownloadComp = (props: any) => {
  const navigate = useCustomNavigate();

  const renderTitle = (key: any) => {
    if (key === "ORDER_DOWNLOAD_SESSION_FILE_STORAGE") {
      return "Storage for contains user download data";
    }
    if (key === "STAC_ITEM_THUMBNAIL_STORAGE") {
      return "Storage config for stac item thumbnails";
    }
    if (key === "STAC_COLLECTION_THUMBNAIL_STORAGE") {
      return "Storage config for stac collection thumbnails";
    }
    if (key === "WEB_CLIENT_MAP_CONFIG") {
      return "Storage config for web map client";
    }

    if (key === "SCAN_SESSION_EXPIRE_TIME_IN_MINS") {
      return "Configure the expiration time for Scan Session";
    }
  };

  const renderPreviewImage = (key: string) =>
  {
    if (key === "SCAN_SESSION_EXPIRE_TIME_IN_MINS") {
      return ScanSvg;
    }  return StorageSvg
  }

  return (
    <>
      {props?.dataKey?.map((item: any, index: number) => {
        return (
          <section key={index}>
            <div className="bg-[#2E3240] rounded">
              <Card
                onClick={() =>
                  navigate(`./${item?.key}`, {
                    state: {
                      item: item,
                    },
                  })
                }
                orientation="horizontal"
                className="card-item-storage !bg-[#2E3240]"
              >
                <CardPreview
                  className={
                    `div-center div-card-preview div-card-preview-card-none-download` +
                    (item?.thumbnail?.[0] ? " !bg-black" : "")
                  }
                  // className={`div-center div-card-preview div-card-preview-card-none-download bg-black`}
                >
                  <img
                    loading="lazy"
                    className="object-contain img-card-item-catalog"
                    src={renderPreviewImage(item?.key)}
                  />
                </CardPreview>
                <CardHeader
                  style={{ width: "calc(100% - 105px)" }}
                  header={
                    <div className="divHeader relative">
                      <div className="block w-full">
                        <Tooltip
                          relationship="description"
                          // @ts-ignore
                          content={renderTitle(item?.key)}
                        >
                          <b
                            className="!uppercase text-title-card ellipsis-text-one-line"
                            style={{ overflowWrap: "anywhere" }}
                          >
                            {renderTitle(item?.key)}
                          </b>
                        </Tooltip>

                        <p className="mt-1 text-des">
                          {Object.keys(item?.detail)?.length === 0 &&
                            "No config"}
                        </p>
                      </div>
                    </div>
                  }
                />

                <CardFooter className="footer-card-action">
                  <div
                    onClick={() =>
                      navigate(`./${item?.key}`, {
                        state: {
                          item: item,
                        },
                      })
                    }
                  >
                    <img
                      className="img-svg-icon"
                      src={NextSvg}
                      alt="Info Circle"
                    />
                  </div>
                </CardFooter>
              </Card>
            </div>
          </section>
        );
      })}

      <section key={5}>
        <div className="bg-[#2E3240] rounded">
          <Card
            onClick={() => navigate(`./SYSTEM_AUTOMATIC_TASKS`)}
            orientation="horizontal"
            className="card-item-storage !bg-[#2E3240]"
          >
            <CardPreview
              className={`div-center div-card-preview div-card-preview-card-none-download`}
              // className={`div-center div-card-preview div-card-preview-card-none-download bg-black`}
            >
              <img
                className="object-contain img-card-item-catalog"
                src={TaskSvg}
              />
            </CardPreview>
            <CardHeader
              style={{ width: "calc(100% - 105px)" }}
              header={
                <div className="divHeader relative">
                  <div className="block w-full">
                    <Tooltip
                      relationship="description"
                      // @ts-ignore
                      content="System automatic tasks"
                    >
                      <b
                        className="!uppercase text-title-card ellipsis-text-one-line"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        System automatic tasks
                      </b>
                    </Tooltip>

                    {/* <p className="mt-1 text-des">
                      {Object.keys(item?.detail)?.length === 0 && "No config"}
                    </p> */}
                  </div>
                </div>
              }
            />

            <CardFooter className="footer-card-action">
              <div onClick={() => navigate(`./SYSTEM_AUTOMATIC_TASKS`)}>
                <img className="img-svg-icon" src={NextSvg} alt="Info Circle" />
              </div>
            </CardFooter>
          </Card>
        </div>
      </section>

      <section key={6}>
        <div className="bg-[#2E3240] rounded">
          <Card
            onClick={() => navigate(`./SYSTEM_MANUAL_TASKS`)}
            orientation="horizontal"
            className="card-item-storage !bg-[#2E3240]"
          >
            <CardPreview
              className={`div-center div-card-preview div-card-preview-card-none-download`}
              // className={`div-center div-card-preview div-card-preview-card-none-download bg-black`}
            >
              <img
                className="object-contain img-card-item-catalog"
                src={TaskSvg}
              />
            </CardPreview>
            <CardHeader
              style={{ width: "calc(100% - 105px)" }}
              header={
                <div className="divHeader relative">
                  <div className="block w-full">
                    <Tooltip
                      relationship="description"
                      // @ts-ignore
                      content="System manual tasks"
                    >
                      <b
                        className="!uppercase text-title-card ellipsis-text-one-line"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        System manual tasks
                      </b>
                    </Tooltip>

                    {/* <p className="mt-1 text-des">
                      {Object.keys(item?.detail)?.length === 0 && "No config"}
                    </p> */}
                  </div>
                </div>
              }
            />

            <CardFooter className="footer-card-action">
              <div onClick={() => navigate(`./SYSTEM_MANUAL_TASKS`)}>
                <img className="img-svg-icon" src={NextSvg} alt="Info Circle" />
              </div>
            </CardFooter>
          </Card>
        </div>
      </section>
    </>
  );
};

export default CardItemDownloadComp;
