import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIdCollection,
  changeItemViewInfor,
} from "../../store/exploreSlice";

import { changeStateVisibleDrawerInforDataset } from "../../store/DrawerSlice";

import { useEffect, useState } from "react";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { RootState } from "../../store/store";
import LayerSvg from "./../../assets/dataset-admin.svg";
import EyeSvg from "./../../assets/info-circle.svg";

const CardItem = (props: any) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();

  const { idCollection } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const { visibleDrawerInforDataset } = useSelector(
    (state: RootState) => state.drawer
  );
  const [ip, setIp] = useState<any>("");

  const handleGetData = async () => {
    setIp(localStorage.getItem("REACT_APP_ENDPOINT"));
  };

  useEffect(() => {
    handleGetData();
  }, [props?.data]);

  return (
    <>
      {props?.data?.map((item: any, index: number) => (
        <section key={index} onClick={() => navigate(`/dataset/${item?.id}`)}>
          <div className="div-card">
            <Card
              orientation="horizontal"
              className={
                "card-item-storage cursor-pointer shadow-[none] " +
                (item?.id === idCollection ? "!bg-[#3E4353]" : "!bg-[#2E3240]")
              }
            >
              <CardPreview
                className={
                  `div-center div-card-preview`
                  // + (item?.thumbnail?.[0] ? " !bg-black" : "")
                }
                onClick={() => navigate(`/dataset/${item?.id}`)}
              >
                <img
                  loading="lazy"
                  className={`!rounded-sm ${
                    item?.thumbnail?.[0]
                      ? " img-card-item-catalog-search-drawer"
                      : " img-card-item-catalog"
                  }
                     `}
                  src={
                    item?.thumbnail?.[0]
                      ? `${ip}/collections/${item?.id}/thumbnails/${item?.thumbnail?.[0]}/`
                      : LayerSvg
                  }
                  alt="layer"
                  onError={(e: any) => {
                    e.target.src = LayerSvg;
                  }}
                />
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <Tooltip relationship="description" content={item?.title}>
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.title}
                        </p>
                      </Tooltip>

                      <Tooltip
                        relationship="description"
                        content={item?.description}
                      >
                        <p className="ellipsis-text-one-line text-des">
                          {item?.description}
                        </p>
                      </Tooltip>
                    </div>

                    <div />
                  </div>
                }
                onClick={() => navigate(`/dataset/${item?.id}`)}
              />

              <CardFooter className="footer-card-action">
                <div className="flex" onClick={(e) => e.stopPropagation()}>
                  <Button
                    className="btn-action-card"
                    size="medium"
                    iconPosition="before"
                    icon={
                      <img
                        className="img-svg-icon"
                        src={EyeSvg}
                        alt="Info Circle"
                      />
                    }
                    onClick={async () => {
                      dispatch(changeItemViewInfor(item));
                      dispatch(changeIdCollection(item?.id));
                      dispatch(
                        changeStateVisibleDrawerInforDataset(
                          !visibleDrawerInforDataset
                        )
                      );
                    }}
                  />
                </div>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItem;
