import {
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import Editor from "@monaco-editor/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import {
  changeStateStringValidate,
  editCollectionsAsync,
  getFeaturedByIdAsync,
  uploadImgCollectionsAsync,
  validateCollectionsAsync,
} from "./../../store/exploreSlice";

const EditDatasetPage = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const location = useLocation();

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const { statusValidate } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const [value, setValue] = useState<any>(undefined);
  const [state, setState] = useState(!statusValidate ? "validate" : "post");
  const customTheme = {
    base: "vs-dark",
    inherit: true,
    rules: [
      {
        token: "comment",
        foreground: "#5d7988",
        fontStyle: "italic",
      },
      { token: "constant", foreground: "#e06c75" },
    ],
    colors: {
      "editor.background": "#21252b",
    },
  };

  useEffect(() => {
    dispatch(changePath("/manage-dataset/edit"));
    dispatch(changeName("manage-dataset/edit"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);
  useEffect(() => {
    // @ts-ignore
    monaco.editor.defineTheme("my-custom-theme", customTheme);
    monaco.editor.setTheme("my-custom-theme");
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(changeStateStringValidate());
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getFeaturedByIdAsync(location.state.item?.id)
        ).unwrap();

        if (res?.status === 200) {
          setValue(JSON.stringify(res?.data, null, 2));
        }

        // dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  const handleEditorChange = (value: any, event: any) => {
    setValue(value);
    setState("validate");
    // @ts-ignore
    dispatch(changeStateStringValidate());
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (result: any, type?: string) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>
          {state === "validate" && type !== "upload" && (
            <p>
              {result?.status === 200
                ? "Validate successfully"
                : "Validate fail"}
            </p>
          )}
          {state === "post" && type !== "upload" && (
            <p>{result === 200 ? "Update successfully" : "Update fail"}</p>
          )}

          {type === "upload" && (
            <p>
              {result === 200
                ? `Upload image for ${location?.state?.item?.id} successfully`
                : `Upload image for ${location?.state?.item?.id} fail`}
            </p>
          )}
        </ToastTitle>
      </Toast>,
      {
        timeout: timeout,
        position: "bottom",
        intent: result?.status === 200 || result === 200 ? "success" : "error",
      }
    );
  };

  const handleValidate = () => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    dispatch(validateCollectionsAsync(value))
      .then(unwrapResult)
      .then((result: any) => {
        setState(result?.status === 200 ? "post" : "validate");

        setTimeout(() => {
          notify(result);
          dispatch(changeVisibleSpinner(false));
        }, 2500);
      })
      .catch((error: any) => {
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 2500);
      });
  };

  const handleFinish = async () => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      editCollectionsAsync({ id: location?.state?.item?.id, value })
    ).unwrap();
    if (res?.status === 200) {
      // @ts-ignore
      setValue(undefined);
      setState("validate");
      // @ts-ignore
      dispatch(changeStateStringValidate());

      setTimeout(() => {
        notify(res?.status);
        dispatch(changeVisibleSpinner(false));
        navigate(`/manage-dataset`);
        // @ts-ignore
      }, "2500");
    } else {
      setTimeout(() => {
        notify(res?.status);
        dispatch(changeVisibleSpinner(false));
        navigate(`/manage-dataset`);
        // @ts-ignore
      }, "2500");
    }
  };

  const dataBread = [
    {
      key: 0,
      value: "Dataset",
      path: "manage-dataset",
    },
    {
      key: 2,
      value: `Edit ${location?.state?.item?.id}`,
      path: "",
    },
  ];

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const handleFileChange = async (e: any) => {
    dispatch(changeVisibleSpinner(true));
    if (e.target.files) {
      const formData = new FormData();

      formData.append("file", e.target.files[0]);

      const res = await dispatch(
        // @ts-ignore
        uploadImgCollectionsAsync({
          id: location?.state?.item?.id,
          formData,
        })
      ).unwrap();

      if (res?.status === 201 || res?.status === 200) {
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
          notify(200, "upload");
          navigate(`/manage-dataset`);
        }, 1500);
      } else {
        setTimeout(() => {
          notify(500, "upload");
          dispatch(changeVisibleSpinner(false));
          navigate(`/manage-dataset`);
        }, 1500);
      }
    }
  };
  return (
    <>
      <div className="px-6 py-6 !w-full !relative">
        {visibleSpinner && <LoadingGlobalComp />}
        {/* <p className="mb-4">Enter information Datasets</p> */}
        <Editor
          height="calc(100vh - 230px)"
          defaultLanguage="json"
          theme="my-custom-theme"
          onChange={handleEditorChange}
          onMount={() => {
            dispatch(changeVisibleSpinner(false));
          }}
          value={value}
        />

        <div className="mt-4 float-right flex justify-between w-full">
          <input
            title="Upload image"
            id="file"
            type="file"
            // disabled={value?.id === null}
            accept="image/png, image/gif, image/jpeg, image/jpg"
            onChange={handleFileChange}
            className="block w-full text-sm text-white
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-[4px] file:border-0
                                file:text-sm file:font-semibold
                                file:bg-white/80 file:text-ink-100
                                cursor-pointer
                                hover:file:bg-white/70 hover:file:cursor-pointer"
          />
          <div className="flex">
            <Button
              className="btn-back"
              title="Cancel"
              onClick={() => navigate(`/manage-dataset`)}
            />
            <Button
              appearance="primary"
              title={state === "post" ? "Update" : "Validate"}
              className="text-black !ml-3"
              onClick={state === "post" ? handleFinish : handleValidate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDatasetPage;
