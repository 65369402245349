import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DatasetState {
  // contains state visible drawer using in catalog(user) & dataset(admin)
  visibleDrawerInforDataset?: boolean;
  visibleDrawerScan?: boolean;
  visibleDrawerInforItemDataset?: boolean;
  showDrawerMainMap?: boolean;
}

export interface DrawerCartState {
  visibleCart: boolean;
}

export interface DrawerFilterState {
  visible: boolean;
}

export interface DrawerSortState {
  visibleSort: boolean;
}

export interface DrawerDownload {
  visibleDrawerDownload: boolean;
}

export interface DrawerNotification {
  visibleNoti: boolean;
}

export interface DrawerReadNotification {
  visibleReadNoti: boolean;
}

export interface DrawerShowQuotaUser {
  visibleShowQuotaUser: boolean;
}

const initialState: DatasetState &
  DrawerCartState &
  DrawerNotification &
  DrawerDownload &
  DrawerSortState &
  DrawerFilterState &
  DrawerReadNotification &
  DrawerShowQuotaUser = {
  visibleDrawerInforDataset: false,
  visibleDrawerScan: false,
  visibleDrawerInforItemDataset: false,
  showDrawerMainMap: false,
  visibleCart: false,
  visible: false,
  visibleSort: false,
  visibleDrawerDownload: false,
  visibleNoti: false,
  visibleReadNoti: false,
  visibleShowQuotaUser: false,
};

export const DrawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    resetAllStateDrawer: (state, action) => {
      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;

      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },
    //start dataset
    changeShowDrawerMainMap: (state, action) => {
      state.showDrawerMainMap = action.payload;

      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },

    changeStateVisibleDrawerInforDataset: (state, action) => {
      state.visibleDrawerInforDataset = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },
    changeStateVisibleDrawerScan: (state, action) => {
      state.visibleDrawerScan = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },
    changeStateVisibleDrawerInforItemDataset: (state, action) => {
      state.visibleDrawerInforItemDataset = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },

    //end dataset

    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },

    toggleSort: (state, action: PayloadAction<boolean>) => {
      state.visibleSort = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },
    toggleDrawerNoti: (state, action: PayloadAction<boolean>) => {
      state.visibleNoti = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },
    toggleDrawerCart: (state, action: PayloadAction<boolean>) => {
      state.visibleCart = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visible = false;
      state.visibleSort = false; 
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },

    toggleDrawerDownload: (state, action: PayloadAction<boolean>) => {
      state.visibleDrawerDownload = action.payload;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = false;
    },

    changeSateVisibleReadNoti: (state, action) => {
      state.visibleReadNoti = action.payload;

      state.visibleNoti = false;
      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleShowQuotaUser = false;
    },

    toggleShowQuotaUser: (state, action: PayloadAction<boolean>) => {
      state.visibleDrawerDownload = false;

      state.showDrawerMainMap = false;
      state.visibleDrawerInforDataset = false;
      state.visibleDrawerScan = false;
      state.visibleDrawerInforItemDataset = false;
      state.visibleCart = false;
      state.visible = false;
      state.visibleSort = false;
      state.visibleNoti = false;
      state.visibleReadNoti = false;
      state.visibleShowQuotaUser = action.payload;
    },
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const {
  resetAllStateDrawer,
  changeShowDrawerMainMap,
  changeStateVisibleDrawerInforDataset,
  changeStateVisibleDrawerScan,
  changeStateVisibleDrawerInforItemDataset,

  toggleDrawer,
  toggleSort,
  toggleDrawerDownload,
  toggleDrawerCart,
  toggleDrawerNoti,

  changeSateVisibleReadNoti,
  toggleShowQuotaUser,
} = DrawerSlice.actions;

export default DrawerSlice.reducer;
