import * as React from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogTrigger,
  DialogBody,
  Button,
  Dropdown,
  Option,
  useRestoreFocusTarget,
} from "@fluentui/react-components";

import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import * as shpwrite from "@mapbox/shp-write";
export default function DownloadAoi(props: any) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<string>("shapefile");

  const onDownload = () => {
    switch (selected) {
      case "shapefile":
        exportShapeFile();
        break;
      case "geojsonfile":
        exportGeojsonFile();
        break;
      case "kmlfile":
        exportKmlFile();
        break;
    }
  };

  const exportShapeFile = () => {
    let fileName = getFileName(props?.feature);
    const options: any = {
      folder: fileName,
      filename: fileName,
      outputType: "blob",
      compression: "DEFLATE",
      types: {
        point: fileName,
        polygon: fileName,
        polyline: fileName,
      },
    };

    // a GeoJSON bridge for features
    const zipData = shpwrite.zip(
      {
        type: "FeatureCollection",
        features: [props?.feature],
      },
      options
    );
    zipData.then((blob) => {
      //@ts-ignore
      saveAs(blob, fileName + ".zip");

      props.onClose();
    });
  };

  const exportGeojsonFile = () => {
    let newFeature = {
      type: "FeatureCollection",
      features: [props?.feature],
    };
    // debugger
    var blob = new Blob([JSON.stringify(newFeature)], {
      type: "application/json",
    });
    saveAs(blob, getFileName(props?.feature) + ".geojson");

    props.onClose();
  };

  const exportKmlFile = () => {
    let coordinates = getCoordinates(props?.feature);
    let strCoors = "";
    if (coordinates && coordinates.length > 0) {
      for (let i = 0; i < coordinates.length; i++) {
        strCoors += coordinates[i].join(",") + "\n";
      }
    }
    let kmlStyle = "";
    switch (props?.feature?.geometry?.type) {
      case "Point":
        kmlStyle =
          `<kml xmlns="http://www.opengis.net/kml/2.2"><Document>
                                <Placemark>
                                <ExtendedData></ExtendedData>
                                <Point><coordinates>` +
          strCoors +
          `</coordinates></Point>
                                </Placemark></Document>
                            </kml>`;
        break;
      case "LineString":
        kmlStyle =
          `<kml xmlns="http://www.opengis.net/kml/2.2"><Document>
                                <Placemark>
                                <ExtendedData></ExtendedData>
                                <LineString><coordinates>
                                ` +
          strCoors +
          `
                                </coordinates></LineString>
                                </Placemark></Document></kml>`;
        break;
      case "Polygon":
        kmlStyle =
          `<kml xmlns="http://www.opengis.net/kml/2.2"><Document>
                            <Placemark>
                            <ExtendedData></ExtendedData>
                            <Polygon>
                            <outerBoundaryIs>
                            <LinearRing><coordinates>
                           ` +
          strCoors +
          `
                            </coordinates></LinearRing>
                            </outerBoundaryIs></Polygon></Placemark></Document>
                        </kml> `;
        break;
    }

    var blob = new Blob([kmlStyle], {
      type: "application/vnd.google-earth.kml+xml",
    });
    saveAs(blob, getFileName(props?.feature) + ".kml");

    props.onClose();
  };

  const getCoordinates = (feature: any) => {
    let results = [];
    switch (feature?.geometry?.type) {
      case "Point":
        results = feature?.geometry?.coordinates;
        break;
      case "LineString":
        results = feature?.geometry?.coordinates[0];
        break;
      case "Polygon":
        results = feature?.geometry?.coordinates[0];
        break;
    }
    return results;
  };

  const getFileName = (feature: any) => {
    return feature?.properties?.displayText
      ? feature?.properties?.displayText
      : feature?.geometry?.type;
  };

  return (
    <Dialog
      // this controls the dialog open state
      open={props.show}
      onOpenChange={(event, data) => {
        props.onClose();
      }}
    >
      <DialogSurface className="w-96 !bg-s-background1">
        <DialogBody>
          <DialogTitle>{t("Download aoi")}</DialogTitle>
          <DialogContent>
            <div className="flex flex-col my-2">
              <label className="mb-1">{t("Select a type")}</label>
              <Dropdown
                value={selected}
                appearance="filled-lighter"
                onOptionSelect={(e: any, data: any) => {
                  setSelected(data?.optionValue);
                }}
                className="div-dropdown"
              >
                <Option key={"shapefile"} value="shapefile">
                  {t("Shape file")}
                </Option>
                <Option key={"geojsonfile"} value="geojsonfile">
                  {t("Geojson file")}
                </Option>
                <Option key={"kmlfile"} value="kmlfile">
                  {t("Kml file")}
                </Option>
              </Dropdown>
            </div>
          </DialogContent>

          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                className="!bg-s-background1"
                onClick={() => props.onClose()}
                appearance="secondary"
              >
                {t("Close")}
              </Button>
            </DialogTrigger>
            <Button onClick={() => onDownload()} appearance="primary">
              {t("Download")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
