import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Option,
  Radio,
  RadioGroup,
  Switch,
  useToastController,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTaskAsync,
  getDayAsync,
  getDayRelativeAsync,
  getFreqTypeAsync,
  getRepeatTypeAsync,
  getTypeDayAsync,
  updateTaskAsync,
} from "../../store/ManageScheduler";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";

import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeVisibleSpinner } from "../../store/NavSlice";
import StyledFluentInput from "../InputCus";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";

const FormAutoScan = (props: any) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [valueM, setValueM] = useState("");
  const [valueH, setValueH] = useState("");
  const [errH, setErrH] = useState("");
  const [errM, setErrM] = useState("");

  const [arrRepType, setArrRepType] = useState<any>([]);
  const [arrDay, setArrDay] = useState<any>([]);
  const [arrDayRelative, setArrDayRelative] = useState<any>([]);
  const [arrFreqType, setArrFreqType] = useState<any>([]);
  const [arrTypeDay, setArrTypeDay] = useState<any>([]);

  const [days, setDays] = useState<any>([]);
  const [name, setName] = useState<any>(null);
  const [enable, setEnable] = useState(true);
  const [freqType, setFreqType] = useState("Daily");
  const [repeatType, setRepeatType] = useState<any>(null);
  const [interval, setInterVal] = useState("00");
  const [stateDate, setStateDate] = useState("Day");
  const [dayRelative, setDayRelative] = useState<any>([]);
  const [startTime, setStartTime] = useState(null);

  const [checkContinueRunning, setCheckContinueRunning] = useState(false);

  const [open, setOpen] = useState(false);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        let res;

        res = await dispatch(
          // @ts-ignore
          getDayAsync()
        ).unwrap();

        // let a = [];
        // for (let i = 0; i < res?.length; i++) {
        //   a.push({
        //     title: res?.[i],
        //     checked: false,
        //   });
        // }

        setArrDay(res);

        res = await dispatch(
          // @ts-ignore
          getRepeatTypeAsync()
        ).unwrap();

        setArrRepType(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getFreqTypeAsync()
        ).unwrap();

        setArrFreqType(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getTypeDayAsync()
        ).unwrap();

        setArrTypeDay(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getDayRelativeAsync()
        ).unwrap();

        setArrDayRelative(res ?? []);

        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (props?.item && props?.item?.id)
        try {
          setLoading(true);
          setName(props?.item?.name);
          setStateDate(props?.item?.freq_type ? "Day" : "date");
          setFreqType(
            // @ts-ignore
            props?.item?.freq_type && props?.item?.freq_type === "Daily"
              ? "Daily"
              : props?.item?.freq_type && props?.item?.freq_type?.Weekly
              ? "Weekly"
              : props?.item?.freq_type && props?.item?.freq_type?.Monthly
              ? "Monthly"
              : null
          );

          setDayRelative(
            props?.item?.freq_type?.Monthly?.select_day_relative_type
          );

          setDays(
            props?.item?.freq_type?.Monthly?.select_days ??
              props?.item?.freq_type?.Weekly?.select_days ??
              []
          );

          setStartTime(
            // @ts-ignore
            props?.item?.freq_type
              ? undefined
              : moment(props?.item?.active_start_date).toDate()
          );

          setValueM(props?.item?.active_start_time?.split(":")?.[1]);
          setValueH(props?.item?.active_start_time?.split(":")?.[0]);
          setCheckContinueRunning(props?.item?.repeat_type ? true : false);
          setRepeatType(props?.item?.repeat_type);
          setInterVal(props?.item?.repeat_interval ?? "00");
          setEnable(props?.item?.enable);
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
    })();
  }, [props?.item]);

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = useCallback(
    (newValue: string): Date => {
      const previousValue = startTime || new Date();
      // const previousValue = new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [startTime]
  );

  let hour = [];
  for (let i = 0; i < 23; i++) {
    hour.push(i);
  }

  let minus = [];
  for (let i = 0; i < 59; i++) {
    minus.push(i);
  }

  const handleInputChange = (event: any, type: any) => {
    if (type === "hour") {
      const regex = /^[0-9\b]+$/; // Allows only digits
      const inputValue = event.target.value;
      let a = regex.test(inputValue);

      if ((a || inputValue === "") && Number(inputValue) < 25) {
        setErrH("");
        setValueH(inputValue);
      } else {
        setErrH("Ener only digit from 00 to 23");
      }
    }
    if (type === "minus") {
      const regex = /^[0-9\b]+$/; // Allows only digits
      const inputValue = event.target.value;
      let a = regex.test(inputValue);

      if ((a || inputValue === "") && Number(inputValue) < 61) {
        setErrM("");
        setValueM(inputValue);
      } else {
        setErrM("Ener only digit from 00 to 59");
      }
    }
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    let payload = {
      name: name,
      enable: enable,
      task_type: "SCAN_COLLECTION",
      context: {
        collection_id: props?.collection_id,
        storage_id: props?.storage_id,
      },
      freq_type:
        stateDate === "date"
          ? null
          : freqType === "Daily"
          ? "Daily"
          : {
              [freqType]: {
                select_days: days,
                select_day_relative_type:
                  freqType === "Monthly" && dayRelative?.length !== 0
                    ? dayRelative
                    : null,
              },
            },
      active_start_date:
        stateDate === "date" ? moment(startTime).format("YYYY-MM-DD") : null,
      active_start_time: valueH && valueM ? `${valueH}:${valueM}:00.000` : null,
      repeat_type: repeatType,
      repeat_interval: Number(interval),
      when_system_start: false,
    };

    dispatch(changeVisibleSpinner(true));

    let res;

    if (props?.item && props?.item?.id) {
      // @ts-ignore
      res = await dispatch(
        // @ts-ignore
        updateTaskAsync({ id: props?.item?.id, ...payload })
      ).unwrap();

      if (res?.status === 200) {
        notify("Update task successfully", "success");

        setTimeout(() => {
          navigate(`/manage-dataset/setting/${props?.collection_id}`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Update task fail", "error");

        setTimeout(() => {
          // navigate(`/manage-dataset/setting/${props?.collection_id}`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    } else {
      // @ts-ignore
      res = await dispatch(
        // @ts-ignore
        createTaskAsync(payload)
      ).unwrap();

      if (res?.status === 200) {
        notify("Add task successfully", "success");

        setTimeout(() => {
          navigate(`/manage-dataset/setting/${props?.collection_id}`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Add task fail", "error");

        setTimeout(() => {
          // navigate(`/manage-dataset/setting/${props?.collection_id}`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    }
  };

  const handleCheckActiveAdd = () => {
    let flag = false;

    if (!name || name === "") {
      flag = true;
    }

    return flag;
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      <div
        className="div-form-storage-small"
        style={{
          opacity: loading ? 0.3 : 1,
          cursor: loading ? "none" : "initial",
        }}
        onClick={() => {
          if (open) {
            setOpen(false);
          }
        }}
      >
        <p className="mb-2">Title</p>
        <Input
          placeholder="Enter title..."
          className="div-dropdown"
          value={name}
          onChange={(e: any) => setName(e?.target?.value)}
        />
        <p className="mt-6 mb-3">Date</p>
        <RadioGroup value={stateDate}>
          <div className="flex w-full">
            <div className="block w-full">
              <Radio
                value="Day"
                label="Run on the following day"
                className="w-full"
                onChange={(e: any, val: any) => {
                  setStateDate(val?.value);
                }}
              />
            </div>
            <div className="block w-full">
              <Dropdown
                placeholder="Select type"
                className="div-dropdown !w-full"
                value={freqType}
                onOptionSelect={(e: any, data: any) => {
                  setFreqType(data?.optionValue);

                  if (data?.optionValue === "Monthly") {
                    setDays([]);
                  }
                }}
                disabled={stateDate === "date"}
              >
                {arrFreqType?.map((option: string) => (
                  <Option key={option}>{option}</Option>
                ))}
              </Dropdown>

              {freqType === "Monthly" && stateDate === "Day" && (
                <>
                  <Dropdown
                    placeholder="Select day relative"
                    className="div-dropdown !w-full mb-2 mt-2"
                    value={dayRelative?.join(", ")}
                    selectedOptions={dayRelative}
                    onOptionSelect={(e: any, data: any) => {
                      setDayRelative(data?.selectedOptions);
                    }}
                    multiselect
                  >
                    {arrDayRelative?.map((option: string) => (
                      <Option key={option}>{option}</Option>
                    ))}
                  </Dropdown>
                </>
              )}

              {freqType !== "Daily" && (
                <Dropdown
                  placeholder="Select day"
                  className="div-dropdown !w-full mb-2 mt-2"
                  value={days?.join(", ")}
                  selectedOptions={days}
                  onOptionSelect={(e: any, data: any) => {
                    setDays(data?.selectedOptions);
                  }}
                  open={open}
                  onClick={() => setOpen(!open)}
                  multiselect
                >
                  {arrTypeDay?.map((option: string) => (
                    <div
                      className="pt-1 pl-3 cursor-pointer"
                      onClick={(e) => {
                        setOpen(true);
                        e.preventDefault();

                        if (option === "WeekendDay") {
                          setDays(["Sunday", "Saturday"]);
                        } else if (option === "WeekDay") {
                          setDays([
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                          ]);
                        } else if (option === "Day") {
                          if (days?.length < 5) {
                            setDays([
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Sunday",
                              "Saturday",
                            ]);
                          } else setDays([]);
                        }
                      }}
                    >
                      {option}
                    </div>
                  ))}

                  <Divider className="mt-2" />

                  {arrDay?.map((item: any) => (
                    <Option
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      key={item}
                    >
                      {item}
                    </Option>
                  ))}
                </Dropdown>
              )}
            </div>
          </div>

          <div className="flex mt-4 w-full">
            <Radio
              value="date"
              label="Run on the following date"
              className="w-full"
              onChange={(e: any, val: any) => {
                setStateDate(val?.value);
              }}
            />
            <DatePicker
              formatDate={onFormatDate}
              allowTextInput
              placeholder="  dd/mm/yyyy"
              className="div-dropdown !w-full datepicker-cus"
              parseDateFromString={onParseDateFromStringStartAt}
              disabled={stateDate === "Day"}
              value={startTime}
              onSelectDate={(date: any) => {
                if (date) {
                  setStartTime(date);
                } else {
                  setStartTime(null);
                }
              }}
              //     maxDate={endAt}
            />
          </div>
        </RadioGroup>

        <p className="mt-6">Time</p>

        <div className="mt-3">
          <span>Set the first run time for the sellected date</span>
          <div className="flex gap-4 mt-2">
            <div className="block !w-full">
              <StyledFluentInput
                placeholder="00"
                className="div-dropdown"
                onChange={(e) => handleInputChange(e, "hour")}
                value={valueH}
                err={errH}
              />
              {errH}
            </div>
            :
            <div className="block !w-full">
              <StyledFluentInput
                placeholder="00"
                className="div-dropdown"
                onChange={(e) => handleInputChange(e, "minus")}
                value={valueM}
                err={errM}
              />
              {errM}
            </div>
          </div>

          <div className="flex mt-4">
            <Checkbox
              checked={checkContinueRunning}
              onChange={(ev: any, data: any) =>
                setCheckContinueRunning(data.checked)
              }
              label="Continue running within the same day"
              className="!w-full"
            />

            <Dropdown
              placeholder="Select type"
              className="div-dropdown !w-full"
              value={repeatType}
              onOptionSelect={(e: any, data: any) =>
                setRepeatType(data?.optionValue)
              }
              disabled={!checkContinueRunning}
            >
              {arrRepType?.map((option: string) => (
                <Option key={option}>{option}</Option>
              ))}
            </Dropdown>
          </div>
          {checkContinueRunning && (
            <div className="flex mt-4">
              <div className="block !w-full" />
              <div className="block !w-full">
                <p className="mb-2">Interval</p>
                <StyledFluentInput
                  placeholder="00"
                  className="div-dropdown"
                  onChange={(e) => setInterVal(e?.target?.value)}
                  value={interval}
                  // err={errH}
                />
                {/* {errH} */}
              </div>
            </div>
          )}
        </div>

        <p className="mt-6">Enabled</p>

        <Switch
          checked={enable}
          onChange={(_, data: any) => {
            setEnable(data?.checked);
          }}
        />

        <div className="flex absolute bottom-18 right-12">
          <Button
            appearance="outline"
            className="btn-extra-action"
            onClick={() => {
              navigate(`/manage-dataset/setting/${props?.collection_id}`);
            }}
            disabled={loading}
          >
            Cancel
          </Button>

          <Button
            id="action-card-add"
            appearance="primary"
            className="btn-action"
            disabled={handleCheckActiveAdd()}
            onClick={add}
          >
            {props?.item && props?.item?.id ? "Update" : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormAutoScan;
