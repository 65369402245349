
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AddSvg from "./../../assets/add.svg";
import useCustomNavigate from "../../hook/useCustomNavigate";


const ButtonCreateComp = (props: any) => {
  const { visibleBtnCreate, pathCreate, stateItem, visibleSpinner } =
    useSelector((state: RootState) => state.nav);
  const navigate = useCustomNavigate();

  const renderButtonCreate = () => {
    return visibleBtnCreate ? (
      <div
        className="mr-6 !cursor-pointer"
        onClick={() =>
          navigate(
            `${pathCreate}`,
            stateItem
              ? {
                  state: stateItem,
                }
              : undefined
          )
        }
      >
        <img src={AddSvg} className="img-svg-icon-add" alt="Map" />
      </div>
    ) : null;
  };

  const renderSpin = () => {
    return visibleSpinner ? (
      <svg
        className="animate-spin mr-6"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          transform="rotate(45 12 12)"
          stroke="#565656"
          stroke-width="3"
        />
        <path
          d="M19.0708 19.0711C15.1656 22.9763 8.83395 22.9763 4.92871 19.0711C1.02347 15.1658 1.02347 8.8342 4.92871 4.92896"
          stroke="#60CDFF"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    ) : null;
  };

  return (
    <div className="flex">
      {renderSpin()}
      {renderButtonCreate()}
    </div>
  );
};
export default ButtonCreateComp;
