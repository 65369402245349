import {
    MenuItem,
} from "@fluentui/react-components";
import UploadMapSvg from "../../../assets/upload.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { setOpenDialog } from '../../../store/uploadAoiSlice';
export default function UploadMenuItem() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onOpenUpload = (value: boolean) => {
        dispatch(setOpenDialog(value));
    }
    return (
        <>
            <MenuItem onClick={() => onOpenUpload(true)} className="!bg-s-background1 hover:!bg-ink-300">
                <div className="flex gap-x-2">
                    <img src={UploadMapSvg} alt="Upload" />
                    <span>{t('Upload')}</span>
                </div>
            </MenuItem>
        </>
    );
}