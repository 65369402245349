import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { useParams } from "react-router";
import FormEditTask from "../../components/ManageScheduler/FormEditTask";
import { useLocation } from "react-router-dom";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const EditTaskPage = (props: any) => {
  const dispatch = useDispatch();
  const param = useParams();
  const location = useLocation();
  const itemEdit = location?.state?.item;
  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { key: 0, value: "Scheduler" },
        { key: 1, value: "Task", path: "scheduler/task" },
        { key: 2, value: `Edit ${itemEdit?.name}` },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));

      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/scheduler/job/edit"));
    dispatch(changeName("scheduler/job/edit"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });
  return <FormEditTask />;
};

export default EditTaskPage;
