import {
  Card,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
import IconInfoSvg from "../../assets/search-info.svg";
import IconIntersect from "../../assets/search-intersect.svg";

import moment from "moment";

const CardHistorySearchComp = (props: any) => {
  const renderTextDateTime = (datetime: any) => {
    let a: any = "";
    if (datetime) {
      if (datetime?.split("/")?.[0] && datetime?.split("/")?.[0] !== "..") {
        a += ` Datetime: [${moment(datetime?.split("/")?.[0]).format(
          "DD/MM/YYYY"
        )}`;
      } else {
        a += ` Datetime: [--`;
      }
      if (datetime?.split("/")?.[1] && datetime?.split("/")?.[1] !== "..") {
        a += ` ,${moment(datetime?.split("/")?.[1]).format("DD/MM/YYYY")}]`;
      } else {
        a += ` ,--]`;
      }
    }
    return a;
  };
  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        const textTooltipCloudCover =
          `Cloud cover: [${item?.query?.query?.["eo:cloud_cover"]?.gte}, ${item?.query?.query?.["eo:cloud_cover"]?.lte}]` +
          renderTextDateTime(item?.query?.datetime);

        return (
          <section key={index}>
            <div className="bg-[#2E3240] rounded">
              <Card
                onClick={() => props?.handleNavigateMap(item)}
                orientation="horizontal"
                className="card-item-storage !bg-[#2E3240]"
              >
                <CardPreview className="div-center div-card-preview">
                  <img
                    src={item?.query?.intersects ? IconIntersect : IconInfoSvg}
                    alt="img item"
                    className="img-card-item-catalog"
                  />
                </CardPreview>
                <CardHeader
                  // onClick={() => props?.handleNavigateMap(item?.query)}
                  style={{ width: "calc(100% - 105px)" }}
                  header={
                    <div className="divHeader relative">
                      <div className="block w-full">
                        <Tooltip
                          relationship="description"
                          // @ts-ignore
                          content={moment(item?.created).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        >
                          <b
                            className="!uppercase text-title-card ellipsis-text-one-line"
                            style={{ overflowWrap: "anywhere" }}
                          >
                            {moment(item?.created).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </b>
                        </Tooltip>

                        <Tooltip
                          relationship="description"
                          // @ts-ignore
                          content={
                            item?.query?.query
                              ? textTooltipCloudCover
                              : `Type: ${item?.query?.intersects?.type}`
                          }
                        >
                          <p className="mt-1 text-des ellipsis-text-one-line">
                            {item?.query?.query && (
                              <>
                                <span className="mr-2">
                                  Cloud cover: [
                                  {item?.query?.query?.["eo:cloud_cover"]?.gte},{" "}
                                  {item?.query?.query?.["eo:cloud_cover"]?.lte}]
                                </span>
                                {item?.query?.datetime && (
                                  <span className="mr-2">
                                    Datetime: [
                                    {item?.query?.datetime?.split("/")?.[0] &&
                                    item?.query?.datetime?.split("/")?.[0] !==
                                      ".."
                                      ? moment(
                                          item?.query?.datetime?.split("/")?.[0]
                                        ).format("DD/MM/YYYY")
                                      : "--"}
                                    ,{" "}
                                    {item?.query?.datetime?.split("/")?.[1] &&
                                    item?.query?.datetime?.split("/")?.[1] !==
                                      ".."
                                      ? moment(
                                          item?.query?.datetime?.split("/")?.[1]
                                        ).format("DD/MM/YYYY")
                                      : "--"}
                                    ]
                                  </span>
                                )}
                              </>
                            )}
                            {item?.query?.intersects && (
                              <>
                                <span>
                                  Type: {item?.query?.intersects?.type}
                                </span>
                              </>
                            )}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  }
                />

                {/* <CardFooter
                  className="footer-card-action"
                  onClick={() => props?.handleNavigateMap(item?.query)}
                >
                  <div onClick={() => props?.handleNavigateMap(item?.query)}>
                    <img
                      className="img-svg-icon"
                      src={NextSvg}
                      alt="Info Circle"
                    />
                  </div>
                </CardFooter> */}
              </Card>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CardHistorySearchComp;
