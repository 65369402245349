import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "./../services/explore/exploreServices";

export const searchAsync = createAsyncThunk<
  null, // Returned,
  { payload: any },
  {
    state: { filter: { loading: string; currentRequestId: string } };
  }
>("itemsCollection/search", async (payload: any, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().filter;
  if (loading !== "pending" || requestId !== currentRequestId) {
    return;
  }
  const response = await search(payload);
  return response.data;
});

export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
}

var error: SerializedError = {};

var items: Array<any> = [];

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    callSearch: false,
    searchText: "",
    criteria: undefined,
    showCriteria: false,
    showItemsCollection: false,
    featureCollection: null,
    items: items,

    timeSlider: [], // Array min, max date view. Can use for silder or range slider. Current is select 1

    loading: "idle",
    currentRequestId: undefined,
    error: error,
    itemsViewMap: [],
    showResultSearch: false,
  },
  reducers: {
    changeCallSearch: (state, action) => {
      state.callSearch = action?.payload;
    },
    changeSearchText: (state, action) => {
      state.searchText = action?.payload;
    },
    changeCriteria: (state, action) => {
      state.criteria = action?.payload;
    },
    clearCriteria: (state) => {
      state.criteria = undefined;
    },
    changeShowCriteria: (state, action) => {
      state.showCriteria = action?.payload;
    },
    changeShowResultSearch: (state, action) => {
      state.showResultSearch = action?.payload;
    },
    changeShowItemsCollection: (state, action) => {
      state.showItemsCollection = action?.payload;
    },
    changeItems: (state, action) => {
      state.items = action?.payload;
      state.timeSlider = [];
    },

    changeItemsViewMap: (state, action) => {
      state.itemsViewMap = action?.payload;
    },
    updateThumbnailItem: (state, action: any) => {
      //@ts-ignore
      let index = state.items?.findIndex(
        (x: any) =>
          x.collection === action?.collection_id && x.id === action?.item_id
      );
      if (index >= 0) {
        //@ts-ignore
        state.items[index] = {
          ...state.items[index],
          thumbnail: action?.thumbnail,
        };
      }
    },

    changeTimeSlider: (state, action) => {
      state.timeSlider = action?.payload ?? [];
    },
    clearItems: (state) => {
      state.items = [];
      state.timeSlider = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // getItemCollectionById
      .addCase(searchAsync.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          //@ts-ignore
          state.currentRequestId = action?.meta?.requestId;
        }
      })
      .addCase(searchAsync.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
          let featureCollection: any = action.payload;
          state.featureCollection = featureCollection;
          let itemCopy: Array<any> = state.items.slice(); // copy
          state.items = [
            ...itemCopy,
            ...(featureCollection?.features ? featureCollection?.features : []),
          ];
        }
      })
      .addCase(searchAsync.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {
  changeCallSearch,
  changeSearchText,
  changeCriteria,
  clearCriteria,
  changeShowCriteria,
  changeShowItemsCollection,
  changeItems,
  updateThumbnailItem,
  changeTimeSlider,
  clearItems,
  changeItemsViewMap,
  changeShowResultSearch,
} = filterSlice.actions;

export default filterSlice.reducer;
