import {
  Button,
  Dropdown,
  Input,
  Option,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeSuccess,
  changeCreatedFrom,
  changeCreatedTo,
  changeCollectionId,
  changeStorageId,
  resetFilter,
} from "../../store/ManageStorageSlice";
import { getListAsync as getListStorage } from "./../../store/ManageStorageSlice";

import { getListAsync as getListCollection } from "../../store/exploreSlice";

const FilterHistoryScanComp = (props: any) => {
  // @ts-ignore
  const {
    success,
    created_from,
    created_to,
    collection_id,
    storage_id,
    collection_title,
    storage_des,
  } = useSelector((state: RootState) => state.managestorage);

  const dispatch = useDispatch();

  const [collection, setCollection] = useState([]);
  const [storage, setStorage] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const res = await dispatch(
          // @ts-ignore
          getListCollection({
            size: 10000,
            sortby: "modified",
            asc: false,
            page: 0,
          })
        ).unwrap();

        setCollection(res?.collections);

        const resStorage = await dispatch(
          // @ts-ignore
          getListStorage({
            size: 10000,
            page: 0,
          })
        ).unwrap();
        setStorage(resStorage?.result);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const changeTime = (date: any, type: string) => {
    if (type === "start_at") {
      if (date) {
        dispatch(changeCreatedFrom(date));
      } else {
        dispatch(changeCreatedFrom(null));
      }
    }
    if (type === "end_at") {
      {
        if (date) {
          dispatch(changeCreatedTo(date));
        } else {
          dispatch(changeCreatedTo(null));
        }
      }
    }
  };

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = useCallback(
    (newValue: string): Date => {
      const previousValue = created_from || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [created_from]
  );

  const onParseDateFromStringEndAt = useCallback(
    (newValue: string): Date => {
      const previousValue = created_to || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [created_to]
  );

  const onOptionSelectType = (e: any, data: any, type: any) => {
    if (type === "storage") {
      dispatch(changeStorageId(data?.optionValue));
    } else if (type === "collection") {
      dispatch(changeCollectionId(data?.optionValue));
    }
  };
  return (
    <>
      <div>
        <p className="mb-2">Success</p>
        <Dropdown
          placeholder="Select collection"
          className="div-dropdown"
          multiselect={false}
          value={success}
          onOptionSelect={(e: any, data: any) => {
            dispatch(changeSuccess(data?.optionValue));
          }}
          selectedOptions={[success]}
        >
          {["True", "False", "None"]?.map((option: any) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Dropdown>

        <p className="mb-2 mt-5">Collection</p>
        <Dropdown
          placeholder="Select collection"
          className="div-dropdown"
          multiselect={false}
          value={collection_title}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelectType(e, data, "collection")
          }
          selectedOptions={collection?.filter(
            (item: any) => item?.id === collection_id
          )}
        >
          {collection?.map((option: any) => (
            <Option key={option?.id} value={option}>
              {option?.title}
            </Option>
          ))}
        </Dropdown>
        <p className="mb-2 mt-5">Storage</p>
        <Dropdown
          placeholder="Select storage"
          className="div-dropdown"
          multiselect={false}
          value={storage_des}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelectType(e, data, "storage")
          }
          selectedOptions={storage?.filter(
            (item: any) => item?.id === storage_id
          )}
        >
          {storage?.map((option: any) => (
            <Option key={option?.id} value={option}>
              {option?.name}
            </Option>
          ))}
        </Dropdown>

        <p className="mb-2 mt-5">Created from</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          parseDateFromString={onParseDateFromStringStartAt}
          value={created_from}
          onSelectDate={(date: any) => changeTime(date, "start_at")}
          maxDate={created_to}
        />
        <p className="mb-2 mt-5">Created to</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          value={created_to}
          onSelectDate={(date: any) => changeTime(date, "end_at")}
          parseDateFromString={onParseDateFromStringEndAt}
          minDate={created_from}
        />
      </div>
    </>
  );
};

export default FilterHistoryScanComp;
