import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHealthCheck } from "../services/Health";

export interface HealthState {}

const initialState: HealthState = {};

export const HealthSlice = createSlice({
  name: "health",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // builder;
  },
});

export const getHealthCheckAsync = createAsyncThunk(
  "health/getHealthCheck",
  async (payload) => {
    try {
      const response = await getHealthCheck(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const {} = HealthSlice.actions;

export default HealthSlice.reducer;
