import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import FormAddDatasetStorageComp from "../../components/ManageDataset/FormAddDatasetStorage";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeName, changePath } from "../../store/pageSlice";

const AddCollectionStoragePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();

  const dataBread = [
    {
      key: 0,
      value: "Dataset",
      path: "manage-dataset",
    },
    {
      key: 1,
      value: `Setting storage's collection ${
        location?.state?.item?.id ?? params?.id
      }`,
      path: `manage-dataset/setting/${location?.state?.item?.id ?? params?.id}`,
    },
    {
      key: 1,
      value: `Create`,
      path: "create",
    },
  ];

  useEffect(() => {
    dispatch(changePath("/manage-dataset/setting/idCollection/create"));
    dispatch(changeName("manage-dataset/setting/idCollection/create"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  return <FormAddDatasetStorageComp />;
};

export default AddCollectionStoragePage;
