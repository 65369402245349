import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Switch,
  Tooltip,
} from "@fluentui/react-components";

import DeleteSvg from "./../../assets/delete.svg";
import EditSvg from "./../../assets/edit-light.svg";
import NextSvg from "./../../assets/next.svg";
import QuotaSvg from "./../../assets/quota-icon-menu.svg";

import moment from "moment";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardItemQuotaComp = (props: any) => {
  const navigate = useCustomNavigate();

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section
            key={index}
            onClick={() => {
              navigate(`${document.location.pathname}/edit/${item?.id}`, {
                state: { item: item },
              });
            }}
          >
            <div className="bg-[#2E3240] rounded">
              <Card
                orientation="horizontal"
                className="card-item-storage !bg-[#2E3240]"
              >
                <CardPreview className="div-center div-card-preview">
                  <img
                    src={QuotaSvg}
                    alt="img item"
                    className="img-card-item-catalog"
                  />
                </CardPreview>
                <CardHeader
                  className="w-auto div-card-header"
                  header={
                    <div className="divHeader relative">
                      <div className="block w-full">
                        <Tooltip
                          relationship="description"
                          content={item?.name}
                        >
                          <p className="!uppercase text-title-card ellipsis-text-one-line">
                            {item?.name}
                          </p>
                        </Tooltip>
                        <Tooltip
                          relationship="description"
                          content={`${item?.limit_value} ${
                            item?.unit
                          } | expire: 
                            ${
                              item?.expire?.interval
                                ? item?.expire?.interval
                                : `from ${moment(item?.expire?.started).format(
                                    "hh:mm:ss DD/MM/YYYY"
                                  )} to ${moment(item?.expire?.ended).format(
                                    "hh:mm:ss DD/MM/YYYY"
                                  )}`
                            }`}
                        >
                          <p className="!mt-1 text-des ellipsis-text-one-line">
                            {item?.limit_value} {item?.unit} | expire:{" "}
                            {item?.expire?.interval
                              ? item?.expire?.interval
                              : `from ${moment(item?.expire?.started).format(
                                  "hh:mm:ss DD/MM/YYYY"
                                )} to ${moment(item?.expire?.ended).format(
                                  "hh:mm:ss DD/MM/YYYY"
                                )}`}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  }
                />

                <CardFooter className="footer-card-action">
                  <div
                    className="div-action-card"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="mr-3"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`./edit/${item?.id}`, {
                          state: { item: item },
                        });
                      }}
                    >
                      <img
                        className="mr-2 img-svg-icon"
                        src={EditSvg}
                        alt="Dismiss"
                      />
                    </div>

                    <div>
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <img
                            className="mr-2 img-svg-icon"
                            src={DeleteSvg}
                            alt="Dismiss"
                          />
                        </MenuTrigger>

                        <MenuPopover>
                          <MenuList
                            onClick={() => {
                              props?.deleQuota(item?.id);
                            }}
                          >
                            <MenuItem>Delete </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="mr-3" onClick={(e) => e.stopPropagation()}>
                      <Switch
                        checked={item?.enable}
                        onChange={(e, data: any) => {
                          e.stopPropagation();
                          props?.handleEnable(data, item);
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        navigate(`./edit/${item?.id}`, {
                          state: { item: item },
                        });
                      }}
                    >
                      <img
                        className="img-svg-icon"
                        src={NextSvg}
                        alt="Info Circle"
                      />
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CardItemQuotaComp;
