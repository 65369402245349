import { useEffect } from "react";
import { useDispatch } from "react-redux";
import DetailSystemKeyComp from "../../components/Download/DetailSystemKey";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";

const DetailSystemKeyPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePath("/upd-setting-system"));
    dispatch(changeName("upd-setting-system"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));
    };
  });
  return <DetailSystemKeyComp />;
};

export default DetailSystemKeyPage;
