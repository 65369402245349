import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import FormAddTask from "../../components/ManageScheduler/FormAddTask";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const AddTaskPage = (props: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { key: 0, value: "Scheduler" },
        { key: 1, value: "Task", path: "scheduler/task" },
        { key: 2, value: "Add" },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/scheduler/job/add"));
    dispatch(changeName("scheduler/job/add"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });
  return <FormAddTask />;
};

export default AddTaskPage;
