import {
  Button,
  Dropdown,
  Input,
  Option,
  useToastController,
} from "@fluentui/react-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";

import useCustomNavigate from "../../hook/useCustomNavigate";
import {
  applyAdminRolesAsync,
  applyUserRolesAsync,
  setUserAsAdminAsync,
  setUserAsUserAsync,
} from "../../store/ManageUserSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";

const FormEditUserComp = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const location = useLocation();
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [lastName, setLastName] = useState(
    location?.state?.item?.traits?.name?.last
  );

  const [firstName, setFirstName] = useState(
    location?.state?.item?.traits?.name?.first
  );

  const [email, setEmail] = useState(location?.state?.item?.traits?.email);

  const [group, setGroup] = useState(
    location?.state?.item?.groups?.length === 0
      ? "User"
      : location?.state?.item?.groups?.length > 0 &&
        location?.state?.item?.groups?.includes("admin")
      ? "Admin"
      : "Staff"
  );

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  let originGroup =
    location?.state?.item?.groups?.length === 0
      ? "User"
      : location?.state?.item?.groups?.length > 0 &&
        location?.state?.item?.groups?.includes("admin")
      ? "Admin"
      : "Staff";

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    dispatch(changeVisibleSpinner(true));
    if (group === "User") {
      const resSetUser = await dispatch(
        // @ts-ignore
        setUserAsUserAsync(location?.state?.item?.id)
      ).unwrap();

      const resApplyRolesUser = await dispatch(
        // @ts-ignore
        applyUserRolesAsync(location?.state?.item?.id)
      ).unwrap();

      if (resSetUser?.status === 200 && resApplyRolesUser?.status === 200) {
        notify("Set group user successfully", "success");
        setTimeout(() => {
          navigate(`/manage-user`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Set group user fail", "error");
        setTimeout(() => {
          // navigate(`/manage-user`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    }

    if (group === "Admin") {
      const resSetAdmin = await dispatch(
        // @ts-ignore
        setUserAsAdminAsync(location?.state?.item?.id)
      ).unwrap();

      const resApplyRolesAdmin = await dispatch(
        // @ts-ignore
        applyAdminRolesAsync(location?.state?.item?.id)
      ).unwrap();

      if (resSetAdmin?.status === 200 && resApplyRolesAdmin?.status === 200) {
        notify("Set group admin successfully", "success");
        setTimeout(() => {
          navigate(`/manage-user`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      } else {
        notify("Set group admin fail", "error");
        setTimeout(() => {
          // navigate(`/manage-user`);
          dispatch(changeVisibleSpinner(false));
        }, 2000);
      }
    }
  };
  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage-small">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "100%", marginRight: "4px" }}>
            <p className="mb-2">Last name</p>
            <Input
              value={lastName}
              onChange={(e: any) => setLastName(e?.target?.value)}
              placeholder="Enter name..."
              className="div-dropdown"
              disabled
            />
          </div>
          <div style={{ width: "100%", marginLeft: "4px" }}>
            <p className="mb-2">First name</p>
            <Input
              value={firstName}
              onChange={(e: any) => setFirstName(e?.target?.value)}
              placeholder="Enter name..."
              className="div-dropdown"
              disabled
            />
          </div>
        </div>

        <p className="mb-2 mt-3">Email</p>
        <Input
          value={email}
          onChange={(e: any) => setEmail(e?.target?.value)}
          placeholder="Enter name..."
          className="div-dropdown"
          disabled
        />

        <p className="mb-2 mt-3">Group</p>

        <Dropdown
          placeholder="Select group"
          className="div-dropdown"
          value={group}
          onOptionSelect={(e: any, data: any) => {
            setGroup(data.optionText);
          }}
          selectedOptions={[group]}
        >
          {["Admin", "User"].map((option: string) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => navigate(`/manage-user`)}
          disabled={visibleSpinner}
        >
          Cancel
        </Button>

        <Button
          appearance="outline"
          className="btn-action"
          onClick={() => add()}
          disabled={visibleSpinner || originGroup === group}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default FormEditUserComp;
