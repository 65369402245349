import axios, { AxiosResponse } from "axios";

import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ToastComp from "../components/Toast";
import { RootState } from "../store/store";
import { loadConfig } from "./utils";

const api = axios.create();

api.defaults.timeout = 60000000;
api.defaults.withCredentials = true;

// api.interceptors.response.use(
//   (response) =>
//     // Do something with response data
//     response,
//   async (error) => {
//     console.log(error, "error");
//     // If the error status is 401 and there is no originalRequest._retry flag,
//     // it means the token has expired and we need to refresh it
//     if (error?.response?.status === 401) {
//       const data = await loadConfig(process.env.REACT_APP_CFG_URL);

//       console.log(error, "401");
//       window.location.replace(data?.REACT_APP_ENDPOINT_ACCOUNT);
//     }

//     if (error?.response?.status === 403) {
//       useToast();
//     }

//     return Promise.reject(error);
//   }
// );

const AxiosInterceptor = ({ children }: { children: any }) =>
{
  const [isSet, setIsSet] = useState(false);
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);
  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: 3000,
      intent: type,
    });
  };

  useEffect(() => {
    setIsSet(true);
    const resInterceptor = (response: AxiosResponse<any, any>) => {
      return response;
    };

    const errInterceptor = async (error: any) => {
      console.log(error, "error");
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error?.response?.status === 401) {
        const data = await loadConfig(process.env.REACT_APP_CFG_URL);

        console.log(error, "401");
        window.location.replace(data?.REACT_APP_ENDPOINT_ACCOUNT);
      }

      if (error?.response?.status === 403)
      {
        const message = error?.response?.data?.error?.message?? "403 Error"
        notify(message, 'error');
      }

      return Promise.reject(error);
    };

    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => api.interceptors.response.eject(interceptor);
  }, []);
  return isSet && children;
};

export default api;
export { AxiosInterceptor };
