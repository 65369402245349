import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import { ButtonComponent } from "@web/react-component-ui-user";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextSvg from "./../../assets/next.svg";
import { cancelOrderAdminAsync } from "./../../store/ManageOrderSlice";

import OrderSuccessSvg from "./../../assets/images/order-success.svg";

import OrderRejectSvg from "./../../assets/images/order-reject.svg";

import OrderPendingSvg from "./../../assets/images/order-pending.svg";

import useCustomNavigate from "../../hook/useCustomNavigate";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import ToastComp from "../Toast";
import RejectSvg from "./../../assets/Dismiss.svg";

const CardInvoiceComp = (props: any) => {
  const dispatch = useDispatch();

  const navigate = useCustomNavigate();
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const handleCancelOrder = async (item: any) => {
    const res = await dispatch(
      // @ts-ignore
      cancelOrderAdminAsync({
        orderId: item?.id,
        owner_id: item?.user_id,
      })
    ).unwrap();

    if (res.status === 200) {
      props?.getData();
      notify("Cancel successfully", "success");
    }
  };

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  return (
    <>
      {props?.data?.map((item: any, index: number) => (
        <section
          key={index}
          onClick={() => {
            if (item?.states?.[item?.states?.length - 1]?.state !== "Create") {
              navigate(`/invoice/${item?.id}`, {
                state: {
                  item: item,
                },
              });
            }
          }}
        >
          <div className="div-card">
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview
                className="div-center div-card-preview"
                onClick={() => {
                  if (
                    item?.states?.[item?.states?.length - 1]?.state !== "Create"
                  ) {
                    navigate(`/invoice/${item?.id}`, {
                      state: {
                        item: item,
                      },
                    });
                  }
                }}
              >
                <Tooltip
                  content={item?.states?.[item?.states?.length - 1]?.state}
                  relationship="label"
                >
                  <div className="div-center">
                    <img
                      src={
                        item?.states?.[item?.states?.length - 1]?.state ===
                        "Accept"
                          ? OrderSuccessSvg
                          : item?.states?.[item?.states?.length - 1]?.state ===
                            "Submit"
                          ? OrderPendingSvg
                          : OrderRejectSvg
                      }
                      alt="img item"
                      className="img-card-item-catalog"
                    />
                  </div>
                </Tooltip>
              </CardPreview>
              <CardHeader
                className="w-auto div-card-header"
                onClick={() => {
                  if (
                    item?.states?.[item?.states?.length - 1]?.state !== "Create"
                  ) {
                    navigate(`/invoice/${item?.id}`, {
                      state: {
                        item: item,
                      },
                    });
                  }
                }}
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <p className="!uppercase text-title-card ellipsis-text-one-line">
                        {moment(item?.modified).format("DD/MM/YYYY HH:mm:ss")}
                      </p>

                      <p className="mt-1 text-des">
                        {item?.items?.length} item
                        {item?.items?.length > 1 ? "s" : ""}
                      </p>
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <>
                  <div className="flex" onClick={(e) => e.stopPropagation()}>
                    {item?.states?.[item?.states?.length - 1]?.state ===
                      "Submit" && (
                      <div className="div-action-card">
                        <div
                          onClick={(e: any) => {
                            e.preventDefault();
                            handleCancelOrder(item);
                          }}
                          className="mr-3"
                        >
                          <img
                            className="img-svg-icon"
                            src={RejectSvg}
                            alt="Dismiss"
                          />
                        </div>
                      </div>
                    )}

                    {item?.states?.[item?.states?.length - 1]?.state !==
                      "Create" && (
                      <ButtonComponent
                        className="btn-show-order"
                        size="medium"
                        onClick={() =>
                          navigate(`/invoice/${item?.id}`, {
                            state: {
                              item: item,
                            },
                          })
                        }
                        icon={
                          <Tooltip
                            content="Show items in order"
                            relationship="label"
                          >
                            <img
                              className="img-svg-icon"
                              src={NextSvg}
                              alt="Info Circle"
                            />
                          </Tooltip>
                        }
                        iconPosition="after"
                      />
                    )}
                  </div>
                </>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardInvoiceComp;
