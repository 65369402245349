import { configureStore } from "@reduxjs/toolkit";
import ManageCollectionStorageSlice from "./ManageCollectionStorageSlice";
import ManageOrderReducer from "./ManageOrderSlice";
import ManageQuotaReducer from "./ManageQuotaSlice";
import ManageStorageReducer from "./ManageStorageSlice";
import ManageUserSlice from "./ManageUserSlice";
import exploreReducer from "./exploreSlice";
import pagingReducer from "./pagingSlice";

import BreadcrumbSlice from "./BreadcrumbSlice";
import DrawerSlice from "./DrawerSlice";
import HealthSlice from "./HealthSlice";
import ManageScheduler from "./ManageScheduler";
import ManageSearchHis from "./ManageSearchHis";
import NavSlice from "./NavSlice";
import dowloadSlice from "./dowloadSlice";
import drawSlice from "./drawSlice";
import filterSlice from "./filterSlice";
import itemCollectionSlice from "./itemCollectionSlice";
import maplibreGLSlice from "./maplibreGLSlice";
import notificationsSlice from "./notificationsSlice";
import pageSlice from "./pageSlice";
import uploadAoiSlice from "./uploadAoiSlice";

export const store = configureStore({
  reducer: {
    paging: pagingReducer,
    exploredataset: exploreReducer,
    managestorage: ManageStorageReducer,
    manageorder: ManageOrderReducer,
    managequota: ManageQuotaReducer,
    manageuser: ManageUserSlice,
    managecollectionstorage: ManageCollectionStorageSlice,
    draw: drawSlice,
    itemCollection: itemCollectionSlice,
    filter: filterSlice,
    map: maplibreGLSlice,
    notifications: notificationsSlice,
    page: pageSlice,
    uploadAoi: uploadAoiSlice,
    download: dowloadSlice,
    breadcrumn: BreadcrumbSlice,
    nav: NavSlice,
    managescheduler: ManageScheduler,
    managesearchhis: ManageSearchHis,
    drawer: DrawerSlice,
    health: HealthSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
