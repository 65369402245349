import { useCallback, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { DatePicker } from "@fluentui/react-datepicker-compat";
import { addDays } from "@fluentui/react-calendar-compat";

import { RootState } from "../../../../store/store";
import { changeTimeSlider } from "../../../../store/filterSlice";

import MultiRangeSlider from "../../../multiRangeSlider";

export default function RangeSlider(props: any) {

    const [minValue, setMinValue] = useState<number | any>(0);
    const [maxValue, setMaxValue] = useState<number | any>(0);

    const [selectedMinDate, setSelectedMinDate] = useState<Date | any>(null);
    const [selectedMaxDate, setSelectedMaxDate] = useState<Date | any>(null);

    const [maxDate, setMaxDate] = useState<Date | any>(null);
    const [minDate, setMinDate] = useState<Date | any>(null);

    const [restrictedDates, setRestrictedDates] = useState<Array<Date> | Array<any>>([]);

    const [allowedDates, setAllowedDates] = useState<Array<Date> | Array<any>>([]);

    const dispatch = useDispatch();

    const items: Array<any> = useSelector(
        (state: RootState) => state.filter?.items
    );

    useEffect(() => {
        getMinMaxDate();
    }, [items]); // get min max date

    const getMinMaxDate = () => {
        if (items && items.length) {
            getAllowedDates();
        } else {
            setMinDate(null);
            setMaxDate(null);
        }
    }

    const getAllowedDates = () => {
        let dates: Array<Date> | Array<any> = [];
        for (let i = 0; i < items.length; i++) {
            dates.push(new Date(items[i]?.properties?.datetime));
        }
        //@ts-ignore
        var max = new Date(Math.max.apply(null, dates));
        //@ts-ignore
        var min = new Date(Math.min.apply(null, dates));

        dates.sort(function (a, b) {
            return a - b;
        });

        setSelectedMaxDate(max);
        setSelectedMinDate(min);

        setMinDate(min);
        setMaxDate(max);
        if (min && max && (min.getDate() != max.getDate()
            || min.getMonth() != max.getMonth()
            || min.getFullYear() != max.getFullYear())) {
            props.setShowTimeType(true);
        } else props.setShowTimeType(false);

        setAllowedDates(dates);

        setMaxValue(dates.length - 1);

        getRestrictedDates(min, max, dates);
    }

    const getRestrictedDates = (min: Date, max: Date, allowed: Array<Date>) => {
        let dates: Array<Date> | Array<any> = [];
        let differenceInTimes = max.getTime() - min.getTime();

        let differenceInDays = Math.round(differenceInTimes / (1000 * 3600 * 24));

        for (let i = 1; i < differenceInDays; i++) {
            let newDate = addDays(min, i);
            if (allowed?.findIndex(x => x.getDate() == newDate.getDate() && x.getMonth() == newDate.getMonth() && x.getFullYear() == newDate.getFullYear()) < 0)
                dates.push(newDate);
        }
        setRestrictedDates(dates);
    }

    const onFormatDate = (date?: any): string => {
        return !date
            ? ""
            : (date?.getDate() < 10 ? ("0" + date?.getDate()) : date?.getDate()) +
            "/" +
            ((date?.getMonth() + 1) < 10 ? ("0" + (date?.getMonth() + 1)) : (date?.getMonth() + 1)) +
            "/" +
            (date?.getFullYear());
    };

    const onParseMinDateFromString = useCallback(
        (newValue: string): Date => {
            const previousValue = selectedMinDate || new Date();
            const newValueParts = (newValue || "").trim().split("/");
            const day =
                newValueParts.length > 0
                    ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
                    : previousValue.getDate();
            const month =
                newValueParts.length > 1
                    ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
                    : previousValue.getMonth();
            let year =
                newValueParts.length > 2
                    ? parseInt(newValueParts[2], 10)
                    : previousValue.getFullYear();
            if (year < 100) {
                year +=
                    previousValue.getFullYear() - (previousValue.getFullYear() % 100);
            }
            return new Date(year, month, day);
        },
        [selectedMinDate]
    );

    const onParseMaxDateFromString = useCallback(
        (newValue: string): Date => {
            const previousValue = selectedMaxDate || new Date();
            const newValueParts = (newValue || "").trim().split("/");
            const day =
                newValueParts.length > 0
                    ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
                    : previousValue.getDate();
            const month =
                newValueParts.length > 1
                    ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
                    : previousValue.getMonth();
            let year =
                newValueParts.length > 2
                    ? parseInt(newValueParts[2], 10)
                    : previousValue.getFullYear();
            if (year < 100) {
                year +=
                    previousValue.getFullYear() - (previousValue.getFullYear() % 100);
            }
            return new Date(year, month, day);
        },
        [selectedMaxDate]
    );

    const changeMinDate = (date: any) => {
        setSelectedMinDate(date);
        dispatch(changeTimeSlider([date, selectedMaxDate]));

        let index = allowedDates?.findIndex(x => x.getDate() == date.getDate() && x.getMonth() == date.getMonth() && x.getFullYear() == date.getFullYear());
        if (index >= 0) setMinValue(index);
    };

    const changeMaxDate = (date: any) => {
        setSelectedMaxDate(date);
        dispatch(changeTimeSlider([selectedMinDate, date]));

        let index = allowedDates?.findIndex(x => x.getDate() == date.getDate() && x.getMonth() == date.getMonth() && x.getFullYear() == date.getFullYear());
        if (index >= 0) setMaxValue(index);
    };

    const onChangeRangeSlider = (min: number, max: number) => {
        setMinValue(min);
        setMaxValue(max);
        setSelectedMinDate(allowedDates[min]);
        setSelectedMaxDate(allowedDates[max]);

        dispatch(changeTimeSlider([allowedDates[min], allowedDates[max]]));
    };

    return (
        <>
            {(minDate != null && maxDate != null
                && (minDate.getDate() != maxDate.getDate()
                    || minDate.getMonth() != maxDate.getMonth()
                    || minDate.getFullYear() != maxDate.getFullYear())) && <div className="flex gap-x-2">
                    <DatePicker
                        formatDate={onFormatDate}
                        placeholder="dd/mm/yyyy"
                        className={"w-32 !border-0 !bg-transparent after:!border-b-0 " + (minDate != null && maxDate != null ? "block" : "hidden")}
                        parseDateFromString={onParseMinDateFromString}
                        onSelectDate={(date: any) => changeMinDate(date)}
                        value={selectedMinDate}
                        calendar={{
                            restrictedDates: restrictedDates,
                        }}
                        minDate={minDate}
                        maxDate={selectedMaxDate}
                        showGoToToday={false}
                    />
                    <div className="flex text-left self-center items-center w-96">
                        <MultiRangeSlider
                            min={0}
                            max={allowedDates.length ? allowedDates.length - 1 : 0}
                            currentMax={maxValue}
                            currentMin={minValue}
                            onChange={({ min, max }) => onChangeRangeSlider(min, max)}
                        />
                    </div>
                    <DatePicker
                        formatDate={onFormatDate}
                        placeholder="dd/mm/yyyy"
                        className={"w-32 !border-0 !bg-transparent after:!border-b-0 " + (minDate != null && maxDate != null ? "block" : "hidden")}
                        parseDateFromString={onParseMaxDateFromString}
                        onSelectDate={(date: any) => changeMaxDate(date)}
                        value={selectedMaxDate}
                        calendar={{
                            restrictedDates: restrictedDates,
                        }}
                        minDate={selectedMinDate}
                        maxDate={maxDate}
                        showGoToToday={false}
                    />
                </div>}
        </>
    )
}