import {
  Button,
  Dropdown,
  Option,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import ToastComp from "../../components/Toast";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { addUserQuotaAsync, getListAsync } from "../../store/ManageQuotaSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import { getListUserAsync } from "./../../store/ManageUserSlice";

const dataBread = [
  {
    key: 0,
    value: "Quotas",
    path: "manage-quota",
  },
  {
    key: 1,
    value: "User quota",
    path: "manage-user-quota",
  },
  {
    key: 2,
    value: "Add",
    path: "add",
  },
];

const AddUserQuotaPage = () => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [arrQuota, setArrQuota] = useState([]);
  const [arrUser, setArrUser] = useState([]);

  const [user, setUser] = useState(undefined);
  const [quota, setQuota] = useState(undefined);

  const onOptionSelect = (e: any, data: any, type: string) => {
    if (type === "user") {
      setUser(data?.optionValue);
    }

    if (type === "quota") {
      setQuota(data?.optionValue);
    }
  };

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListAsync()
        ).unwrap();

        let sun: any = [];
        sun = res?.result?.filter((item: any) => item?.enable);

        setArrQuota(sun ?? []);

        const resUser = await dispatch(
          // @ts-ignore
          getListUserAsync()
        ).unwrap();

        setArrUser(resUser);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/add-user-quota"));
    dispatch(changeName("add-user-quota"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  const add = async () => {
    dispatch(changeVisibleSpinner(true));

    const res = await dispatch(
      // @ts-ignore
      addUserQuotaAsync({ userId: user, quotaId: quota })
    ).unwrap();

    if (res?.status === 200) {
      notify("Add successfully", "success");

      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
        navigate(`/manage-user-quota`);
        // @ts-ignore
      }, 2000);
    } else {
      notify("Add fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage-small">
        <p className="mb-2 mt-6">User</p>
        <Dropdown
          placeholder="Select user"
          className="div-dropdown"
          value={user}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelect(e, data, "user")
          }
        >
          {arrUser?.map((user: any) => {
            return user?.traits?.name?.first && user?.traits?.name?.last ? (
              // @ts-ignore
              <Option key={user?.id} value={user?.id}>
                {user?.traits?.name?.first} {user?.traits?.name?.last}
              </Option>
            ) : (
              <Option key={user?.id} value={user?.id}>
                {user?.traits?.email}
              </Option>
            );
          })}
        </Dropdown>

        <p className="mb-2 mt-6">Quota</p>
        <Dropdown
          placeholder="Select quota"
          className="div-dropdown"
          value={quota}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelect(e, data, "quota")
          }
        >
          {arrQuota.map((quota: any) => (
            // @ts-ignore
            <Option key={quota?.id} value={quota?.id}>
              {quota?.name}
            </Option>
          ))}
        </Dropdown>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => navigate(`/manage-user-quota`)}
        >
          Cancel
        </Button>

        <Button
          appearance="outline"
          className="btn-action"
          onClick={() => add()}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddUserQuotaPage;
