import { useEffect, useState } from "react";
import LoadingListComp from "../../components/Loading/LoadingList";
import CardHistorySearchComp from "../../components/ManageHistorySeacrh/CardHistorySearch";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  changeSateVisibleReadNoti,
  changeShowDrawerMainMap,
  resetAllStateDrawer,
  toggleDrawerCart,
  toggleDrawerDownload,
  toggleDrawerNoti,
} from "../../store/DrawerSlice";
import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  getListSearchHisAsync,
  resetFilterState,
} from "../../store/ManageSearchHis";
import { changeVisibleSpinner } from "../../store/NavSlice";
import {
  changeCloudCoverFrom,
  changeCloudCoverTo,
  changeEndAt,
  changeFlagFilterItem,
  changeFlagFromHistory,
  changeStartAt,
  changeTypeVT,
  getProductTypeAsync,
} from "../../store/exploreSlice";
import { changeShowCriteria } from "../../store/filterSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { changePage, changeTotal, resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import EmptySvg from "./../../assets/nodata.svg";

const ListSearchHisPage = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const {
    flagFilter,
    flagFresh,
    flagSort,
    created_from,
    created_to,
    sort_by,
    sort_order,
  } = useSelector((state: RootState) => state.managesearchhis);

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(changePath("/search-history"));
    dispatch(changeName("search-history"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "Search history",
          path: "search-history",
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      if (!window.location.pathname?.includes("search-history")) {
        dispatch(resetFilterState({}));
      }
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const getData = async (props: any) => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      getListSearchHisAsync({
        page: props?.page ? props?.page : page,
        limit: limit,
        sortby: sort_by,
        asc: sort_order === "asc" ? true : false,
        filter: {
          method_get: false,
          created_from: created_from,
          created_to: created_to
            ? moment(created_to).endOf("days").toISOString()
            : null,
        },
      })
    ).unwrap();

    setData(res?.data?.result ?? []);
    dispatch(changeTotal(res?.data?.paging?.total ?? 0));
    dispatch(changeVisibleSpinner(false));
    setLoading(false);
  };

  useEffect(() => {
    (async function () {
      if (!flagFilter || !flagFresh || !flagSort)
        try {
          getData({ page: page });
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilter)
        try {
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFilter(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilter(false));
        }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagSort(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagSort(false));
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          dispatch(changePage(0));
          getData({ page: 0 });
          dispatch(changeFlagFresh(false));

          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFresh(false));
        }
    })();
  }, [flagFresh]);

  const handleNavigateMap = async (item: any) => {
    // them get VT cho nay

    dispatch(changeVisibleSpinner(true));
    dispatch(changeShowCriteria(false));

    let query = item?.query;

    if (query?.datetime) {
      let sun: any = query?.datetime?.split("/");

      if (sun?.[0] && sun?.[0] !== "..") {
        dispatch(changeStartAt(new Date(sun?.[0])));
      }
      if (sun?.[1] && sun?.[1] !== "..") {
        dispatch(changeEndAt(new Date(sun?.[1])));
      }
    }
    if (query?.query?.platform) {
      const res = await dispatch(
        // @ts-ignore
        getProductTypeAsync()
      ).unwrap();

      dispatch(
        changeTypeVT(
          res?.data.filter((x: any) =>
            query?.query?.["platform"]["in"].includes(x.platform)
          )
        )
      );
    }
    if (query?.query?.["eo:cloud_cover"]) {
      dispatch(changeCloudCoverFrom(query?.query?.["eo:cloud_cover"]?.gte));
      dispatch(changeCloudCoverTo(query?.query?.["eo:cloud_cover"]?.lte));
    }

    dispatch(changeFlagFromHistory(true));
    dispatch(changeFlagFilterItem(true));
    dispatch(changeShowDrawerMainMap(true));

    dispatch(toggleDrawerDownload(false));
    dispatch(changeSateVisibleReadNoti(false));
    dispatch(toggleDrawerCart(false));
    dispatch(changeSateVisibleReadNoti(false));
    dispatch(toggleDrawerNoti(false));

    navigate("/main-map?history=" + item?.id);

    dispatch(changeVisibleSpinner(false));
  };

  return (
    <div className="px-6 py-6 !w-full div-list !relative">
      {loading && <LoadingListComp />}
      {!loading && visibleSpinner && <LoadingGlobalComp />}
      {!data?.length && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}
      {data?.length !== 0 && !loading && (
        <div>
          <CardHistorySearchComp
            data={data}
            handleNavigateMap={handleNavigateMap}
          />
        </div>
      )}
    </div>
  );
};

export default ListSearchHisPage;
