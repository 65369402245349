const updateToIndexedDb = (
  db: IDBDatabase,
  collectionName: string,
  key: string,
  data: any
) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(collectionName, "readwrite");
    const objectStore = transaction.objectStore(collectionName);
    const request = objectStore.get(key);
    request.onsuccess = () => {
      let res = request.result;
      res = {
        ...res,
        ...data,
      };
      const updateRequest = objectStore.put(res);
      updateRequest.onsuccess = () => {
        resolve(res);
      };
      updateRequest.onerror = (e) => {
        console.log(e, "err");
        reject(false);
      };
    };
    request.onerror = (e) => {
      console.log(e, "indexedDb err update");
      reject(false);
    };
  });
};

export default updateToIndexedDb;
