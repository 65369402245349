import * as React from "react";
import {
  Button,
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Tab,
  TabList,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  readGeoJson,
  readKml,
  kmlToGeoJSON,
  readZip,
  shpToGeoJSON,
} from "../../../utils/file/FileUtils";
import { useTranslation } from "react-i18next";

import { RootState } from "../../../store/store";
import { addFeature, changeActiveFeature } from "../../../store/drawSlice";

import { updateAlert } from "../../../store/notificationsSlice";
import { useSelector, useDispatch } from "react-redux";

import ShapeUpload from "./uploaddialog/Shape";
import GeojsonUpload from "./uploaddialog/Geojson";
import KmlUpload from "./uploaddialog/Kml";

export default function UploadDialog(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let draw: any = useSelector((state: RootState) => state.draw?.draw);

  const [selectedValue, setSelectedValue] = React.useState<TabValue>("shape");

  const [shapeFile, setShapeFile] = React.useState<any>(null);
  const [geojsonFile, setGeojsonFile] = React.useState<any>(null);
  const [kmlFile, setKmlFile] = React.useState<any>(null);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };
  const onClose = () => {
    props.onClose();
  };

  const onChangeShapeFile = (file: File) => {
    setShapeFile(file);
  };

  const onChangeGeojsonFile = (file: File) => {
    setGeojsonFile(file);
  };

  const onChangeKmlFile = (file: File) => {
    setKmlFile(file);
  };

  const readFile = () => {
    switch (selectedValue) {
      case "shape":
        readShapeFile(shapeFile);
        break;
      case "geojson":
        readGeojsonFile(geojsonFile);
        break;
      case "kml":
        readKmlFile(kmlFile);
        break;
    }
    onClose();
  };

  const readShapeFile = async (file: File) => {
    try {
      let zipContent = await readZip(file);
      if (zipContent) {
        //@ts-ignore
        let geojson = await shp(zipContent);
        if (geojson && geojson.features) {
          //@ts-ignore
          let feature = geojson?.features.length ? geojson?.features[0] : null;
          if (feature) {
            feature.id = selectedValue + "_" + new Date().getTime();
            addGeojson2Map(feature);
          }
        }
      }
    } catch (ex) {
      dispatch(
        updateAlert({
          show: true,
          title: "Error",
          message: ex,
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };
  const readGeojsonFile = async (file: File) => {
    try {
      let geojson = await readGeoJson(file);

      if (geojson?.geoJSON?.features) {
        let feature = geojson?.geoJSON?.features.length
          ? geojson?.geoJSON?.features[0]
          : null;
        if (feature) {
          feature.id = selectedValue + "_" + new Date().getTime();

          addGeojson2Map(feature);
        }
      }
    } catch (ex) {
      dispatch(
        updateAlert({
          show: true,
          title: "Error",
          message: ex,
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };
  const readKmlFile = async (file: File) => {
    try {
      let kmlContent = await readKml(file);
      if (kmlContent) {
        let geojson: any[] = kmlToGeoJSON(kmlContent);
        if (geojson && geojson.length) {
          //@ts-ignore
          let feature = geojson[0]?.features.length
            ? geojson[0]?.features[0]
            : null;
          if (feature) {
            feature.id = selectedValue + "_" + new Date().getTime();
            addGeojson2Map(feature);
          }
        }
      }
    } catch (ex) {
      dispatch(
        updateAlert({
          show: true,
          title: "Error",
          message: ex,
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
  };

  const addGeojson2Map = (feature: string) => {
    if (draw) {
      try {
        draw.add(feature);
        dispatch(addFeature(feature));
        dispatch(changeActiveFeature(feature));
      } catch (error) {
        dispatch(
          updateAlert({
            show: true,
            title: t("Error"),
            message: t("Canot add to map"),
            buttons: {
              close: true,
              yes: false,
              no: false,
            },
          })
        );
      }
    }
  };

  const renderTabs = () => {
    return (
      <>
        <Tab key={"shape"} value={"shape"}>
          {t("Shape")}
        </Tab>
        <Tab key={"geojson"} value={"geojson"}>
          {t("Geojson")}
        </Tab>
        <Tab key={"kml"} value={"kml"}>
          {t("Kml")}
        </Tab>
      </>
    );
  };
  const renderContentTabs = () => {
    return (
      <div>
        {selectedValue === "shape" && (
          <ShapeUpload
            file={shapeFile}
            onChange={(file: File) => onChangeShapeFile(file)}
          />
        )}
        {selectedValue === "geojson" && (
          <GeojsonUpload
            file={geojsonFile}
            onChange={(file: File) => onChangeGeojsonFile(file)}
          />
        )}
        {selectedValue === "kml" && (
          <KmlUpload
            file={kmlFile}
            onChange={(file: File) => onChangeKmlFile(file)}
          />
        )}
      </div>
    );
  };
  return (
    <Dialog open={props.open}>
      <DialogSurface className="!bg-s-background1">
        <DialogBody>
          <DialogTitle>{t("Upload AOI")}</DialogTitle>
          <DialogContent>
            <TabList
              selectedValue={selectedValue}
              onTabSelect={onTabSelect}
              size="small"
            >
              {renderTabs()}
            </TabList>
            {renderContentTabs()}
          </DialogContent>
          <DialogActions position="end">
            <Button
              onClick={() => onClose()}
              className="!bg-s-background1"
              appearance="secondary"
            >
              {t("Close")}
            </Button>
            <Button
              onClick={() => readFile()}
              className="!bg-primary-500 !text-black dark:!text-white"
              appearance="primary"
            >
              {t("Upload")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
