import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteCollections,
  deleteItemCollections,
  editCollections,
  editItemCollections,
  getFeaturedById,
  getItemCollectionById,
  getItemsByCollectionId,
  getItemsByCollectionIdFilter,
  getItemsCollectionByPath,
  getList,
  getProductType,
  getThumbnailDataset,
  getThumbnailItemCollection,
  getThumbnailItemDataset,
  postCollections,
  addItemCollections as postItemCollections,
  search,
  uploadImgCollections,
  validateCollections,
  validateItemCollections,
} from "./../services/explore/exploreServices";

export interface ExploreDatasetState {
  status: string;
  danhSach: Array<any>;
  item: any;
  itemSearch: any;
  itemFeatured: any;
  statusValidate: any;

  start_at: any;
  end_at: any;
  cover_cloud: Array<any>;
  typeVT: Array<any>;
  cloudCoverFrom: number;
  cloudCoverTo: number;
  itemViewInfor: any;
  idCollection?: string;
  idCollectionStorage?: string;

  arrPathNextPage?: any;
  flagFilterItem?: boolean;
  flagRefeshItem?: boolean;
  flagFromHistory?: boolean;

  allowIntersect: boolean;
  coordinates: Array<any>;

  itemHover: any;
  flagReloadCart?: boolean;

  idSearch?: any
}
const initialState: ExploreDatasetState = {
  status: "ilde",
  danhSach: [],
  item: {},
  itemSearch: {},
  itemFeatured: {},
  statusValidate: false,

  start_at: null,
  end_at: null,
  cover_cloud: [0, 100],
  typeVT: [],
  cloudCoverFrom: 0,
  cloudCoverTo: 100,
  itemViewInfor: {},
  idCollection: undefined,
  idCollectionStorage: undefined,
  arrPathNextPage: [],

  flagFilterItem: false,
  flagRefeshItem: false,
  flagFromHistory: false,

  // filter dataset in map
  allowIntersect: false,
  coordinates: [],

  // horver footprint
  itemHover: null,
  flagReloadCart: false,

  idSearch: null
};

export const getProductTypeAsync = createAsyncThunk(
  "exploredataset/getProductType",
  async () => {
    try {
      const response = await getProductType();

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getItemsCollectionByPathAsync = createAsyncThunk(
  "exploredataset/getItemsCollectionByPath",
  async (payload) => {
    try {
      const response = await getItemsCollectionByPath(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const uploadImgCollectionsAsync = createAsyncThunk(
  "exploredataset/uploadImgCollections",
  async (payload: any) => {
    try {
      const response = await uploadImgCollections(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getThumbnailDatasetAsync = createAsyncThunk(
  "exploredataset/getThumbnailDataset",
  async (payload) => {
    try {
      const response = await getThumbnailDataset(payload);

      return response.data;
    } catch (e) {
      return {};
    }
  }
);

export const getThumbnailItemDatasetAsync = createAsyncThunk(
  "exploredataset/getThumbnailItemDataset",
  async (payload) => {
    try {
      const response = await getThumbnailItemDataset(payload);

      return response.data;
    } catch (e) {
      return {};
    }
  }
);

export const getListAsync = createAsyncThunk(
  "exploredataset/getList",
  async (payload) => {
    try {
      const response = await getList(payload);

      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getThumbnailItemCollectionAsync = createAsyncThunk(
  "exploredataset/getThumbnailItemCollection",
  async (payload) => {
    try {
      const response = await getThumbnailItemCollection(payload);

      return response;
    } catch (e) {
      return [];
    }
  }
);
export const getItemCollectionByIdAsync = createAsyncThunk(
  "exploredataset/getItemCollectionById",
  async (payload) => {
    try {
      const response = await getItemCollectionById(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getItemsByCollectionIdAsync = createAsyncThunk(
  "exploredataset/getItemsByCollectionId",
  async (payload) => {
    try {
      const response = await getItemsByCollectionId(payload);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const getItemsByCollectionIdFilterAsync = createAsyncThunk(
  "exploredataset/getItemsByCollectionIdFilter",
  async (payload: any) => {
    try {
      const response = await getItemsByCollectionIdFilter(payload);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const getFeaturedByIdAsync = createAsyncThunk(
  "exploredataset/getFeaturedById",
  async (id: string) => {
    try {
      const response = await getFeaturedById(id);

      return response;
    } catch (e) {
      return [];
    }
  }
);

export const searchAsync = createAsyncThunk(
  "exploredataset/search",
  async (payload: any) => {
    try {
      const response = await search(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const validateCollectionsAsync = createAsyncThunk(
  "exploredataset/validateCollections",
  async (payload: any) => {
    const parsedObject = JSON.parse(payload);
    const response = await validateCollections(parsedObject);
    return response;
  }
);

export const deleteCollectionsAsync = createAsyncThunk(
  "exploredataset/deleteCollections",
  async (payload: any) => {
    const response = await deleteCollections(payload);
    return response;
  }
);

export const postCollectionsAsync = createAsyncThunk(
  "exploredataset/postCollections",
  async (payload: any) => {
    const parsedObject = JSON.parse(payload);
    const response = await postCollections(parsedObject);
    return response;
  }
);

export const editCollectionsAsync = createAsyncThunk(
  "managestorage/editCollections",
  async (payload: any) => {
    const newPay = {
      id: payload?.id,
      value: JSON.parse(payload?.value),
    };
    const response = await editCollections(newPay);
    return response;
  }
);

// item

export const validateItemCollectionsAsync = createAsyncThunk(
  "exploredataset/validateItemCollections",
  async (payload: any) => {
    const parsedObject = JSON.parse(payload);
    const response = await validateItemCollections(parsedObject);
    return response;
  }
);

export const deleteItemCollectionsAsync = createAsyncThunk(
  "exploredataset/deleteItemCollections",
  async (payload: any) => {
    const response = await deleteItemCollections(payload);
    return response;
  }
);

export const postItemCollectionsAsync = createAsyncThunk(
  "exploredataset/postItemCollections",
  async (payload: any) => {
    const parsedObject = JSON.parse(payload);
    const response = await postItemCollections(parsedObject);
    return response;
  }
);

export const editItemCollectionsAsync = createAsyncThunk(
  "managestorage/editItemCollections",
  async (payload: any) => {
    const newPay = {
      ...payload,

      dataItem: JSON.parse(payload?.dataItem),
    };

    const response = await editItemCollections(newPay);
    return response;
  }
);

export const exploreSlice = createSlice({
  name: "exploredataset",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      state.item = {};
      state.itemSearch = {};
      state.itemFeatured = {};
    },

    changeArrPathNextPage: (state, action) => {
      state.arrPathNextPage = action.payload;
    },

    changeFlagFilterItem: (state, action) => {
      state.flagFilterItem = action.payload;
    },

    changeFlagRefeshItem: (state, action) => {
      state.flagRefeshItem = action.payload;
    },
    changeItemViewInfor: (state, action) => {
      state.itemViewInfor = action.payload;
    },
    changeIdCollection: (state, action) => {
      state.idCollection = action.payload;
    },
    changeIdCollectionStorage: (state, action) => {
      state.idCollectionStorage = action.payload;
    },

    changeStateFilter: (state, action) => {
      state.start_at = null;
      state.end_at = null;
      state.cover_cloud = [0, 100];
      state.cloudCoverFrom = 0;
      state.cloudCoverTo = 100;
      state.typeVT = action.payload;
      state.flagFromHistory = false;
      state.idSearch = ''
    },
    changeFlagFromHistory: (state, action) => {
      state.flagFromHistory = action.payload;
    },

    changeStateStringValidate: (state, action) => {
      state.statusValidate = false;
    },

    changeStartAt: (state, action) => {
      state.start_at = action.payload;
    },

    changeEndAt: (state, action) => {
      state.end_at = action.payload;
    },

    changeCoverCloud: (state, action) => {
      state.cover_cloud = action.payload;
    },

    changeCloudCoverFrom: (state, action) => {
      state.cloudCoverFrom = action.payload;
    },

    changeCloudCoverTo: (state, action) => {
      state.cloudCoverTo = action.payload;
    },

    changeTypeVT: (state, action) => {
      state.typeVT = action.payload;
    },

    changeAllowIntersect: (state, action) => {
      state.allowIntersect = action.payload ?? false;
    },

    changeCoordinates: (state, action) => {
      state.coordinates = action.payload ?? [];
    },

    changeItemHover: (state, action) => {
      state.itemHover = action.payload ?? null;
    },

    changeFlagReloadCart: (state, action) => {
      state.flagReloadCart = action.payload ?? null;
    },

    changeIdSearch : (state, action) => {
      state.idSearch = action.payload ?? null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.danhSach = action.payload?.data?.collections;
      })
      //getItemsByCollectionId
      .addCase(getItemsByCollectionIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getItemsByCollectionIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // @ts-ignore
        state.item = action.payload?.data;
      })
      // search
      .addCase(searchAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.itemSearch = action.payload;
      })
      // get featured
      .addCase(getFeaturedByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFeaturedByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // @ts-ignore
        state.itemFeatured = action.payload?.data;
      })
      // validate collection
      .addCase(validateCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(validateCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // @ts-ignore

        // @ts-ignore
        state.statusValidate = action?.payload?.valid;
      })
      // post collection
      .addCase(postCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      // edit collection
      .addCase(editCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // delete collection
      .addCase(deleteCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      //item
      // validate item collection
      .addCase(validateItemCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(validateItemCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.statusValidate = action?.payload?.valid;
      })

      // post item collection
      .addCase(postItemCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postItemCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // edit item collection
      .addCase(editItemCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editItemCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // delete item collection
      .addCase(deleteItemCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteItemCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const {
  changeIdSearch,
  changeArrPathNextPage,
  changeSate,
  changeStateStringValidate,
  changeCoverCloud,
  changeEndAt,
  changeStartAt,
  changeStateFilter,
  changeTypeVT,
  changeCloudCoverFrom,
  changeCloudCoverTo,
  changeItemViewInfor,
  changeIdCollection,
  changeIdCollectionStorage,
  changeFlagFilterItem,
  changeFlagRefeshItem,
  changeFlagFromHistory,
  changeAllowIntersect,
  changeCoordinates,
  changeItemHover,
  changeFlagReloadCart,
} = exploreSlice.actions;

export default exploreSlice.reducer;
