import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import React from "react";
import {
  changeCreatedFrom,
  changeCreatedTo,
} from "../../store/ManageSearchHis";

const arrStatus = ["All", "Accept", "Reject", "Submit", "Cancel"];

const arrStatusAdmin = ["All", "Accept", "Reject", "Submit"];

const FilterSearchHistoryComp = (props: any) => {
  const dispatch = useDispatch();

  const { created_from, created_to } = useSelector(
    (state: RootState) => state.managesearchhis
  );

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = React.useCallback(
    (newValue: string): Date => {
      const previousValue = created_from || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [created_from]
  );

  const onParseDateFromStringEndAt = React.useCallback(
    (newValue: string): Date => {
      const previousValue = created_to || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [created_to]
  );

  const changeTime = (date: any, type: string) => {
    if (type === "created_from") {
      if (date) {
        dispatch(changeCreatedFrom(date));
      } else {
        dispatch(changeCreatedFrom(null));
      }
    }
    if (type === "created_to") {
      {
        if (date) {
          dispatch(changeCreatedTo(date));
        } else {
          dispatch(changeCreatedTo(null));
        }
      }
    }
  };
  return (
    <>
      <div>
        <p className="mb-2 mt-2">Create from</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          parseDateFromString={onParseDateFromStringStartAt}
          value={created_from}
          onSelectDate={(date: any) => changeTime(date, "created_from")}
          // @ts-ignore
          maxDate={created_to}
        />
        <p className="mb-2 mt-4">Create to</p>
        <DatePicker
          formatDate={onFormatDate}
          allowTextInput
          placeholder="dd/mm/yyyy"
          className="div-dropdown"
          value={created_to}
          onSelectDate={(date: any) => changeTime(date, "created_to")}
          parseDateFromString={onParseDateFromStringEndAt}
          // @ts-ignore
          minDate={created_from}
        />
      </div>
    </>
  );
};

export default FilterSearchHistoryComp;
