import { Toaster } from "@fluentui/react-components";
import App from "./App";
import { RootState } from "./store/store";
import { useSelector } from "react-redux";
import { horizontal, vertical } from "./utils/constants";

const Wrapper = () => {
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  return (
    <>
      <App />
      <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} />
    </>
  );
};
export default Wrapper;
