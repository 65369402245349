import { Dropdown, Field, Option } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { TimePicker } from "@fluentui/react-timepicker-compat";
import { getTaskTypeAsync } from "../../store/ManageScheduler";

import {
  changeEnable,
  changeOneTimeOnly,
  changeSelectedDateFrom,
  changeSelectedDateTo,
  changeSelectedTimeFrom,
  changeSelectedTimeTo,
  changeTaskType,
  changeTimePickerValueFrom,
  changeTimePickerValueTo,
} from "../../store/ManageScheduler";

const FilterTaskComp = (props: any) => {
  const {
    selectedDateFrom,
    timePickerValueFrom,
    selectedDateTo,
    timePickerValueTo,
    taskType,
    enable,
    oneTimeOnly,
  } = useSelector((state: RootState) => state.managescheduler);

  const dispatch = useDispatch();

  const [arrTaskType, setArrTaskType] = useState<any>([]);

  useEffect(() => {
    (async function () {
      try {
        let res;
        res = await dispatch(
          // @ts-ignore
          getTaskTypeAsync()
        ).unwrap();

        setArrTaskType(res ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const onOptionSelectType = (e: any, data: any, type: string) => {
    if (type === "task_type") {
      dispatch(changeTaskType(data?.selectedOptions));
    } else if (type === "enable") dispatch(changeEnable(data?.optionValue));
    else if (type === "one_time_only")
      dispatch(changeOneTimeOnly(data?.optionValue));
  };

  const getErrorMessage = (error?: any) => {
    switch (error) {
      case "invalid-input":
        return "Invalid time format. Please use the 24-hour format HH:MM.";
      case "out-of-bounds":
        return "Time out of the 10:00 to 19:59 range.";
      // case "required-input":
      //   return "Time is required.";
      default:
        return "";
    }
  };

  const [errorType, setErrorType] = useState<any>();
  const handleTimeChange = (_ev: any, data: any, type: string) => {
    setErrorType(data.errorType);
    if (type === "to") {
      // console.log(moment(data?.selectedTime).utc());
      // console.log(moment(data?.selectedTime).utcOffset(0, true).toISOString());
      // console.log(moment(data?.selectedTime).toISOString());
      dispatch(changeSelectedTimeTo(data?.selectedTime));
      dispatch(changeTimePickerValueTo(data?.selectedTimeText));
    } else {
      // console.log(moment(data?.selectedTime).utc());
      // console.log(moment(data?.selectedTime).utcOffset(0, true).toISOString());
      // console.log(moment(data?.selectedTime).toISOString());
      dispatch(changeSelectedTimeFrom(data?.selectedTime));
      dispatch(changeTimePickerValueFrom(data?.selectedTimeText));
    }
  };

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const changeTime = (date: any, type: string) => {
    if (type === "from") {
      if (date) {
        dispatch(changeSelectedDateFrom(date));
      } else {
        dispatch(changeSelectedDateFrom(null));
      }
    }
    if (type === "to") {
      {
        if (date) {
          dispatch(changeSelectedDateTo(date));
        } else {
          dispatch(changeSelectedDateTo(null));
        }
      }
    }
  };

  return (
    <>
      <div>
        {/* <p className="mb-2">Keyword</p>
        <Input
          className="div-dropdown"
          placeholder="-- Enter keyword --"
          value={keywordFilter}
          onChange={(e: any) => {
            dispatch(changeKeywordFilter(e?.target?.value));
          }}
        /> */}

        <p className="mb-2 mt-4">Task type</p>
        <Dropdown
          placeholder="Select type"
          className="div-dropdown"
          multiselect
          value={taskType?.length > 0 ? taskType?.join(", ") : null}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelectType(e, data, "task_type")
          }
          selectedOptions={taskType ?? []}
        >
          {arrTaskType?.map((option: string) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>

        <p className="mb-2 mt-4">Start date</p>
        <div className="flex justify-between gap-2 items-center w-full mb-3">
          <DatePicker
            formatDate={onFormatDate}
            allowTextInput
            placeholder="dd/mm/yyyy"
            className="div-datepicker-cus !w-[49%]"
            value={selectedDateFrom}
            onSelectDate={(date: any) => changeTime(date, "from")}
            //   parseDateFromString={onParseDateFromStringEndAt}
            maxDate={selectedDateTo}
          />
          <DatePicker
            formatDate={onFormatDate}
            allowTextInput
            placeholder="dd/mm/yyyy"
            className="div-datepicker-cus !w-[49%]"
            //   parseDateFromString={onParseDateFromStringStartAt}
            value={selectedDateTo}
            onSelectDate={(date: any) => changeTime(date, "to")}
            minDate={selectedDateFrom}
          />
        </div>

        <p className="mb-2 mt-4">Start time</p>
        <div className="flex justify-between gap-2 items-center w-full mb-3">
          <Field label={""} validationMessage={getErrorMessage(errorType)}>
            <TimePicker
              className="div-datepicker-cus !min-w-[49%]"
              freeform
              startHour={0}
              endHour={24}
              onTimeChange={(e: any, data: any) =>
                handleTimeChange(e, data, "from")
              }
              placeholder="hh:mm:ss"
              hourCycle={"h23"}
              showSeconds
              value={timePickerValueFrom}
            />
          </Field>
          <Field label={""} validationMessage={getErrorMessage(errorType)}>
            <TimePicker
              className="div-datepicker-cus !min-w-[49%]"
              freeform
              startHour={0}
              endHour={24}
              onTimeChange={(e: any, data: any) =>
                handleTimeChange(e, data, "to")
              }
              placeholder="hh:mm:ss"
              hourCycle={"h23"}
              showSeconds
              value={timePickerValueTo}
            />
          </Field>
        </div>

        <p className="mb-2 mt-4">Enabled</p>
        <Dropdown
          placeholder="Select type"
          className="div-dropdown"
          value={enable}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelectType(e, data, "enable")
          }
          selectedOptions={[enable]}
        >
          {["True", "False", "None"]?.map((option: any) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Dropdown>
        <p className="mb-2 mt-4">One Time Only</p>
        <Dropdown
          placeholder="Select type"
          className="div-dropdown"
          value={oneTimeOnly}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelectType(e, data, "one_time_only")
          }
          selectedOptions={[oneTimeOnly]}
        >
          {["True", "False", "None"]?.map((option: any) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Dropdown>
      </div>
    </>
  );
};

export default FilterTaskComp;
