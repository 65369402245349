import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  addDownloadStorage,
  delDownloadStorage,
  expireSession,
  getDownload,
  getDownloadStorage,
  getSessionDownload,
  headDownload,
  initSessionDownload,
  updDownloadStorage,
} from "./../services/Download/DownloadService";

export interface DownloadState {
  selectedItems: Array<any>;
  percentDownload: number;
  loadingDownload: boolean;
  indexDownload: { [key: string]: boolean };
  flagHaveSessionDownload: boolean;
  pageReloaded: boolean;
}

const initialState: DownloadState & { [key: string]: any } = {
  selectedItems: [],
  percentDownload: 0,
  loadingDownload: false,
  indexDownload: {},
  flagHaveSessionDownload: false,
  pageReloaded: false,
};

export const initSessionDownloadAsync = createAsyncThunk(
  "download/initSessionDownload",
  async (payload) => {
    try {
      const response = await initSessionDownload(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getDownloadAsync = createAsyncThunk(
  "download/getDownload",
  async (payload) => {
    try {
      const response = await getDownload(payload);
      return response;
    } catch (error: any) {
      // Error
      console.log(error, error.message, "download error");
      return {
        message: error.message,
      };
    }
  }
);

export const getSessionDownloadAsync = createAsyncThunk(
  "download/getSessionDownload",
  async (payload) => {
    try {
      const response = await getSessionDownload(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const headDownloadAsync = createAsyncThunk(
  "download/headDownload",
  async (payload) => {
    try {
      const response = await headDownload(payload);
      return response;
    } catch (e) {
      return false;
    }
  }
);

export const getDownloadStorageAsync = createAsyncThunk(
  "download/getDownloadStorage",
  async (payload) => {
    try {
      const response = await getDownloadStorage(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const addDownloadStorageAsync = createAsyncThunk(
  "download/addDownloadStorage",
  async (payload) => {
    try {
      const response = await addDownloadStorage(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const updDownloadStorageAsync = createAsyncThunk(
  "download/updDownloadStorage",
  async (payload) => {
    try {
      const response = await updDownloadStorage(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const expireSessionAsync = createAsyncThunk(
  "download/expireSession",
  async (payload) => {
    try {
      const response = await expireSession(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const delDownloadStorageAsync = createAsyncThunk(
  "manageorder/delDownloadStorage",
  async (payload) => {
    try {
      const response = await delDownloadStorage(payload);

      // Doi api delete xong, ket qua thi cha ve ngay nhung tren server van dang xoa
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const DownloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    changeSelectedItems: (state, action) => {
      state.selectedItems = action?.payload;
    },
    changeLoading: (state, action) => {
      state[`loading_${action.payload.item_id}`] = action.payload.value;
    },
    changeLoadingRequestDownloadButton: (state, action) => {
      state[`loading-request-download-button_${action.payload.item_id}`] =
        action.payload.value;
    },
    changeClickStartRequest: (state, action) => {
      state[`click_start_request_${action.payload.item_id}`] =
        action.payload.value;
    },
    changeLoadingButtonDownload: (state, action) => {
      state[`loading-button-download_${action.payload.item_id}`] =
        action.payload.value;
    },
    changeLoadingButton: (state, action) => {
      state[`loading-button_${action.payload.item_id}`] = action.payload.value;
    },
    changeVisibleBtnDownload: (state, action) => {
      state[`visible-btn-download_${action.payload.item_id}`] =
        action.payload.value;
    },
    changeVisibleBtnPause: (state, action) => {
      state[`visible-btn-pause_${action.payload.item_id}`] =
        action.payload.value;
    },
    changePreparing: (state, action) => {
      state[`preparing_${action.payload.item_id}`] = action.payload.value;
    },
    changePause: (state, action) => {
      state[`pause_${action.payload.item_id}`] = action.payload.value;
    },
    changePageReloaded: (state, action) => {
      state.pageReloaded = action.payload;
    },
    changePercentDownload: (state, action) => {
      localStorage.setItem(
        `percent-download_${action.payload.item_id}`,
        action.payload.value.toString()
      );
      state[`percent-download_${action.payload.item_id}`] =
        action.payload.value;
    },
    resetPercentDownload: (state, action) => {
      state[`percent-download_${action.payload.item_id}`] = 0;
    },

    changeIndexDownload: (state, action) => {
      state.indexDownload = {
        ...state.indexDownload,
        [action?.payload]: !state.indexDownload[action?.payload],
      };
    },

    changeLoadingDownload: (state, action) => {
      state.loadingDownload = action?.payload;
    },

    changeController: (state, action) => {
      state[`controller_${action.payload.item_id}`] = action.payload.value;
    },

    changeFlagHaveSessionDownload: (state, action) => {
      state.flagHaveSessionDownload = action?.payload;
    },

    // changeArrOrderDownload: (state, action) => {
    //   state.arrOrderDownload = action?.payload;
    // },
    addArrOrderDownloadRedux: (state, action) => {
      state[`order-download_${action.payload.item_id}`] = action.payload.value;
    },
    removeArrOrderDownloadRedux: (state, action) => {
      state[`order-download_${action.payload.item_id}`] = undefined;
    },
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const {
  changeSelectedItems,
  changeVisibleBtnDownload,
  changeVisibleBtnPause,
  changeLoadingButton,
  changePreparing,
  changeLoadingRequestDownloadButton,
  changeClickStartRequest,
  changeLoadingButtonDownload,
  changePause,
  changePageReloaded,
  changeLoading,
  changePercentDownload,
  resetPercentDownload,
  changeLoadingDownload,
  changeIndexDownload,
  changeFlagHaveSessionDownload,
  changeController,
  addArrOrderDownloadRedux,
  removeArrOrderDownloadRedux,
} = DownloadSlice.actions;

export default DownloadSlice.reducer;
