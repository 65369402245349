import { Button, Dropdown, Option, Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getListAsync as getCollectionStorage,
  scanCollectionStorageAsync,
} from "../../store/ManageCollectionStorageSlice";

import { changeIdCollectionStorage } from "../../store/exploreSlice";

const ScanCollectionComp = (props: any) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [storageId, setStorageId] = useState("");

  useEffect(() => {
    (async function () {
      try {
        const res = await dispatch(
          // @ts-ignore
          getCollectionStorage({
            collection_id: props?.idCollection,
          })
        ).unwrap();

        setData(res ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const scan = async () => {
    setLoading(true);

    const res = await dispatch(
      // @ts-ignore
      scanCollectionStorageAsync({
        storage_id: storageId,
        collection_id: props?.idCollection,
      })
    ).unwrap();

    if (res.status === 200) {
      setLoading(false);
      props?.close(res?.message ?? "Starting scan");
    } else {
      setLoading(false);
      props?.close("Scan fail");
    }
  };

  return (
    <>
      <center>
        <Spinner
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: loading ? "initial" : "none",
            zIndex: 9999,
          }}
        />
      </center>
      <div className="mb-4">
        <p className="mb-2">Collection's Storage</p>
        <Dropdown
          placeholder="-- Select --"
          className="div-dropdown"
          onOptionSelect={(e: any, data: any) => {
            setStorageId(data?.optionValue?.storage_id);
            dispatch(changeIdCollectionStorage(data?.optionValue?.storage_id));
          }}
        >
          {data?.map((item: any) => (
            <Option value={item} key={item?.id}>
              {`Storage: ` + item?.storage?.description}
            </Option>
          ))}
        </Dropdown>
      </div>
      {/* <div
        className="grid grid-cols-1 absolute bottom-3 w-11/12"
        id="action-button"
      >
        <Button
          appearance="outline"
          className="btn-apply"
          disabled={storageId === ""}
          onClick={() => scan()}
        >
          Scan
        </Button>
      </div> */}
    </>
  );
};

export default ScanCollectionComp;
