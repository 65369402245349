import { useEffect } from "react";
import { useDispatch } from "react-redux";
import MapPlugins from "../components/map/Map";
import { resetAllStateDrawer } from "../store/DrawerSlice";
import { changeName, changePath } from "../store/pageSlice";
import { resetState } from "../store/pagingSlice";
import { changeShowCriteria } from "../store/filterSlice";
import { changeFlagFilterItem } from "../store/exploreSlice";
export default function MainMap() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePath("/main-map"));
    dispatch(changeName("mainMap"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));

      dispatch(changeShowCriteria(false));
      dispatch(changeFlagFilterItem(false));
    };
  }, []);
  return <MapPlugins />;
}
