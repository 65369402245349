import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedDateScheduledJobFrom,
  changeSelectedDateScheduledJobTo,
  changeSelectedTimeScheduledJobFrom,
  changeSelectedTimeScheduledJobTo,
  changeTimePickerValueScheduledJobFrom,
  changeTimePickerValueScheduledJobTo,
} from "../../store/ManageScheduler";
import { RootState } from "../../store/store";
import { TimePickerWithDatePicker } from "../DatepickerCus";
import * as moment from "moment-timezone";
// moment.tz.setDefault("Etc/UTC");

const FliterTaskJobsComp = (props: any) => {
  const {
    timePickerValueScheduledJobFrom,
    selectedTimeScheduledJobFrom,
    selectedDateScheduledJobFrom,
    timePickerValueScheduledJobTo,
    selectedTimeScheduledJobTo,
    selectedDateScheduledJobTo,
  } = useSelector((state: RootState) => state.managescheduler);

  const dispatch = useDispatch();

  const onSelectDate = (date: any, type: string) => {
    if (type === "from") {
      if (date) {
        dispatch(changeSelectedDateScheduledJobFrom(date));
      } else {
        dispatch(changeSelectedDateScheduledJobFrom(null));
      }
    }
    if (type === "to") {
      {
        if (date) {
          dispatch(changeSelectedDateScheduledJobTo(date));
        } else {
          dispatch(changeSelectedDateScheduledJobTo(null));
        }
      }
    }
  };

  return (
    <>
      <div>
        <div className="w-full mt-2">
          <p className="mb-2 mt-4">Scheduled from</p>
          <TimePickerWithDatePicker
            timePickerValue={timePickerValueScheduledJobFrom}
            onChangeTime={(data: any) => {
              dispatch(changeSelectedTimeScheduledJobFrom(data?.selectedTime));
              dispatch(
                changeTimePickerValueScheduledJobFrom(
                  data?.selectedTimeText ?? ""
                )
              );
            }}
            selectedTime={selectedTimeScheduledJobFrom}
            selectedDate={selectedDateScheduledJobFrom}
            onInput={(e: any) => {
              if (e?.target?.value)
                dispatch(
                  changeTimePickerValueScheduledJobFrom(e?.target?.value)
                );
            }}
            //date-picker
            onSelectDate={(date: any) => onSelectDate(date, "from")}
          />
        </div>
        <div className="w-full">
          <p className="mb-2">Scheduled to</p>
          <TimePickerWithDatePicker
            timePickerValue={timePickerValueScheduledJobTo}
            onChangeTime={(data: any) => {
              dispatch(changeSelectedTimeScheduledJobTo(data?.selectedTime));
              dispatch(
                changeTimePickerValueScheduledJobTo(
                  data?.selectedTimeText ?? ""
                )
              );
            }}
            selectedTime={selectedTimeScheduledJobTo}
            selectedDate={selectedDateScheduledJobTo}
            onInput={(e: any) => {
              if (e?.target?.value)
                dispatch(changeTimePickerValueScheduledJobTo(e?.target?.value));
            }}
            //date-picker
            onSelectDate={(date: any) => onSelectDate(date, "to")}
          />
        </div>
      </div>
    </>
  );
};

export default FliterTaskJobsComp;
