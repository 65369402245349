import { Toast, ToastBody, ToastTitle } from "@fluentui/react-components";
import React from "react";

const ToastComp = (props: any) => {
  return (
    <Toast className="!bg-[#1a2030]">
      <ToastTitle>{props?.message}</ToastTitle>
      {/* <ToastBody>
        <p>{props?.message}</p>
      </ToastBody> */}
    </Toast>
  );
};

export default ToastComp;
