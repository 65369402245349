import {
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import Editor from "@monaco-editor/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import {
  changeStateStringValidate,
  postCollectionsAsync,
  uploadImgCollectionsAsync,
  validateCollectionsAsync,
} from "./../../store/exploreSlice";

const dataBread = [
  {
    key: 0,
    value: "Dataset",
    path: "manage-dataset",
  },
  {
    key: 2,
    value: "Create",
    path: "",
  },
];

const CreateDatasetPage = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();

  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(null);

  const { statusValidate, status } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const [value, setValue] = useState(undefined);
  const [state, setState] = useState(!statusValidate ? "validate" : "post");
  const customTheme = {
    base: "vs-dark",
    inherit: true,
    rules: [
      {
        token: "comment",
        foreground: "#5d7988",
        fontStyle: "italic",
      },
      { token: "constant", foreground: "#e06c75" },
    ],
    colors: {
      "editor.background": "#21252b",
    },
  };

  useEffect(() => {
    // @ts-ignore
    monaco.editor.defineTheme("my-custom-theme", customTheme);
    monaco.editor.setTheme("my-custom-theme");
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(changeStateStringValidate());
    };
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-dataset/add"));
    dispatch(changeName("manage-dataset/add"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  const handleEditorChange = (value: any, event: any) => {
    setValue(value);
    setState("validate");
    // @ts-ignore
    dispatch(changeStateStringValidate());
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (result: any, type?: string) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>
          {state === "validate" && type !== "upload" && (
            <p>
              {result?.status === 200
                ? "Validate successfully"
                : "Validate fail"}
            </p>
          )}
          {state === "post" && type !== "upload" && (
            <p>{result === 200 ? "Add successfully" : "Add fail"}</p>
          )}

          {type === "upload" && <p>{result}</p>}
        </ToastTitle>
      </Toast>,
      { timeout: timeout, position: "bottom", intent: "success" }
    );
  };

  const handleValidate = () => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    dispatch(validateCollectionsAsync(value))
      .then(unwrapResult)
      .then((result: any) => {
        setState(result?.status === 200 ? "post" : "validate");
        notify(result, "success");
        setTimeout(() => {
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }, 1500);
      })
      .catch((error: any) => {
        console.error(error);
        notify("Error", "error");
        setTimeout(() => {
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }, 1500);
      });
  };

  const handleFinish = async () => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(postCollectionsAsync(value)).unwrap();

    if (res?.status === 200) {
      setValue(undefined);
      setState("validate");
      // @ts-ignore
      dispatch(changeStateStringValidate());
      setId(res?.data?.id);

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const resUpload = await dispatch(
          // @ts-ignore
          uploadImgCollectionsAsync({
            id: res?.data?.id,
            formData,
          })
        ).unwrap();
      }

      setTimeout(() => {
        notify(res?.status);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
        navigate(`/manage-dataset`);
        // @ts-ignore
      }, "2500");
    } else {
      setTimeout(() => {
        notify("Add collection fail");
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
        navigate(`/manage-dataset`);
        // @ts-ignore
      }, "2500");
    }
  };

  const [file, setFile] = useState<any>();

  const handleFileChange = async (e: any) => {
    // setLoading(false);
    if (e.target.files) {
      setFile(e.target.files[0]);

      // const formData = new FormData();
      // formData.append("file", e.target.files[0]);

      // const res = await dispatch(
      //   // @ts-ignore
      //   uploadImgCollectionsAsync({
      //     id: id,
      //     formData,
      //   })
      // ).unwrap();

      // if (res?.status === 201) {
      //   setLoading(false);
      //   notify(`Upload image for ${id} successfully`, "upload");
      // } else {
      //   setLoading(false);
      // }
    }
  };
  return (
    <>
      <div className="px-6 py-6 !w-full !relative">
        {loading && <LoadingGlobalComp />}
        <Editor
          height="calc(100vh - 230px)"
          defaultLanguage="json"
          defaultValue="// Attributes of dataset here"
          theme="my-custom-theme"
          onChange={handleEditorChange}
        />
        <div className="mt-4 float-right flex justify-between w-full">
          <input
            title="Upload image"
            id="file"
            type="file"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            onChange={handleFileChange}
            className="block w-full text-sm text-white
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-[4px] file:border-0
                                file:text-sm file:font-semibold
                                file:bg-white/80 file:text-ink-100
                                cursor-pointer
                                hover:file:bg-white/70 hover:file:cursor-pointer"
          />
          <div className="flex">
            <Button
              className="btn-back"
              title="Cancel"
              onClick={() => navigate(`/manage-dataset`)}
            />
            <Button
              appearance="primary"
              title={state === "post" ? "Add" : "Validate"}
              className="text-black !ml-3"
              onClick={state === "post" ? handleFinish : handleValidate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDatasetPage;
