import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
} from "@fluentui/react-breadcrumb-preview";
import * as React from "react";
import useCustomNavigate from "../../hook/useCustomNavigate";

const BreadcrumbComp = (props: any) => {
  const navigate = useCustomNavigate();
  return (
    <>
      <Breadcrumb
        aria-label="Small Breadcrumb"
        size="large"
        style={{
          ...props?.style,
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        {props?.data.map((item: any, index: any) => (
          <React.Fragment key={`item-${item.key}`}>
            <BreadcrumbItem>
              {item?.path ? (
                <BreadcrumbButton
                  className={index == 0 ? "!pl-0" : ""}
                  icon={item.icon}
                  current={props?.data.length - 1 === index}
                  style={{ fontSize: "14px" }}
                  onClick={() =>
                  {
                    
                    console.log(item.path, 'item.path')
                    navigate(item.path);
                  }}
                >
                  {item.value}
                </BreadcrumbButton>
              ) : (
                <BreadcrumbButton
                  className={index == 0 ? "!pl-0" : ""}
                  icon={item.icon}
                  current={props?.data.length - 1 === index}
                  style={{ fontSize: "14px" }}
                >
                  {item.value}
                </BreadcrumbButton>
              )}
            </BreadcrumbItem>
            {!(props?.data.length - 1 === index) && <BreadcrumbDivider />}
          </React.Fragment>
        ))}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbComp;
