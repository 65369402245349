import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

import NextSvg from "./../../assets/next.svg";

import moment from "moment";
import IconDefaultSvg from "../../assets/file-default.svg";
import IconHmlSvg from "../../assets/file-html.svg";
import IconPdfSvg from "../../assets/file-pdf.svg";
import IconXmlSvg from "../../assets/file-xml.svg";
import FolderSvg from "../../assets/folder.svg";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardItemBrowse = (props: any) => {
  const navigate = useCustomNavigate();

  const renderImgItem = (item: any) => {
    if (item?.is_dir) {
      return (
        <img src={FolderSvg} alt="img item" className="img-card-item-catalog" />
      );
    } else {
      let a = item?.mime_type;
      if (a === "application/pdf") {
        return (
          <img
            src={IconPdfSvg}
            alt="img item"
            className="img-card-item-catalog"
          />
        );
      } else if (a === "text/html") {
        return (
          <img
            src={IconHmlSvg}
            alt="img item"
            className="img-card-item-catalog"
          />
        );
      } else if (a === "text/xml") {
        return (
          <img
            src={IconXmlSvg}
            alt="img item"
            className="img-card-item-catalog"
          />
        );
      } else {
        return (
          <img
            src={IconDefaultSvg}
            alt="img item"
            className="img-card-item-catalog"
          />
        );
      }
    }
  };

  const renderTitleCard = (item: any) => {
    if (item?.resource?.FS?.path) {
      if (item?.resource?.FS?.path?.includes("/")) {
        return item?.resource?.FS?.path?.split("/")?.[
          item?.resource?.FS?.path?.split("/")?.length - 1
        ];
      } else {
        return item?.resource?.FS?.path;
      }
    } else if (item?.resource?.S3?.object) {
      let arrSplit = item?.resource?.S3?.object?.split("/");
      if (arrSplit[arrSplit?.length - 1] !== "")
        return arrSplit[arrSplit?.length - 1];
      else {
        return arrSplit[arrSplit?.length - 2];
      }
    }
  };

  return (
    <>
      {props?.data?.map((item: any, index: number) => {
        return (
          <section key={index} onClick={(e: any) => props?.handleClick(item)}>
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview className="div-center div-card-preview flex !flex-row">
                {renderImgItem(item)}
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <p
                        style={{ overflowWrap: "anywhere" }}
                        className="uppercase text-title-card ellipsis-text-one-line"
                      >
                        {renderTitleCard(item)}
                      </p>

                      {item?.modified && (
                        <p className="mt-1 ellipsis-text-one-line text-des">
                          {moment(item?.modified).format("DD/MM/YYYY HH:mm:ss")}
                        </p>
                      )}
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                {item?.is_dir && (
                  <div className="flex items-center">
                    <div
                      onClick={() =>
                        navigate(
                          `${document.location.pathname}/edit/${item?.id}`,
                          {
                            state: {
                              item: item,
                            },
                          }
                        )
                      }
                    >
                      <img
                        className="img-svg-icon"
                        src={NextSvg}
                        alt="Info Circle"
                      />
                    </div>
                  </div>
                )}
              </CardFooter>
            </Card>
          </section>
        );
      })}
    </>
  );
};

export default CardItemBrowse;
