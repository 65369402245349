import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingListComp from "../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../store/DrawerSlice";
import { changeVisibleSpinner } from "../store/NavSlice";
import { getListAsync, getThumbnailDatasetAsync } from "../store/exploreSlice";
import { changeName, changePath } from "../store/pageSlice";
import { resetState } from "../store/pagingSlice";
import { RootState } from "../store/store";
import EmptySvg from "./../assets/nodata.svg";
import CardItem from "./../components/CardItem";
import MyQuotasComp from "../components/ManageQuota/MyQuota";


const DatasetUserPage = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const { page, limit } = useSelector((state: RootState) => state.paging);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListAsync({
            size: limit,
            sortby: "modified",
            asc: false,
          })
        ).unwrap();

        let b: any = [];

        if (res?.collections?.length > 0) {
          for (let i = 0; i < res?.collections?.length; i++) {
            let resThumbnail;
            resThumbnail = await dispatch(
              // @ts-ignore
              getThumbnailDatasetAsync({
                collection_id: res?.collections?.[i]?.id,
              })
            ).unwrap();

            b.push({
              ...res?.collections?.[i],
              thumbnail: resThumbnail,
            });
          }
        }

        setData(b ?? []);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, [page]);

  useEffect(() => {
    dispatch(changePath("/dataset"));
    dispatch(changeName("dataset"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "Catalog",
          path: "dataset",
        },
      ])
    );
    return () =>
    {
      
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    return () =>
    {
     
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  return (
    <>
      <div className="px-6 py-6 !w-full div-list relative">
        {loading && <LoadingListComp />}

       
        {/* <div className="div-breadcrumb">
          <BreadcrumbComp data={dataBread} />
        </div> */}

        {data?.length === 0 && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div>
            <CardItem data={data} />
          </div>
        )}
      </div>
    </>
  );
};

export default DatasetUserPage;
