import * as React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Divider,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
const InfoMetadata = (props: any) => {

  const [openItems, setOpenItems] = useState([]);
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    /* @ts-ignore */

    setOpenItems(data.openItems);
  };

  const [arrGroup, setArrGroup] = useState([]);

  useEffect(() =>
  {
    if (props?.dataProperties)
    {
      let sun: any = handleGroupKey(props?.dataProperties);

      setArrGroup(sun);
    }
      
  }, [props?.dataProperties]);

  const handleGroupKey = (val: any) => {
    let result = {};

    Object?.keys(val)?.map((key) => {
      const arr = key?.split(":");
      if (arr.length === 2) {
        result = {
          ...result,
          [arr[0]]: {
            // @ts-ignore
            ...result[arr[0]],
            [arr[1]]: val[key],
          },
        };
      } else {
        result = {
          ...result,
          [key]: val[key],
        };
      }
    });

    let sun: any[] = [];
    let count: any = [0];
    let a = 0;

    Object?.keys(result)?.map((item) => {
      // @ts-ignore
      if (typeof result[item] === "object" && !Array.isArray(result[item])) {
        sun?.push({
          title: item,
          // @ts-ignore
          ...result[item],
        });
        count.push((a += 1));
      }
    });

    setOpenItems(count);

    return sun;
  };

  const renderArray = (obj: any[]) => {
    return (
      <div className="flex justify-end">
        {obj?.map((item: any, index: number) => {
          if (typeof item !== "object") {
            return (
              <span
                className="text-des text-ellipsis overflow-hidden"
                key={index}
              >
                {item}
                {index !== obj?.length - 1 ? ", " : ""}
              </span>
            );
          } else {
            return ""; // here!!!
          }
        })}
      </div>
    );
  };

  const renderObject = (
    key: any,
    obj: any,
    padding: any,
    isFlexCol: boolean = true
  ) => {
    return (
      <div className={"flex gap-y-3 w-full " + (isFlexCol ? "flex-col" : "")}>
        <span className="text-des">{key}:</span>

        {typeof obj !== "object" && !Array.isArray(obj) && (
          <span className=" text-right text-ellipsis overflow-hidden w-full">
            {obj}
          </span>
        )}
        {typeof obj === "object" && !Array.isArray(obj) && (
          <div className="flex flex-col pl-3 text-ellipsis overflow-hidden">
            {obj &&
              Object.keys(obj).map((k) =>
                renderObject(k, obj[k], padding + 1, false)
              )}
          </div>
        )}
        {typeof obj === "object" && Array.isArray(obj) && renderArray(obj)}
      </div>
    );
  };

  return (
    <div className="mb-4">
      <div className="grid grid-cols-2 gap-4 mb-6">
        {props?.data?.map((item: any, index: any) => (
          <div key={index}>
            <span className="flex text-des gap-x-1 text-xs">
              <img src={item?.icon} className="w-4 h-4 max-w-[16px]" />
              {item?.title}
            </span>
            <p className="text-sm ml-6 mt-1">{item?.content}</p>
          </div>
        ))}
      </div>
      <div>
        {/* @ts-ignore */}
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
          className="flex flex-col gap-y-4"
        >
          {arrGroup?.map((item: any, index: any) => (
            <AccordionItem
              className="!rounded !bg-[#2E3240]"
              value={index}
              key={index}
            >
              <AccordionHeader
                expandIconPosition="end"
                className=" text-des !bg-[#2E3240]"
              >
                {item?.title}
              </AccordionHeader>
              <AccordionPanel>
                <Divider className="!text-s-line1" />
                <div className="py-4 flex flex-col gap-y-2">
                  {/* @ts-ignore */}
                  {item &&
                    Object.keys(item).map((key: any, index: any) => {
                      if (key !== "title" && typeof item[key] !== "object") {
                        return (
                          <div className="flex flex-row w-full" key={index}>
                            <span className="text-des">{key}:</span>
                            <span className="text-right w-full text-ellipsis overflow-hidden">
                              &nbsp;{item[key]}
                            </span>
                          </div>
                        );
                      } else if (
                        key !== "title" &&
                        typeof item[key] === "object"
                      ) {
                        return renderObject(key, item[key], 1);
                      }
                    })}
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default InfoMetadata;
