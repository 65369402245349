import axios from "../../utils/axios";

export async function getSystemKey(payload: any) {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/settings/system_keys/`,
    {
      params: { ...payload },
    }
  );
}

export async function addConfig(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/system/settings/`,
    payload
  );
}

export async function getDetailConfig(payload: any) {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/system/settings/keys/${payload?.key}/`
  );
}

export async function updateConfig(payload: any) {
  
  const newPay = {
    description: payload?.description,
    enable: payload?.enable,
    value: payload?.value,
  };
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/system/settings/${payload?.key}/`,
    newPay
  );
}

export async function getTaskType() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/settings/system_keys/`
  );
}

export async function configAutoTask(payload: any) {
  
  //  action : STOP START
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/system/settings/auto_task/${payload?.taskType}/${payload?.action}/`
  );
}

export async function cleanImmeDlsessionExpired() {
//  clean dlsession expired data in manual tasks
  return axios.post(
    `${localStorage.getItem(
      "REACT_APP_ENDPOINT"
    )}/admin/download_sessions/clean_task/start/now/`
  );
}


export async function expireScansession() {
  //  expire scansession in manual tasks
  return axios.post(
    `${localStorage.getItem(
      "REACT_APP_ENDPOINT"
    )}/admin/download_sessions/clean_task/start/`
  );
}

