import { createSlice } from "@reduxjs/toolkit";

export const drawSlice = createSlice({
  name: "draw",
  initialState: {
    draw: undefined,
    isDrawing: false,
    drawingType: null,
    activeFeature: null,
    features: [],
    showDownloadAOI: false,
  },
  reducers: {
    setDraw: (state, action) => {
      state.draw = action?.payload;
    },
    setIsDrawing: (state, action) => {
      state.isDrawing = action?.payload;
    },
    setDrawingType: (state, action) => {
      state.drawingType = action?.payload;
    },
    addFeature: (state, action) => {
      // @ts-ignore
      state.features.push(action?.payload);
    },
    updateFeature: (state, action) => {
      const features: Object[] = state.features;
      // @ts-ignore
      let index = features?.findIndex((x) => x["id"] == action?.payload?.id);
      // @ts-ignore
      state.features[index] = action?.payload;
      // @ts-ignore
      if (state.activeFeature && state.activeFeature?.id == action?.payload?.id)
        state.activeFeature = action?.payload;
    },
    removeFeature: (state, action) => {
      const features: Object[] = state.features;
      // @ts-ignore
      let index = features?.findIndex((x) => x?.id == action?.payload?.id);
      if (index >= 0) state.features.splice(index, 1);
    },
    clearFeatures: (state) => {
      state.features = [];
      state.activeFeature = null;
    },
    changeActiveFeature: (state, action) => {
      state.activeFeature = action?.payload;
    },
    setShowDownloadAOI: (state, action) => {
      state.showDownloadAOI = action?.payload;
    },
  },
});
export const {
  setDraw,
  setIsDrawing,
  setDrawingType,
  addFeature,
  updateFeature,
  removeFeature,
  clearFeatures,
  changeActiveFeature,
  setShowDownloadAOI,
} = drawSlice.actions;

export default drawSlice.reducer;
