import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import { truncateString } from "../../utils/utils";
import DeleteSvg from "./../../assets/delete-dark.svg";
import EditSvg from "./../../assets/edit-dark.svg";
import EyeSvg from "./../../assets/info-circle.svg";
import NextSvg from "./../../assets/next.svg";

import LayerSvg from "./../../assets/dataset-admin.svg";
import SettingSvg from "./../../assets/layer-storage.svg";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";

import ScanThumbnailSvg from "../../assets/scan-thumbnail.svg";
import { changeStateVisibleDrawerInforDataset } from "../../store/DrawerSlice";
import {
  changeIdCollection,
  changeItemViewInfor,
} from "../../store/exploreSlice";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardDatasetAdmin = (props: any) => {
  // const navigate = useNavigate();
  const navigate = useCustomNavigate();

  const { visibleDrawerInforDataset } = useSelector(
    (state: RootState) => state.drawer
  );

  const dispatch = useDispatch();

  return (
    <>
      {props?.data?.map((item: any, index: any) => (
        <section
          key={index}
          onClick={() => navigate(`/manage-dataset/${item?.id}`)}
        >
          <div className={"rounded bg-[#2E3240]"}>
            <Card
              className={
                "card-item-storage cursor-pointer shadow-[none] !bg-[#2E3240]"
              }
              orientation="horizontal"
            >
              <CardPreview
                className={
                  `div-center div-card-preview`
                  // + (item?.thumbnail?.[0] ? " !bg-black" : "")
                }
                onClick={() => navigate(`/manage-dataset/${item?.id}`)}
              >
                <img
                  loading="lazy"
                  className={`!rounded-sm ${
                    item?.thumbnail?.[0]
                      ? " img-card-item-catalog-search-drawer"
                      : " img-card-item-catalog"
                  }
                     `}
                  src={
                    item?.thumbnail?.[0]
                      ? `${localStorage.getItem(
                          "REACT_APP_ENDPOINT"
                        )}/collections/${item?.id}/thumbnails/${
                          item?.thumbnail?.[0]
                        }/`
                      : LayerSvg
                  }
                  onError={(e: any) => {
                    e.target.src = LayerSvg;
                  }}
                  alt="img item"
                />
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <>
                    <div className="divHeader relative">
                      <div className="block w-full">
                        <Tooltip
                          relationship="description"
                          content={item?.title}
                        >
                          <p className="!uppercase text-title-card ellipsis-text-one-line">
                            {item?.title}
                          </p>
                        </Tooltip>

                        <Tooltip
                          relationship="description"
                          content={item?.description}
                        >
                          <p className="!mt-1 text-des ellipsis-text-one-line">
                            {truncateString(item?.description, 150)}
                          </p>
                        </Tooltip>
                      </div>

                      <div />
                    </div>
                  </>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/manage-dataset/${item?.id}`);
                }}
              />
              <CardFooter className="footer-card-action">
                <div
                  className="div-action-card"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="mr-3"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      navigate(`/manage-dataset/edit/${item?.id}`, {
                        state: { item: item },
                      });
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon"
                      src={EditSvg}
                      alt="Dismiss"
                    />
                  </div>
                  <div
                    className="mr-3"
                    onClick={async (e) => {
                      e.stopPropagation();
                      dispatch(changeItemViewInfor(item));
                      dispatch(changeIdCollection(item?.id));
                      dispatch(
                        changeStateVisibleDrawerInforDataset(
                          !visibleDrawerInforDataset
                        )
                      );
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon"
                      src={EyeSvg}
                      alt="Dismiss"
                    />
                  </div>

                  <div className="mr-3">
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="mr-2 img-svg-icon"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() => props?.deleteDataset(item?.id)}
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                    {/* <Popover>
                      <PopoverTrigger>
                        <img className="mr-2" src={DeleteSvg} alt="Dismiss" />
                      </PopoverTrigger>

                      <PopoverSurface aria-labelledby={item?.id}>
                        <h3>Are you sure?</h3>
                        <ButtonFlu
                          onClick={() => props?.deleteDataset(item?.id)}
                        >
                          Ok
                        </ButtonFlu>
                      </PopoverSurface>
                    </Popover> */}
                  </div>
                  {/* <Tooltip relationship="description" content="Scan thumbnail"> */}
                  <div className="mr-3">
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="img-svg-icon mr-2"
                          src={ScanThumbnailSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList onClick={() => props?.scanThumbnail(item)}>
                          <MenuItem>Scan thumbnail </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                  {/* </Tooltip> */}
                  <Tooltip
                    relationship="description"
                    content="Collection's storage"
                  >
                    <div
                      onClick={(e: any) => {
                        e.stopPropagation();
                        navigate(`/manage-dataset/setting/${item?.id}`, {
                          state: { item: item },
                        });
                      }}
                    >
                      <img
                        className="mr-2 img-svg-icon"
                        src={SettingSvg}
                        alt="Dismiss"
                      />
                    </div>
                  </Tooltip>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/manage-dataset/${item?.id}`);
                  }}
                >
                  <img
                    className="img-svg-icon"
                    src={NextSvg}
                    alt="Info Circle"
                  />
                </div>
              </CardFooter>
            </Card>
            {/* </Link> */}
          </div>
        </section>
      ))}
    </>
  );
};

export default CardDatasetAdmin;
