import {
  Card,
  CardHeader,
  CardPreview,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { useNavigate } from "react-router";

import JobSuccessSvg from "../../assets/job-success.svg";
import JobErrorSvg from "../../assets/job-error.svg";
import OrderPendingSvg from "./../../assets/job-pending.svg";

import moment from "moment";

const CardItemJobComp = (props: any) => {
  const renderStatus = (item: any) => {
    if (item?.start && !item?.completed && !item?.failed) {
      return (
        <img
          className="img-card-item-catalog"
          src={OrderPendingSvg}
          alt="img item"
        />
      );
    } else if (item?.scheduled && !item?.picked && !item?.started) {
      return (
        <img
          className="img-card-item-catalog"
          src={OrderPendingSvg}
          alt="img item"
        />
      );
    } else if (item?.scheduled && item?.picked && item?.started) {
      return !item?.result?.err_msg ? (
        <img
          className="img-card-item-catalog"
          src={item.completed ? JobSuccessSvg : OrderPendingSvg}
          alt="img item"
        />
      ) : (
        <Tooltip relationship="label" content={item?.result?.err_msg}>
          <img
            className="img-card-item-catalog"
            src={
              item?.failed
                ? JobErrorSvg
                : item.completed
                ? JobSuccessSvg
                : OrderPendingSvg
            }
            alt="img item"
          />
        </Tooltip>
      );
    }
  };

  const renderTime = (item: any) => {
    let a: any;
    if (item?.scheduled && !item?.picked && !item?.started) {
      a = `Next run at: ${moment(item?.scheduled).format(
        "DD/MM/YYYY HH:mm:ss"
      )}`;
    }
    if (item?.completed) {
      a = `Completed at:${moment(item?.completed).format(
        "DD/MM/YYYY HH:mm:ss"
      )}`;
    }

    if (item?.failed) {
      a = ` Failed at: ${moment(item?.failed).format("DD/MM/YYYY HH:mm:ss")}`;
    }
    if (item?.start && !item?.completed && !item?.failed) {
      a = `Running at: ${moment(item?.start).format("DD/MM/YYYY HH:mm:ss")}`;
    }

    return a;
  };
  return (
    <>
      {props?.data?.map((item: any, index: any) => (
        <section key={index}>
          <div className="div-card">
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview className="div-center div-card-preview">
                {renderStatus(item)}
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <Tooltip
                        relationship="description"
                        content={item?.task_name ?? "---"}
                      >
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.task_name ?? "---"}
                        </p>
                      </Tooltip>

                      <Tooltip
                        relationship="description"
                        content={"" + renderTime(item)}
                      >
                        <p className="mt-1 text-des ellipsis-text-one-line">
                          {renderTime(item)}
                        </p>
                      </Tooltip>
                    </div>

                    <div />
                  </div>
                }
              />
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItemJobComp;
