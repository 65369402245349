import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import
  {
    getHistoryScanByIdAsync,
    getListAsync as getListStorage,
    getScannedResourceAsync,
  } from "../../store/ManageStorageSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { changeTotal, resetState } from "../../store/pagingSlice";

import moment from "moment";
import CardInforHistoryScan from "../../components/ManageStorage/CardInforHistoryScan";
import CardScanedResourceComp from "../../components/ManageStorage/CardScanedResource";
import PieComp from "../../components/ManageStorage/Pie";

import UpdateIcon from "../../assets/update.svg";
import LoadingListComp from "../../components/Loading/LoadingList";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { RootState } from "../../store/store";
import DividerSvg from "./../../assets/line-divider.svg";
import NewIcon from "./../../assets/new.svg";

import ScannedIcon from '../../assets/scanned.svg';
import SkipIcon from '../../assets/skip.svg';

const HistoryScanViewDetailPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<any>({});
  const param = useParams();
  const dispatch = useDispatch();
  const [storageName, setStorageName] = useState("");

  const [dataResources, setDataResources] = useState<any>([]);

  const { page, limit } = useSelector((state: RootState) => state.paging);

  useEffect(() => {
    dispatch(changePath("/history-scan/id-item"));
    dispatch(changeName("history-scan/id-item"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getHistoryScanByIdAsync(
            window.location.pathname.split("/")?.[2] ?? param?.id
          )
        ).unwrap();

        setItem(res?.data ?? {});

        dispatch(
          changeDataBreadcrumb([
            {
              key: "history-scan",
              value: "History Scan",
              path: "history-scan",
            },
            {
              // @ts-ignore
              key: window.location.pathname.split("/")?.[2] ?? param?.id,
              // @ts-ignore
              value: moment(res?.data?.created).format("DD/MM/YYYY HH:mm:ss"),
            },
          ])
        );

        const resStorage = await dispatch(
          // @ts-ignore
          getListStorage({
            page: 0,
            limit: 10000,
          })
        ).unwrap();

        let a = resStorage?.result?.filter(
          (item: any) => res?.data?.storage_id === item?.id
        );

        setStorageName(a?.[0]?.name);

        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }

      return () => {
        dispatch(changeDataBreadcrumb([]));
        // @ts-ignore
        dispatch(resetState({}));
      };
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const resResources = await dispatch(
          // @ts-ignore
          getScannedResourceAsync({
            id: window.location.pathname.split("/")?.[2] ?? param?.id,
            page: page,
            size: limit,
          })
        ).unwrap();

        dispatch(changeTotal(resResources?.data?.paging?.total ?? 0));

        setDataResources(resResources?.data?.result ?? []);

        // setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        // setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, [page]);

  const renderHeaderPage = () => {
    return (
      <div className="flex gap-4">
        <span>
          <span className="text-color-des">Status: </span>
          <span
            className={
              item?.summary?.success ? "text-color-success" : "text-color-error"
            }
          >
            {item?.summary?.success ? "Success" : "Fail"}
          </span>
        </span>

        <img src={DividerSvg} />
        <span>
          <span className="text-color-des">Collection: </span>
          {item?.collection_id}
        </span>

        <img src={DividerSvg} />

        <span>
          <span className="text-color-des">Storage: </span>
          {storageName}
        </span>
      </div>
    );
  };

  const renderCardInfor = () => {
    const dataInfor = [
      {
        title: "Create time",
        value: moment(item?.created).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        title: "Expire time",
        value: moment(item?.expired).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        title: "Start time",
        value: moment(item?.summary?.started).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        title: "Finish time",
        value: moment(item?.summary?.finished).format("DD/MM/YYYY HH:mm:ss"),
      },
    ];
    return <CardInforHistoryScan data={dataInfor} />;
  };

  const dataSummary = [
    {
      title: "Scaned",
      value: item?.summary?.scaned,
      icon: ScannedIcon,
    },
    {
      title: "New",
      value: item?.summary?.nb_new,
      icon: NewIcon,
    },
    {
      title: "Update",
      value: item?.summary?.nb_update,
      icon: UpdateIcon,
    },
    {
      title: "Skip",
      value: item?.summary?.nb_skip,
      icon: SkipIcon,
    },
  ];

  const dataPie = {
    labels: ["Error", "Parsed"],
    datasets: [
      {
        data: [
          parseInt(item?.summary?.scaned) - parseInt(item?.summary?.parsed),
          item?.summary?.parsed,
        ],
        backgroundColor: ["#AA4644", "#4CC2FF"],
        borderColor: ["#AA4644", "#4CC2FF"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="px-6 py-6 !w-full !relative !overflow-auto">
      {loading && <LoadingListComp />}

      {!loading && (
        <>
          {renderHeaderPage()}
          {renderCardInfor()}
          <div className="w-full mb-8 lg:flex md:block">
            <div className=" lg:w-1/3  md:w-full ">
              <PieComp data={dataPie} />
            </div>

            <div className="lg:w-2/3  md:w-full">
              <CardInforHistoryScan data={dataSummary} />
            </div>
          </div>

          {!visibleSpinner ? (
            <CardScanedResourceComp data={dataResources ?? []} />
          ) : (
            <LoadingListComp />
          )}
        </>
      )}
    </div>
  );
};

export default HistoryScanViewDetailPage;
