import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
import moment from "moment";
import MapSvg from "./../../assets/map.svg";
import { useDispatch } from "react-redux";
import { getItemCollectionByIdAsync } from "../../store/exploreSlice";
import LayerSvg from "./../../assets/images/item-collection-default.png";
import { useEffect, useState } from "react";

const CardItemDetailOrderAdmin = (props: any) =>
{
  const dispatch = useDispatch();
  const onSelectedItem = async (e: any, item: any) => {
    e.preventDefault();
    if (!props?.selectedIds?.includes(item?.stac_item_id)) {
      const res = await dispatch(
        // @ts-ignore
        getItemCollectionByIdAsync({
          collectionId: item?.collection_id,
          itemId: item?.stac_item_id,
        })
      ).unwrap();

      if (res?.status !== 200) {
        props?.notify(res?.response?.data?.message, "error");
      } else {
        props.onSelectedItem(res?.data);
      }
    } else {
      props.onSelectedItem({ id: item?.stac_item_id });
    }
  };

  return (
    <>
      {/* <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} /> */}
      {props?.data?.map((item: any, index: number) => (
        <section key={index}>
          <div
            className="div-card"
            onMouseEnter={() => props.onMouseMoveItem(item)}
            onMouseLeave={() => props.onMouseLeaveItem(item)}
          >
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240] !cursor: default"
            >
              <CardPreview
                className={`div-center div-card-preview`}
                onClick={(e: any) => {
                  e.preventDefault();
                  props?.handleViewInfo(
                    item?.collection_id,
                    item?.stac_item_id
                  );
                }}
              >
                <img
                  loading='lazy'
                  className="img-card-item-catalog-search-drawer rounded-sm"
                  src={
                    item?.thumbnail?.[0]
                      ? `${localStorage.getItem(
                          "REACT_APP_ENDPOINT"
                        )}/collections/${item?.collection_id}/items/${
                          item?.stac_item_id
                        }/thumbnails/${item?.thumbnail?.[0]}/`
                      : LayerSvg
                  }
                  onError={(e: any) => {
                    e.target.src = LayerSvg;
                  }}
                />
              </CardPreview>

              <CardHeader
                onClick={(e: any) => {
                  e.preventDefault();
                  props?.handleViewInfo(
                    item?.collection_id,
                    item?.stac_item_id
                  );
                }}
                className="div-card-header"
                style={{ width: "calc(100% - 110px)" }}
                header={
                  <div className="divHeader relative">
                    <div className="block">
                      <Tooltip
                        relationship="description"
                        // @ts-ignore
                        content={item?.stac_item_id}
                      >
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.stac_item_id}
                        </p>
                      </Tooltip>

                      <Tooltip
                        relationship="description"
                        // @ts-ignore
                        content={
                          item?.collection_id +
                          " | " +
                          moment?.(item?.created).format("DD/MM/YYYY HH:mm:ss")
                        }
                      >
                        <div className="ellipsis-text-one-line">
                          <p className="items-center mt-1 text-des ">
                            {item?.collection_id}
                            <span className="border-right-divider" />

                            {moment?.(
                              item?.resItem?.properties?.datetime
                            ).format("DD/MM/YYYY HH:mm:ss")}
                          </p>
                        </div>
                      </Tooltip>
                    </div>

                    <div />
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <Tooltip
                  relationship="description"
                  content={"View item in map"}
                >
                  <Button
                    className={
                      "w-full " +
                      (props?.selectedIds?.includes(item?.stac_item_id)
                        ? "!bg-primary-500 !border-primary-500"
                        : "!bg-transparent !border-none")
                    }
                    iconPosition="before"
                    icon={
                      <img
                        className="img-svg-icon"
                        src={MapSvg}
                        alt="selected"
                      />
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      onSelectedItem(e, item);
                    }}
                  />
                </Tooltip>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItemDetailOrderAdmin;
