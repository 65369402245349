import axios from "../../utils/axios";

export async function getListSearchHis(payload: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/users/search_sessions/`, {
    params: { ...payload },
  });
}

export async function getSearchHisById(id: string) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/users/search_sessions/${id}/`);
}
