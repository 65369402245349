import { isNaN, round, flattenDeep, chunk } from 'lodash';
import Proj4js from 'proj4';
const proj4 = Proj4js;

export function convertCoorProjections(source: string, destination: string, coordinate: any) {
    let coor = proj4(source, destination, [coordinate.lng, coordinate.lat]);
    return { lng: coor[0], lat: coor[1] }
}


export function convertDDToDMS(D: any, lng: boolean, seconds: any) {

    // round to the smaller absolute integer value
    let d = D >= 0 ? Math.floor(D) : Math.ceil(D);

    let minFloat = Math.abs((D - d) * 60);
    let m = Math.floor(minFloat);
    let secFloat = (minFloat - m) * 60;
    let s = round(secFloat, seconds?.decimals ?? 4);
    d = Math.abs(d);

    if (s === 60) {
        m++;
        s = 0;
    }
    if (m === 60) {
        d++;
        m = 0;
    }

    if (isNaN(d) || D === "") {
        // reset the inputs
        return {
            degrees: "",
            minutes: "",
            seconds: "",
            direction: lng ? 'E' : 'N' // let's chose some default direction if coord is 0
        };
    }
    let values = {
        degrees: d,
        minutes: m,
        seconds: s,
        direction: D < 0 ? lng ? 'W' : 'S' : lng ? 'E' : 'N'
    };

    return values;
};

export function convertDMSToDD(degrees: any, minutes: any, seconds: any, direction: any) {
    let deg = 0;
    let min = 0;
    let sec = 0;
    if (degrees === undefined && minutes === undefined && seconds === undefined) {
        return undefined;
    }
    if (!isNaN(degrees)) {
        deg = degrees;
    }
    if (!isNaN(minutes)) {
        min = minutes;
    }
    if (!isNaN(seconds)) {
        sec = seconds;
    }

    // conversion dmsToDD
    let dd = deg + min / 60 + sec / (60 * 60);

    // this change is needed you have 0 as degrees and a negative minutes or seconds i.e direction swapping side is caused by minutes or seconds being negative
    // if (dd > 0 && (direction === 'S' || direction === 'W') || dd < 0 && (direction === 'N' || direction === 'E')) {
    //     dd = dd * -1;
    // } // Don't do anything for N or E
    return dd;
}

export function centroid(poly: { coordinates: Array<any> }) {
    var c = [0, 0];
    var ring = poly.coordinates[0];
    for (let i = 0; i < (ring.length - 1); i++) {
        c[0] += (ring[i][0] + ring[i + 1][0]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
        c[1] += (ring[i][1] + ring[i + 1][1]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    }
    var a = area(poly);
    c[0] /= a * 6;
    c[1] /= a * 6;
    return c;
}

export function area(poly: { coordinates: Array<any> }) {
    var s = 0.0;
    var ring = poly.coordinates[0];
    for (let i = 0; i < (ring.length - 1); i++) {
        s += (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    }
    return 0.5 * s;
}


/**
 * Calculates the extent for the geoJSON passed. It used a small buffer for points.
 * Like turf/bbox but works only with simple geometries.
 * @deprecated  We may replace it with turf/bbox + turf/buffer in the future, so using it with geometry is discouraged
 * @param {geoJSON|geometry} GeoJSON or geometry
 * @return {array} extent of the geoJSON
 */
// @ts-ignore
export function getGeoJSONExtent(geoJSON: any) {
    let newExtent: Array<any> = [Infinity, Infinity, -Infinity, -Infinity];
    const reduceCollectionExtent = (extent: any, collectionElement: any) => {
        // @ts-ignore
        let ext = getGeoJSONExtent(collectionElement);
        if (isValidExtent(ext)) {
            return extendExtent(ext, extent);
        }
        return ext;
    };
    if (geoJSON.coordinates) {
        if (geoJSON.type === "Point") {
            let point = geoJSON.coordinates;
            newExtent[0] = point[0] - point[0] * 0.01;
            newExtent[1] = point[1] - point[1] * 0.01;
            newExtent[2] = point[0] + point[0] * 0.01;
            newExtent[3] = point[1] + point[1] * 0.01;
        }
        // other kinds of geometry
        const flatCoordinates = chunk(flattenDeep(geoJSON.coordinates), 2);
        return flatCoordinates.reduce((extent: any, point: any) => {
            return [
                point[0] < extent[0] ? point[0] : extent[0],
                point[1] < extent[1] ? point[1] : extent[1],
                point[0] > extent[2] ? point[0] : extent[2],
                point[1] > extent[3] ? point[1] : extent[3]
            ];
        }, newExtent);

    } else if (geoJSON.type === "GeometryCollection") {
        let geometries = geoJSON.geometries;
        return geometries.reduce(reduceCollectionExtent, newExtent);
    } else if (geoJSON.type) {
        if (geoJSON.type === "FeatureCollection") {
            return geoJSON.features.reduce(reduceCollectionExtent, newExtent);
        } else if (geoJSON.type === "Feature" && geoJSON.geometry) {
            // @ts-ignore
            return getGeoJSONExtent(geoJSON.geometry);
        }
    }

    return newExtent;
};
/**
 * Check extent validity
 *
 * @param extent {array} [minx, miny, maxx, maxy]
 *
 * @return {bool}
 */
export const isValidExtent = function (extent: any) {
    return !(
        extent.indexOf(Infinity) !== -1 || extent.indexOf(-Infinity) !== -1 ||
        extent[0] > extent[2] || extent[1] > extent[3]
    );
};

/**
 * Extend an extent given another one
 *
 * @param extent1 {array} [minx, miny, maxx, maxy]
 * @param extent2 {array} [minx, miny, maxx, maxy]
 *
 * @return {array} [minx, miny, maxx, maxy]
 */
export const extendExtent = function (extent1: any, extent2: any) {
    let newExtent = extent1.slice();
    if (extent2[0] < extent1[0]) {
        newExtent[0] = extent2[0];
    }
    if (extent2[2] > extent1[2]) {
        newExtent[2] = extent2[2];
    }
    if (extent2[1] < extent1[1]) {
        newExtent[1] = extent2[1];
    }
    if (extent2[3] > extent1[3]) {
        newExtent[3] = extent2[3];
    }
    return newExtent;
};