import { useRef, useEffect, useState, useMemo } from "react";
import { Button, Checkbox, Spinner } from "@fluentui/react-components";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import MapItemSvg from "./../../assets/map-item.svg";
import { ButtonComponent } from "@web/react-component-ui-user";

import infoCircle from "../../assets/info-circle-drawer.svg";
import filterSvg from "../../assets/filter.svg";
import itemsCollectionSvg from "../../assets/images/items-collection.svg";

import { RootState } from "../../store/store";
import { updateAlert } from "../../store/notificationsSlice";
import {
  changeCallSearch,
  changeCriteria,
  clearCriteria,
  changeShowCriteria,
  changeShowResultSearch,
} from "../../store/filterSlice";
import {
  clearFeatures,
  setDraw,
  setShowDownloadAOI,
} from "../../store/drawSlice";
import { searchAsync, clearItems } from "../../store/filterSlice";
import {
  changeStateFilter,
  getProductTypeAsync,
  changeFlagFilterItem,
  getItemCollectionByIdAsync,
  getThumbnailItemDatasetAsync as getThumbnail,
} from "../../store/exploreSlice";

import { changeShowDrawerMainMap } from "../../store/DrawerSlice";

import DrawerComp from "./../../components/Drawer";
import MaplibreGL from "./maplibregl/Map";
import OpenlayersMap from "./openlayers/Map";
import InfoMetadata from "../InfoMetadata/index";
import FilterDatasetComp from "../FilterComponent/FilterDataset";
import ItemsCollection from "./ItemsCollection";
import DownloadAoi from "./DownloadAoi";

import RefreshSvg from "./../../assets/refresh.svg";
import ApplySvg from "./../../assets/accept.svg";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import { changeVisibleSpinner } from "../../store/NavSlice";
import LoadingListComp from "../Loading/LoadingList";

export default function MapPlugins(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mapChildRef = useRef();

  let { showCriteria, callSearch, searchText, items, criteria } = useSelector(
    (state: RootState) => state.filter
  );

  let featuresDraw: Array<any> = useSelector(
    (state: RootState) => state.draw?.features
  );
  let draw: any = useSelector((state: RootState) => state.draw?.draw);
  let activeFeature: any = useSelector(
    (state: RootState) => state.draw?.activeFeature
  );

  let featuresCart: any = useSelector(
    (state: RootState) => state.manageorder?.itemsMapSelected
  );

  let showDownloadAoi: any = useSelector(
    (state: RootState) => state.draw?.showDownloadAOI
  );

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  let [searchParams, setSearchParams] = useSearchParams({ history: "" });
  let historyId = searchParams.get("history");

  const [loading, setLoading] = useState(false);

  const [mapType, setMapType] = useState("maplibreGL");
  const [showInfoMetadata, setShowInfoMetadata] = useState(false);
  const [dataInfo, setDataInfo] = useState<any>(undefined);
  const [infoItem, setInfoItem] = useState<any>(undefined);
  // const [showItemsCollection, setShowItemsCollection] = useState(false);

  // change showItemsCollection to flagFilterItem in store redux

  // when user navigate from item in search history, this page auto show showItemsCollection

  // result of search api. To check next page
  const [featureCollection, setFeatureCollection] = useState<any>(null);

  // load ding
  const [drawerLoading, setDrawerLoading] = useState(false);

  // item add 2 map
  const [selected, setSelected] = useState<Array<any>>(
    props?.items ? [...props?.items] : []
  );

  // criteria search
  const [searchCriteria, setSearchCriteria] = useState<any>(undefined);
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPageSize] = useState<number>(0);
  const [satellites, setSatellites] = useState<Array<any>>([]);

  useEffect(() => {
    (async function () {
      try {
        const res = await dispatch(
          // @ts-ignore
          getProductTypeAsync()
        ).unwrap();

        setSatellites(res?.data ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    return () => {
      if (draw) {
        dispatch(setDraw(undefined));
      }
    };
  }, []);

  useEffect(() => {
    // setMapType("maplibreGL");
    dispatch(clearFeatures());
    dispatch(clearItems());
    dispatch(changeShowDrawerMainMap(false));
    dispatch(changeFlagFilterItem(false));

    return () => {
      dispatch(clearCriteria());
      dispatch(clearFeatures());
      dispatch(changeFlagFilterItem(false));
      dispatch(changeStateFilter({}));
      // @ts-ignore
      dispatch(changeShowDrawerMainMap(false));
      dispatch(clearItems());
      dispatch(changeShowResultSearch(false));
    };
  }, []);

  useEffect(() => {
    if (props?.items && props?.items.length > 0) {
      // setShowItemsCollection(true);
      dispatch(changeFlagFilterItem(true));
      // setShowDrawer(true);
      // @ts-ignore
      dispatch(changeShowDrawerMainMap(true));
      dispatch(changeShowCriteria(false));
    }
  }, [props?.items]);

  useEffect(() => {
    if (items && items.length > 0) {
      // setShowItemsCollection(true);
      dispatch(changeFlagFilterItem(true));
      // setShowDrawer(true);
      // @ts-ignore
      dispatch(changeShowDrawerMainMap(true));
      dispatch(changeShowCriteria(false));
    } else {
      dispatch(changeFlagFilterItem(false));
      // setShowDrawer(true);
      // @ts-ignore
      dispatch(changeShowDrawerMainMap(false));
      dispatch(changeShowCriteria(false));
    }
  }, [items]);

  useEffect(() => {
    // setShowDrawer(showCriteria);

    if (showCriteria) {
      // @ts-ignore
      dispatch(changeShowDrawerMainMap(showCriteria));
      setShowInfoMetadata(false);
      dispatch(changeFlagFilterItem(false));
    }
  }, [showCriteria]);

  useEffect(() => {
    if (criteria && Object.keys(criteria).length) {
      setCurrentPageSize(0);
      dispatch(clearItems());

      //here
      onSearch(criteria);
    }
  }, [criteria]);

  useEffect(() => {
    if (callSearch) {
      setCurrentPageSize(0);
      dispatch(clearItems());

      //here
      onSearch(searchCriteria);
    }
  }, [callSearch]);

  useEffect(() => {
    if (mapChildRef) changeSourceDataCart(featuresCart);
  }, [featuresCart]);

  useEffect(() => {
    if (mapChildRef) updateFeaturesText(featuresDraw);
    return;

    if (mapChildRef) updateFeaturesText(featuresDraw);
    changeColor(featuresDraw, "#3bb2d0");
    changeColor([activeFeature], "#ff0000");
  }, [featuresDraw]);

  useEffect(() => {
    if (activeFeature && Object.keys(activeFeature).length && draw) {
      // debugger;
      // return;
      // changeColor([activeFeature], "#ff0000");

      // if (!flagFilterItem) {
      //   dispatch(clearItems());
      //   setCurrentPageSize(0);
      //   onSearch(searchCriteria);
      // }
      dispatch(clearItems());
      setCurrentPageSize(0);
      onSearch(searchCriteria);
    }
  }, [activeFeature]);

  // showDrawerMainMap thay cho showdrawer tai vi khi mo drawer search nay thi ko mo duoc drawer khac
  // dung redux de store/changeState drawer nay
  const {
    start_at,
    end_at,
    cloudCoverFrom,
    cloudCoverTo,
    typeVT,
    flagFilterItem,
  } = useSelector((state: RootState) => state.exploredataset);

  const { showDrawerMainMap } = useSelector((state: RootState) => state.drawer);

  const changeSourceDataCart = (features: Array<any>) => {
    //@ts-ignore
    mapChildRef.current.changeSourceDataCart({
      type: "FeatureCollection",
      features: features.slice(),
    });
  };

  const updateFeaturesText = (features: Array<any>) => {
    //@ts-ignore
    mapChildRef.current.updateFeaturesText(features.slice());
  };

  //change colors
  const changeColor = (features: Array<any>, color: string) => {
    if (features && features?.length) {
      features?.forEach((f) => {
        if (draw && f) {
          // return
          draw?.setFeatureProperty(f?.id, "portColor", color);
          var feat = draw.get(f?.id);
          draw?.add(feat);
        }
      });
    }
  };

  const onShowInfoMetadata = async (item: any) => {
    setInfoItem(item);

    //setShowItemsCollection(false);
    setShowInfoMetadata(true);
    // setShowDrawer(true);
    dispatch(changeShowDrawerMainMap(true));
  };

  const onSelectedItem = (item: any) => {
    let selectedCopy: Array<any> =
      selected && selected.length
        ? selected.slice()
        : props.items
        ? [...props.items]
        : [];
    let index = selectedCopy?.findIndex((x) => x.id === item?.id);
    if (index < 0) selectedCopy.push(item);
    else selectedCopy.splice(index, 1);

    setSelected(selectedCopy ?? []);
  };

  const onSelectedViewAll = (item: any) => {
    setSelected(item ?? []);
  };

  const onSelectFeatureChange = async (features: Array<any>) => {
    setDataInfo(features && features.length ? features[0] : undefined);

    const res = await dispatch(
      // @ts-ignore
      getItemCollectionByIdAsync({
        collectionId: features?.[0]?.properties?.collection,
        itemId: features?.[0]?.properties?.id,
      })
    ).unwrap();

    setInfoItem(res?.data);

    let show = features && features.length ? true : false;
    setShowInfoMetadata(show);
    // setShowDrawer(show);

    dispatch(changeShowDrawerMainMap(show));
  };

  const toggleDrawer = () => {
    // dispatch(clearItems());
    if (
      showInfoMetadata &&
      flagFilterItem // showItemsCollection
    ) {
      // setShowDrawer(true);
      dispatch(changeShowDrawerMainMap(true));
      setShowInfoMetadata(false);
      dispatch(changeShowCriteria(false));
    } else {
      dispatch(changeShowCriteria(false));
      setShowInfoMetadata(false);
      // setShowItemsCollection(false);
      dispatch(changeFlagFilterItem(false));
      dispatch(changeShowDrawerMainMap(false));
      // setShowDrawer(!showDrawer);
    }
  };

  const onSearch = async (criteria: Object, type?: string, next?: string) => {
    dispatch(changeVisibleSpinner(true));
    setDrawerLoading(true);
    if (type === "clear") {
      // setShowItemsCollection(false);
      dispatch(changeFlagFilterItem(false));
      dispatch(changeCallSearch(false));
      setShowInfoMetadata(false);
      dispatch(changeShowDrawerMainMap(false));

      setSearchCriteria(undefined);

      // dispatch(changeStateFilter(satellites.filter((x) => x.platform)));
      dispatch(changeStateFilter([]));

      // dispatch(changeShowDrawerMainMap(false));
      dispatch(changeShowCriteria(false));
      dispatch(changeShowResultSearch(false));
    } else {
      dispatch(changeCallSearch(false));
      criteria = createCriteria(criteria, next);
      try {
        if (!showDrawerMainMap) setLoading(true);
        setDrawerLoading(true);
        const response = await dispatch(
          // @ts-ignore
          searchAsync(
            // @ts-ignore
            // searchParams.get("history")
            type !== "search" && historyId
              ? { ...criteria, from_history: true }
              : criteria
          )
        ).unwrap();

        setFeatureCollection(response);

        setLoading(false);
        setDrawerLoading(false);
        dispatch(changeShowCriteria(false));
        setShowInfoMetadata(false);
        setTimeout(() => {
          // setShowItemsCollection(true);
          dispatch(changeFlagFilterItem(true));
          // setShowDrawer(true);
          dispatch(changeShowDrawerMainMap(true));
        }, 500);
      } catch (err: any) {
        setLoading(false);
        setDrawerLoading(false);
        dispatch(
          updateAlert({
            show: true,
            title: "Error",
            message: err?.error?.message,
            buttons: {
              close: true,
              yes: false,
              no: false,
            },
          })
        );
      }

      dispatch(changeShowResultSearch(true));

      // setTimeout(() => {
      //   // setShowItemsCollection(true);
      //   dispatch(changeFlagFilterItem(true));
      //   // setShowDrawer(true);
      //   dispatch(changeShowDrawerMainMap(true));
      // }, 1000);
    }
    dispatch(changeShowCriteria(false));

    setDrawerLoading(false);
    dispatch(changeVisibleSpinner(false));
  };

  const onSearchWithFeature = async (feature: any) => {
    dispatch(clearItems());
    dispatch(changeCallSearch(false));
    let criteria = createCriteria(searchCriteria);
    try {
      if (!showDrawerMainMap) setLoading(true);
      setDrawerLoading(true);
      const response = await dispatch(
        // @ts-ignore
        searchAsync({ ...criteria, intersects: feature.geometry })
      ).unwrap();

      setFeatureCollection(response);

      setLoading(false);
      setDrawerLoading(false);
      dispatch(changeShowCriteria(false));
      setShowInfoMetadata(false);
      setTimeout(() => {
        dispatch(changeFlagFilterItem(true));
        dispatch(changeShowDrawerMainMap(true));
      }, 500);
    } catch (err: any) {
      setLoading(false);
      setDrawerLoading(false);
      dispatch(
        updateAlert({
          show: true,
          title: "Error",
          message: err?.error?.message,
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }

    // setTimeout(() => {
    //   dispatch(changeFlagFilterItem(true));
    //   dispatch(changeShowDrawerMainMap(true));
    // }, 1000);
  };

  const createCriteria = (criteria: any, next?: string) => {
    let payload: any = {
      //filter: {},
      //"filter-lang": "cql2-json",
      limit: criteria?.limit ?? pageSize,
      page: criteria?.page ?? currentPage,
      sortby: criteria?.sortby ?? [
        { field: "properties.datetime", direction: "desc" },
      ],
      next: next,
    };
    if (criteria && criteria.datetime) payload.datetime = criteria.datetime;
    if (criteria && criteria.query) payload.query = criteria.query;

    if (searchText && searchText.length) payload.ids = [searchText];

    if (activeFeature) {
      payload.intersects = activeFeature.geometry;
    }

    setSearchCriteria(payload);
    // dispatch(changeCriteria(payload));
    return payload;
  };

  const onCloseCriteria = () => {
    dispatch(changeShowCriteria(false));
    // setShowDrawer(false);
    dispatch(changeShowDrawerMainMap(false));
  };

  const onClear = () => {
    dispatch(clearCriteria());
    onSearch({}, "clear");
  };

  const mapChangedBounds = (bounds: any) => {};

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    if (flagFilterItem && !showInfoMetadata) {
      //@ts-ignore
      let {
        //@ts-ignore
        target: { scrollTop, clientHeight, scrollHeight },
      } = e;
      if (scrollTop + clientHeight >= scrollHeight) {
        // check next page
        if (featureCollection && featureCollection?.context) {
          let { links } = featureCollection;

          //@ts-ignore
          let linkNext = links.find((x) => x.rel == "next");
          if (linkNext) {
            var url = new URL(linkNext.href);
            let next = url.searchParams.get("next");
            if (next && next.trim()) {
              let currentPageCopy = currentPage;
              setCurrentPageSize(currentPageCopy + 1);
              onSearch(searchCriteria, "search", "next=" + next);
            }
          }
        }
      }
    }
  };

  const onMouseEnterItem = (item: any) => {
    //@ts-ignore
    mapChildRef.current.changeSourceDataHover(item);
  };
  const onMouseLeaveItem = (item: any) => {
    //@ts-ignore
    mapChildRef.current.changeSourceDataHover({
      type: "FeatureCollection",
      features: [],
    });
  };

  const renderDrawHeader = () => {
    if (showInfoMetadata)
      return (
        <div className="flex text-sm items-center">
          <img src={infoCircle} alt="info" />
          <span className="ml-2">{t("Information")}</span>
        </div>
      );
    else if (showCriteria)
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img src={filterSvg} alt="Dismiss" />
            <span className="ml-1 font-thin">{t("Filter")}</span>
          </div>

          <div className="flex">
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={ApplySvg}
                  alt="Info Circle"
                />
              }
              onClick={() => {
                dispatch(clearItems());
                let payload: any = {};

                if (typeVT.length) {
                  //for (let i = 0; i < typeVT.length; i++) {
                  payload.query = {
                    platform: { in: typeVT.map((x) => x.platform) }, //typeVT[i]?.platform,
                    //  "mission": typeVT[i]?.mission,
                    "proj:epsg": 4326,
                    "eo:cloud_cover": {
                      gte: cloudCoverFrom,
                      lte: cloudCoverTo,
                    },
                    //}
                  };
                } else {
                  payload.query = {
                    "eo:cloud_cover": {
                      gte: cloudCoverFrom,
                      lte: cloudCoverTo,
                    },
                  };
                }

                if (start_at && end_at)
                  payload.datetime = `${moment(
                    start_at
                  ).toISOString()}/${moment(end_at).toISOString()}`;
                if (end_at && !start_at)
                  payload.datetime = `../${moment(end_at).toISOString()}`;
                if (!end_at && start_at)
                  payload.datetime = `${moment(start_at).toISOString()}/..`;
                if (!end_at && !start_at) payload.datetime = null;

                onSearch(payload, "search");
              }}
              // appearance="transparent"
              className="btn-action-drawer !mr-2"
            >
              {t("Apply")}
            </Button>
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={RefreshSvg}
                  alt="Info Circle"
                />
              }
              className="btn-action-drawer"
              onClick={() => {
                dispatch(
                  changeStateFilter(satellites.filter((x) => x.platform))
                );
                onClear();
              }}
            />
          </div>
        </div>
      );
    else if (
      flagFilterItem
      // showItemsCollection
    )
      return (
        <div className="flex text-sm items-center justify-between">
          <div className="flex">
            <img src={itemsCollectionSvg} alt="filter" />
            <span className="ml-2">{t("Items")}</span>
          </div>

          {pageName === "mainMap" && (
            <ButtonComponent
              appearance="outline"
              icon={
                <img className="img-svg-icon" src={MapItemSvg} alt="Dismiss" />
              }
              className={
                "!border-2 !h-[30px] w-8 !ml-2 " +
                (selected?.length === items?.length && items?.length > 0
                  ? "!bg-primary-500 !border-primary-500 "
                  : "!bg-[#272D3C] !border-none")
              }
              disabled={items?.length === 0}
              onClick={(e: any) => {
                if (selected?.length === items?.length) {
                  setSelected([]);
                } else {
                  setSelected(items);
                }
              }}
            />
          )}

          {/* <Checkbox
            className="py-0 !h-8"
            checked={selected?.length === items?.length}
            label={"View all items in map"}
            onChange={(e: any, data: any) => {
              if (data?.checked) {
                setSelected(items);
              } else setSelected([]);
            }}
          /> */}
        </div>
      );
    return null;
    // return (
    //   <div className="flex text-sm items-center">
    //     <img src={infoCircle} alt="info" />
    //     <span className="ml-2">{t("Information")}</span>
    //   </div>
    // );
  };

  const renderDrawer = () => {
    return (
      <DrawerComp
        showDrawer={showDrawerMainMap}
        toggleDrawer={() => toggleDrawer()}
        modalType="non-modal"
        position="end"
        className="text-white decoration-white !absolute top-0 !h-full !border !border-s-background1"
        header={renderDrawHeader()}
        onScroll={(e: React.UIEvent<HTMLElement>) => onScroll(e)}
      >
        {showInfoMetadata && renderInfoMetadata()}
        {showCriteria && renderCriteria()}
        {flagFilterItem && // showItemsCollection
          !showCriteria &&
          renderItemsCollection()}
        {drawerLoading && (
          <LoadingListComp />
          // {/* <div className="absolute top-0 left-0 w-full h-full z-2 flex items-center justify-center bg-white/0.015">
          //   <center>
          //     <Spinner className="absolute top-1/2 left-1/2" />
          //   </center>
          // </div> */}
        )}
      </DrawerComp>
    );
  };

  const renderInfoMetadata = () => {
    return (
      <div
        className={
          flagFilterItem || // showItemsCollection
          showCriteria
            ? ""
            : ""
        }
      >
        <InfoMetadata
          data={infoItem ?? {}}
          selectedIds={
            selected && selected.length
              ? selected.map((x) => x?.id)
              : props?.items && props?.items.length
              ? props?.items.map((y: any) => y?.id)
              : []
          }
        />
      </div>
    );
  };

  const renderCriteria = () => {
    return (
      <FilterDatasetComp
        loadding={drawerLoading}
        criteria={searchCriteria}
        onSearch={(criteria: Object) => onSearch(criteria)}
        onClear={() => onClear()}
        onClose={() => onCloseCriteria()}
      />
    );
  };

  const renderItemsCollection = () => {
    return (
      <div className={showCriteria || showInfoMetadata ? "hidden" : ""}>
        <ItemsCollection
          //@ts-ignore
          selectedIds={
            selected && selected.length
              ? selected.map((x) => x?.id)
              : props?.items && props?.items.length
              ? props?.items.map((y: any) => y?.id)
              : []
          }
          onShowInfoMetadata={(item: any) => onShowInfoMetadata(item)}
          onSelectedItem={(item: any) => onSelectedItem(item)}
          onMouseEnterItem={(item: any) => onMouseEnterItem(item)}
          onSelectedViewAll={(item: any) => onSelectedViewAll(item)}
          onMouseLeaveItem={(item: any) => onMouseLeaveItem(item)}
        />
      </div>
    );
  };

  // openlayers or maplibreGL
  const renderMap = () => {
 
    if (mapType === "openlayers") return <OpenlayersMap {...props} />;
    else {
      return (
        <MaplibreGL
          ref={mapChildRef}
          {...props}
          items={selected && selected.length ? selected : props.items}
          onSelectFeatureChange={(features: Array<any>) =>
            onSelectFeatureChange(features)
          }
          mapChangedBounds={(bounds: any) => mapChangedBounds(bounds)}
          onSearchWithFeature={(feature: any) => onSearchWithFeature(feature)}
        />
      );
    }
  };

  const renderDownloadAoi = () => {
    return (
      <DownloadAoi
        show={showDownloadAoi}
        feature={activeFeature}
        onClose={() => dispatch(setShowDownloadAOI(false))}
      />
    );
  };

  return (
    <div className="w-full relative">
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full z-2 flex items-center justify-center bg-white/5">
          <center>
            <Spinner className="absolute top-1/2 left-1/2" />
          </center>
        </div>
      )}
      {renderDownloadAoi()}
      {renderMap()}

     
      {renderDrawer()}
    </div>
  );
}
