const LoadingListComp = () => {
  return (
    <>
      {/* <Skeleton {...props}>
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
        <SkeletonItem className="!h-[78px] mb-3" appearance="translucent" />
      </Skeleton> */}
      <div role="status" className="animate-pulse">
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700" />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <div className="h-[78px] mb-2 rounded bg-gray-700  dark:bg-gray-700 " />
        <span className="sr-only">Loading...</span>
      </div>
    </>
  );
};

export default LoadingListComp;
