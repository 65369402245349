import {
  Button,
  Divider,
  Dropdown,
  Input,
  Option,
  Switch,
  Textarea,
  useToastController,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  addExpireAsync,
  delExpireAsync,
  getExpireTypesAsync,
  getQuotaTyeAsync,
  getTyeUnitAsync,
  updateQuotaAsync,
} from "./../../store/ManageQuotaSlice";

import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { timeout } from "../../utils/constants";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";

const FormEditQuotaComp = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getExpireTypesAsync({})
        ).unwrap();

        setArrExpireTypes(res ?? []);

        const resTypeUnit = await dispatch(
          // @ts-ignore
          getTyeUnitAsync({})
        ).unwrap();

        setArrTypeUint(resTypeUnit ?? []);

        const resQuotaType = await dispatch(
          // @ts-ignore
          getQuotaTyeAsync({})
        ).unwrap();

        setArrQuotaType(resQuotaType ?? []);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  const [arrTypeUnit, setArrTypeUint] = useState([]);
  const [arrQuotaType, setArrQuotaType] = useState([]);
  const [arrExpireTypes, setArrExpireTypes] = useState([]);

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const [name, setName] = useState(props?.itemEdit?.name);
  const [description, setDescription] = useState(props?.itemEdit?.description);
  const [enable, setEnable] = useState(props?.itemEdit?.enable);
  const [limitValue, setLimitValue] = useState(props?.itemEdit?.limit_value);
  const [unit, setUnit] = useState(props?.itemEdit?.unit);
  const [type, setType] = useState(props?.itemEdit?.quota_type);

  const [stateExpire, setStateExpire] = useState(false);

  const [enableExpire, setEnableExpire] = useState(
    props?.itemEdit?.expire?.enable
  );
  const [interval, setInterval] = useState(
    props?.itemEdit?.expire?.interval ?? "SPECIFIC_TIME"
  );

  const [startAt, setStartAt] = useState(
    props?.itemEdit?.expire?.started
      ? moment(props?.itemEdit?.expire?.started).toDate()
      : undefined
  );

  const [endtAt, setEndAt] = useState(
    props?.itemEdit?.expire?.ended
      ? moment(props?.itemEdit?.expire?.ended).toDate()
      : undefined
  );

  const onOptionSelectType = (e: any, data: any) => {
    setType(data.optionText);
  };

  const onOptionSelectInterval = (e: any, data: any) => {
    setInterval(data.optionText);
    setStateExpire(true);
  };

  const changeTime = (date: any, type: string) => {
    setStateExpire(true);
    if (type === "start_at" && date) {
      setStartAt(date);
    }
    if (type === "end_at" && date) {
      setEndAt(date);
    }
  };
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    dispatch(changeVisibleSpinner(true));
    let res;
    let payload = {
      description: description,
      // enable: enable === "active" ? true : false,
      limit_value: Number(limitValue),
      quota_type: type,
      unit: unit,
      name: name,
      id: props?.itemEdit?.id,
    };
    // @ts-ignore
    res = await dispatch(updateQuotaAsync(payload)).unwrap();

    if (stateExpire && res?.status === 200) {
      if (
        res.status === 200 &&
        enableExpire &&
        enableExpire !== props?.itemEdit?.expire?.enable
      ) {
        let quota_id = props?.itemEdit?.id;
        // let payExpire = {
        //   enable: enableExpire,
        //   interval: interval === "SPECIFIC_TIME" ? null : interval,
        //   quota_id: quota_id,
        //   ended:
        //     interval === "SPECIFIC_TIME" ? moment(endtAt)?.toISOString() : null,
        //   started:
        //     interval === "SPECIFIC_TIME"
        //       ? moment(startAt)?.toISOString()
        //       : null,
        // };

        let payExpire =
          interval === "SPECIFIC_TIME"
            ? {
                // enable: enableExpire,
                // interval: interval === "SPECIFIC_TIME" ? null : interval,
                quota_id: quota_id,
                ended:
                  interval === "SPECIFIC_TIME"
                    ? moment(endtAt).endOf("days")?.toISOString()
                    : null,
                started:
                  interval === "SPECIFIC_TIME"
                    ? moment(startAt)?.toISOString()
                    : null,
              }
            : {
                interval: interval,
                quota_id: quota_id,
              };
        res = await dispatch(
          // @ts-ignore
          addExpireAsync(payExpire)
        ).unwrap();
      } else if (res?.status === 200 && !enableExpire) {
        res = await dispatch(
          // @ts-ignore
          delExpireAsync({ quota_id: props?.itemEdit?.id })
        ).unwrap();
      } else if (
        res?.status === 200 &&
        enableExpire &&
        enableExpire === props?.itemEdit?.expire?.enable
      ) {
        res = await dispatch(
          // @ts-ignore
          delExpireAsync({ quota_id: props?.itemEdit?.id })
        ).unwrap();
        if (res?.status === 200) {
          let quota_id = props?.itemEdit?.id;
          let payExpire = {
            enable: enableExpire,
            interval: interval === "SPECIFIC_TIME" ? null : interval,
            quota_id: quota_id,
            ended:
              interval === "SPECIFIC_TIME"
                ? moment(endtAt)?.toISOString()
                : null,
            started:
              interval === "SPECIFIC_TIME"
                ? moment(startAt)?.toISOString()
                : null,
          };
          res = await dispatch(
            // @ts-ignore
            addExpireAsync(payExpire)
          ).unwrap();
        }
      }
    }

    if (res?.status === 200) {
      notify("Update successfully", "success");
      setTimeout(() => {
        navigate(`/manage-quota`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Update fail", "error");
      setTimeout(() => {
        // navigate(`/manage-quota`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };
  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage">
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            <p className="mb-2">Name</p>
            <Input
              value={name}
              onChange={(e: any) => setName(e?.target?.value)}
              placeholder="Enter name..."
              className="div-dropdown"
            />

            <p className="mb-2 mt-6">Description</p>
            <Textarea
              value={description}
              onChange={(e: any) => setDescription(e?.target?.value)}
              placeholder="Enter description..."
              className="div-dropdown"
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "100%", marginRight: "4px" }}>
                <p className="mb-2 mt-6">Limit value</p>
                <Input
                  value={limitValue}
                  onChange={(e: any) => setLimitValue(e?.target?.value)}
                  placeholder="Enter limit value..."
                  className="div-dropdown"
                />
              </div>
              <div style={{ width: "100%", marginLeft: "4px" }}>
                <p className="mb-2 mt-6">Unit</p>
                <Dropdown
                  placeholder="Select unit"
                  className="div-dropdown"
                  value={unit}
                  onOptionSelect={(e: any, data: any) => {
                    setUnit(data.optionText);
                  }}
                >
                  {arrTypeUnit.map((option: string) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </div>
            </div>

            <p className="mb-2 mt-6">Quota type</p>
            <Dropdown
              placeholder="Select quota type"
              className="div-dropdown"
              value={type}
              onOptionSelect={onOptionSelectType}
            >
              {arrQuotaType.map((option: string) => (
                <Option key={option}>{option}</Option>
              ))}
            </Dropdown>
          </div>
          <Divider vertical />
          <div style={{ width: "95%", marginLeft: "35px" }}>
            <p className="mb-2 mt-4">Enable expire</p>
            <Switch
              checked={enableExpire}
              onChange={(_, data: any) => {
                setEnableExpire(data?.checked);
                setStateExpire(true);
              }}
            />

            {enableExpire && (
              <>
                <p className="mb-2 mt-6">Interval</p>
                <Dropdown
                  placeholder="Select interval"
                  className="div-dropdown"
                  value={interval}
                  onOptionSelect={onOptionSelectInterval}
                >
                  <Option key="SPECIFIC_TIME">SPECIFIC_TIME</Option>
                  {arrExpireTypes.map((option: string) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
                {interval === "SPECIFIC_TIME" && (
                  <>
                    <p className="mb-2 mt-6">Start at</p>
                    <DatePicker
                      maxDate={endtAt}
                      className="div-dropdown"
                      placeholder="Select a date..."
                      formatDate={onFormatDate}
                      allowTextInput
                      value={startAt}
                      onSelectDate={(date: any) => changeTime(date, "start_at")}
                    />

                    <p className="mb-2 mt-6">End at</p>
                    <DatePicker
                      minDate={startAt}
                      value={endtAt}
                      className="div-dropdown"
                      placeholder="Select a date..."
                      formatDate={onFormatDate}
                      allowTextInput
                      onSelectDate={(date: any) => changeTime(date, "end_at")}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => navigate(`/manage-quota`)}
          disabled={visibleSpinner}
        >
          Cancel
        </Button>

        <Button
          appearance="outline"
          className="btn-action"
          onClick={() => add()}
          disabled={visibleSpinner}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default FormEditQuotaComp;
