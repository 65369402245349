import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import MyQuotasComp from "./MyQuota";
import DeleteSvg from "../../assets/delete.svg";
import {
  Divider,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useToastController,
} from "@fluentui/react-components";
import { changeVisibleSpinner } from "../../store/NavSlice";
import ToastComp from "../Toast";
import { timeout } from "../../utils/constants";
import {
  changeFlagFresh,
  changeItemUserQuota,
  delUserQuotaAsync,
  getUserQuotasAsync,
} from "../../store/ManageQuotaSlice";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import { toggleShowQuotaUser } from "../../store/DrawerSlice";

const DrawerUserQuota = () => {
  const { itemUserQuota } = useSelector(
    (state: RootState) => state.managequota
  );
  const dispatch = useDispatch();

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const { dispatchToast } = useToastController(toasterId);

  const [data, setData] = useState<any>({});

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const deleteItem = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      delUserQuotaAsync({
        userId: itemUserQuota?.user?.id,
        quotaId: item?.quota_id,
      })
    ).unwrap();

    if (res?.status === 200) {
      reloadData();
      dispatch(changeVisibleSpinner(false));
      dispatch(changeFlagFresh(true)); 
      notify("Delete successfully", "success");
    } else {
      dispatch(changeVisibleSpinner(false));
      notify("Delete fail", "error");
    }
  };

  useEffect(() => {
    setData(itemUserQuota);
    //     return () => {
    //       dispatch(changeItemUserQuota({}));
    //     };
  }, [itemUserQuota]);

  const reloadData = async () => {
    let a: any;

    const res = await dispatch(
      // @ts-ignore
      getUserQuotasAsync(itemUserQuota?.user?.id)
    ).unwrap();
    if (res?.status === 200) {
      if (res?.data?.length === 0) {
        dispatch(toggleShowQuotaUser(false));
      } else {
        a = {
          user: { ...itemUserQuota?.user },
          data: res?.data,
        };

        setData(a);

        dispatch(changeItemUserQuota(a));
      }

      dispatch(changeVisibleSpinner(false));
    } else {
      // a = {
      //   user: { ...itemUserQuota?.user },
      //   data: [],
      // };
      // setData(a);
      // dispatch(changeItemUserQuota(a));
      dispatch(changeVisibleSpinner(false));
    }
  };


  return (
    <div>
      {visibleSpinner && <LoadingGlobalComp />}
      {data?.data?.map((item: any, ind: any) => (
        <div className="mb-2 !bg-[#2E3240] rounded p-2">
          <div className="flex justify-between items-center">
            <MyQuotasComp data={[item]} key={ind} type={"Admin"} />

            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <img
                  className="ml-2 img-svg-icon cursor-pointer"
                  src={DeleteSvg}
                  alt="Dismiss"
                />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  <MenuItem onClick={() => deleteItem(item)}>Delete </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>

          {/* {ind !== itemUserQuota?.data?.length - 1 && (
            <Divider className="!w-2/3 mt-2 mb-2" />
          )} */}
        </div>
      ))}
    </div>
  );
};

export default DrawerUserQuota;
