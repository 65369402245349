import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getListSearchHis,
  getSearchHisById,
} from "../services/ManageSearchHistory";

export interface ManageSearchHisState {
  created_from?: null;
  created_to?: null;
  flagFilter: boolean;
  flagSort: boolean;
  flagFresh: boolean;
  sort_by: string;
  sort_order: string;
}

const initialState: ManageSearchHisState = {
  created_from: null,
  created_to: null,
  flagFilter: false,
  flagSort: false,
  flagFresh: false,
  sort_by: "created",
  sort_order: "desc",
};

export const getListSearchHisAsync = createAsyncThunk(
  "managesearchHis/getListSearchHis",
  async (payload: any) => {
    try {
      const response = await getListSearchHis(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getSearchHisByIdAsync = createAsyncThunk(
  "managesearchHis/getSearchHisById",
  async (id: string) => {
    try {
      const response = await getSearchHisById(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const ManageSearchHisSlice = createSlice({
  name: "managesearchHis",
  initialState,
  reducers: {
    changeStateBtnCreate: (state, action) => {},

    changeCreatedFrom: (state, action) => {
      state.created_from = action?.payload;
    },
    changeCreatedTo: (state, action) => {
      state.created_to = action?.payload;
    },

    changeFlagFilter: (state, action) => {
      state.flagFilter = action?.payload;
    },
    changeFlagSort: (state, action) => {
      state.flagSort = action?.payload;
    },
    changeFlagFresh: (state, action) => {
      state.flagFresh = action?.payload;
    },

    changeSortBy: (state, action) => {
      state.sort_by = action.payload;
    },
    changeSortOrder: (state, action) => {
      state.sort_order = action.payload;
    },

    resetFilterState: (state, action) => {
      state.created_from = null;
      state.created_to = null;
    },

    resetSortState: (state, action) => {
      state.sort_by = "created";
      state.sort_order = "desc";
    },
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const {
  changeStateBtnCreate,
  changeFlagFilter,
  changeFlagSort,
  changeFlagFresh,
  changeCreatedFrom,
  changeCreatedTo,
  changeSortBy,
  changeSortOrder,
  resetFilterState,
  resetSortState,
} = ManageSearchHisSlice.actions;

export default ManageSearchHisSlice.reducer;
