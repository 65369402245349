import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import EmptySvg from "./../../assets/nodata.svg";

import { changeName, changePath } from "./../../store/pageSlice";
import { changeTotal } from "./../../store/pagingSlice";

import CardItemDownloadComp from "../../components/Download/CardItemDownload";
import LoadingListComp from "../../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import {
  getDetailConfigAsync,
  getSystemKeyAsync,
} from "../../store/ManageSetting";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { settingKeys } from "../../utils/constants";
import { resetState } from "./../../store/pagingSlice";

const dataBread = [
  {
    key: 0,
    value: "Setting system",
    path: "setting-system",
  },
];

const SettingSystemPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(changePath("/setting-system"));
    dispatch(changeName("setting-system"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        let a: any = [];
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getSystemKeyAsync({})
        ).unwrap();

        for (let i = 0; i < res?.data?.length; i++) {
          let resDetail = await dispatch(
            // @ts-ignore
            getDetailConfigAsync({
              key: res?.data?.[i],
            })
          ).unwrap();

          a.push({
            ...res?.data?.id,
            key: res?.data?.[i],
            detail: resDetail?.status !== 200 ? {} : resDetail?.data,
          });
        }

        setData(a ?? []);
        dispatch(changeTotal(res?.data?.length ?? 0));
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}
        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div>
            <CardItemDownloadComp data={settingKeys} dataKey={data} />
          </div>
        )}
      </div>
    </>
  );
};
export default SettingSystemPage;
