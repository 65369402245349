const deleteFromIndexedDb = (
  db: IDBDatabase,
  collectionName: string,
  key: string
) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(collectionName, "readwrite");
    const objectStore = transaction.objectStore(collectionName);
    const request = objectStore.delete(key);
    request.onsuccess = () => {
      resolve(true);
    };
    request.onerror = (e) => {
      console.log(e, "indexedDb err del");
      reject(false);
    };
  });
};

export default deleteFromIndexedDb;
