import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  InputProps,
  Option,
  Radio,
  RadioGroup,
  Switch,
  useToastController,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { getListAsync as getListCollections } from "../../store/exploreSlice";
import { getListAsync as getCollectionStorage } from "../../store/ManageCollectionStorageSlice";
import {
  createTaskAsync,
  getDayAsync,
  getDayRelativeAsync,
  getFreqTypeAsync,
  getRepeatTypeAsync,
  getTaskTypeAsync,
  getTypeDayAsync,
} from "../../store/ManageScheduler";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import ToastComp from "../Toast";
import useCustomNavigate from "../../hook/useCustomNavigate";

interface IStyledInput extends InputProps {
  err?: string;
}

const StyledFluentInput: React.FC<IStyledInput> = styled(Input)`
  border-bottom-color: ${(props) =>
    props?.err && props?.err !== "" ? "rgba(221, 44, 0, 1)" : "none"};

  &:focus-within {
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(96, 205, 255, 1)"};

    &:after {
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(96, 205, 255, 1)"};
    }
  }

  &:hover {
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(149, 149, 149, 1)"};

    &:after {
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(149, 149, 149, 1)"};
    }
  }
`;

const FormAddTask = (props: any) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const [valueM, setValueM] = useState("");
  const [valueH, setValueH] = useState("");
  const [errH, setErrH] = useState("");
  const [errM, setErrM] = useState("");

  const [dataCollection, setDataCollection] = useState<any>([]);
  const [idCollection, setIdCollection] = useState(props?.idCollection);
  const [dataCollectionStorage, setArrCollectionStorage] = useState<any>([]);
  const [idStorage, setIdStorage] = useState(undefined);

  const [arrRepType, setArrRepType] = useState<any>([]);
  const [arrDay, setArrDay] = useState<any>([]);
  const [arrDayRelative, setArrDayRelative] = useState<any>([]);
  const [arrFreqType, setArrFreqType] = useState<any>([]);
  const [arrTypeDay, setArrTypeDay] = useState<any>([]);

  const [days, setDays] = useState<any>([]);
  const [name, setName] = useState(undefined);
  const [enable, setEnable] = useState(true);
  const [taskType, setTaskType] = useState(props?.taskType);
  const [freqType, setFreqType] = useState("Daily");
  const [repeatType, setRepeatType] = useState(undefined);
  const [interval, setInterVal] = useState("00");

  const [taskTypes, setTaskTypes] = useState([]);
  const [stateDate, setStateDate] = useState("Day");
  const [dayRelative, setDayRelative] = useState<any>([]);
  const [startTime, setStartTime] = useState(undefined);

  const [checkContinueRunning, setCheckContinueRunning] = useState(false);

  const [open, setOpen] = useState(false);
  const { visibleSpinner }: any = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        let res;
        res = await dispatch(
          // @ts-ignore
          getTaskTypeAsync()
        ).unwrap();

        setTaskTypes(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getDayAsync()
        ).unwrap();

        // let a = [];
        // for (let i = 0; i < res?.length; i++) {
        //   a.push({
        //     title: res?.[i],
        //     checked: false,
        //   });
        // }

        setArrDay(res);

        res = await dispatch(
          // @ts-ignore
          getRepeatTypeAsync()
        ).unwrap();

        setArrRepType(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getFreqTypeAsync()
        ).unwrap();

        setArrFreqType(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getTypeDayAsync()
        ).unwrap();

        setArrTypeDay(res ?? []);

        res = await dispatch(
          // @ts-ignore
          getDayRelativeAsync()
        ).unwrap();

        setArrDayRelative(res ?? []);

        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListCollections({
            size: 10000,
            sortby: "modified",
            asc: false,
            page: 0,
          })
        ).unwrap();

        setDataCollection(res?.collections);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (idCollection)
        try {
          const res = await dispatch(
            // @ts-ignore
            getCollectionStorage({
              collection_id: idCollection,
            })
          ).unwrap();

          setArrCollectionStorage(res ?? []);
        } catch (e) {
          console.error(e);
        }
    })();
  }, [idCollection]);

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromStringStartAt = useCallback(
    (newValue: string): Date => {
      const previousValue = startTime || new Date();
      // const previousValue = new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [startTime]
  );

  let hour = [];
  for (let i = 0; i < 23; i++) {
    hour.push(i);
  }

  let minus = [];
  for (let i = 0; i < 59; i++) {
    minus.push(i);
  }

  const handleInputChange = (event: any, type: any) => {
    if (type === "hour") {
      const regex = /^[0-9\b]+$/; // Allows only digits
      const inputValue = event.target.value;
      let a = regex.test(inputValue);

      if ((a || inputValue === "") && Number(inputValue) < 25) {
        setErrH("");
        setValueH(inputValue);
      } else {
        setErrH("Ener only digit from 00 to 23");
      }
    }
    if (type === "minus") {
      const regex = /^[0-9\b]+$/; // Allows only digits
      const inputValue = event.target.value;
      let a = regex.test(inputValue);

      if ((a || inputValue === "") && Number(inputValue) < 61) {
        setErrM("");
        setValueM(inputValue);
      } else {
        setErrM("Ener only digit from 00 to 59");
      }
    }
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    let payload = {
      name: name,
      enable: enable,
      task_type: taskType,
      context: {
        collection_id: idCollection,
        storage_id: idStorage,
      },
      freq_type:
        stateDate === "date"
          ? null
          : freqType === "Daily"
          ? "Daily"
          : {
              [freqType]: {
                select_days: days,
                select_day_relative_type:
                  freqType === "Monthly" && dayRelative?.length !== 0
                    ? dayRelative
                    : null,
              },
            },
      active_start_date:
        stateDate === "date" ? moment(startTime).format("YYYY-MM-DD") : null,
      active_start_time: valueH && valueM ? `${valueH}:${valueM}:00.000` : null,
      repeat_type: repeatType,
      repeat_interval: Number(interval),
      when_system_start: false,
    };

    dispatch(changeVisibleSpinner(true));

    // @ts-ignore

    const res = await dispatch(createTaskAsync(payload)).unwrap();

    if (res?.status === 200) {
      notify("Add task successfully", "success");

      setTimeout(() => {
        navigate(`/scheduler/task`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Add task fail", "error");

      setTimeout(() => {
        // navigate(`/scheduler/task`);
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const handleCheckActiveAdd = () => {
    let flag = false;

    if ((!idStorage && taskType === "SCAN_COLLECTION") || !taskType || !name) {
      flag = true;
    }

    return flag;
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      <div
        className="div-form-storage relative"
        onClick={() => {
          if (open) {
            setOpen(false);
          }
        }}
      >
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            <p className="mb-2">Task type</p>
            <Dropdown
              placeholder="Select type"
              className="div-dropdown !w-full"
              value={taskType}
              onOptionSelect={(e: any, data: any) => {
                setTaskType(data?.optionValue);
              }}
              disabled={props?.taskType === "SCAN_COLLECTION"}
            >
              {taskTypes?.map((option: string) => (
                <Option key={option}>{option}</Option>
              ))}
            </Dropdown>

            {taskType === "SCAN_COLLECTION" && (
              <>
                <p className="mb-2 mt-4">Collection</p>
                <Dropdown
                  placeholder="Select type"
                  className="div-dropdown !w-full"
                  value={idCollection}
                  onOptionSelect={(_e: any, data: any) => {
                    setIdCollection(data?.optionValue);
                  }}
                  disabled={props?.taskType === "SCAN_COLLECTION"}
                >
                  {dataCollection?.map((option: any) => (
                    <Option key={option} value={option?.id}>
                      {option?.title}
                    </Option>
                  ))}
                </Dropdown>
              </>
            )}

            {idCollection && (
              <>
                <p className="mb-2 mt-4">Collection's Storage</p>
                <Dropdown
                  placeholder="Select type"
                  className="div-dropdown !w-full"
                  value={idStorage}
                  onOptionSelect={(_e: any, data: any) => {
                    setIdStorage(data?.optionValue?.storage_id);
                  }}
                >
                  {dataCollectionStorage?.map((item: any) => (
                    <Option value={item} key={item?.id}>
                      {item?.storage?.description}
                    </Option>
                  ))}
                </Dropdown>
              </>
            )}
          </div>
          <Divider vertical />
          <div style={{ width: "95%", marginLeft: "35px" }}>
            <p className="mb-2">Title</p>
            <Input
              placeholder="Enter title..."
              className="div-dropdown"
              value={name}
              onChange={(e: any) => setName(e?.target?.value)}
            />
            <p className="mt-6 mb-3">Date</p>
            <RadioGroup value={stateDate}>
              <div className="flex w-full">
                <div className="block w-full">
                  <Radio
                    value="Day"
                    label="Run on the following day"
                    className="w-full"
                    onChange={(e: any, val: any) => {
                      setStateDate(val?.value);
                    }}
                  />
                </div>
                <div className="block w-full">
                  <Dropdown
                    placeholder="Select type"
                    className="div-dropdown !w-full"
                    value={freqType}
                    onOptionSelect={(e: any, data: any) => {
                      setFreqType(data?.optionValue);

                      if (data?.optionValue === "Monthly") {
                        setDays([]);
                      }
                    }}
                    disabled={stateDate === "date"}
                  >
                    {arrFreqType?.map((option: string) => (
                      <Option key={option}>{option}</Option>
                    ))}
                  </Dropdown>

                  {freqType === "Monthly" && stateDate === "Day" && (
                    <>
                      <Dropdown
                        placeholder="Select day relative"
                        className="div-dropdown !w-full mb-2 mt-2"
                        value={dayRelative?.join(", ")}
                        selectedOptions={dayRelative}
                        onOptionSelect={(e: any, data: any) => {
                          setDayRelative(data?.selectedOptions);
                        }}
                        multiselect
                      >
                        {arrDayRelative?.map((option: string) => (
                          <Option key={option}>{option}</Option>
                        ))}
                      </Dropdown>
                    </>
                  )}

                  {freqType !== "Daily" && (
                    <Dropdown
                      placeholder="Select day"
                      className="div-dropdown !w-full mb-2 mt-2"
                      value={days?.join(", ")}
                      selectedOptions={days}
                      onOptionSelect={(e: any, data: any) => {
                        setDays(data?.selectedOptions);
                      }}
                      open={open}
                      onClick={() => setOpen(!open)}
                      multiselect
                    >
                      {arrTypeDay?.map((option: string) => (
                        <div
                          className="pt-1 pl-3 cursor-pointer"
                          onClick={(e) => {
                            setOpen(true);
                            e.preventDefault();

                            if (option === "WeekendDay") {
                              setDays(["Sunday", "Saturday"]);
                            } else if (option === "WeekDay") {
                              setDays([
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                              ]);
                            } else if (option === "Day") {
                              if (days?.length < 5) {
                                setDays([
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Sunday",
                                  "Saturday",
                                ]);
                              } else setDays([]);
                            }
                          }}
                        >
                          {option}
                        </div>
                      ))}

                      <Divider className="mt-2" />

                      {arrDay?.map((item: any) => (
                        <Option
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          key={item}
                        >
                          {item}
                        </Option>
                      ))}
                    </Dropdown>
                  )}
                </div>
              </div>

              <div className="flex mt-4 w-full">
                <Radio
                  value="date"
                  label="Run on the following date"
                  className="w-full"
                  onChange={(e: any, val: any) => {
                    setStateDate(val?.value);
                  }}
                />
                <DatePicker
                  formatDate={onFormatDate}
                  allowTextInput
                  placeholder="  dd/mm/yyyy"
                  className="div-dropdown !w-full datepicker-cus"
                  parseDateFromString={onParseDateFromStringStartAt}
                  disabled={stateDate === "Day"}
                  value={startTime}
                  onSelectDate={(date: any) => {
                    if (date) {
                      setStartTime(date);
                    } else {
                      setStartTime(undefined);
                    }
                  }}
                  //     maxDate={endAt}
                />
              </div>
            </RadioGroup>

            <p className="mt-6">Time</p>

            <div className="mt-3">
              <span>Set the first run time for the sellected date</span>
              <div className="flex gap-4 mt-2">
                <div className="block !w-full">
                  <StyledFluentInput
                    placeholder="00"
                    className="div-dropdown"
                    onChange={(e) => handleInputChange(e, "hour")}
                    value={valueH}
                    err={errH}
                  />
                  {errH}
                </div>
                :
                <div className="block !w-full">
                  <StyledFluentInput
                    placeholder="00"
                    className="div-dropdown"
                    onChange={(e) => handleInputChange(e, "minus")}
                    value={valueM}
                    err={errM}
                  />
                  {errM}
                </div>
              </div>

              <div className="flex mt-4">
                <Checkbox
                  checked={checkContinueRunning}
                  onChange={(ev: any, data: any) =>
                    setCheckContinueRunning(data.checked)
                  }
                  label="Continue running within the same day"
                  className="!w-full"
                />

                <Dropdown
                  placeholder="Select type"
                  className="div-dropdown !w-full"
                  value={repeatType}
                  onOptionSelect={(e: any, data: any) =>
                    setRepeatType(data?.optionValue)
                  }
                  disabled={!checkContinueRunning}
                >
                  {arrRepType?.map((option: string) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </div>
              {checkContinueRunning && (
                <div className="flex mt-4">
                  <div className="block !w-full" />
                  <div className="block !w-full">
                    <p className="mb-2">Interval</p>
                    <StyledFluentInput
                      placeholder="00"
                      className="div-dropdown"
                      onChange={(e) => setInterVal(e?.target?.value)}
                      value={interval}
                      // err={errH}
                    />
                    {/* {errH} */}
                  </div>
                </div>
              )}
            </div>

            <p className="mt-6">Enabled</p>

            <Switch
              checked={enable}
              onChange={(_, data: any) => {
                setEnable(data?.checked);
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-18 right-12">
        <Button
          appearance="outline"
          className="btn-extra-action"
          onClick={() => {
            props?.taskType === "SCAN_COLLECTION"
              ? navigate(`/manage-dataset`)
              : navigate(`/scheduler/task`);
          }}
          disabled={visibleSpinner}
        >
          Cancel
        </Button>

        <Button
          id="action-card-add"
          appearance="primary"
          className="btn-action"
          disabled={handleCheckActiveAdd()}
          onClick={add}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default FormAddTask;
