import {
  Button,
  Spinner,
  useToastController,
} from "@fluentui/react-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ContinueSvg from "../../assets/continute.svg";
import PauseSvg from "../../assets/pause.svg";
import CardItemDowload from "../../components/CardItemDowload";
import LoadingListComp from "../../components/Loading/LoadingList";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import {
  addArrOrderDownloadRedux,
  changeClickStartRequest,
  changeController,
  changeFlagHaveSessionDownload,
  changeLoading,
  changeLoadingButton,
  changeLoadingButtonDownload,
  changeLoadingDownload,
  changeLoadingRequestDownloadButton,
  changePageReloaded,
  changePause,
  changePercentDownload,
  changePreparing,
  changeSelectedItems,
  changeVisibleBtnDownload,
  changeVisibleBtnPause,
  expireSessionAsync,
  getDownloadAsync,
  getSessionDownloadAsync,
  headDownloadAsync,
  initSessionDownloadAsync,
  removeArrOrderDownloadRedux,
  resetPercentDownload,
} from "../../store/dowloadSlice";
import { getThumbnailItemDatasetAsync as getThumbnail } from "../../store/exploreSlice";
import { resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import addToIndexedDb from "../../utils/indexedDb/add";
import deleteFromIndexedDb from "../../utils/indexedDb/delete";
import getFromIndexedDb from "../../utils/indexedDb/get";
import updateToIndexedDb from "../../utils/indexedDb/update";
import DowloadSvg from "./../../assets/download.svg";
import EmptySvg from "./../../assets/nodata.svg";
import {
  cancelOrderAdminAsync,
  getItemInOrderAsync,
} from "./../../store/ManageOrderSlice";
import { changeName, changePath } from "./../../store/pageSlice";
import {
  addOrderDownload,
  getArrOrderDownloadRedux,
  removeOrderDownload,
} from "./utils";


export const MAX_THREAD = 4;

let db: IDBDatabase;
const collectionName = "chunks";
const request = window.indexedDB.open("storeDownload", 5);
request.onsuccess = (e) => {
  db = request.result;
};

request.onupgradeneeded = (e) => {
  db = request.result;
  const objectStore = db.createObjectStore(collectionName, {
    keyPath: "id",
  });
  objectStore.createIndex("id", "id", { unique: true });
  objectStore.createIndex("chunks", "chunks", { unique: false });
  objectStore.createIndex("chunkSize", "chunkSize", { unique: false });
  objectStore.createIndex("percent", "percent", { unique: false });
  objectStore.transaction.oncomplete = (e) => {};
};

// let controller = new AbortController();

let breakDownloading = false;

const checkChunksDownloaded = (arr: any[]) => {
  let flag = 0;
  arr.map((item) => {
    // Chunk chua duoc down
    if (item === undefined) {
      flag++;
    }
  });
  return flag;
};

const calculateProgress = (arr: any[]) => {
  let total = arr.length;
  let downloaded = 0;
  arr.map((item) => {
    if (item !== undefined) {
      downloaded++;
    }
  });
  return (downloaded * 100) / total;
};

const InvoiceItem = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: RootState) => state.download);
  const downloadRedux = useSelector((state: RootState) => state.download);
  const itemInvoice = location?.state?.item;

  const controller = downloadRedux?.[`controller_${itemInvoice.id}`];
  const setController = (value: any) => {
    dispatch(
      changeController({
        item_id: itemInvoice.id,
        value,
      })
    );
  };

  const arrOrderDownload = getArrOrderDownloadRedux(downloadRedux);

  const [stateOrder, setStateOrder] = useState(
    itemInvoice?.states?.[itemInvoice?.states?.length - 1]?.state
  );

  // const [loading, setLoading] = useState(true);
  const loading = downloadRedux?.[`loading_${itemInvoice.id}`];
  const setLoading = (value: boolean) => {
    dispatch(
      changeLoading({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  // const [loadingButton, setLoadingButton] = useState(false);
  const loadingButton = downloadRedux?.[`loading-button_${itemInvoice.id}`];
  const setLoadingButton = (value: boolean) => {
    dispatch(
      changeLoadingButton({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  // const [loadingRequestDownloadButton, setLoadingRequestDownloadButton] =
  //   useState(false);
  const loadingRequestDownloadButton =
    downloadRedux?.[`loading-request-download-button_${itemInvoice.id}`];
  const setLoadingRequestDownloadButton = (value: boolean) => {
    dispatch(
      changeLoadingRequestDownloadButton({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  const clickStartRequest =
    downloadRedux?.[`click_start_request_${itemInvoice.id}`];
  const setClickStartRequest = (value: boolean) => {
    dispatch(
      changeClickStartRequest({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  // const [loadingButtonDownload, setLoadingButtonDownload] = useState(false);
  const loadingButtonDownload =
    downloadRedux?.[`loading-button-download_${itemInvoice.id}`];
  const setLoadingButtonDownload = (value: boolean) => {
    dispatch(
      changeLoadingButtonDownload({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  const [data, setData] = useState<any[]>([]);
  const [dataOrder, setDataOrder] = useState<any>({});
  // const [visibleBtnDownload, setVisibleBtnDownload] = useState(false);
  const visibleBtnDownload =
    downloadRedux?.[`visible-btn-download_${itemInvoice.id}`];
  const setVisibleBtnDownload = (value: boolean) => {
    dispatch(
      changeVisibleBtnDownload({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  // const [visibleBtnPause, setVisibleBtnPause] = useState(false);
  const visibleBtnPause =
    downloadRedux?.[`visible-btn-pause_${itemInvoice.id}`];
  const setVisibleBtnPause = (value: boolean) => {
    dispatch(
      changeVisibleBtnPause({
        item_id: itemInvoice.id,
        value,
      })
    );
  };
  const [idSession, setIdSession] = useState(null);

  // const [pause, setPause] = useState(false);
  const pause = downloadRedux?.[`pause_${itemInvoice.id}`];
  const setPause = (value: boolean) => {
    dispatch(
      changePause({
        item_id: itemInvoice.id,
        value,
      })
    );
  };

  useEffect(() => {
    if (downloadRedux.pageReloaded) {
      setPause(true);
      dispatch(changePageReloaded(false));
    }
  }, [downloadRedux.pageReloaded]);

  const preparing = downloadRedux?.[`preparing_${itemInvoice.id}`];
  const setPreparing = (value: boolean) => {
    dispatch(
      changePreparing({
        item_id: itemInvoice.id,
        value,
      })
    );
  };

  const reloadData = async () => {
    // @ts-ignore
    let gao = await dispatch(getItemInOrderAsync(itemInvoice?.id)).unwrap();

    let dataWithImg: any = [];
    let b = gao?.result?.[0]?.items;

    for (let i = 0; i < b?.length; i++) {
      let res;
      res = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: b?.[i]?.collection_id,
          item_id: b?.[i]?.stac_item_id,
        })
      ).unwrap();

      dataWithImg.push({
        ...b?.[i],
        thumbnail: res,
      });
    }
    setDataOrder(dataWithImg);
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "My order",
          path: "invoice",
        },
        {
          key: 1,
          value: moment(gao?.result?.[0]?.modified).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
        },
      ])
    );

    setLoadingButton(true);
    const resSession = await dispatch(
      // @ts-ignore
      getSessionDownloadAsync({ orderId: itemInvoice?.id })
    ).unwrap();

    if (resSession?.status === 200) {
      let a = resSession?.data?.result;

      if (a?.length > 0) {
        let b: any = [];
        let hasInit = false;
        for (let i = 0; i < a.length; i++) {
          b = a[i]?.items;
          if (a[i]?.success && moment(a[i]?.expired).isAfter(moment())) {
            setIdSession(a[i]?.id);
            setVisibleBtnDownload(true);
            dispatch(changeFlagHaveSessionDownload(true));
            hasInit = true;
            break;
          }
        }

        if (!hasInit) {
          checkLoadingRequestDownloadButton(true);
        }
        let c: any = [];

        if (b?.length > 0) {
          let selected: any[] = [];
          dataWithImg?.map((itemData: any) => {
            let flag = false;
            b?.map((itemB: any) => {
              if (itemB?.order_item_id === itemData?.id) {
                flag = true;
              }
            });
            if (flag) {
              selected = selected.concat(itemData);
            }
            c.push({
              ...itemData,
              // state: flag,
            });
          });
          setData(c);

          dispatch(changeSelectedItems(selected));
        } else {
          setData(dataWithImg);
          dispatch(changeSelectedItems([]));
        }
      } else {
        // test
        dispatch(changeFlagHaveSessionDownload(false));
        dispatch(changeSelectedItems([]));
        setVisibleBtnDownload(false);
        setLoadingRequestDownloadButton(false);
        setData(dataWithImg);
      }
    }
    setLoadingButton(false);
  };

  const checkLoadingRequestDownloadButton = async (isReload: boolean) => {
    while (true) {
      const resSession = await dispatch(
        // @ts-ignore
        getSessionDownloadAsync({ orderId: itemInvoice?.id })
      ).unwrap();

      if (resSession?.status === 200) {
        let a = resSession?.data?.result;

        if (a.length === 0) {
          if (isReload) {
            break;
          } else {
            if (clickStartRequest) continue;
            const response = await initSessionDownload({
              orderId: itemInvoice?.id,
              ids: selectedItems?.map((item: any) => item?.id),
            });
            if (response) {
              continue;
            } else break;
          }
        }
        for (let i = 0; i < a.length; i++) {
          if (a[i]?.success && moment(a[i]?.expired).isAfter(moment())) {
            setIdSession(a[i]?.id);
            setLoadingButton(false);
            setVisibleBtnDownload(true);
            dispatch(changeFlagHaveSessionDownload(true));
            setLoadingRequestDownloadButton(false);
            dispatch(changeVisibleSpinner(false));
            setClickStartRequest(false);
            return;
          }
          if (a[i].success === false && a[i].expired !== undefined) {
            notify("Request download failed. Please re-create", "error");
            setLoadingButton(false);
            setVisibleBtnDownload(false);
            dispatch(changeFlagHaveSessionDownload(false));
            setLoadingRequestDownloadButton(false);
            dispatch(changeVisibleSpinner(false));
            setClickStartRequest(false);
            await cancelReqDownload();
            return;
          }
        }
      } else {
        notify("Please retry");

        setLoadingButton(false);
        setVisibleBtnDownload(false);
        dispatch(changeFlagHaveSessionDownload(false));
        setLoadingRequestDownloadButton(false);
        dispatch(changeVisibleSpinner(false));
        setClickStartRequest(false);
        await cancelReqDownload();
        return;
      }
      await new Promise((r) => setTimeout(r, 15000));
    }
  };

  useEffect(() => {
    if (loadingRequestDownloadButton) {
      checkLoadingRequestDownloadButton(false);
    }
  }, [loadingRequestDownloadButton]);

  const getDataOrder = async () => {
    // @ts-ignore
    let gao = await dispatch(getItemInOrderAsync(itemInvoice?.id)).unwrap();

    let a: any = [];
    let b = gao?.result?.[0]?.items;

    for (let i = 0; i < b?.length; i++) {
      let res;
      res = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: b?.[i]?.collection_id,
          item_id: b?.[i]?.stac_item_id,
        })
      ).unwrap();

      a.push({
        ...b?.[i],
        thumbnail: res,
      });
    }

    setData(a);
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(changeName("invoiceItem"));
    dispatch(changePath("/invoiceItem"));
    (async function () {
      try {
        setLoading(true);
        setController(new AbortController());
        await reloadData();
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();

    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, [itemInvoice?.id]);

  useEffect(() => {
    if (idSession && itemInvoice?.id) {
      // Tinh % dang download
      const currentDownloaded = localStorage.getItem(
        `current_downloaded_${idSession}_${itemInvoice?.id}`
      );
      const totalChunks = localStorage.getItem(
        `total_chunks_${idSession}_${itemInvoice?.id}`
      );
      if (currentDownloaded && totalChunks) {
        setVisibleBtnPause(true);
        // const isReload = getIsReloadPage();
        // if (isReload) {
        //   setPause(true);
        //   updateIsReloadPage("FALSE");
        // }
        dispatch(
          changePercentDownload({
            item_id: itemInvoice?.id,
            value:
              (parseInt(currentDownloaded ?? 0) * 100) /
              parseInt(totalChunks ?? 0),
          })
        );
      }
    }
  }, [idSession, itemInvoice?.id]);

  const initSessionDownload = async (payload: any) => {
    let newPay = {
      ...payload,
      configHeader: {
        header: {
          "Content-Type": "application/json",
        },
      },
    };
    const res = await dispatch(
      // @ts-ignore
      initSessionDownloadAsync(newPay)
    ).unwrap();

    if (res?.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const getSessionDown = async () => {
    setLoadingButton(true);
    dispatch(changeVisibleSpinner(true));

    const resSession = await dispatch(
      // @ts-ignore
      getSessionDownloadAsync({ orderId: itemInvoice?.id })
    ).unwrap();

    setLoadingRequestDownloadButton(true);

    if (resSession?.status === 200) {
      let a = resSession?.data?.result;

      if (a?.length === 0) {
        const response = await initSessionDownload({
          orderId: itemInvoice?.id,
          ids: selectedItems?.map((item: any) => item?.id),
        });
        if (!response) {
          notify("Create request download failed", "error");
          setLoadingButton(false);
          dispatch(changeVisibleSpinner(false));
          setLoadingRequestDownloadButton(false);
          setClickStartRequest(false);
          return;
        }
      } else {
        let flag = true;
        for (let i = 0; i < a.length; i++) {
          if (a[i].success !== undefined || a[i].expired !== undefined) {
            // TH3: là có result[i] nào đó mà chưa có success  (do server chuuaw prepare xong) =>> getSession()
            flag = false;
          }
          if (a[i]?.success && moment(a[i]?.expired).isAfter(moment())) {
            flag = false;
            break;
          }

          if (a[i].success === false && a[i].expired !== undefined) {
            // TH4: là có result[i] nhuwng success la false =>>> breake
            flag = false;
          }
        }
        if (flag) {
          // TH2: tất cả các result[i] đều bị hết hạn => initSession() => getSession()
          const response = await initSessionDownload({
            orderId: itemInvoice?.id,
            ids: selectedItems?.map((item: any) => item?.id),
          });
          if (!response) {
            setLoadingButton(false);
            dispatch(changeVisibleSpinner(false));
            setClickStartRequest(false);
            notify("Create request download failed", "error");
          }
        }
      }
    } else {
      setLoadingButton(false);
      dispatch(changeVisibleSpinner(false));
      setLoadingRequestDownloadButton(false);
      setClickStartRequest(false);
      notify("Create request download failed", "error");
      return;
    }
  };

  const getChunkDownload = async () => {
    const resChunk = await dispatch(
      // @ts-ignore
      headDownloadAsync({
        orderId: itemInvoice?.id,
        downloadSessions: idSession,
      })
    ).unwrap();

    return resChunk;
  };

  const getDownloadByChunk = async (range: any) => {
    const res = await dispatch(
      // @ts-ignore
      getDownloadAsync({
        orderId: itemInvoice?.id,
        downloadSessions: idSession,
        responseType: "arraybuffer",
        headers: { Range: range },
        // @ts-ignore
        signal: controller.signal,
      })
    ).unwrap();

    return res;
  };

  const addOrUpdateChunks = async (
    chunks: Uint8Array,
    percent: number,
    numberChunks: number
  ) => {
    try {
      // Order id: itemInvoice?.id
      // Download session: idSession
      const id = `${idSession}_${itemInvoice?.id}_${numberChunks}`; // id co format dang idSession_idOrder
      const data = await getFromIndexedDb(db, collectionName, id);
      if (data) {
        // Nế u id đã tồn tại => cập nhật
        const updated = await updateToIndexedDb(db, collectionName, id, {
          // chunks: "abc updated",
          chunks,
          percent,
        });
      } else {
        // Chua ton tai => them moi
        const created = await addToIndexedDb(db, collectionName, {
          id,
          // chunks: "abc",
          chunks,
          percent,
        });
      }
    } catch (e) {
      console.log(e, "errr");
    }
  };

  const chunksToFile = (chunks: Uint8Array[], fileName: string) => {
    const blob = new Blob(chunks, { type: "application/zip" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${fileName ?? "downloaded.zip"}`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleAddOrderDownload = () => {
    // let sun: any = Array.from(arrOrderDownload);

    let a = arrOrderDownload?.filter(
      (item: any) =>
        item?.orderId === itemInvoice?.id && item.idSession === idSession
    );

    if (a?.length === 0 && idSession) {
      // sun.push({
      //   orderId: itemInvoice?.id,
      //   idSession: idSession,
      //   items: selectedItems.length,
      // });
      addOrderDownload({
        orderId: itemInvoice?.id,
        idSession: idSession,
        items: selectedItems.length,
      });
      dispatch(
        addArrOrderDownloadRedux({
          item_id: itemInvoice.id,
          value: {
            orderId: itemInvoice?.id,
            idSession: idSession,
            items: selectedItems.length,
          },
        })
      ); // de hien thi item download o drawer
    }
  };

  const handleRemoveOrderDownload = (oId: string) => {
    localStorage.removeItem(`percent-download_${oId}`);
    dispatch(
      resetPercentDownload({
        item_id: itemInvoice.id,
      })
    );
    // let temp = arrOrderDownload.filter((item) => item?.orderId !== oId);
    removeOrderDownload(oId);
    dispatch(
      removeArrOrderDownloadRedux({
        item_id: itemInvoice.id,
      })
    );
  };

  const handleDownLoad = async (disableIndexedDb: boolean = false) => {
    dispatch(changeVisibleSpinner(true));
    setVisibleBtnPause(true);
    setPause(false);
    setLoadingButtonDownload(true);
    dispatch(changeLoadingDownload(true));
    setController(new AbortController());

    let a = await getChunkDownload();

    const totalSize = a?.headers?.["content-length"];
    const fileName =
      (a?.headers?.["content-disposition"] ?? "").match(/"([^"]*)"/)?.[1] ??
      undefined;
    if (!a || !totalSize) {
      notify("Download failed. Please try again", "error");
      return;
    }
    // return;
    const chunkSize = 100 * 1024 * 1024; // 100MB chunks (adjust as needed)

    const sizeStore: any = await navigator.storage.estimate();
    if (
      sizeStore &&
      sizeStore.quota &&
      sizeStore.usage &&
      sizeStore.quota <= sizeStore.usage + chunkSize &&
      !disableIndexedDb
    ) {
      const persistent = await navigator.storage.persist();
      if (!persistent) {
        notify("Not enough storages for download", "error");
      } else {
        console.log(
          await navigator.storage.estimate(),
          chunkSize,
          "new storage"
        );
      }
    }

    // Tinh tong so luong chunks phai download
    const totalChunks =
      Math.floor(totalSize / chunkSize) + (totalSize % chunkSize === 0 ? 0 : 1);
    const id = `${idSession}_${itemInvoice?.id}`;
    const total_chunks_id = `total_chunks_${id}`;
    localStorage.setItem(total_chunks_id, totalChunks.toString());

    const current_downloaded_id = `current_downloaded_${id}`;
    const currentDownloaded = disableIndexedDb
      ? 0
      : parseInt(localStorage.getItem(current_downloaded_id) ?? "0");
    dispatch(
      changePercentDownload({
        item_id: itemInvoice?.id,
        value: (currentDownloaded * 100) / totalChunks,
      })
    );

    // Khoi tao mang ktr xem nhung chunk nao da duoc tai
    let downloadedChunks: any[] = [];
    for (let i = 0; i < totalChunks; i++) {
      downloadedChunks = downloadedChunks.concat(undefined);
    }
    console.log(
      "init downloadedChunks",
      downloadedChunks,
      totalChunks,
      " chunks"
    );

    let numberChunks = 0;

    breakDownloading = false;

    try {
      // checkChunksDownloaded ham kiem tra khi nao du chunks hoac pause thi moi dung
      while (true) {
        const numberChunksNotDownloaded =
          checkChunksDownloaded(downloadedChunks);
        console.log("Checking number chunks", numberChunksNotDownloaded);
        if (breakDownloading) return;
        if (numberChunksNotDownloaded === 0) {
          break;
        }

        let numberThreads: number[] = [];
        for (let thread = 0; thread < totalChunks; thread++) {
          if (numberThreads.length === MAX_THREAD) break;
          if (!downloadedChunks[thread]) {
            numberThreads = numberThreads.concat(thread);
          }
        }

        const arrThreads = numberThreads.map(async (i) => {
          if (breakDownloading) return undefined;
          // Ktr tung chunk mot, neu co trong indexedDb thi cap nhat vao mang
          let chunkResponse: Uint8Array = await getFromIndexedDb(
            db,
            collectionName,
            `${idSession}_${itemInvoice?.id}_${i}`
          );
          // @ts-ignore
          if (chunkResponse && chunkResponse.chunks) {
            console.log(
              // @ts-ignore
              chunkResponse.id,
              "join chunk"
            );

            // downloadedChunks[i] = downloadedChunks.chunks;
            // @ts-ignore
            downloadedChunks[i] = chunkResponse.chunks;
            numberChunks++;

            // const progress = calculateProgress(downloadedChunks);
            // dispatch(
            //   changePercentDownload({
            //     item_id: itemInvoice?.id,
            //     value: progress,
            //   })
            // );
            // continue;
            // @ts-ignore
            return chunkResponse.chunks;
          }
          // Neu chunk chua duoc tai thi tai chunk bi thieu
          let start = 0;
          let end = -1;

          start = i * chunkSize;
          if ((i + 1) * chunkSize < totalSize) {
            end = (i + 1) * chunkSize - 1;
          }
          const range = `bytes=${start}-${end === -1 ? "" : end.toString()}`;
          // console.log(range, chunkSize, end - start + 1, i, "check range");

          const response = await getDownloadByChunk(range);
          if (response.status === undefined) {
            let res = "pause";
            if (response.message === "canceled") {
              setController(new AbortController());
              console.log("Pause axios");
            } else {
              res = "error";
              notify("Download error. Paused", "error");
            }
            setPause(true);
            dispatch(
              changePercentDownload({
                item_id: itemInvoice?.id,
                value: (numberChunks * 100) / totalChunks,
              })
            );
            return undefined;
            // clearTimeout(timeoutLoop);
            // resolve(res);
          }
          let progress = -1;
          if (response.status === 206) {
            chunkResponse = new Uint8Array(response?.data);
            downloadedChunks[i] = chunkResponse;

            progress = calculateProgress(downloadedChunks);
            // Neu tai xong luu vao indexedDb
            if (!disableIndexedDb) {
              await addOrUpdateChunks(chunkResponse, progress, i);
            }

            numberChunks++;
          }
          if (numberChunks >= currentDownloaded) {
            if (progress !== -1) {
              // Sau khi luu vao indexedDb moi cap nhat %
              dispatch(
                changePercentDownload({
                  item_id: itemInvoice?.id,
                  value: progress,
                })
              );
            }
            localStorage.setItem(
              current_downloaded_id,
              numberChunks.toString()
            );
          }

          console.log("Chunk ", i, " done");
          return chunkResponse;
        });

        await new Promise((resolve, reject) => {
          Promise.all(arrThreads)
            .then((data) => {
              console.log(data.length, "data promise all");
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        });

        console.log(
          "Check downloaded all chunks",
          downloadedChunks,
          checkChunksDownloaded(downloadedChunks)
        );
      }

      console.log("handle done");

      setPreparing(true);

      // Sau khi tải hết các chunks thực hien xoa trong indexedDb
      if (!disableIndexedDb) {
        for (let i = 0; i < totalChunks; i++) {
          const id = `${idSession}_${itemInvoice?.id}_${i}`;
          const deleted = await deleteFromIndexedDb(db, collectionName, id);
          console.log("deleted ", id);
        }
      }
      console.log("Deleted all chunks after download");

      chunksToFile(downloadedChunks, fileName);

      await cancelReqDownload(disableIndexedDb);

      localStorage.removeItem(total_chunks_id);
      localStorage.removeItem(current_downloaded_id);
      handleRemoveOrderDownload(itemInvoice.id);

      setPreparing(false);

      dispatch(changeLoadingDownload(false));
      setVisibleBtnPause(false);
      setLoadingButtonDownload(false);
      dispatch(changeVisibleSpinner(false));
      setLoadingButton(false);
    } catch (error) {
      console.error("Download error:", error);
      dispatch(changeVisibleSpinner(false));
    }
  };

  // const handleDownLoadNotChunk = async () => {
  //   dispatch(changeVisibleSpinner(true));
  //   setVisibleBtnPause(true);
  //   setPause(false);
  //   setLoadingButtonDownload(true);
  //   dispatch(changeLoadingDownload(true));
  //   setController(new AbortController());
  //   let a = await getChunkDownload();
  //   const totalSize = a?.headers?.["content-length"];
  //   const fileName =
  //     (a?.headers?.["content-disposition"] ?? "").match(/"([^"]*)"/)?.[1] ??
  //     undefined;
  //   if (!a || !totalSize) {
  //     notify("Download failed. Please try again", "error");
  //     return;
  //   }
  //   const range = "bytes=0-";
  //   const response = await getDownloadByChunk(range);
  //   if (response.status === undefined) {
  //     let res = "pause";
  //     if (response.message === "canceled") {
  //       setController(new AbortController());
  //       console.log("Pause axios");
  //     } else {
  //       res = "error";
  //       notify("Download error. Paused", "error");
  //     }
  //     setPause(true);

  //     return undefined;
  //     // clearTimeout(timeoutLoop);
  //     // resolve(res);
  //   }

  //   await cancelReqDownload(true);
  //   handleRemoveOrderDownload(itemInvoice.id);
  //   dispatch(changeLoadingDownload(false));
  //   setVisibleBtnPause(false);
  //   setLoadingButtonDownload(false);
  //   dispatch(changeVisibleSpinner(false));
  //   setLoadingButton(false);

  //   if (response.status === 206) {
  //     const chunkResponse = new Uint8Array(response?.data);
  //     chunksToFile([chunkResponse], fileName);
  //   }
  // };

  const onSelectedItem = (itemSelected: any, val: any) => {
    let gao = data;
    let tun: any = [];

    let sun = Array.from(selectedItems);

    gao?.map((item: any, index: any) => {
      if (val) {
        tun.push({
          ...item,
          state: itemSelected?.id === item?.id ? true : item?.state,
        });

        if (itemSelected?.id === item?.id) {
          sun?.push({ ...item, state: true });
        }
      } else if (!val) {
        tun.push({
          ...item,
          state: itemSelected?.id === item?.id ? false : item?.state,
        });

        if (itemSelected?.id === item?.id) {
          sun = selectedItems?.filter(
            (item: any) => itemSelected?.id !== item?.id
          );
        }
      }
    });

    setData(tun);

    dispatch(changeSelectedItems(sun));
  };

  const cancelReqDownload = async (disableIndexedDb: boolean = false) => {
    console.log("cancelReqDownload");
    dispatch(changeVisibleSpinner(true));
    const id = `${idSession}_${itemInvoice?.id}`;
    const total_chunks_id = `total_chunks_${id}`;
    const current_downloaded_id = `current_downloaded_${id}`;
    const totalChunks = parseInt(localStorage.getItem(total_chunks_id) ?? "0");

    if (controller) {
      controller.abort();
    }
    if (!disableIndexedDb) {
      for (let i = 0; i < totalChunks; i++) {
        const idIdb = `${id}_${i}`;
        const deleted = await deleteFromIndexedDb(db, collectionName, idIdb);
        console.log("deleted ", idIdb);
      }
    }

    handleRemoveOrderDownload(itemInvoice.id);

    localStorage.removeItem(total_chunks_id);
    localStorage.removeItem(current_downloaded_id);

    const resExpireSession = await dispatch(
      // @ts-ignore
      expireSessionAsync({ orderId: itemInvoice?.id })
    ).unwrap();

    if (resExpireSession?.status === 200) {
      dispatch(changeSelectedItems([]));
      dispatch(changeFlagHaveSessionDownload(false));
      setLoadingButton(false);
      getDataOrder();
      setIdSession(null);
      setVisibleBtnDownload(false);
      dispatch(changeVisibleSpinner(false));
      dispatch(changeLoadingDownload(false));
      dispatch(changeSelectedItems([]));
    } else {
      dispatch(changeSelectedItems([]));
      dispatch(changeFlagHaveSessionDownload(false));
      setIdSession(null);
      setLoadingButton(false);
      setVisibleBtnDownload(true);
      dispatch(changeVisibleSpinner(false));
      dispatch(changeLoadingDownload(false));
    }
    setPause(false);
    setVisibleBtnPause(false);
    setLoadingButtonDownload(false);
    setLoadingRequestDownloadButton(false);
    setPreparing(false);
  };

  const pauseDownloading = async () => {
    if (!pause) {
      setPause(true);
      breakDownloading = true;
      dispatch(changeVisibleSpinner(false));
      if (controller) {
        controller.abort();
      }
      setController(new AbortController());
      return;
    } else {
      setPause(false);
      dispatch(changeVisibleSpinner(true));
      handleDownLoad();

      // handleDownLoad(isDownloadChunk);
    }
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const handleCancelOrder = async () => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      cancelOrderAdminAsync({
        orderId: itemInvoice?.id,
        owner_id: itemInvoice?.user_id,
      })
    ).unwrap();

    if (res.status === 200) {
      setStateOrder("Cancel");
      reloadData();
      notify("Cancel successfully", "success");
      dispatch(changeVisibleSpinner(false));
    } else {
      notify("Cancel fail", "error");
      dispatch(changeVisibleSpinner(false));
    }
  };

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const handleCheckDownload = async () => {
   
    if (

      localStorage.getItem('REACT_APP_CONFIG_DIRECT_DOWNLOAD')
  &&
      localStorage.getItem('REACT_APP_CONFIG_DIRECT_DOWNLOAD')=== "true"
    ) {
      // handleAddOrderDownload();

      window.open(
        `${localStorage.getItem("REACT_APP_ENDPOINT")}/orders/${
          itemInvoice?.id
        }/download_sessions/${idSession}/download/`
      );
      // await cancelReqDownload(true);
    } else {
      handleAddOrderDownload();
      // config download with chunk or not
      handleDownLoad();
    }
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      <div className="header-order-item-page">
        <p>Total items: {dataOrder?.length}</p>
        <div className="flex items-center">
          {stateOrder === "Accept" && (
            <>
              {!visibleBtnDownload && (
                <Button
                  className="btn-download"
                  size="medium"
                  onClick={async () => {
                    setClickStartRequest(true);
                    getSessionDown();
                  }}
                  icon={
                    <img
                      className="img-svg-icon"
                      src={DowloadSvg}
                      alt="Info Circle"
                    />
                  }
                  title="Download"
                  iconPosition="after"
                  appearance="primary"
                  disabled={
                    loadingButton ||
                    selectedItems?.length === 0 ||
                    loadingRequestDownloadButton
                  }
                >
                  {(loadingButton || loadingRequestDownloadButton) && (
                    <Spinner size="extra-tiny" className="mr-2" />
                  )}
                  Request download
                </Button>
              )}

              {visibleBtnDownload && (
                <>
                  <div>
                    {(!loadingButtonDownload || visibleBtnPause) && (
                      <Button
                        className="btn-download !mr-2"
                        size="medium"
                        onClick={async () => {
                          breakDownloading = true;
                          setPreparing(true);
                          cancelReqDownload();
                        }}
                        appearance="primary"
                        disabled={
                          loadingButton ||
                          selectedItems?.length === 0 ||
                          preparing
                        }
                      >
                        {loadingButton && <Spinner size="extra-tiny" />}
                        Cancel Request Download
                      </Button>
                    )}
                    {visibleBtnPause && (
                      <Button
                        className="btn-download !mr-2"
                        size="medium"
                        onClick={async () => {
                          if (!pause) notify("Pause successed", "success");
                          pauseDownloading();
                        }}
                        disabled={
                          loadingButton ||
                          preparing ||
                          selectedItems?.length === 0
                        }
                        appearance="primary"
                        icon={
                          <img
                            className="img-svg-icon"
                            src={pause ? ContinueSvg : PauseSvg}
                            alt="Info Circle"
                          />
                        }
                      />
                    )}
                    {!loadingButtonDownload && !visibleBtnPause && (
                      <Button
                        className="btn-download"
                        size="medium"
                        onClick={async () => {
                          // handleAddOrderDownload();
                          // // config download with chunk or not
                          // // handleDownLoad();
                          // const data = await loadConfig(
                          //   process.env.REACT_APP_CFG_URL
                          // );
                          // window.open(
                          //   `${data?.REACT_APP_ENDPOINT}/orders/${itemInvoice?.id}/download_sessions/${idSession}/download/`
                          // );
                          // cancelReqDownload(true);

                          handleCheckDownload();
                        }}
                        icon={
                          <img
                            className="img-svg-icon"
                            src={DowloadSvg}
                            alt="Info Circle"
                          />
                        }
                        title="Download"
                        iconPosition="after"
                        appearance="primary"
                      >
                        Download
                      </Button>
                    )}
                    {((downloadRedux?.[
                      `percent-download_${itemInvoice?.id}`
                    ] !== 100 &&
                      loadingButtonDownload) ||
                      visibleBtnPause) && (
                      <span>
                        {preparing ? (
                          <>Preparing...</>
                        ) : (
                          <>
                            {pause ? "Downloaded " : "Downloading "}:
                            {(
                              downloadRedux?.[
                                `percent-download_${itemInvoice?.id}`
                              ] ?? 0
                            ).toFixed()}
                            %
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {stateOrder === "Submit" && (
            <span
              className="btn-action-page-item-order mr-4"
              onClick={() => handleCancelOrder()}
            >
              Cancel
            </span>
          )}
        </div>
      </div>

      {loading && <LoadingListComp />}

      {data?.length === 0 && !loading && (
        <center className="mt-3">
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}

      {data?.length !== 0 && !loading && (
        <div className="div-list-order mt-3">
          <CardItemDowload
            parent={itemInvoice}
            data={data}
            onSelectedItem={onSelectedItem}
            visibleBtnDownload={visibleBtnDownload}
            loadingRequestDownloadButton={loadingRequestDownloadButton}
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceItem;
