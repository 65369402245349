import { Spinner } from "@fluentui/react-spinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import FormAutoScan from "../../components/ManageDataset/FormAutoScan";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { getListTaskAsync } from "../../store/ManageScheduler";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { RootState } from "../../store/store";

const AutoScanPage = () => {
  const [loading, setLoading] = useState(false);

  const { page, limit } = useSelector((state: RootState) => state.paging);

  const [item, setItem] = useState(undefined);

  const location = useLocation();

  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "Dataset",
          path: "manage-dataset",
        },

        {
          key: 1,
          value: `Setting storage's collection ${location?.state?.item?.collection_id}`,
          path: `manage-dataset/setting/${location?.state?.item?.collection_id}`,
        },

        {
          key: 3,
          value: `Auto scan ${location?.state?.item?.storage?.description}`,
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-dataset/auto-scan"));
    dispatch(changeName("manage-dataset/auto-scan"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getListTaskAsync({
            size: limit,
            sortby: "modified",
            asc: false,
            page: page,

            filter: {
              task_types: ["SCAN_COLLECTION"],
            },
          })
        ).unwrap();

        let arrItem = res?.result?.filter(
          (item: any) =>
            item?.context?.collection_id ===
              location?.state?.item?.collection_id &&
            item?.context?.storage_id === location?.state?.item?.storage_id
        );
        setItem(arrItem?.[0]);

        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  return (
    <div
      style={{
        opacity: loading ? 0.3 : 1,
        cursor: loading ? "none" : "initial",
      }}
      className="px-6 py-6 !w-full"
    >
      {loading && (
        <center>
          <Spinner className="absolute top-1/2 left-1/2 z-[9999]" />
        </center>
      )}
      <FormAutoScan
        collection_id={location?.state?.item?.collection_id}
        storage_id={location?.state?.item?.storage_id}
        item={item}
      />
    </div>
  );
};

export default AutoScanPage;
