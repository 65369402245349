import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Pagination from "../Pagination/index";
import MapFooter from "../map/Footer";
export default function Footer(props: any) {
  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  return (
    <div
      className={
        "w-full h-full px-6 py-3 text-center text-white " +
        (pageName == "mainMap"
          ? "border-t border-s-background1 bg-s-background1"
          : "border-t border-s-line1 bg-s-background2")
      }
    >
      {pageName == "mainMap" ? (
        <MapFooter />
      ) : (
        <Pagination config={props?.config} />
      )}
    </div>
  );
}
