import { Dropdown, Option } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  changeSelectedDateScheduledJobFrom,
  changeSelectedDateScheduledJobTo,
  changeSelectedTimeScheduledJobFrom,
  changeSelectedTimeScheduledJobTo,
  changeTimePickerValueScheduledJobFrom,
  changeTimePickerValueScheduledJobTo,
  getTaskTypeAsync,
} from "../../store/ManageScheduler";
import { TimePickerWithDatePicker } from "../DatepickerCus";
import { changeTaskType } from "../../store/ManageScheduler";

import moment from "moment";

const FilterJobComp = (props: any) => {
  const {
    taskType,
    timePickerValueScheduledJobFrom,
    selectedTimeScheduledJobFrom,
    selectedDateScheduledJobFrom,
    timePickerValueScheduledJobTo,
    selectedTimeScheduledJobTo,
    selectedDateScheduledJobTo,
  } = useSelector((state: RootState) => state.managescheduler);

  const dispatch = useDispatch();

  const [arrTaskType, setArrTaskType] = useState<any>([]);

  useEffect(() => {
    (async function () {
      try {
        let res;
        res = await dispatch(
          // @ts-ignore
          getTaskTypeAsync()
        ).unwrap();

        setArrTaskType(res ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const onOptionSelectType = (e: any, data: any, type: string) => {
    if (type === "task_type") dispatch(changeTaskType(data?.selectedOptions));
  };

  const onSelectDate = (date: any, type: string) => {
    if (type === "from") {
      if (date) {
        dispatch(
          changeSelectedTimeScheduledJobFrom(
            new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              selectedTimeScheduledJobFrom.getHours(),
              selectedTimeScheduledJobFrom.getMinutes()
            )
          )
        );
        dispatch(changeSelectedDateScheduledJobFrom(date));
      } else {
        dispatch(changeSelectedDateScheduledJobFrom(null));
      }
    }
    if (type === "to") {
      {
        if (date) {
          dispatch(changeSelectedDateScheduledJobTo(date));
          dispatch(
            changeSelectedTimeScheduledJobTo(
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                selectedTimeScheduledJobTo.getHours(),
                selectedTimeScheduledJobTo.getMinutes()
              )
            )
          );
        } else {
          dispatch(changeSelectedDateScheduledJobTo(null));
        }
      }
    }
  };

  return (
    <>
      <div>
        <p className="mb-2 ">Task type</p>
        <Dropdown
          placeholder="Select type"
          className="div-dropdown"
          multiselect
          value={taskType?.length > 0 ? taskType?.join(", ") : null}
          onOptionSelect={(e: any, data: any) =>
            onOptionSelectType(e, data, "task_type")
          }
          selectedOptions={taskType ?? []}
        >
          {arrTaskType?.map((option: string) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>

        <div className="w-full mt-2">
          <p className="mb-2 mt-4">Scheduled from</p>
          <TimePickerWithDatePicker
            timePickerValue={timePickerValueScheduledJobFrom}
            onChangeTime={(data: any) => {
              dispatch(changeSelectedTimeScheduledJobFrom(data?.selectedTime));
              dispatch(
                changeTimePickerValueScheduledJobFrom(
                  data?.selectedTimeText ?? ""
                )
              );
            }}
            selectedTime={selectedTimeScheduledJobFrom}
            selectedDate={selectedDateScheduledJobFrom}
            onInput={(e: any) => {
              if (e?.target?.value)
                dispatch(
                  changeTimePickerValueScheduledJobFrom(e?.target?.value)
                );
            }}
            //date-picker
            onSelectDate={(date: any) => onSelectDate(date, "from")}
          />
        </div>
        <div className="w-full">
          <p className="mb-2">Scheduled to</p>
          <TimePickerWithDatePicker
            timePickerValue={timePickerValueScheduledJobTo}
            onChangeTime={(data: any) => {
              dispatch(changeSelectedTimeScheduledJobTo(data?.selectedTime));
              dispatch(
                changeTimePickerValueScheduledJobTo(
                  data?.selectedTimeText ?? ""
                )
              );
            }}
            selectedTime={selectedTimeScheduledJobTo}
            selectedDate={selectedDateScheduledJobTo}
            onInput={(e: any) => {
              if (e?.target?.value)
                dispatch(changeTimePickerValueScheduledJobTo(e?.target?.value));
            }}
            //date-picker
            onSelectDate={(date: any) => onSelectDate(date, "to")}
          />
        </div>
      </div>
    </>
  );
};

export default FilterJobComp;
