import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { Select, useId } from "@fluentui/react-components";

import { changeMapCrs } from "../../../../store/maplibreGLSlice";

export default function CrsSelector() {
  const dispatch = useDispatch();

  const options = [
    {
      value: "EPSG:4326",
      label: "EPSG:4326",
    },
    {
      value: "EPSG:3857",
      label: "EPSG:3857",
    },
  ];
  const selectId = useId();
  let crs: any = useSelector((state: RootState) => state.map?.crs);

  const onChangeCrs = (ev: any, data: any) => {
    dispatch(changeMapCrs(data.value));
  };

  return (
    <Select
      className="[&>*]:!bg-ink-300"
      id={"crs-" + selectId}
      onChange={(ev, data) => onChangeCrs(ev, data)}
      defaultValue={crs}
      size="medium"
      appearance="filled-lighter"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
