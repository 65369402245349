import moment from "moment";
import React from "react";

const InforNotiComp = (props: any) => {
  return (
    <div>
      <p>{props?.data?.title}</p>
      <div className="inline-block">
        <p className="text-color-des  mb-2 mt-1">
          {props?.data?.noti_type}{" "}
          {moment(props?.data?.sented).format("hh:mm:ss DD/MM/YYYY")}
        </p>
      </div>

      <p>
        {typeof props?.data?.content !== "object"
          ? props?.data?.content
          : props?.data?.content?.content}
      </p>
    </div>
  );
};

export default InforNotiComp;
