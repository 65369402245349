import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../store/pageSlice";

import FormEditUserComp from "../../components/ManageUser/FormEditUser";
import { useLocation } from "react-router-dom";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { resetState } from "../../store/pagingSlice";

const ManageUserDetailPage = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(changePath("/manage-user/id-user"));
    dispatch(changeName("manage-user/id-user"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  const dataBread = [
    {
      key: 1,
      value: "Manage user",
      path: "manage-user",
    },
    {
      key: 2,
      value:
        location?.state?.item?.traits?.name?.last &&
        location?.state?.item?.traits?.name?.first
          ? `Update user ${location?.state?.item?.traits?.name?.last}  ${location?.state?.item?.traits?.name?.first}`
          : `Update user ${location?.state?.item?.traits?.email}`,
    },
  ];

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
      // @ts-ignore
      dispatch(resetState());
    };
  }, []);

  return <FormEditUserComp />;
};

export default ManageUserDetailPage;
