import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardItemJobComp from "../../components/ManageScheduler/CardItemJob";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  changeFlagFilterJob,
  changeFlagFreshJob,
  changeFlagSortJob,
  getListJobAsync,
  resetStateFilterJob,
  resetStateSortJob,
} from "../../store/ManageScheduler";
import { changeName, changePath } from "../../store/pageSlice";
import { changePage, changeTotal } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import EmptySvg from "./../../assets/nodata.svg";

import LoadingListComp from "../../components/Loading/LoadingList";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { resetState } from "../../store/pagingSlice";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";

const ListJobPage = (props: any) => {
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state: RootState) => state.paging);
  const {
    sort_by_job,
    sort_order_job,
    flagFilterJob,
    flagSortJob,
    flagFreshJob,
    taskType,
    selectedTimeScheduledJobFrom,
    selectedTimeScheduledJobTo,
  } = useSelector((state: RootState) => state.managescheduler);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { key: 0, value: "Scheduler" },
        { key: 1, value: "Jobs" },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/scheduler/job"));
    dispatch(changeName("scheduler/job"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState());
      dispatch(changePage(0));
      dispatch(changeTotal(0));
      if (!window.location.pathname?.includes("scheduler/task")) {
        // @ts-ignore
        dispatch(resetStateFilterJob({}));
        dispatch(resetStateSortJob({}));
      }

      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    (async function () {
      if (!flagFilterJob || !flagFreshJob || !flagSortJob)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getListJobAsync({
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: page,

              filter: {
                scheduled_from: moment(
                  selectedTimeScheduledJobFrom
                ).toISOString(),
                scheduled_to: moment(selectedTimeScheduledJobTo).toISOString(),
                task_types: taskType,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
           setTimeout(() => {
             dispatch(changeVisibleSpinner(false));
             setLoading(false);
           }, 500);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilterJob)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          dispatch(changeTotal(0));
          dispatch(changePage(0));

          const res = await dispatch(
            // @ts-ignore
            getListJobAsync({
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: 0,

              filter: {
                scheduled_from: moment(
                  selectedTimeScheduledJobFrom
                ).toISOString(),
                scheduled_to: moment(selectedTimeScheduledJobTo).toISOString(),
                task_types: taskType,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagFilterJob(false));
           setTimeout(() => {
             dispatch(changeVisibleSpinner(false));
             setLoading(false);
           }, 500);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilterJob(false));
        }
    })();
  }, [flagFilterJob]);

  useEffect(() => {
    (async function () {
      if (flagSortJob)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          const res = await dispatch(
            // @ts-ignore
            getListJobAsync({
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: 0,

              filter: {
                scheduled_from: moment(
                  selectedTimeScheduledJobFrom
                ).toISOString(),
                scheduled_to: moment(selectedTimeScheduledJobTo).toISOString(),

                task_types: taskType,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagSortJob(false));
           setTimeout(() => {
             dispatch(changeVisibleSpinner(false));
             setLoading(false);
           }, 500);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagSortJob(false));
        }
    })();
  }, [flagSortJob]);

  useEffect(() => {
    (async function () {
      if (flagFreshJob)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          const res = await dispatch(
            // @ts-ignore
            getListJobAsync({
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: 0,

              filter: {
                task_types: [
                  "SCAN_COLLECTION",
                  "CLEAN_EXPIRED_DLSESSION_DATA",
                  "EXPIRE_SCAN_SESSIONS",
                ],
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagFreshJob(false));
           setTimeout(() => {
             dispatch(changeVisibleSpinner(false));
             setLoading(false);
           }, 500);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFreshJob(false));
        }
    })();
  }, [flagFreshJob]);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  return (
    <div className="px-6 py-6 !w-full div-list !relative">
      {loading && <LoadingListComp />}
      {visibleSpinner && !loading && <LoadingGlobalComp />}

      {!data?.length && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}
      {data?.length !== 0 && !loading && (
        <div className="w-full">
          <CardItemJobComp data={data} />
        </div>
      )}
    </div>
  );
};

export default ListJobPage;
