import
  {
    Button,
    Card,
    CardHeader,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Text,
  } from "@fluentui/react-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentRole } from "../../store/ManageUserSlice";
import { RootState } from "../../store/store";
import { arrRole } from "../../utils/constants";

import { MD5_TEXT_ROLE } from "../../utils/constants";

import { useTranslation } from "react-i18next";
import useCustomNavigate from "../../hook/useCustomNavigate";

const FormChangeRoleComp = () => {

  const { t } = useTranslation();

  const { currentRole } = useSelector((state: RootState) => state.manageuser);
  const [valRole, setValRole] = useState(currentRole);
  const [loading, setLoading] = useState(false);
   const navigate = useCustomNavigate();
  const dispatch = useDispatch();

  const findRoleLabel = (r: string) => {
    let index = arrRole?.findIndex(x => x.value == r);
    return index >= 0 ? arrRole[index].label : ""
  }

  return (
    <>
      <div className="div-form-storage">
        <div className="flex w-full justify-between">
          <div className="w-full grid grid-rows-1 grid-flow-col gap-4">
            {arrRole?.map((item: any, index: any) => (
              <Card
                key={index}
                className={
                  item.value === valRole ? "card-default-active" : "card-default"
                }
                orientation="horizontal"
                floatingAction={
                  // @ts-ignore
                  <RadioGroup value={valRole}>
                    <Radio
                      value={item.value}
                      onChange={(e: any, val: any) => {
                        setValRole(val?.value);
                      }}
                    />
                  </RadioGroup>
                }
              >
                <CardHeader header={<Text weight="semibold">{item.label}</Text>} />
              </Card>
            ))}
          </div>
        </div>

        <div className="flex absolute bottom-2 right-2">
          <Button
            appearance="outline"
            className="btn-extra-action"
            onClick={() => navigate(`/`)}
          >
            {t('Cancel')}
          </Button>

          <Popover appearance="inverted">
            <PopoverTrigger>
              <Button
                appearance="outline"
                className="btn-action"
                disabled={currentRole === valRole ? true : false || loading}
              >
                {t('Update')}
              </Button>
            </PopoverTrigger>

            <PopoverSurface tabIndex={-1}>
              <div>
                <h3>
                  {t('Are you sure you want to change role')}&nbsp;{findRoleLabel(currentRole)}&nbsp;{t('to')}&nbsp;{findRoleLabel(valRole)}?
                </h3>
                <center>
                  <Button
                    appearance="subtle"
                    onClick={() => {
                      dispatch(changeCurrentRole(valRole));
                      // @ts-ignore
                      localStorage.setItem(MD5_TEXT_ROLE, valRole);
                    }}
                  >
                    {t('Ok')}
                  </Button>
                </center>
              </div>
            </PopoverSurface>
          </Popover>
        </div>
      </div>
    </>
  );
};

export default FormChangeRoleComp;
