import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  ForwardedRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import bbox from "@turf/bbox";
import area from "@turf/area";

import {
  setMap,
  changeMousePointer,
  changeCenter,
  changeZoomLevel,
} from "../../../store/maplibreGLSlice";
import {
  setDraw,
  setIsDrawing,
  setDrawingType,
  addFeature,
  updateFeature,
  changeActiveFeature,
} from "../../../store/drawSlice";
import {
  changeCallSearch,
  changeCriteria,
  changeShowCriteria,
} from "../../../store/filterSlice";
import { changeFlagFilterItem } from "../../../store/exploreSlice";

import { getSearchHisByIdAsync } from "../../../store/ManageSearchHis";

import { RootState } from "../../../store/store";

import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from "../../../utils/drawing/RectangleUtil";
import { centroid } from "../../../utils/map/CoordinatesUtils";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { v4 as uuidv4 } from "uuid";

import { useSearchParams } from "react-router-dom";
import { getItemCollectionByIdAsync } from "../../../store/exploreSlice";
import { changeVisibleSpinner } from "../../../store/NavSlice";

const MaplibreGL = forwardRef((props: any, ref?: ForwardedRef<unknown>) => {
  useImperativeHandle(ref, () => {
    return {
      changeSourceDataHover: changeSourceDataHover,
      updateFeaturesText: updateFeaturesText,
      changeSourceDataCart: changeSourceDataCart,
    };
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const map = useRef({});

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  const { itemHover } = useSelector((state: RootState) => state.exploredataset);

  // get center, zoom level map from redux
  let center = useSelector((state: RootState) => state.map?.center);
  let zoom = useSelector((state: RootState) =>
    pageName == "main-map" ? state.map?.zoomLevel : 3.8
  );

  let isDrawing: boolean = useSelector(
    (state: RootState) => state.draw?.isDrawing
  );

  let draw: any = useSelector((state: RootState) => state.draw?.draw);

  const [maplibreGL, setMaplibreGL] = useState<maplibregl.Map | any>({});
  // data of items collection
  const [sourceData, setSourceData] = useState<{
    type: string;
    features: Array<any>;
  }>({
    type: "FeatureCollection",
    features: [],
  });
  const [sourceId, setSourceId] = useState<string>(uuidv4());

  const [searchParams, setSearchParams] = useSearchParams();
  let historyId = searchParams.get("history");

  // useEffect(() => {
  //   //if (map.current) return;
  //   if (!draw) {
  //     initMap();
  //   }
  // }, [props?.intersects]);

  // useEffect(() => {
  //   //if (map.current) return;
  //   if (!draw && !historyId) {
  //     initMap();
  //   }
  // }, [draw]);

  useEffect(() => {
    dispatch(changeVisibleSpinner(true));

    if (!draw) {
      initMap();
    }

    dispatch(changeVisibleSpinner(false));
  }, [draw]);

  useEffect(() => {
    // draw
    if (props?.items) {
      drawItems(props?.items);
    }
  }, [maplibreGL]);

  useEffect(() => {
    return () => {
      dispatch(setDraw(undefined));
    };
  }, []);

  useEffect(() => {
    if (maplibreGL && Object.keys(maplibreGL).length) {
      if (isDrawing) {
        // @ts-ignore
        maplibreGL.getCanvas().style.cursor = "crosshair";
      } else {
        // @ts-ignore
        maplibreGL.getCanvas().style.cursor = "";
      }
    }
  }, [isDrawing]);

  useEffect(() => {
    // draw
    if (maplibreGL) {
      // debugger
      drawItems(props?.items);
    }
  }, [props?.items]);

  useEffect(() => {
    // hover item dataset for admin. (Manage dataset)
    if (itemHover && Object.keys(itemHover).length) {
      changeSourceDataHover(itemHover);
    } else {
      changeSourceDataHover({
        type: "FeatureCollection",
        features: [],
      });
    }
  }, [itemHover]);

  const initMap = async () => {
    var maplibre = new maplibregl.Map({
      container: "map",
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [
              localStorage.getItem("REACT_APP_BASE_MAP")
                ? localStorage.getItem("REACT_APP_BASE_MAP") + ""
                : "https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}",
            ],
            tileSize: 256,
          },
        },
        glyphs: localStorage.getItem("REACT_APP_GLYPHS")
          ? localStorage.getItem("REACT_APP_GLYPHS") + ""
          : "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
        //"glyphs": "http://192.168.14.112:8000/{fontstack}/{range}.pbf",
        layers: [
          {
            id: "Google Satellite",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [center.lng, center.lat],
      zoom: zoom,
    });
    map.current = maplibre;

    setMaplibreGL(maplibre);

    setTimeout(() => {
      // init items collection layer
      initItemsCollectionLayer(maplibre);

      initHoverLayer(maplibre);

      initTextlayer(maplibre);

      initCartLayer(maplibre);
    }, 50);
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";

    const draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        polygon: false,
        point: false,
        trash: false,
      },
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle_drag: DrawRectangle,
      },
      styles: [
        {
          id: "gl-draw-polygon-fill-inactive",
          type: "fill",
          filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "fill-color": "red",
            "fill-outline-color": "#3bb2d0",
            "fill-opacity": 0.3,
          },
        },
        {
          id: "gl-draw-polygon-fill-active",
          type: "fill",
          filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
          paint: {
            "fill-color": "#fbb03b",
            "fill-outline-color": "#fbb03b",
            "fill-opacity": 0.1,
          },
        },
        {
          id: "gl-draw-polygon-midpoint",
          type: "circle",
          filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
          paint: {
            "circle-radius": 3,
            "circle-color": "#fbb03b",
          },
        },
        {
          id: "gl-draw-polygon-stroke-inactive",
          type: "line",
          filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#3bb2d0",
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#fbb03b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 5,
            "circle-color": "#fff",
          },
        },
        {
          id: "gl-draw-polygon-and-line-vertex-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 3,
            "circle-color": "#fbb03b",
          },
        },

        {
          id: "gl-draw-polygon-fill-static",
          type: "fill",
          filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
          paint: {
            "fill-color": "#404040",
            "fill-outline-color": "#404040",
            "fill-opacity": 0.1,
          },
        },
        {
          id: "gl-draw-polygon-stroke-static",
          type: "line",
          filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#404040",
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-polygon-color-picker",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["has", "user_portColor"],
          ],
          paint: {
            "fill-color": ["get", "user_portColor"],
            "fill-outline-color": ["get", "user_portColor"],
            "fill-opacity": 0.25,
          },
        },
      ],
    });

    // @ts-ignore
    maplibre.addControl(draw, "top-left");
    maplibre.on("draw.create", onDrawing);
    maplibre.on("draw.delete", onDrawing);
    maplibre.on("draw.update", onDrawing);

    var polygonID: any = null;

    // When a click event occurs on a feature in the items collection layer
    maplibre.on("click", sourceId + "_fill_click", async (e: any) => {
      if (e.features.length > 0) {
        if (polygonID) {
          maplibre.removeFeatureState({
            source: sourceId,
            id: polygonID,
          });
        }

        polygonID = e.features[0]?.id;

        maplibre.setFeatureState(
          {
            source: sourceId,
            id: polygonID,
          },
          {
            clicked: true,
          }
        );
      }

      props.onSelectFeatureChange(e.features);
    });

    maplibre.on("mousemove", (e: any) => {
      dispatch(
        changeMousePointer({
          lnglat: e.lngLat,
          point: e.point,
        })
      );
    });

    maplibre.on("moveend", (e: any) => {
      // console.log(e.target.getCenter(), "e.target.getCenter())");
      dispatch(changeCenter(e.target.getCenter()));
      dispatch(changeZoomLevel(e.target.getZoom()));
      props.mapChangedBounds(e.target.getBounds());
    });

    let hoveredStateId: any = null;

    // When the user moves their mouse over the items collection layer, we'll update the
    // feature state for the feature under the mouse.
    maplibre.on("mousemove", sourceId + "_fill_hover", (e: any) => {
      if (e.features.length > 0) {
        if (hoveredStateId) {
          maplibre.setFeatureState(
            { source: sourceId, id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        maplibre.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: true }
        );
      }
    });

    // When the mouse leaves the items collection layer, update the feature state of the
    // previously hovered feature.
    maplibre.on("mouseleave", sourceId + "_fill_hover", () => {
      if (hoveredStateId) {
        maplibre.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
    });

    // Change the cursor to a pointer when the mouse is over the items collection layer.
    maplibre.on("mouseenter", sourceId + "_fill_hover", () => {
      maplibre.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    maplibre.on("mouseleave", sourceId + "_fill_hover", () => {
      maplibre.getCanvas().style.cursor = "";
    });

    dispatch(setMap(maplibre));
    dispatch(setDraw(draw));

    // fit to bounds
    if (props.coordinates && props.coordinates.length) {
      let bounds = new maplibregl.LngLatBounds(
        [props.coordinates[0], props.coordinates[1]],
        [props.coordinates[2], props.coordinates[3]]
      );
      maplibre.fitBounds(bounds);
    }

    props.mapChangedBounds(maplibre.getBounds());

    // check query history
    if (historyId) {
      getSearchHistory(historyId, draw);
    }

    if (props?.intersects) {
      let feature = {
        geometry: props.intersects,
        properties: {},
        type: "Feature",
        id: "shape" + "_" + new Date().getTime(),
      };

      //@ts-ignore
      draw.add(feature);
      dispatch(addFeature(feature));
      dispatch(changeActiveFeature(feature));
    }
  };

  const onDrawing = (e: any) => {
    switch (e["type"]) {
      case "draw.create":
        dispatch(changeFlagFilterItem(false));
        dispatch(setIsDrawing(false));
        dispatch(setDrawingType(null));
        dispatch(addFeature(e["features"][0]));
        dispatch(changeActiveFeature(e["features"][0]));
        break;
      case "draw.update":
        dispatch(updateFeature(e["features"][0]));
        props.onSearchWithFeature(e["features"][0]);
        break;
      case "draw.delete":
        break;
    }
  };

  const drawItems = async (items: Array<any>) => {
    let data = { ...sourceData }; // copy object
    let centerPoint: any;

    if (items?.length > (data?.features.length ?? 0)) {
      for (let i = 0; i < items?.length; i++) {
        if (
          items[i] &&
          data?.features?.findIndex((x) => x?.properties?.id === items[i]?.id) <
            0
        ) {
          // add

          let properties = items[i]?.properties
            ? { ...items[i]?.properties }
            : {};
          properties.id = properties?.id ? properties?.id : items[i]?.id;

          properties.collection = items[i]?.collection ?? "";
          data.features = [
            ...data?.features,
            {
              id: data?.features.length + 1,
              type: items[i]?.type ? items[i]?.type : "Feature",
              geometry: items[i]?.geometry,
              properties,
            },
          ];

          centerPoint = centroid(items[i]?.geometry);
        }
      }

      // items?.forEach(async (item) => {

      //   if (
      //     item &&
      //     data?.features?.findIndex((x) => x?.properties?.id === item?.id) < 0
      //   ) {
      //     // add

      //     // const res = await dispatch(
      //     //   // @ts-ignore
      //     //   getItemCollectionByIdAsync({
      //     //     collectionId: item?.collection,
      //     //     itemId: item?.id,
      //     //   })
      //     // ).unwrap();

      //     // let assets = res?.data?.assets ?? {};
      //     // let collection = res?.data?.collection ?? "";
      //     let properties = item?.properties ? { ...item?.properties } : {};
      //     properties.id = properties?.id ? properties?.id : item?.id;
      //     data.features = [
      //       ...data?.features,
      //       {
      //         id: data?.features.length + 1,
      //         type: item?.type ? item?.type : "Feature",
      //         geometry: item?.geometry,
      //         properties,
      //         // assets: assets,
      //         // collection: collection,
      //       },
      //     ];

      //     centerPoint = centroid(item?.geometry);
      //   }
      // });
    } else {
      if (data?.features) {
        data.features = data?.features.filter((x: any) =>
          items?.map((y: any) => y?.id).includes(x?.properties?.id)
        );
      }
    }

    setSourceData(data);

    setTimeout(() => {
      // @ts-ignore
      if (Object.keys(maplibreGL).length && maplibreGL.getSource(sourceId)) {
        // @ts-ignore
        maplibreGL.getSource(sourceId).setData(data);
        if (centerPoint) {
          // @ts-ignore
          // maplibreGL.flyTo({
          //   center: centerPoint,
          //   essential: true // this animation is considered essential with respect to prefers-reduced-motion
          // });
          maplibreGL.panTo(centerPoint);
        }
      }
    }, 200);
  };

  // init layer of items collection
  const initItemsCollectionLayer = (maplibre: maplibregl.Map) => {
    maplibre.addSource(sourceId, {
      type: "geojson",
      data: sourceData,
    });
    maplibre.addLayer({
      id: sourceId + "_fill_hover",
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#00E676", // if selected true,
          "#fed766",
        ],
        "fill-opacity": 0.4,
      },
    });

    maplibre.addLayer({
      id: sourceId + "_fill_click",
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "clicked"], false],
          "#00E676", // if selected true,
          "#fed766",
        ],
        "fill-opacity": 0.4,
      },
    });
    // Add a black outline around the polygon.
    maplibre.addLayer({
      id: sourceId + "_outline",
      type: "line",
      source: sourceId,
      layout: {},
      paint: {
        "line-color": "#fed766 ",
        "line-width": 2,
      },
    });
  };

  // init hover layer of items collection
  const initHoverLayer = (maplibre: maplibregl.Map) => {
    maplibre.addSource(sourceId + "_hover", {
      type: "geojson",
      data: sourceData,
    });
    maplibre.addLayer({
      id: sourceId + "_hover_fill",
      type: "fill",
      source: sourceId + "_hover",
      layout: {},
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#FF0000", // if selected true,
          "#00E676",
        ],
        "fill-opacity": 0.4,
      },
    });

    maplibre.addLayer({
      id: sourceId + "_hover_fill",
      type: "fill",
      source: sourceId + "_click",
      layout: {},
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "clicked"], false],
          "#FF0000", // if selected true,
          "#00E676",
        ],
        "fill-opacity": 0.4,
      },
    });
    // Add a black outline around the polygon.
    maplibre.addLayer({
      id: sourceId + "_hover_outline",
      type: "line",
      source: sourceId + "_hover",
      layout: {},
      paint: {
        "line-color": "#fed766",
        "line-width": 2,
      },
    });
  };

  // init cart layer of items collection
  const initCartLayer = (maplibre: maplibregl.Map) => {
    maplibre.addSource(sourceId + "_cart", {
      type: "geojson",
      data: sourceData,
    });
    maplibre.addLayer({
      id: sourceId + "_cart_fill",
      type: "fill",
      source: sourceId + "_cart",
      layout: {},
      paint: {
        "fill-color": "#00E676 ",
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.5,
          0.25,
        ],
      },
    });
    // Add a black outline around the polygon.
    maplibre.addLayer({
      id: sourceId + "_cart_outline",
      type: "line",
      source: sourceId + "_cart",
      layout: {},
      paint: {
        "line-color": "#00E676 ",
        "line-width": 2,
      },
    });
  };

  const initTextlayer = (maplibre: maplibregl.Map) => {
    maplibre.addSource("mapbox-gl-draw-text-source", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    // Add a symbol layer
    maplibre.addLayer({
      id: "mapbox-gl-draw-text",
      type: "symbol",
      source: "mapbox-gl-draw-text-source",
      layout: {
        // get the title name from the source's "title" property
        "text-field": ["get", "displayText"],
        "text-offset": [0, -2.5],
        "text-anchor": "top",
        "text-size": 12,
        "text-justify": "center",
      },
      paint: {
        "text-color": "#f03b20",
        "text-halo-color": "white",
        "text-halo-width": 1.5,
        "text-halo-blur": 1,
      },
    });
  };

  const updateFeaturesText = (features: Array<any>) => {
    if (
      Object.keys(maplibreGL).length &&
      maplibreGL.getSource("mapbox-gl-draw-text-source")
    ) {
      let geojson = {
        type: "FeatureCollection",
        features: [],
      };
      if (features && features.length) {
        let index = 0;
        features.forEach((f) => {
          if (f) {
            index++;

            const extent = bbox(f);
            const outArea = area(f);
            let output;
            if (outArea > 10000) {
              output =
                Math.round((outArea / 1000000) * 100) / 100 + " " + "km2";
            } else {
              output = Math.round(outArea * 100) / 100 + " " + "m2";
            }

            if (extent && extent.length == 4) {
              //@ts-ignore
              geojson["features"].push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [extent[0], extent[3]],
                },
                properties: {
                  displayText: t("Polygon") + " " + index + " ~" + output,
                },
              });
            }
          }
        });
      }
      maplibreGL.getSource("mapbox-gl-draw-text-source").setData(geojson);
    }
  };

  const changeSourceDataHover = (data: any) => {
    if (
      Object.keys(maplibreGL).length &&
      maplibreGL.getSource(sourceId + "_hover")
    ) {
      // @ts-ignore
      maplibreGL.getSource(sourceId + "_hover").setData(data);
    }
  };

  const changeSourceDataCart = (data: any) => {
    if (
      Object.keys(maplibreGL).length &&
      maplibreGL.getSource(sourceId + "_cart")
    ) {
      // @ts-ignore
      maplibreGL.getSource(sourceId + "_cart").setData(data);
    }
  };

  const changeSourceData = (sId: string, data: any) => {
    if (Object.keys(maplibreGL).length && maplibreGL.getSource(sId)) {
      // @ts-ignore
      maplibreGL.getSource(sId).setData(data);
    }
  };

  const getBoundsFromCoordinates = (coordinates: Array<any>) => {
    const bounds = coordinates.reduce((bounds, coord) => {
      return bounds.extend(coord);
    }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
    return bounds;
  };

  // nen de o file map ngoai
  const getSearchHistory = async (id: string, draw: MapboxDraw) => {
    const response = await dispatch(
      // @ts-ignore
      getSearchHisByIdAsync(id)
    ).unwrap();

    if (response && response.status === 200) {
      let payload: any = {
        limit: 25,
        page: 0,
        sortby: [{ field: "properties.datetime", direction: "desc" }],
      };
      if (response.data?.query?.datetime)
        payload.datetime = response.data?.query?.datetime;
      if (response.data?.query?.query)
        payload.query = response.data?.query?.query;
      if (response.data?.query?.query?.platform)
        payload.platform = response.data?.query?.query?.platform;

      if (response.data?.query?.intersects) {
        payload.intersects = response.data?.query?.intersects;
        let feature = {
          geometry: payload.intersects,
          properties: {},
          type: "Feature",
          id: "shape" + "_" + new Date().getTime(),
        };
        //@ts-ignore
        draw.add(feature);

        dispatch(addFeature(feature));

        dispatch(changeActiveFeature(feature));
      }

      dispatch(changeFlagFilterItem(false));
      // dispatch(changeCallSearch(true));
      dispatch(changeCriteria(payload));
    }
  };

  console.log(draw, "draw out side");

  return (
    <div
      className={
        "map-wrap relative w-full " +
        (props.heightClass ? props.heightClass : "h-[calc(100vh-58px-57px)]")
      }
    >
      <div
        id="map"
        className={
          "absolute w-full h-full" + (pageName === "main-map" ? "" : " rounded")
        }
      ></div>
    </div>
  );
});
export default MaplibreGL;
