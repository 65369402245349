import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";

import EmptySvg from "./../../assets/nodata.svg";

import { changeSateVisibleReadNoti } from "../../store/DrawerSlice";
import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeSateItemRead,
  deleteNotiAsync,
  getListAsync,
  resetFilter,
  resetSortOption,
  sendNotiAsync,
} from "../../store/notificationsSlice";

import moment from "moment";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import CardNotiComp from "../../components/Notification/CardNoti";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeStateFilter } from "../../store/ManageQuotaSlice";
import { getListUserAsync } from "../../store/ManageUserSlice";
import {
  changeStateBtnCreate,
  changeVisibleSpinner,
} from "../../store/NavSlice";
import { timeout } from "../../utils/constants";
import { changeName, changePath } from "./../../store/pageSlice";
import { changePage, changeTotal, resetState } from "./../../store/pagingSlice";

const dataBread = [
  {
    key: 0,
    value: "Manage notification",
    path: "manage-notification",
  },
];

const ManageNotificationPage = () => {
  const dispatch = useDispatch();

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const {
    typeFilter,
    sentAtToFilter,
    sentAtFromFilter,
    keywordFilter,
    sort_by,
    sort_order,
    flagFresh,
    flagFilter,
    flagSort,
  } = useSelector((state: RootState) => state.notifications);

  const [arrUser, setArrUser] = useState([]);
  useEffect(() => {
    (async function () {
      try {
        setLoading(true);

        dispatch(changeVisibleSpinner(true));

        const resUser = await dispatch(
          // @ts-ignore
          getListUserAsync()
        ).unwrap();

        let a: any = [];

        resUser?.map((item: any, ind: any) => {
          a.push(item?.id);
        });

        setArrUser(a);

        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        setLoading(false);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState());
      if (!window.location.pathname?.includes("manage-notification")) {
        // @ts-ignore
        dispatch(resetFilter({}));
        dispatch(resetSortOption({}));
      }
     

      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-notification"));
    dispatch(changeName("manage-notification"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    (async function () {
      if (!flagFresh || !flagFilter || !flagSort)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getListAsync({
              size: limit,
              sortby: sort_by,
              asc: sort_order === "asc" ? true : false,
              page: page,

              filter: {
                keyword:
                  keywordFilter && keywordFilter !== "" ? keywordFilter : null,
                noti_types:
                  typeFilter && typeFilter?.length > 0 ? typeFilter : null,
                sent_from:
                  sentAtFromFilter && sentAtFromFilter !== ""
                    ? sentAtFromFilter
                    : null,
                sent_to:
                  sentAtToFilter && sentAtToFilter !== ""
                    ? moment(sentAtToFilter).endOf("days").toISOString()
                    : null,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilter)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          const res = await dispatch(
            // @ts-ignore
            getListAsync({
              size: limit,
              sortby: sort_by,
              asc: sort_order === "asc" ? true : false,
              page: 0,

              filter: {
                keyword:
                  keywordFilter && keywordFilter !== "" ? keywordFilter : null,
                noti_types:
                  typeFilter && typeFilter?.length > 0 ? typeFilter : null,
                sent_from:
                  sentAtFromFilter && sentAtFromFilter !== ""
                    ? sentAtFromFilter
                    : null,
                sent_to:
                  sentAtToFilter && sentAtToFilter !== ""
                    ? moment(sentAtToFilter).endOf("days").toISOString()
                    : null,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagFilter(false));
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilter(false));
        }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          const res = await dispatch(
            // @ts-ignore
            getListAsync({
              size: limit,
              sortby: sort_by,
              asc: sort_order === "asc" ? true : false,
              page: 0,

              filter: {
                keyword:
                  keywordFilter && keywordFilter !== "" ? keywordFilter : null,
                noti_types:
                  typeFilter && typeFilter?.length > 0 ? typeFilter : null,
                sent_from:
                  sentAtFromFilter && sentAtFromFilter !== ""
                    ? sentAtFromFilter
                    : null,
                sent_to:
                  sentAtToFilter && sentAtToFilter !== ""
                    ? moment(sentAtToFilter).endOf("days").toISOString()
                    : null,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagSort(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagSort(false));
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          const res = await dispatch(
            // @ts-ignore
            getListAsync({
              size: limit,
              sortby: "modified",
              asc: false,
              page: 0,
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagFresh(false));
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFresh(false));
        }
    })();
  }, [flagFresh]);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(changeStateFilter());
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: "/manage-notification/add",
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
    };
  }, []);

  const getData = async () => {
    const res = await dispatch(
      // @ts-ignore
      getListAsync({
        size: limit,
        sortby: sort_by,
        asc: sort_order === "asc" ? true : false,
        page: page,
      })
    ).unwrap();

    setData(res?.result ?? []);
    dispatch(changeTotal(res?.paging?.total ?? 0));
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const deleNoti = async (id: any) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteNotiAsync({ id: id })).unwrap();
    if (res?.status === 200) {
      notify("Delete successfully");
      getData();
      dispatch(changeVisibleSpinner(false));
    } else {
      notify("Delete fail");

      dispatch(changeVisibleSpinner(false));
    }
  };

  const handleReadItem = async (item: any) => {
    // dispatch(toggleDrawerNoti(false));
    dispatch(changeSateVisibleReadNoti(true));
    dispatch(changeSateItemRead(item));
    // if (item?.readed) {
    //   dispatch(changeSateVisibleReadNoti(true));
    //   dispatch(changeSateItemRead(item));
    // } else {
    //   setLoading(true);
    //   const res = await dispatch(
    //     // @ts-ignore
    //     userUpdateNotiAsync({ id: item?.notification_id, pay: { read: true } })
    //   ).unwrap();

    //   if (res?.status === 200) {
    //     dispatch(changeSateVisibleReadNoti(true));
    //     dispatch(changeSateItemRead(item));
    //     setLoading(false);
    //   }
    // }
  };

  const sendTo = async (id: any) => {
    dispatch(changeVisibleSpinner(true));
    const resSend = await dispatch(
      // @ts-ignore
      sendNotiAsync({
        id: id,
        arrRecipients: arrUser,
      })
    ).unwrap();

    if (resSend?.status === 200) {
      dispatch(changeVisibleSpinner(false));
      notify("Send notication successfully");
    } else {
      dispatch(changeVisibleSpinner(false));
      notify("Send notication fail");
    }

    await getData();
  };
  return (
    <>
      <div className="px-6 py-6 !w-full div-list relative">
        {loading && <LoadingListComp />}
        {visibleSpinner && !loading && <LoadingGlobalComp />}
        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div className="!w-full">
            <CardNotiComp
              data={data}
              deleNoti={deleNoti}
              getData={getData}
              handleReadItem={handleReadItem}
              sendTo={sendTo}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ManageNotificationPage;
