export const getIsReloadPage = () => {
  return localStorage.getItem("reload_page") ?? "FALSE";
};

export const updateIsReloadPage = (value: "TRUE" | "FALSE") => {
  localStorage.setItem("reload_page", value);
};

export const addOrderDownload = ({
  orderId,
  items,
  idSession,
}: {
  orderId: string;
  items: number;
  idSession: string;
}) => {
  localStorage.setItem(
    `order_download_${orderId}`,
    JSON.stringify({ orderId, items, idSession })
  );
};

export const getOrderDownload = () => {
  const keysLocalStorage = Object.keys(localStorage);
  let res: any[] = [];
  keysLocalStorage.map((key) => {
    if (key !== null && key.search("order_download") !== -1) {
      const item = localStorage.getItem(key ?? "");
      res = res.concat(JSON.parse(item ?? "{}"));
    }
  });
  return res;
};

export const updateOrderDownload = ({
  orderId,
  items,
  idSession,
}: {
  orderId: string;
  items: number;
  idSession: string;
}) => {
  // localStorage.setItem("order_download", JSON.stringify(arr));
  const keysLocalStorage = Object.keys(localStorage);
  keysLocalStorage.map((key) => {
    if (key === `order_download_${orderId}`) {
      localStorage.setItem(key, JSON.stringify({ orderId, items, idSession }));
    }
  });
};

export const removeOrderDownload = (orderId: string) => {
  localStorage.removeItem("order_download_" + orderId);
};

export const getArrOrderDownloadRedux = (downloadRedux: any) => {
  let arr: any[] = [];
  Object.keys(downloadRedux).map((key) => {
    if (key.search("order-download") !== -1 && downloadRedux?.[key]) {
      arr = arr.concat(downloadRedux?.[key]);
    }
  });
  return arr;
};
