import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearCriteria } from "../../store/filterSlice";
import { changeName, changePath } from "../../store/pageSlice";
import {
  addItemOderAsync,
  changeCartItems,
  changeTotalCart,
  deleteItemInOrderAsync,
  getItemInOrderAsync,
} from "./../../store/ManageOrderSlice";
import {
  changeArrPathNextPage,
  changeFlagFilterItem,
  changeFlagRefeshItem,
  changeFlagReloadCart,
  changeItemViewInfor,
  changeSate,
  changeStateFilter,
  getFeaturedByIdAsync,
  getItemCollectionByIdAsync,
  getItemsByCollectionIdAsync,
  getItemsCollectionByPathAsync,
  getThumbnailItemDatasetAsync as getThumbnail,
} from "./../../store/exploreSlice";
import { updateAlert } from "./../../store/notificationsSlice";
import { resetNextPage, resetState } from "./../../store/pagingSlice";
import { RootState } from "./../../store/store";

import EmptySvg from "./../../assets/nodata.svg";

import { timeout } from "../../utils/constants";

import { useToastController } from "@fluentui/react-components";
import moment from "moment";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  changeStateVisibleDrawerInforItemDataset,
  resetAllStateDrawer,
} from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import CardItemDetail from "./../../components/CardItemDetail";

export default function DatasetUserIdPage() {
  const { t } = useTranslation();

  let { id } = useParams();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const { cartId } = useSelector((state: RootState) => state.manageorder);

  const [dataItems, setDataItems] = useState([]);

  const { indPathNext, flagPage } = useSelector(
    (state: RootState) => state.paging
  );
  const { arrPathNextPage } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const {
    start_at,
    end_at,
    cloudCoverFrom,
    cloudCoverTo,
    flagFilterItem,
    flagRefeshItem,
    idSearch,
    itemViewInfor,
  } = useSelector((state: RootState) => state.exploredataset);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  useEffect(() => {
    dispatch(changePath("/dataset-item"));
    dispatch(changeName("dataset-item"));

    return () => {
      dispatch(changeSate({}));
      dispatch(changeStateFilter({}));
      // @ts-ignore
      dispatch(resetState());
      dispatch(changePath(null));
      dispatch(changeName(null));
      dispatch(clearCriteria());
      // @ts-ignore
      dispatch(resetNextPage({}));
      dispatch(changeArrPathNextPage([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    if (flagFilterItem) {
      getData();
    }
  }, [flagFilterItem]);

  useEffect(() => {
    if (flagRefeshItem) {
      getData();
    }
  }, [flagRefeshItem]);

  useEffect(() => {
    (async function () {
      if (!flagFilterItem || !flagRefeshItem)
        try {
          dispatch(changeVisibleSpinner(true));

          //  get thong tin hien thi o breadcrumb
          const resReatured = await dispatch(
            // @ts-ignore
            getFeaturedByIdAsync(
              `${window.location.pathname.split("/")?.[2] || id}`
            )
          ).unwrap();

          if (resReatured.status === 200) {
            dispatch(
              changeDataBreadcrumb([
                {
                  key: "dataset",
                  value: t("Catalog"),
                  path: "dataset",
                },
                {
                  key: resReatured?.data?.id,
                  value: resReatured?.data?.id,
                },
              ])
            );
          }

          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
        }
    })();
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));

        getData();

        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, [indPathNext]);

  const handleDelete = async (item: any) => {
    dispatch(changeVisibleSpinner(true));

    const res = await dispatch(
      // @ts-ignore
      deleteItemInOrderAsync({
        idOrder: cartId,
        item_id: item?.id,
        collection_id: item?.collection,
      })
    ).unwrap();
    if (res?.status === 200) {
      const response = await dispatch(
        // @ts-ignore
        getItemInOrderAsync(cartId)
      ).unwrap();
      dispatch(changeCartItems(response?.result?.[0]?.items));
      dispatch(changeTotalCart(response?.result?.[0]?.items?.length));
      dispatch(changeFlagReloadCart(true));
      notify(t("Delete item from cart successfully"), "success");
    } else {
      let msg = res?.message;
      if (msg) {
        try {
          let msgJson = JSON.parse(msg);
          msg = msgJson?.message ?? null;
        } catch (error) {
          msg = null;
        }
      }
      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: msg ?? t("Delete item from cart error"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
    dispatch(changeVisibleSpinner(false));

    dispatch(changeFlagReloadCart(true));
  };

  const handleAddIntoCart = async (item: any) => {
    dispatch(changeVisibleSpinner(true));

    const res = await dispatch(
      // @ts-ignore
      addItemOderAsync({
        order_id: cartId,
        item_id: item?.id,
        collection_id: item?.collection,
      })
    ).unwrap();

    if (res?.status === 200) {
      // @ts-ignore
      const response = await dispatch(getItemInOrderAsync(cartId)).unwrap();

      dispatch(changeCartItems(response?.result?.[0]?.items));
      dispatch(changeTotalCart(response?.result?.[0]?.items?.length));
      dispatch(changeFlagReloadCart(true));

      notify(t("Add item to cart successfully"), "success");
    } else {
      
      let a: any = JSON.parse(res?.message);

      dispatch(
        updateAlert({
          show: true,
          title: t("Error"),
          message: res?.message ? a?.message : t("Can not add item into cart"),
          buttons: {
            close: true,
            yes: false,
            no: false,
          },
        })
      );
    }
    dispatch(changeVisibleSpinner(false));
  };

  const getData = async () => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    let payload: any = {
      id: id,
      fields: {
        "eo:cloud_cover": {
          gte: cloudCoverFrom,
          lte: cloudCoverTo,
        },
      },
      // filter: {
      //   op: "and",
      //   args: [
      //     { op: "gte", args: [{ property: "eo:cloud_cover" }, cloudCoverFrom] },
      //     { op: "lte", args: [{ property: "eo:cloud_cover" }, cloudCoverTo] },
      //   ],
      // },

      filter:
        idSearch && idSearch !== ""
          ? `{ "op": "and", "args": [
{ "op": "and", "args": [ { "op": "gte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverFrom} ] }, { "op": "lte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverTo} ] } ] },
{ "op": "eq", "args": [ { "property": "id" },"${idSearch}" ] }
] }
`
          : `{ "op": "and", "args": [ { "op": "gte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverFrom} ] }, { "op": "lte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverTo} ] } ] }`,
      filter_lang: "cql2-json",
    };

    if (start_at && end_at)
      payload.datetime = `${moment(start_at).toISOString()}/${moment(
        end_at
      ).toISOString()}`;
    if (end_at && !start_at)
      payload.datetime = `../${moment(end_at).toISOString()}`;
    if (!end_at && start_at)
      payload.datetime = `${moment(start_at).toISOString()}/..`;
    if (!end_at && !start_at) payload.datetime = null;
    let resItem;

    if (flagFilterItem || flagRefeshItem) {
      dispatch(changeArrPathNextPage([]));
      dispatch(changeFlagFilterItem(false));
      dispatch(changeFlagRefeshItem(false));
      // @ts-ignore
      dispatch(resetNextPage({}));

      let sun: any = [];

      resItem = await dispatch(
        // @ts-ignore
        getItemsByCollectionIdAsync({ ...payload, limit: 8 })
      ).unwrap();
      let gao = resItem?.data?.links?.filter(
        (item: any) => item?.rel === "prev"
      );
      if (!sun?.includes(gao?.[0]?.href)) {
        sun.push(gao?.[0]?.href);
      }
      let tun = resItem?.data?.links?.filter(
        (item: any) => item?.rel === "next"
      );

      if (!sun?.includes(tun?.[0]?.href)) {
        sun.push(tun?.[0]?.href);
      }

      dispatch(changeArrPathNextPage(sun));
    } else {
      if (indPathNext === 0) {
        resItem = await dispatch(
          // @ts-ignore
          getItemsByCollectionIdAsync({ ...payload, limit: 8 })
        ).unwrap();
      } else {
        resItem = await dispatch(
          // @ts-ignore
          getItemsCollectionByPathAsync({
            path: arrPathNextPage?.[indPathNext],

            ...payload,
          })
        ).unwrap();
        // xuwr lys doan get data tu path lay theo indPath trong arrPathNextPage
      }

      if (flagPage === "next") {
        // neu click btn next thi moi can save lai path o next
        let sun = [...arrPathNextPage];
        if (arrPathNextPage?.length === 0) {
          let gao = resItem?.data?.links?.filter(
            (item: any) => item?.rel === "prev"
          );
          if (!sun?.includes(gao?.[0]?.href)) {
            sun.push(gao?.[0]?.href);
          }
        }
        let tun = resItem?.data?.links?.filter(
          (item: any) => item?.rel === "next"
        );

        if (!sun?.includes(tun?.[0]?.href)) {
          sun.push(tun?.[0]?.href);
        }

        dispatch(changeArrPathNextPage(sun));
      }
    }

    let a: any = resItem?.data?.features;
    let b: any = [];

    for (let i = 0; i < a?.length; i++) {
      let res;
      res = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: a?.[i]?.collection,
          item_id: a?.[i]?.id,
        })
      ).unwrap();

      b.push({
        ...a?.[i],
        thumbnail: res,
      });
    }

    setDataItems(b ?? []);

    dispatch(changeVisibleSpinner(false));
    setLoading(false);
  };

  const { visibleDrawerInforDataset } = useSelector(
    (state: RootState) => state.drawer
  );
  const ViewInforItem = async (itemValue: any) =>
  {
    if (itemValue?.id !== itemViewInfor?.id)
    {
      const res = await dispatch(
        // @ts-ignore
        getItemCollectionByIdAsync({
          collectionId: itemValue?.collection,
          itemId: itemValue?.id,
        })
      ).unwrap();

      const resThumnail = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: itemValue?.collection,
          item_id: itemValue?.id,
        })
      ).unwrap();

      let item = {
        ...res?.data,
        thumbnail: resThumnail,
      };

      dispatch(changeItemViewInfor(item));
      dispatch(
        changeStateVisibleDrawerInforItemDataset(!visibleDrawerInforDataset)
      );
    } else
    {
      dispatch(changeItemViewInfor({}));
      dispatch(
        changeStateVisibleDrawerInforItemDataset(false)
      );
    }
  };

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}
        {!loading && visibleSpinner && <LoadingGlobalComp />}

        {!loading && dataItems?.length === 0 && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">{t("No data")}</p>
          </center>
        )}

        {!loading && dataItems?.length !== 0 && (
          <div className="mt-3 !w-full">
            <CardItemDetail
              data={dataItems}
              handleAddIntoCart={handleAddIntoCart}
              handleDelete={handleDelete}
              ViewInforItem={ViewInforItem}
            />
          </div>
        )}
      </div>
    </>
  );
}
