import { Badge, Tooltip } from "@fluentui/react-components";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const HealthCheckComp = () => {
  const { arrCheckHealth } = useSelector((state: RootState) => state.paging);

  const renderContentTooltip = () => {
    let mess = "";

    for (let i = 0; i < arrCheckHealth?.length; i++) {
      if (arrCheckHealth?.[i]?.telemetry) {
        mess = mess + `Telemetry ${arrCheckHealth?.[i]?.telemetry?.status} , `;
      }

      if (arrCheckHealth?.[i]?.settings) {
        const settingsStrings = arrCheckHealth?.[i]?.settings.map(
          (setting: any) => {
            const key = Object.keys(setting)[0]; // Extract the key
            const value = setting[key]; // Extract the value
            return `${key} ${value}`;
          }
        );

        settingsStrings.forEach((settingStr: any) => {
          mess = mess + settingStr + ", ";
        });
      }

      if (arrCheckHealth?.[i]?.uptime) {
        mess = mess + `Telemetry ${arrCheckHealth?.[i]?.uptime?.status} , `;
      }

      if (arrCheckHealth?.[i]?.db) {
        mess = mess + `Telemetry ${arrCheckHealth?.[i]?.db?.status} , `;
      }

      if (arrCheckHealth?.[i]?.keto) {
        mess = mess + `Telemetry ${arrCheckHealth?.[i]?.keto?.status} , `;
      }

      if (arrCheckHealth?.[i]?.kratos) {
        mess = mess + `Telemetry ${arrCheckHealth?.[i]?.kratos?.status} , `;
      }
    }

    return mess;
  };
  return (
    <Tooltip
      relationship="description"
      content={
        arrCheckHealth?.length > 0 ? renderContentTooltip() : "System ok"
      }
    >
      {/* <Badge
        className="ml-4 animate-ping"
        appearance="filled"
        color={arrCheckHealth?.length > 0 ? "danger" : "success"}
        size="small"
      /> */}

      {/* <div className="container-ping">
        <div className="ping"></div>
      </div> */}

      <span className="relative flex h-3 w-3 ml-4">
        <span
          className={`animate-ping absolute inline-flex h-full w-full rounded-full  opacity-75 ${
            arrCheckHealth?.length > 0 ? " bg-red-400" : "bg-sky-400"
          }`}
        ></span>
        <span
          className={`relative inline-flex rounded-full h-3 w-3${
            arrCheckHealth?.length > 0 ? " bg-red-500" : "bg-sky-500"
          }`}
        ></span>
      </span>
    </Tooltip>
  );
};

export default HealthCheckComp;
