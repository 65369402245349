import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import EmptySvg from "./../../assets/nodata.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  getListAsync,
  scanThumbnailAsync,
} from "../../store/ManageCollectionStorageSlice";
import { RootState } from "../../store/store";
import { changeTotal } from "./../../store/pagingSlice";

import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import CardDatasetStorageComp from "../../components/ManageDataset/CardDatasetStorage";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import {
  deleteCollectionStorageAsync,
  scanCollectionStorageAsync,
} from "../../store/ManageCollectionStorageSlice";
import {
  changeStateBtnCreate,
  changeVisibleSpinner,
} from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { timeout } from "../../utils/constants";

const SettingDatasetPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { page, limit } = useSelector((state: RootState) => state.paging);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(changePath("/manage-dataset/setting/idCollection"));
    dispatch(changeName("manage-dataset/setting/idCollection"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  const dataBread = [
    {
      key: 0,
      value: "Dataset",
      path: "manage-dataset",
    },
    {
      key: 1,
      value: `Setting storage's collection ${
        location?.state?.item?.id ?? params?.id
      }`,
    },
  ];

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const res = await dispatch(
          // @ts-ignore
          getListAsync({
            size: limit,
            sortby: "modified",
            asc: false,
            page: page,

            collection_id: location?.state?.item?.id ?? params?.id,
          })
        ).unwrap();

        setData(res ?? []);
        dispatch(changeTotal(res?.length ?? 0));
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [page]);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: `/manage-dataset/setting/${params?.id}/create`,
        // stateItem: { item: location?.state?.item ?? null },
      })
    );
    return () => {
      dispatch(
        changeStateBtnCreate({ flag: false, path: "", stateItem: undefined })
      );
    };
  }, []);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const reloadData = async () => {
    const res = await dispatch(
      // @ts-ignore
      getListAsync({
        size: limit,
        sortby: "modified",
        asc: false,
        page: page,

        collection_id: location?.state?.item?.id ?? params?.id,
      })
    ).unwrap();

    setData(res ?? []);
    dispatch(changeTotal(res?.length ?? 0));
  };

  const deleteCollectionStorage = async (id: string) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      deleteCollectionStorageAsync({ collection_storage_id: id })
    ).unwrap();
    if (res?.status === 200) {
      notify("Delete successfully", "success");
      setTimeout(() => {
        reloadData();

        dispatch(changeVisibleSpinner(false));
      }, 1500);
    } else {
      notify("Delete fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 1500);
    }
  };

  const handleScan = async (item: any, type?: string) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      scanCollectionStorageAsync({
        collection_id: item?.collection_id,
        storage_id: item?.storage_id,
        type: type
      })
    ).unwrap();

    if (res?.status === 200) {
      notify("Start scan successfully", "success");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Start scan fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const scanThumbnail = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      scanThumbnailAsync({
        collection_id: item?.collection_id,
      })
    ).unwrap();

    if (res?.status === 200) {
      notify("Scan thumbnail successfully", "success");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Scan thumbnail fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };
  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}
        {visibleSpinner && !loading && <LoadingGlobalComp />}
        {data?.length === 0 && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div>
            <CardDatasetStorageComp
              data={data}
              delete={deleteCollectionStorage}
              notify={notify}
              setLoading={setLoading}
              handleScan={handleScan}
              scanThumbnail={scanThumbnail}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SettingDatasetPage;
