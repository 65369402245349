import { useState } from "react";
import Pie from "./Pie";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export default function DownloadPercent(props: any) {
  const { item } = props;

  const downloadRedux = useSelector((state: RootState) => state.download);
  return (
    <>
      <Pie
        percentage={downloadRedux[`percent-download_${item.orderId}`]}
        colour={"rgba(96, 205, 255, 1)"}
        textX={props?.textX}
        textY={props?.textY}
      />
    </>
  );
}
