import { useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardItemTaskComp from "../../components/ManageScheduler/CardItemTask";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";

import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  deleteTaskAsync,
  getListTaskAsync,
  resetStateFilterTask,
  resetStateSortTask,
  startTaskAsync,
  stopTaskAsync,
} from "../../store/ManageScheduler";

import { changeName, changePath } from "../../store/pageSlice";
import { changePage, changeTotal, resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import EmptySvg from "./../../assets/nodata.svg";

import moment from "moment";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import ToastComp from "../../components/Toast";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const ListTaskPage = (props: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { page, limit } = useSelector((state: RootState) => state.paging);
  const {
    sort_by_task,
    sort_order_task,
    selectedTimeFrom,
    selectedDateFrom,
    selectedTimeTo,
    selectedDateTo,
    keywordFilter,
    taskType,
    enable,
    oneTimeOnly,
    flagFilter,
    flagFresh,
    flagSort,
  } = useSelector((state: RootState) => state.managescheduler);
  // useEffect(() => {
  //   dispatch(
  //     changeStateBtnCreate({
  //       flag: true,
  //       path: "/scheduler/task/add",
  //     })
  //   );
  //   return () => {
  //     dispatch(changeStateBtnCreate({ flag: false, path: "" }));
  //   };
  // }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { key: 0, value: "Scheduler" },
        { key: 1, value: "Tasks" },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    (async function () {
      if (!flagFresh || !flagFilter || !flagSort)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getListTaskAsync({
              size: limit,
              sortby: sort_by_task,
              asc: sort_order_task === "asc" ? true : false,
              page: page,

              filter: {
                keyword:
                  keywordFilter && keywordFilter !== "" ? keywordFilter : null,
                enable:
                  enable === "True" ? true : enable === "False" ? false : null,
                one_time_only:
                  oneTimeOnly === "True"
                    ? true
                    : oneTimeOnly === "False"
                    ? false
                    : null,
                task_types: taskType,

                // @ts-ignore
                start_time_from: moment(selectedTimeFrom)
                  .utcOffset(0, true)
                  .toISOString(),
                // @ts-ignore
                start_time_to: moment(selectedTimeTo)
                  .utcOffset(0, true)
                  .toISOString(),
                // @ts-ignore
                start_date_from: moment(selectedDateFrom)
                  .utcOffset(0, true)
                  .toISOString(),
                // @ts-ignore
                start_date_to: moment(selectedDateTo)
                  .utcOffset(0, true)
                  .toISOString(),
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilter)
        try {
          setLoading(true);
          dispatch(changePage(0));
          dispatch(changeTotal(0));
          dispatch(changeVisibleSpinner(true));
          reloadData({ page: 0 });
          dispatch(changeFlagFilter(false));
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          dispatch(changeFlagFilter(false));
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          setLoading(true);
          dispatch(changePage(0));
          dispatch(changeTotal(0));
          dispatch(changeVisibleSpinner(true));
          reloadData({ page: 0 });
          dispatch(changeFlagSort(false));
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          dispatch(changeFlagSort(false));
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          setLoading(true);
          dispatch(changePage(0));
          dispatch(changeTotal(0));
          dispatch(changeVisibleSpinner(true));
          reloadData({ page: 0 });
          dispatch(changeFlagFresh(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          dispatch(changeFlagFresh(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        }
    })();
  }, [flagFresh]);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState());
      dispatch(changePage(0));
      dispatch(changeTotal(0));
      if (!window.location.pathname?.includes("scheduler/job")) {
        // @ts-ignore
        dispatch(resetStateFilterTask({}));
        dispatch(resetStateSortTask({}));
      }
      
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/scheduler/task"));
    dispatch(changeName("scheduler/task"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  const reloadData = async (props: any) => {
    const res = await dispatch(
      // @ts-ignore
      getListTaskAsync({
        size: limit,
        sortby: sort_by_task,
        asc: sort_order_task === "asc" ? true : false,
        page: props?.page ? props?.page : page,

        filter: {
          keyword: keywordFilter && keywordFilter !== "" ? keywordFilter : null,
          enable: enable === "True" ? true : enable === "False" ? false : null,
          one_time_only:
            oneTimeOnly === "True"
              ? true
              : oneTimeOnly === "False"
              ? false
              : null,
          task_types: taskType,
          // @ts-ignore
          start_time_from: moment(selectedTimeFrom)
            .utcOffset(0, true)
            .toISOString(),
          // @ts-ignore
          start_time_to: moment(selectedTimeTo)
            .utcOffset(0, true)
            .toISOString(),
          // @ts-ignore
          start_date_from: moment(selectedDateFrom)
            .utcOffset(0, true)
            .toISOString(),
          // @ts-ignore
          start_date_to: moment(selectedDateTo)
            .utcOffset(0, true)
            .toISOString(),
        },
      })
    ).unwrap();

    setData(res?.result ?? []);
    dispatch(changeTotal(res?.paging?.total ?? 0));
  };

  const reloadDataChangeItem = (id?: string, type?: any) => {
    let a = data;
    let b: any = [];

    a?.map((item: any) => {
      if (item?.id === id) {
        b.push({
          ...item,
          schedule_id: type === "stop" ? "" : "fake_id",
        });
      } else b.push(item);
    });

    setData(b);
  };

  const handleStartTask = async (id: any) => {
    setLoading(true);
    // @ts-ignore
    const res = await dispatch(startTaskAsync(id)).unwrap();

    if (res?.status === 200) {
      reloadDataChangeItem(id, "start");
      notify("Start task successfully", "success");
      setLoading(false);
    } else {
      notify("Start task fail", "error");
      setLoading(false);
    }
  };

  const handleStopTask = async (id: any) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(stopTaskAsync(id)).unwrap();

    if (res?.status === 200) {
      reloadDataChangeItem(id, "stop");
      notify("Stop task successfully", "success");
      dispatch(changeVisibleSpinner(false));
    } else {
      notify("Stop task fail", "error");
      dispatch(changeVisibleSpinner(false));
    }
  };

  const handleDeleteTask = async (id: any) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteTaskAsync(id)).unwrap();

    if (res?.status === 200) {
      reloadData({ page: page });
      // reloadDataChangeItem(id);
      notify("Delete task successfully", "success");
      dispatch(changeVisibleSpinner(false));
    } else {
      notify(`Delete task fail ${res?.data?.message}`, "error");
      dispatch(changeVisibleSpinner(false));
    }
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  return (
    <div className="px-6 py-6 !w-full div-list !relative">
      {loading && <LoadingListComp />}

      {visibleSpinner && !loading && <LoadingGlobalComp />}

      {!data?.length && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}
      {data?.length !== 0 && !loading && (
        <div className="w-full">
          <CardItemTaskComp
            data={data}
            handleStartTask={handleStartTask}
            handleStopTask={handleStopTask}
            handleDeleteTask={handleDeleteTask}
          />
        </div>
      )}
    </div>
  );
};

export default ListTaskPage;
