import axios from "../../utils/axios";

export async function getList(payload: any) {
  return axios.get(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/admin/notifications/`,
    {
      params: { ...payload },
    }
  );
}

export async function getTypeNoti(payload: any) {
  return axios.get(
    `${localStorage.getItem(
      "REACT_APP_ENDPOINT"
    )}/dictionaries/notifications/types/`
  );
}

export async function addNoti(payload: any) {
  return axios.post(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/admin/notifications/`,
    payload
  );
}

export async function updateNoti(payload: any) {
  return axios.put(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/admin/notifications/${
      payload?.id
    }/`,
    payload
  );
}

export async function deleteNoti(payload: any) {
  return axios.delete(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/admin/notifications/${
      payload?.id
    }/`
  );
}

export async function getNotiUser(payload: any) {
  return axios.get(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/notifications/`,
    {
      params: { ...payload },
    }
  );
}

export async function sendNoti(payload: any) {
  return axios.post(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/admin/notifications/${
      payload?.id
    }/recipients/`,
    payload?.arrRecipients
  );
}

export async function userUpdateNoti(payload: any) {
  return axios.put(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/notifications/${
      payload?.id
    }/`,
    payload?.pay
  );
}

export async function seenAllNoti(payload: any) {
  return axios.put(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/notifications/`,
    payload
  );
}
