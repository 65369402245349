import { useCallback, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { DatePicker } from "@fluentui/react-datepicker-compat";
import { addDays } from "@fluentui/react-calendar-compat";

import { RootState } from "../../../../store/store";
import { changeTimeSlider } from "../../../../store/filterSlice";

import { Slider, Checkbox } from "@fluentui/react-components";
import type { SliderProps, CheckboxProps } from "@fluentui/react-components";

export default function TimeSlider(props: any) {

    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState<Date | any>(null);

    const [sliderValue, setSliderValue] = useState<number | any>(0);

    const [minValue, setMinValue] = useState<number | any>(0);
    const [maxValue, setMaxValue] = useState<number | any>(0);

    const [maxDate, setMaxDate] = useState<Date | any>(null);
    const [minDate, setMinDate] = useState<Date | any>(null);

    const [restrictedDates, setRestrictedDates] = useState<Array<Date> | Array<any>>([]);

    const [allowedDates, setAllowedDates] = useState<Array<Date> | Array<any>>([]);

    const [checked, setChecked] = useState<CheckboxProps["checked"]>(false);

    const dispatch = useDispatch();

    const items: Array<any> = useSelector(
        (state: RootState) => state.filter?.items
    );

    const timeSlider: Array<any> = useSelector(
        (state: RootState) => state.filter?.timeSlider
    );

    useEffect(() => {
        getMinMaxDate();
    }, [items]); // get min max date

    const getMinMaxDate = () => {
        setSelectedDate(null);
        if (items && items.length) {
            getAllowedDates();
            if (timeSlider && timeSlider.length)
                setSelectedDate(timeSlider[0]);
        } else {
            setMinDate(null);
            setMaxDate(null);
            setSelectedDate(null);
        }
    }

    const getAllowedDates = () => {
        let dates: Array<Date> | Array<any> = [];
        for (let i = 0; i < items.length; i++) {
            dates.push(new Date(items[i]?.properties?.datetime));
        }
        //@ts-ignore
        var max = new Date(Math.max.apply(null, dates));
        //@ts-ignore
        var min = new Date(Math.min.apply(null, dates));

        dates.sort(function (a, b) {
            return a - b;
        });

        setMaxDate(max);
        setMinDate(min);
        if (min && max && (min.getDate() != max.getDate()
            || min.getMonth() != max.getMonth()
            || min.getFullYear() != max.getFullYear())) {
            props.setShowTimeType(true);
        } else props.setShowTimeType(false);

        setAllowedDates(dates);

        setMaxValue(dates.length - 1);

        getRestrictedDates(min, max, dates);

        setSelectedDate(null);
    }

    const getRestrictedDates = (min: Date, max: Date, allowed: Array<Date>) => {
        let dates: Array<Date> | Array<any> = [];
        let differenceInTimes = max.getTime() - min.getTime();

        let differenceInDays = Math.round(differenceInTimes / (1000 * 3600 * 24));

        for (let i = 1; i < differenceInDays; i++) {
            let newDate = addDays(min, i);
            if (allowed?.findIndex(x => x.getDate() == newDate.getDate() && x.getMonth() == newDate.getMonth() && x.getFullYear() == newDate.getFullYear()) < 0)
                dates.push(newDate);
        }
        setRestrictedDates(dates);
    }

    const onFormatDate = (date?: any): string => {
        return !date
            ? ""
            : (date?.getDate() < 10 ? ("0" + date?.getDate()) : date?.getDate()) +
            "/" +
            ((date?.getMonth() + 1) < 10 ? ("0" + (date?.getMonth() + 1)) : (date?.getMonth() + 1)) +
            "/" +
            (date?.getFullYear());
    };

    const onParseDateFromString = useCallback(
        (newValue: string): Date => {
            const previousValue = selectedDate || new Date();
            const newValueParts = (newValue || "").trim().split("/");
            const day =
                newValueParts.length > 0
                    ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
                    : previousValue.getDate();
            const month =
                newValueParts.length > 1
                    ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
                    : previousValue.getMonth();
            let year =
                newValueParts.length > 2
                    ? parseInt(newValueParts[2], 10)
                    : previousValue.getFullYear();
            if (year < 100) {
                year +=
                    previousValue.getFullYear() - (previousValue.getFullYear() % 100);
            }
            return new Date(year, month, day);
        },
        [selectedDate]
    );

    const changeDate = (date: any) => {
        setSelectedDate(date);

        let index = allowedDates?.findIndex(x => x.getDate() == date.getDate() && x.getMonth() == date.getMonth() && x.getFullYear() == date.getFullYear());
        if (index >= 0) setSliderValue(index);
    };

    const onSliderChange: SliderProps["onChange"] = (_, data) => {
        setSliderValue(data.value);

        setSelectedDate(allowedDates[data.value]);

        dispatch(changeTimeSlider([allowedDates[data.value], allowedDates[data.value]]));
    }

    const onCheckChange = (val: any) => {
        setChecked(val);
        dispatch(changeTimeSlider([]));
        setSelectedDate(null);
    }

    return (
        <>
            {(minDate != null && maxDate != null
                && (minDate.getDate() != maxDate.getDate()
                    || minDate.getMonth() != maxDate.getMonth()
                    || minDate.getFullYear() != maxDate.getFullYear())) && <div className="flex gap-x-2">
                    {timeSlider.length ? <DatePicker
                        formatDate={onFormatDate}
                        placeholder="dd/mm/yyyy"
                        className={"w-32 !border-0 !bg-transparent after:!border-b-0 " + (minDate != null && maxDate != null ? "block" : "hidden")}
                        parseDateFromString={onParseDateFromString}
                        onSelectDate={(date: any) => changeDate(date)}
                        value={selectedDate}
                        calendar={{
                            restrictedDates: restrictedDates,
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        showGoToToday={false}
                    /> : ""}
                    <div className="flex text-left self-center items-center w-96">
                        <Slider className="w-96" min={0} max={allowedDates.length ? allowedDates.length - 1 : 0} value={sliderValue} defaultValue={minValue} onChange={onSliderChange} />
                    </div>
                    {timeSlider.length ? <Checkbox
                        checked={checked}
                        onChange={(ev, data) => onCheckChange(data.checked)}
                        label={t('Hiển thị tất cả')}
                    /> : ""}
                </div>}
        </>
    )
}