import axios from "../../utils/axios";

export async function getInfor() {
  //user+ admin
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/users/whoami/`);
}

export async function getMyRoles() {
  //user+ admin
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/users/groups/`);
}

export async function getListUser(params?: any) {
  // admin
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT_KRATOS')}/oa/admin/identities`, {
    params: { ...params },
  });
}

export async function getUserGroups(id: string) {
  // admin
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${id}/groups/`);
}

export async function getUserRoles(id: string) {
  
  // admin
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${id}/roles/`);
}

export async function setUserAsAdmin(id: string) {
  // admin
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${id}/roles/as_admin/`
  );
}

export async function setUserAsUser(id: string) {
  
  // admin
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${id}/roles/as_user/`
  );
}

export async function applyAdminRoles(id: string) {
  
  // admin
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${id}/roles/apply_admin_roles/`
  );
}

export async function applyUserRoles(id: string) {
  
  // admin
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${id}/roles/apply_user_roles/`
  );
}
