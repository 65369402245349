import {
  Card,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";

import { getListAsync } from "../../store/ManageOrderSlice";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { useRef } from "react";
import useCustomNavigate from "../../hook/useCustomNavigate";
import DownloadPercent from "./DownloadPercent";

export default function CardItemCart(props: any) {
  const { t } = useTranslation();
  const controller = useRef(new AbortController());

  const downloadRedux = useSelector((state: RootState) => state.download);

  const renderCardheaderDesc = (item: any) => {
    return (
      <>
        {item ? (
          <div>
            <p className="text-color-des">
              <code>Total items: {item?.items} </code>
            </p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const navigate = useCustomNavigate();
  const dispatch = useDispatch();

  const handleNavigate = async (orderId: any) => {
    const res = await dispatch(
      // @ts-ignore
      getListAsync({
        filter: {
          ids: [orderId],
        },
      })
    ).unwrap();

    navigate(`/invoice/${orderId}`, {
      state: {
        item: res?.result?.[0],
      },
    });
  };

  return (
    <>
      {props?.data?.map((item: any, index: number) => (
        <div key={index} className="hover:cursor-pointer">
          <Card
            orientation="horizontal"
            className="card-item-download-drawer"
            onClick={() => {
              handleNavigate(item?.orderId);
              // navigate(`/invoice/${item?.orderId}`);
            }}
          >
            <CardPreview className="div-center div-preview-card-item-download-drawer">
              <DownloadPercent item={item} textX={"80%"} textY={"90%"} />
            </CardPreview>

            <CardHeader
              header={
                <Tooltip relationship="description" content={item?.orderId}>
                  <p className="ellipsis-text-one-line">{item?.orderId}</p>
                </Tooltip>
              }
              description={
                <div className="!font-normal !leading-5 w-full">
                  {renderCardheaderDesc(item)}
                </div>
              }
            />

            {/* <CardFooter className="cardfooter">
              <div className="flex w-full gap-2">
                {downloadRedux[`percent-download_${item.orderId}`] !== 100 && (
                  <>
                    <Button
                      className="mr-1 btn-action-draw-download"
                      appearance="subtle"
                      size="medium"
                      icon={
                        <img
                          className="img-svg-icon-down"
                          src={PauseSvg}
                          alt="Info Circle"
                        />
                      }
                      onClick={() => {
                        // debugger;
                        // @ts-ignore
                        controller.abort();
                        console.log("Download aborted");
                      }}
                    />
                    <Button
                      className="btn-action-draw-download"
                      appearance="subtle"
                      size="medium"
                      icon={
                        <img
                          className="img-svg-icon-down"
                          src={CloseSvg}
                          alt="Info Circle"
                        />
                      }
                      //   onClick={() => props?.deleteItem(item)}
                    />
                  </>
                )}
              </div>
            </CardFooter> */}
          </Card>
        </div>
      ))}
    </>
  );
}
