import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface PagingState {
  page: number;
  limit: number;
  total: number;
}

export interface PagingNextPage {
  indPathNext: any;
  flagPage?: any;
}

export interface DrawerCartState {
  visibleCart: boolean;
}

export interface FilterState {
  visible: boolean;
}

export interface SortState {
  visibleSort: boolean;
}

export interface VisibleDrawerDownload {
  visibleDrawerDownload: boolean;
}

export interface Notification {
  visibleNoti: boolean;
  arrNoti: any;
}

export interface HealthCheck {
  arrCheckHealth: any;
}

export interface HrefState {
  arrHref: any;
  animationPage: boolean;
}

const initialState: PagingState &
  FilterState &
  DrawerCartState &
  SortState &
  VisibleDrawerDownload &
  Notification &
  PagingNextPage &
  HealthCheck &
  HrefState = {
  page: 0,
  limit: 8,
  visible: false,
  total: 0,
  visibleCart: false,
  visibleSort: false,
  visibleDrawerDownload: false,
  visibleNoti: false,
  arrNoti: [],
  indPathNext: 0,
  flagPage: "next",
  arrCheckHealth: [],
  arrHref: [],
  animationPage: false,
};

export const pagingSlice = createSlice({
  name: "paging",
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    changeIndPathNext: (state, action: PayloadAction<number>) => {
      state.indPathNext = action.payload;
    },
    resetNextPage: (state, action: any) => {
      state.indPathNext = 0;
      state.flagPage = "next";
    },
    changeFlagPage: (state, action?: any) => {
      state.flagPage = action.payload;
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },

    changeHref: (state, action?: any) => {
      let a = action.payload;
      let b = [];
      for (let i = 0; i < a?.length; i++) {
     
        if (a[i]?.charAt(0) === "/") {
          b.push(a[i]?.replace("/", ""));
        } else {
          b.push(a[i]);
        }
      }

      localStorage.setItem("arrHref", b?.join(";"));

      console.log(b, "b");

      state.arrHref = b;
    },

    changeAnimationPage: (state, action: { payload: boolean }) => {
      state.animationPage = action.payload;
    },

    resetState: (state, action: any) => {
      state.page = 0;
      state.total = 0;
      state.visible = false;
      state.visibleSort = false;
      state.visibleDrawerDownload = false;
      state.visibleNoti = false;
    },

    changeStateArrNoti: (state, action: PayloadAction<boolean>) => {
      state.arrNoti = action.payload;
    },

    changeStateArrCheckHealth: (state, action: PayloadAction<boolean>) => {
      state.arrCheckHealth = action.payload;
    },
  },
});

export const {
  changePage,
  changeTotal,
  resetState,
  changeStateArrNoti,
  changeIndPathNext,
  changeFlagPage,
  resetNextPage,
  changeStateArrCheckHealth,
  changeHref,
  changeAnimationPage,
} = pagingSlice.actions;

export default pagingSlice.reducer;
