import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import DeleteSvg from "./../../assets/delete-dark.svg";
import EditSvg from "./../../assets/edit-dark.svg";
import LayerSvg from "./../../assets/images/item-collection-default.png";
import EyeSvg from "./../../assets/info-circle.svg";

import { useNavigate } from "react-router-dom";
import useCustomNavigate from "../../hook/useCustomNavigate";

const CardDatasetAdmin = (props: any) => {
  const navigate = useCustomNavigate();

  const onMouseEnterItem = (item: any) => {
    props.onMouseEnterItem(item);
  };
  const onMouseLeaveItem = (item: any) => {
    props.onMouseLeaveItem(item);
  };

  return (
    <>
      {props?.data?.map((item: any, index: any) => (
        <section
          key={index}
          onClick={async () => {
            props?.ViewInforItem(item);
          }}
        >
          <div
            className={`div-card ${props?.loading ? " card--isloading" : ""}`}
            // onMouseEnter={() => onMouseEnterItem(item)}
            // onMouseLeave={() => onMouseLeaveItem(item)}
          >
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview
                className="div-center div-card-preview rounded"
                onClick={async () => {
                  props?.ViewInforItem(item);
                }}
              >
                <img
                  loading="lazy"
                  className="rounded-sm img-card-item-catalog-search-drawer"
                  src={
                    item?.thumbnail?.[0]
                      ? `${localStorage.getItem(
                          "REACT_APP_ENDPOINT"
                        )}/collections/${item?.collection}/items/${
                          item?.id
                        }/thumbnails/${item?.thumbnail?.[0]}/`
                      : LayerSvg
                  }
                  alt="img item"
                  onError={(e: any) => {
                    e.target.src = LayerSvg;
                  }}
                />
              </CardPreview>

              <CardHeader
                onClick={async () => {
                  props?.ViewInforItem(item);
                }}
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-auto">
                      <Tooltip
                        relationship="description"
                        content={item?.properties?.title}
                      >
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.properties?.title}
                        </p>
                      </Tooltip>

                      <p className="mt-1 text-des ellipsis-text-one-line">
                        Cloud cover: {item?.properties?.["eo:cloud_cover"]}{" "}
                        <span className="ml-2">
                          GSD: {item?.properties?.gsd}
                        </span>
                      </p>
                    </div>

                    <div />
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <div className="div-action-card">
                  <div
                    className="mr-5"
                    onClick={(e) => {
                      e.stopPropagation();

                      navigate(`${document.location.pathname}/edit/${item?.id}`, {
                        state: { item: item, parent: props?.parent },
                      });
                    }}
                  >
                    <img
                      className="img-svg-icon"
                      src={EditSvg}
                      alt="Info Circle"
                    />
                  </div>

                  <div className="mr-5">
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img src={DeleteSvg} alt="Dismiss" />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() => props?.handleDeleteItem(item?.id)}
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                  <div
                    onClick={async (e) => {
                      e.stopPropagation();

                      props?.ViewInforItem(item);
                    }}
                  >
                    <img className="img-svg-icon" src={EyeSvg} alt="img item" />
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardDatasetAdmin;
