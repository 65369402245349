import * as React from "react";
import { Button, Link } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import {
  MessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  MessageBarIntent,
} from "@fluentui/react-message-bar-preview";

const intents: MessageBarIntent[] = ["info", "warning", "error", "success"];
let timeout = 10000;

const NotificationComp = () =>
{
      return (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {/* @ts-ignore */}
              {intents.map((intent) => {
                setTimeout(
                  //  @ts-ignore
                  <MessageBar key={intent} intent={intent}>
                    <MessageBarBody>
                      <MessageBarTitle>{intent}</MessageBarTitle>
                      Message providing information to the user with actionable insights.{" "}
                      <Link>Link</Link>
                    </MessageBarBody>
                    <MessageBarActions
                      containerAction={
                        <Button appearance="transparent" icon={<DismissRegular />} />
                      }
                    >
                      <Button>Action</Button>
                      <Button>Action</Button>
                    </MessageBarActions>
                  </MessageBar>,
                  1000
                );
              })}
            </div>
          );
}

export default NotificationComp;
