import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { useTranslation } from "react-i18next";

import
  {
    getArrOrderDownloadRedux,
    getOrderDownload,
  } from "../../pages/[invoiceId]/utils";
import
  {
    addArrOrderDownloadRedux,
    changePercentDownload,
  } from "../../store/dowloadSlice";
import ItemDownloadDrawer from "./ItemDownloadDrawer";

export default function DrawerDowload(props: any) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const downloadRedux = useSelector((state: RootState) => state.download);
  // const { arrOrderDownload } = downloadRedux;
  const arrOrderDownload = getArrOrderDownloadRedux(downloadRedux);

  useEffect(() => {
    let temp = getOrderDownload();
    temp.map((e: any) => {
      dispatch(
        addArrOrderDownloadRedux({
          item_id: e.orderId,
          value: {
            orderId: e.orderId,
            idSession: e.idSession,
            items: e.items,
          },
        })
      );
    });
    for (let i = 0; i < temp.length; i++) {
      const orderId = temp[i].orderId;
      const percentId = `percent-download_${orderId}`;
      dispatch(
        changePercentDownload({
          item_id: orderId,
          value: parseFloat(localStorage.getItem(percentId) ?? "0"),
        })
      );
    }
  }, []);

  return (
    <>
      <ItemDownloadDrawer data={arrOrderDownload} />
    </>
  );
}
