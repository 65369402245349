import {
  Dropdown,
  Option,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { changeSortBy, changeSortOrder } from "../../store/ManageSearchHis";

import { arrSortHistoryScan, arrSortOrder } from "../../utils/constants";

const SortSearchHisComp = (props: any) => {
  const dispatch = useDispatch();

  const { sort_order, sort_by } = useSelector(
    (state: RootState) => state.managesearchhis
  );

  return (
    <>
      <div>
        <p className="mb-2">Sort by</p>
        <Dropdown
          placeholder="-- Choose field --"
          className="div-dropdown"
          value={sort_by}
          onOptionSelect={(e: any, data: any) => {
            dispatch(changeSortBy(data?.optionValue));
          }}
        >
          {arrSortHistoryScan?.map((option: any) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>

        <p className="mb-2 mt-4">Sort order</p>
        <RadioGroup value={sort_order}>
          {arrSortOrder?.map((item: any, index: any) => (
            <Radio
              key={index}
              label={item}
              value={item}
              onChange={(e: any, val: any) => {
                dispatch(changeSortOrder(val?.value));
              }}
            />
          ))}
        </RadioGroup>
      </div>
    </>
  );
};

export default SortSearchHisComp;
