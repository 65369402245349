import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CardItemJobComp from "../../../components/ManageScheduler/CardItemJob";
import { changeDataBreadcrumb } from "../../../store/BreadcrumbSlice";
import {
  changeFlagFilterJob,
  changeFlagFreshJob,
  changeFlagSortJob,
  getTaskJobsAsync,
  resetStateFilterJob,
  resetStateSortJob,
} from "../../../store/ManageScheduler";
import { changeName, changePath } from "../../../store/pageSlice";
import {
  changePage,
  changeTotal,
  resetState,
} from "../../../store/pagingSlice";
import { RootState } from "../../../store/store";
import EmptySvg from "./../../../assets/nodata.svg";
// import moment from "../../../utils/moment";

import moment from "moment";
import LoadingGlobalComp from "../../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../../components/Loading/LoadingList";
import { resetAllStateDrawer } from "../../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../../store/NavSlice";

const ViewJobInTask = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { page, limit } = useSelector((state: RootState) => state.paging);

  const {
    sort_by_job,
    sort_order_job,
    flagFilterJob,
    flagSortJob,
    flagFreshJob,
    taskType,
    timePickerValueScheduledJobFrom,
    selectedTimeScheduledJobFrom,
    selectedDateScheduledJobFrom,
    timePickerValueScheduledJobTo,
    selectedTimeScheduledJobTo,
    selectedDateScheduledJobTo,
  } = useSelector((state: RootState) => state.managescheduler);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getTaskJobsAsync({
            task_id: location?.state?.item?.id,
            size: limit,
            sortby: sort_by_job,
            asc: sort_order_job === "asc" ? true : false,
            page: page,

            filter: {
              scheduled_from: selectedTimeScheduledJobFrom
                ? // @ts-ignore
                  moment(selectedTimeScheduledJobFrom).toISOString()
                : // @ts-ignore
                  moment(selectedDateScheduledJobFrom).toISOString(),
              scheduled_to: selectedTimeScheduledJobTo
                ? // @ts-ignore
                  moment(selectedTimeScheduledJobTo).toISOString()
                : // @ts-ignore
                  moment(selectedDateScheduledJobTo).toISOString(),
            },
          })
        ).unwrap();
        setData(res?.result ?? []);
        dispatch(changeTotal(res?.paging?.total ?? 0));
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagFilterJob)
        try {
          // @ts-ignore
          // console.log(parseInt(localStorage.getItem("offsetUtc")));
          // return;
          setLoading(true);
          dispatch(changeVisibleSpinner(true));

          const res = await dispatch(
            // @ts-ignore
            getTaskJobsAsync({
              task_id: location?.state?.item?.id,
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: page,

              filter: {
                scheduled_from: selectedTimeScheduledJobFrom
                  ? moment(selectedTimeScheduledJobFrom).toISOString()
                  : moment(selectedDateScheduledJobFrom).toISOString(),
                scheduled_to: selectedTimeScheduledJobTo
                  ? moment(selectedTimeScheduledJobTo).toISOString()
                  : moment(selectedDateScheduledJobTo).toISOString(),
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagFilterJob(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilterJob(false));
        }
    })();
  }, [flagFilterJob]);

  useEffect(() => {
    (async function () {
      if (flagSortJob)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getTaskJobsAsync({
              task_id: location?.state?.item?.id,
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: page,

              filter: {
                scheduled_from: selectedTimeScheduledJobFrom
                  ? // @ts-ignore
                    moment(selectedTimeScheduledJobFrom).toISOString()
                  : // @ts-ignore
                    moment(selectedDateScheduledJobFrom).toISOString(),
                scheduled_to: selectedTimeScheduledJobTo
                  ? // @ts-ignore
                    moment(selectedTimeScheduledJobTo).toISOString()
                  : // @ts-ignore
                    moment(selectedDateScheduledJobTo).toISOString(),
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagSortJob(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
          dispatch(changeFlagSortJob(false));
        }
    })();
  }, [flagSortJob]);

  useEffect(() => {
    (async function () {
      if (flagFreshJob)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getTaskJobsAsync({
              task_id: location?.state?.item?.id,
              size: limit,
              sortby: sort_by_job,
              asc: sort_order_job === "asc" ? true : false,
              page: page,

              filter: {
                // created_from: start_at,
                // created_to: end_at,
                // task_types: type_quota !== "All" ? [type_quota] : null,
              },
            })
          ).unwrap();

          setData(res?.result ?? []);
          dispatch(changeTotal(res?.paging?.total ?? 0));
          dispatch(changeFlagFreshJob(false));
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFreshJob(false));
        }
    })();
  }, [flagFreshJob]);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { key: 0, value: "Scheduler" },
        {
          key: 1,
          value: `Tasks`,
          path: "scheduler/task",
        },
        { key: 3, value: `View jobs task  ${location?.state?.item?.name}` },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/view-task-jobs"));
    dispatch(changeName("view-task-jobs"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    return () => {
      dispatch(changePage(0));
      dispatch(changeTotal(0));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetStateFilterJob({}));
      dispatch(resetStateSortJob({}));
    };
  }, []);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  return (
    <div className="px-6 py-6 !w-full div-list !relative">
      {loading && <LoadingListComp />}

      {visibleSpinner && !loading && <LoadingGlobalComp />}

      {!data?.length && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}
      {data?.length !== 0 && !loading && (
        <div className="w-full">
          <CardItemJobComp data={data} />
        </div>
      )}
    </div>
  );
};

export default ViewJobInTask;
