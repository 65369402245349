import axios from "../../utils/axios";

export async function browseStorage(payload: any) {
  
  const newPay = {
    path: payload?.path,
  };

  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/storages/${payload?.id}/browse/`,
    {
      params: payload?.path ? { ...newPay } : {},
      // paramsSerializer: function (params) {
      //   var result = "";
      //   // Build the query string
      //   return result;
      // },
    }
  );
}

export async function getList(payload: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/storages/`, {
    params: { ...payload },
  });
}

export async function postStorage(payload: any) {
  
  return axios.post(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/storages/`, payload);
}

export async function scanStorage(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/scan/storages/${payload?.storageId}/start_scan/?collection_id=${payload?.collectionId}`
  );
}

export async function configureStorage(payload: any) {
  
  // const newPay = { product_type: payload?.product_type };
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/${payload?.collectionId}/storages/${payload?.storageId}/`
  );
}

export async function editStorage(payload: any) {
  
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/storages/${payload?.id}/`,
    payload
  );
}

export async function getHistoryScan(payload: any) {
  
  // get all
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/scan_sessions/`,
    {
      params: { ...payload },
    }
  );
}

export async function getHistoryScanById(id: string) {
  // get by id
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/scan_sessions/${id}/`,
    {
      params: {
        include_summary: true,
      },
    }
  );
}

export async function getScannedResource(payload: any) {
  // get by id
  

  const newPay = {
    page: payload?.page,
    size: payload?.size,
    sortby: "created",
    asc: false,
  };
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/collections/scan_sessions/${payload?.id}/found_resources/`,
    {
      params: { ...newPay },
    }
  );
}

export async function deleteStorage(id: string) {
  
  return axios.delete(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/storages/${id}/`);
}
