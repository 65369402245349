import {
  Card,
  CardHeader,
  CardPreview,
  Checkbox,
  Tooltip,
} from "@fluentui/react-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import DownloadPercent from "./DownloadPercent";

import { HASH_ROLE_USE } from "../../utils/constants";
import LayerSvg from "./../../assets/images/item-collection-default.png";
import { useEffect, useState } from "react";


const CardItemDowload = (props: any) => {
  const { loadingRequestDownloadButton, visibleBtnDownload } = props;
  const { currentRole } = useSelector((state: RootState) => state.manageuser);
  const { flagHaveSessionDownload, indexDownload, selectedItems } = useSelector(
    (state: RootState) => state.download
  );
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [ip, setIp] = useState<any>("");

  const handleGetData = async () => {
  setIp(localStorage.getItem("REACT_APP_ENDPOINT"));
  };

  useEffect(() => {
    handleGetData();
  }, [props?.data]);
  return (
    <>
      {props?.data?.map((item: any, index: any) => (
        <section key={index}>
          <div className="bg-default-order rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240] !h-[96px] !cursor: default"
            >
              <CardPreview
                className={`div-center div-card-preview !ml-0 ${
                  !indexDownload[item.stac_item_id] &&
                  props?.parent?.states?.[props?.parent?.states?.length - 1]
                    ?.state === "Accept"
                    ? " div-card-preview-card-download"
                    : " div-card-preview-card-none-download"
                } `}
              >
                <div className="!flex !flex-row div-center">
                  {!indexDownload[item.stac_item_id] &&
                    props?.parent?.states?.[props?.parent?.states?.length - 1]
                      ?.state === "Accept" &&
                    currentRole === HASH_ROLE_USE && (
                      <Checkbox
                        className="mr-3"
                        checked={
                          selectedItems.filter((e) => e.id === item.id).length >
                          0
                        }
                        disabled={
                          (flagHaveSessionDownload ||
                            loadingRequestDownloadButton ||
                            visibleBtnDownload ||
                            visibleSpinner) ??
                          false
                        }
                        onChange={(e: any, data: any) => {
                          props?.onSelectedItem(item, data?.checked);
                        }}
                      />
                    )}
                  {indexDownload[item.stac_item_id] && (
                    <DownloadPercent item={item} textX="45%" textY="45%" />
                  )}
                  <img
                    loading='lazy'
                    className={
                      `object-contain rounded-sm img-card-item-catalog-search-drawer mr-2 !h-[64px]` +
                      (item?.thumbnail?.[0] ? " !bg-black" : "")
                    }
                    src={
                      item?.thumbnail?.[0]
                        ? `${ip}/collections/${item?.collection_id}/items/${item?.stac_item_id}/thumbnails/${item?.thumbnail?.[0]}/`
                        : LayerSvg
                    }
                    onError={(e: any) => {
                      e.target.src = LayerSvg;
                    }}
                  />
                </div>
              </CardPreview>

              <CardHeader
                // className="w-4/5"
                style={{ width: "calc(100% - 100px)" }}
                header={
                  <div className="divHeader relative">
                    <div className="block">
                      <Tooltip
                        relationship="description"
                        // @ts-ignore
                        content={item?.stac_item_id}
                      >
                        <p className="!uppercase text-title-card ellipsis-text-one-line">
                          {item?.stac_item_id}
                        </p>
                      </Tooltip>
                      <Tooltip
                        relationship="description"
                        // @ts-ignore
                        content={
                          item?.collection_id +
                          " | " +
                          moment?.(item?.created).format("DD/MM/YYYY HH:mm:ss")
                        }
                      >
                        <div className="ellipsis-text-one-line">
                          <p className="items-center mt-1 text-des ">
                            {item?.collection_id}
                            <span className="border-right-divider" />

                            {moment?.(item?.created).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </p>
                        </div>
                      </Tooltip>
                    </div>

                    <div />
                  </div>
                }
              />
            </Card>
          </div>
          {/* </div> */}
        </section>
      ))}
    </>
  );
};

export default CardItemDowload;
