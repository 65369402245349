export function truncateString(str: any, maxLen: any) {
  if (str) {
    if (str?.length <= maxLen) return str;
    let res = "";
    for (let i = 0; i < maxLen - 3; i++) res += str[i];
    res += "...";
    return res;
  }
  return "";
}

export async function loadConfig(url: any) {
  console.log(url, "url");

  const data = await fetch(url);
  const JSON_CONFIG = await data.json();

  return JSON_CONFIG;
}

export async function renderInfoUserQuota(data: any) {
  let total: any = 0;
  let used: any = 0;
  let unit: any = "";

  if (data?.unit === "Gigabytes") {
    total = data?.limit_value;
    used =
      data?.value?.LimitTotalOrderSize > 0
        ? data?.value?.LimitTotalOrderSize / 1000000000
        : 0;

    unit = "GB";
  } else if (data?.unit === "Tetabytes") {
    total = data?.limit_value;
    used =
      data?.value?.LimitTotalOrderSize > 0
        ? data?.value?.LimitTotalOrderSize / 100000000000
        : 0;
    unit = "TB";
  } else if (data?.unit === "Megabytes") {
    total = data?.limit_value / 1000;
    used =
      data?.value?.LimitTotalOrderSize > 0
        ? data?.value?.LimitTotalOrderSize / 1000000000
        : 0;
    unit = "GB";
  } else if (data?.unit === "Kilobytes") {
    total = data?.limit_value / 1000000;
    used =
      data?.value?.LimitTotalOrderSize > 0
        ? data?.value?.LimitTotalOrderSize / 1000000000
        : 0;
    unit = "GB";
  } else if (data?.unit === "Bytes") {
    total = data?.limit_value / 100000000;
    used =
      data?.value?.LimitTotalOrderSize > 0
        ? data?.value?.LimitTotalOrderSize / 1000000000
        : 0;
    unit = "GB";
  }

  return ((used / total)).toFixed(1) ;
}


export async function removeLeadingSlash(path: any)
{

  if (path && path !=='/' && path.startsWith("/")) {
    return path.substring(1);
  }
  return path;
}
