import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Divider,
} from "@fluentui/react-components";
import { Tooltip } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";

const AssetsComp = (props: any) => {
  const [arrGroup, setArrGroup] = useState([]);

  useEffect(() => {
    if (props?.data) {
      let sun: any = handleData();

      setArrGroup(sun);
    }
  }, [props?.data]);

  const handleData = () => {
    let sun: any[] = [];

    Object?.keys(props?.data)?.map((item: any) => {
      // @ts-ignore
      sun?.push({
        // @ts-ignore
        ...props?.data?.[item],
      });
    });

    return sun;
  };

  const renderArray = (obj: any[]) => {
    return (
      <div style={{ fontSize: "11px" }} className="flex justify-end">
        {obj?.map((item: any, index: number) => {
          if (typeof item !== "object") {
            return (
              <span
                className="ellipsis-text-one-line overflow-hidden"
                key={index}
                style={{ color: "red" }}
              >
                {item}
                {index !== obj?.length - 1 ? ", " : ""}
              </span>
            );
          } else {
            // return (
            //   <div style={{ fontSize: "11px" }} className="block">
            //     {Object.keys(item).map((key: any, index: any) => {
            //       if (typeof item[key] !== "object") {
            //         return (
            //           <div
            //             className="flex flex-row w-full ellipsis-text-one-line"
            //             style={{ fontSize: "11px" }}
            //             key={index}
            //           >
            //             <span className="text-des ellipsis-text-one-line w-1/2">
            //               {key}:
            //             </span>
            //             <Tooltip relationship="description" content={item[key]}>
            //               <span className="text-right w-full ellipsis-text overflow-hidden">
            //                 &nbsp;{item[key]}
            //               </span>
            //             </Tooltip>
            //           </div>
            //         );
            //       } else if (typeof item[key] === "object") {
            //         return renderObject(key, item[key], 1);
            //       }
            //     })}
            //   </div>
            // );
          }
        })}
      </div>
    );
  };

  const renderObject = (
    key: any,
    obj: any,
    padding: any,
    isFlexCol: boolean = true
  ) => {
    return (
      <div
        className={"flex gap-y-3 w-full " + (isFlexCol ? "flex-col" : "")}
        style={{ fontSize: "11px" }}
      >
        <span className="text-des">{key}:</span>

        {typeof obj !== "object" && !Array.isArray(obj) && (
          <span className=" text-right text-ellipsis overflow-hidden w-full">
            {obj}
          </span>
        )}
        {typeof obj === "object" && !Array.isArray(obj) && (
          <div className="flex flex-col pl-3 text-ellipsis overflow-hidden">
            {obj &&
              Object.keys(obj).map((k) =>
                renderObject(k, obj[k], padding + 1, false)
              )}
          </div>
        )}
        {typeof obj === "object" && Array.isArray(obj) && renderArray(obj)}
      </div>
    );
  };
  return (
    <div>
      {/* @ts-ignore */}
      <Accordion multiple collapsible className="flex flex-col gap-y-4">
        {arrGroup?.map((item: any, index: any) => (
          <AccordionItem
            className="rounded !bg-[#2E3240]"
            value={index}
            key={index}
          >
            <AccordionHeader
              expandIconPosition="end"
              className=" text-des ellipsis-text-one-line !bg-[#2E3240]"
              style={{ fontSize: "11px" }}
            >
              {item?.title}
            </AccordionHeader>
            <AccordionPanel>
              <Divider className="!text-s-line1 " />
              <div className="py-4 flex flex-col gap-y-2">
                {/* @ts-ignore */}
                {item &&
                  Object.keys(item).map((key: any, index: any) => {
                    if (key !== "title" && typeof item[key] !== "object") {
                      let a: any = item[key] ?? "";
                      return (
                        <div
                          className="flex flex-row w-full"
                          style={{ fontSize: "11px" }}
                          key={index}
                        >
                          <span className="text-des">{key}:</span>
                          <Tooltip
                            relationship="description"
                            content={item[key]}
                          >
                            <span className="text-right w-full ellipsis-text overflow-hidden">
                              &nbsp;{item[key]}
                            </span>
                          </Tooltip>
                        </div>
                      );
                    } else if (
                      key !== "title" &&
                      typeof item[key] === "object"
                    ) {
                      // return renderObject(key, item[key], 1);
                    }
                  })}
              </div>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default AssetsComp;
