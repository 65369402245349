import { Button } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import BackSvg from "./../../assets/back.svg";
import FilterSvg from "./../../assets/filter-footer.svg";
import NextSvg from "./../../assets/next-paging.svg";
import SortbySvg from "./../../assets/sortby.svg";
import
  {
    changeFlagPage,
    changeIndPathNext,
    changePage,
  } from "./../../store/pagingSlice";
import { RootState } from "./../../store/store";

import { toggleDrawer, toggleSort } from "../../store/DrawerSlice";
import
  {
    HASH_ROLE_ADMIN,
    HASH_ROLE_USE,
    MD5_TEXT_ROLE,
  } from "../../utils/constants";
import
  {
    arrPathDisablePagingFliter,
    arrPathDisablePaing,
  } from "../../utils/data";
import BreadcrumbComp from "../Breadcrumb";
import ItemDownloadFooter from "../CardItemDowload/ItemDownloadFooter";
import HealthCheckComp from "../HealthCheck";
import BreadcrumbStorageComp from "../ManageStorage/Breadcrumb";

const Pagination = (props: any) => {
  const dispatch = useDispatch();
  const { page, limit, total, indPathNext } = useSelector(
    (state: RootState) => state.paging
  );

  const { visible, visibleSort } = useSelector(
    (state: RootState) => state.drawer
  );

  const { arrPathNextPage } = useSelector(
    (state: RootState) => state.exploredataset
  );

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  const { dataBreadcrumb } = useSelector(
    (state: RootState) => state.breadcrumn
  );

  let r = localStorage.getItem(MD5_TEXT_ROLE);

  return (
    <>
      <div className="flex justify-between !h-8">
        {dataBreadcrumb?.length > 0 && pageName !== "view-browse-storage" ? (
          <BreadcrumbComp data={dataBreadcrumb} />
        ) : dataBreadcrumb?.length > 0 && pageName == "view-browse-storage" ? (
          <BreadcrumbStorageComp data={dataBreadcrumb} />
        ) : (
          <div />
        )}
        <div className="flex !h-8 items-center float-right">
          {props?.config?.REACT_APP_CONFIG_DIRECT_DOWNLOAD &&
            props?.config?.REACT_APP_CONFIG_DIRECT_DOWNLOAD !== "true" &&
            r === HASH_ROLE_USE && <ItemDownloadFooter />}

          {(pageName === "manage-dataset-item" ||
            pageName === "dataset-item") && (
            <>
              <Button
                className="divBtnPaginationAction !mr-3"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={BackSvg}
                    alt="back"
                  />
                }
                onClick={() => {
                  dispatch(changeIndPathNext(indPathNext - 1));
                  /* @ts-ignore */
                  dispatch(changeFlagPage("prev"));
                }}
                disabled={indPathNext === 0}
              />
              <Button
                className="divBtnPaginationAction"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={NextSvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(changeIndPathNext(indPathNext + 1));

                  /* @ts-ignore */

                  dispatch(changeFlagPage("next"));
                }}
                disabled={indPathNext === arrPathNextPage?.length - 1}
                style={{ marginRight: "8px" }}
              />
            </>
          )}

          {/* @ts-ignore */}
          {!arrPathDisablePaing?.includes(pageName) && (
            <>
              <Button
                className="divBtnPaginationAction"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={BackSvg}
                    alt="back"
                  />
                }
                onClick={() => {
                  dispatch(changePage(page - 1));
                }}
                disabled={page === 0}
              />
              {total !== 0 && (
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                >
                  {page + 1} /{" "}
                  {Math.floor(total / limit) + (total % limit === 0 ? 0 : 1)}
                </span>
              )}
              {total === 0 && (
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                >
                  1 / 1
                </span>
              )}
              <Button
                className="divBtnPaginationAction"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={NextSvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(changePage(page + 1));
                }}
                disabled={
                  page ===
                  Math.floor(total / limit) + (total % limit === 0 ? 0 : 1) - 1
                }
                style={{ marginRight: "8px" }}
              />
            </>
          )}

          {/* @ts-ignore */}
          {!arrPathDisablePagingFliter?.includes(pageName) && (
            <>
              <Button
                // @ts-ignore
                // disabled={arrPathDisablePagingFliter?.includes(pageName)}
                className={
                  visibleSort ? "btn-active " : "" + "divBtnPaginationAction"
                }
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-filter"
                    src={SortbySvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(toggleSort(!visibleSort));
                }}
                style={{ marginRight: "4px" }}
              />

              <Button
                // @ts-ignore
                // disabled={arrPathDisablePagingFliter?.includes(pageName)}
                className={
                  visible ? "btn-active " : "" + "divBtnPaginationAction"
                }
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-filter"
                    src={FilterSvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(toggleDrawer(!visible));
                }}
              />
            </>
          )}

          {pageName === "manage-dataset-item" && (
            <Button
              // @ts-ignore
              // disabled={arrPathDisablePagingFliter?.includes(pageName)}
              className={
                visible ? "btn-active " : "" + "divBtnPaginationAction"
              }
              appearance="subtle"
              size="medium"
              icon={
                <img
                  className="img-svg-icon-filter"
                  src={FilterSvg}
                  alt="next"
                />
              }
              onClick={() => {
                dispatch(toggleDrawer(!visible));
              }}
            />
          )}

          {r && r === HASH_ROLE_ADMIN && <HealthCheckComp />}
        </div>
      </div>
    </>
  );
};

export default Pagination;
