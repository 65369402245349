import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

import {
  Spinner,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";

import EmptySvg from "./../../assets/nodata.svg";

import CardItemQuotaComp from "../../components/ManageQuota/CardItemQuota";
import {
  deleteQuotaAsync,
  getListAsync,
  updateQuotaAsync,
} from "../../store/ManageQuotaSlice";

import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  changeFlagFilter,
  changeFlagFresh,
  changeFlagSort,
  changeStateFilter,
} from "../../store/ManageQuotaSlice";
import {
  changeStateBtnCreate,
  changeVisibleSpinner,
} from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { timeout } from "../../utils/constants";
import { changePage, changeTotal, resetState } from "./../../store/pagingSlice";
import LoadingListComp from "../../components/Loading/LoadingList";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import ToastComp from "../../components/Toast";
import moment from "moment";

const dataBread = [
  {
    key: 1,
    value: "Quotas",
    path: "manage-quota",
  },
];

const ManageQuotasPage = () => {
  const dispatch = useDispatch();

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    type_quota,
    start_at,
    end_at,
    sort_by,
    sort_order,
    flagFilter,
    flagFresh,
    flagSort,
    keyword,
  } = useSelector((state: RootState) => state.managequota);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-quota"));
    dispatch(changeName("manage-quota"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    (async function () {
      if (!flagFilter || !flagFresh || !flagSort)
        try {
          setLoading(true);
          dispatch(changeVisibleSpinner(true));
          await getData({ page: page });
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [page]);

  useEffect(() => {
    (async function () {
      if (flagSort)
        try {
          setLoading(true);
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          dispatch(changeVisibleSpinner(true));
          await getData({ page: 0 });
          dispatch(changeFlagSort(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagSort(false));
        }
    })();
  }, [flagSort]);

  useEffect(() => {
    (async function () {
      if (flagFilter)
        try {
          setLoading(true);
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          dispatch(changeVisibleSpinner(true));
          await getData({ page: 0 });
          dispatch(changeFlagFilter(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeFlagFilter(false));
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagFilter]);

  useEffect(() => {
    (async function () {
      if (flagFresh)
        try {
          setLoading(true);
          dispatch(changeTotal(0));
          dispatch(changePage(0));
          dispatch(changeVisibleSpinner(true));
          await getData({ page: 0 });
          dispatch(changeFlagFresh(false));
          dispatch(changeVisibleSpinner(false));
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          dispatch(changeFlagFresh(false));
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [flagFresh]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname?.includes("manage-quota")) {
        // @ts-ignore
        dispatch(changeStateFilter());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: "/manage-quota/add",
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
    };
  }, []);

  const getData = async (props: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      getListAsync({
        size: limit,
        sortby: sort_by,
        asc: sort_order === "asc" ? true : false,
        page: props?.page ? props?.page : page,

        filter: {
          created_from: start_at,
          created_to: end_at
            ? moment(end_at).endOf("days").toISOString()
            : null,
          quota_types: type_quota !== "All" ? [type_quota] : null,
          keyword: keyword,
        },
      })
    ).unwrap();

    setData(res?.result ?? []);
    dispatch(changeTotal(res?.paging?.total ?? 0));

    dispatch(changeVisibleSpinner(false));
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const deleQuota = async (id: any) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteQuotaAsync(id)).unwrap();
    if (res?.status === 200) {
      getData({ page: page });
      notify("Delete successfully", "success");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    } else {
      notify("Delete fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 2000);
    }
  };

  const handleEnable = async (dataChecked: any, item: any) => {
    dispatch(changeVisibleSpinner(true));
    const res = await dispatch(
      // @ts-ignore
      updateQuotaAsync({ ...item, enable: dataChecked?.checked })
    ).unwrap();
    if (res?.status) {
      notify(
        `${dataChecked?.checked ? "On " : "Off "}${item?.name} successfully`,
        "success"
      );
      // getData({page:  page});

      let a = data;
      let b: any = [];

      a?.map((itemData: any) => {
        if (itemData?.id === item?.id) {
          b.push({
            ...itemData,
            enable: dataChecked?.checked,
          });
        } else b.push(itemData);
      });

      setData(b);

      dispatch(changeVisibleSpinner(false));
    } else {
      notify(
        `${dataChecked?.checked ? "On " : "Off "}${item?.name} fail`,
        "error"
      );
      dispatch(changeVisibleSpinner(false));
    }
  };
  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {visibleSpinner && !loading && <LoadingGlobalComp />}
        {!data?.length && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}
        {data?.length !== 0 && !loading && (
          <div className="!w-full">
            <CardItemQuotaComp
              data={data}
              deleQuota={deleQuota}
              getData={getData}
              handleEnable={handleEnable}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ManageQuotasPage;
