import axios from "../../utils/axios";

export async function getList(payload: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/`, {
    params: { ...payload },
  });
}

export async function add(payload: any) {
  
  return axios.post(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/`, payload);
}

export async function addExpire(payExpire: any) {
  
  const { quota_id, ...newPay } = payExpire;
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/${payExpire?.quota_id}/expires/`,
    newPay
  );
}

export async function delExpire(payExpire: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/${payExpire?.quota_id}/expires/`
  );
}

export async function deleteQuota(id: any) {
  
  return axios.delete(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/${id}/`);
}

export async function getExpireQuota(id: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/${id}/expires/`);
}

export async function updateQuota(payload: any) {
  
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/${payload?.id}/`,
    payload
  );
}

export async function deleteQuotaExpire(id: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/quotas/${id}/expires/`
  );
}

export async function getUserQuota(userId: string) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${userId}/quotas/`);
}

export async function addUserQuota(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${payload?.userId}/quotas/${payload?.quotaId}/`
  );
}

export async function delUserQuota(payload: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/users/${payload?.userId}/quotas/${payload?.quotaId}/`
  );
}

export async function getQuotaTye() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/quotas/types/`
  );
}

export async function getTyeUnit() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/quotas/unit_types/`
  );
}

export async function getExpireTypes() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/quotas/expire_types/`
  );
}


export async function getMyQuotas() {
  return axios.get(
    `${localStorage.getItem("REACT_APP_ENDPOINT")}/quotas/users/summaries/`
  );
}



export async function getUserQuotas(idUser: string) { // by id user
  return axios.get(
    `${localStorage.getItem(
      "REACT_APP_ENDPOINT"
    )}/admin/quotas/users/${idUser}/summaries/`
  );
}