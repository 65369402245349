import React, { useEffect } from "react";
import FormEditNotiComp from "../../components/Notification/FormEditNoti";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../store/pageSlice";
import { resetState } from "../../store/pagingSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";

const EditNotificationPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePath("/edit-noti"));
    dispatch(changeName("edit-noti"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);
  return <FormEditNotiComp />;
};

export default EditNotificationPage;
