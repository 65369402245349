import axios from "../../utils/axios";

export async function getRepeatType() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/scheduler/repeat_types/`
  );
}

export async function getFreqType() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/scheduler/freq_types/`
  );
}

export async function getTypeDay() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/scheduler/select_day_types/`
  );
}

export async function getDay() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/scheduler/select_days/`
  );
}

export async function getTaskType() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/scheduler/task_types/`
  );
}

export async function getDayRelative() {
  
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/dictionaries/scheduler/select_day_relatives/`
  );
}

///select_day_relative

export async function getListTask(payload: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/`, {
    params: { ...payload },
  });
}

export async function getListJob(payload: any) {
  
  return axios.get(`${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/jobs/`, {
    params: { ...payload },
  });
}

export async function createTask(payload: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/`,
    payload
  );
}

export async function updateTask(payload: any) {
  
  return axios.put(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/${payload?.id}/`,
    payload
  );
}

export async function startTask(id: any) {
  
  return axios.post(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/${id}/start/`
  );
}

export async function stopTask(id: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/${id}/stop/`
  );
}

export async function deleteTask(id: any) {
  
  return axios.delete(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/${id}/`
  );
}

export async function getTaskJobs(payload: any) {
  
  let newPay = {
    size: payload?.size,
    sortby: payload?.sortby,
    asc: payload?.asc,
    page: payload?.page,

    filter: payload?.filter,
  };
  return axios.get(
    `${localStorage.getItem('REACT_APP_ENDPOINT')}/admin/schedulers/tasks/${payload?.task_id}/jobs/`,
    {
      params: { ...newPay },
    }
  );
}
