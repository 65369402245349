import { Input, InputProps } from "@fluentui/react-components";
import React from "react";
import styled from "styled-components";

interface IStyledInput extends InputProps {
  err?: string;
}

const StyledFluentInput: React.FC<IStyledInput> = styled(Input)`
  border-bottom-color: ${(props) =>
    props?.err && props?.err !== "" ? "rgba(221, 44, 0, 1)" : "none"};

  &:focus-within {
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(96, 205, 255, 1)"};

    &:after {
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(96, 205, 255, 1)"};
    }
  }

  &:hover {
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(149, 149, 149, 1)"};

    &:after {
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(149, 149, 149, 1)"};
    }
  }
`;

export default StyledFluentInput;
