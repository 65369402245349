import React, { useEffect, useState } from "react";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import { NavLink } from "react-router-dom";
import Notification from "./../components/Notification/index";

const HistorySearchPage = (props: any) => {
  return (
    <>
      <p>Thnah xuan</p>
      <Notification />
    </>
  );
};

export default HistorySearchPage;
