import { createSlice } from "@reduxjs/toolkit";

export interface BreadcrumbState {
  dataBreadcrumb: any;
}

const initialState: BreadcrumbState = {
  dataBreadcrumb: [],
};

export const BreadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    changeDataBreadcrumb: (state, action) => {
      state.dataBreadcrumb = action?.payload;
    },
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const { changeDataBreadcrumb } = BreadcrumbSlice.actions;

export default BreadcrumbSlice.reducer;
