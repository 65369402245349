import {
  Button,
  Dropdown,
  Input,
  Option,
  Spinner,
  Switch,
  Textarea,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { DropdownProps } from "@fluentui/react-components";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { addDownloadStorageAsync } from "../../store/dowloadSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { resetState } from "../../store/pagingSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import ToastComp from "../Toast";
import { getListAsync as getListStorage } from "./../../store/ManageStorageSlice";

const dataBread = [
  {
    key: 0,
    value: "Setting storage download",
    path: "setting-download",
  },
  {
    key: 1,
    value: "Create",
    path: "Add",
  },
];

const FormAddStorageDownloadComp = (
  props: any,
  propsMultiDropdown: Partial<DropdownProps>
) => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const res = await dispatch(
          // @ts-ignore
          getListStorage({
            size: 1000,
            page: 0,
          })
        ).unwrap();

        setArrStorage(res?.result ?? []);

        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState({}));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const [arrStorage, setArrStorage] = useState<any[]>([]);
  const [name, setName] = useState("");
  const [des, setDes] = useState("");
  const [enable, setEnable] = useState(false);
  const [storageId, setStorageId] = useState("");
  const [storageName, setStorageName] = useState("");

  const onOptionSelectType = (e: any, data: any) => {
    setStorageName(data?.optionText);
    setStorageId(data?.optionValue);
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const add = async () => {
    setLoading(true);
    let res;

    res = await dispatch(
      // @ts-ignore
      addDownloadStorageAsync({
        name: name,
        description: des,
        enable: enable,
        storage_id: storageId,
      })
    ).unwrap();

    if (res?.status === 200) {
      notify("Add successfully", "success");
      setTimeout(() => {
        navigate(`/setting-download`);
        setLoading(false);
      }, 2000);
    } else {
      notify("Add fail", "error");
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="px-6 py-6 !w-full">
      {loading && (
        <center>
          <Spinner className="absolute top-1/2 left-1/2 z-[9999]" />
        </center>
      )}
      <div
        className="div-form-storage-small"
        style={{
          opacity: loading ? 0.3 : 1,
          cursor: loading ? "none" : "initial",
        }}
      >
        <div className="w-full">
          <p className="mb-2">Name</p>
          <Input
            value={name}
            onChange={(e: any) => setName(e?.target?.value)}
            placeholder="Enter name..."
            className="div-dropdown"
          />
          <p className="mb-2 mt-6">Description</p>
          <Textarea
            value={des}
            onChange={(e: any) => setDes(e?.target?.value)}
            placeholder="Enter description..."
            className="div-dropdown"
            resize="both"
          />

          <p className="mb-2 mt-6">Storage</p>
          <Dropdown
            placeholder="Select storage"
            className="div-dropdown"
            value={storageName}
            onOptionSelect={onOptionSelectType}
          >
            {arrStorage?.map((option: any) => (
              <Option key={option?.id} value={option?.id}>
                {option?.description}
              </Option>
            ))}
          </Dropdown>

          <p className="mb-2 mt-6">Enable</p>
          <Switch
            checked={enable}
            onChange={(_, data: any) => {
              setEnable(data?.checked);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            position: "absolute",
            bottom: "30px",
            right: "10px",
          }}
        >
          <Button
            appearance="outline"
            className="btn-extra-action"
            onClick={() => navigate(`/setting-download`)}
            disabled={loading}
          >
            Cancel
          </Button>

          <Button
            appearance="outline"
            className="btn-action"
            onClick={() => add()}
            disabled={loading}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormAddStorageDownloadComp;
