import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Tooltip,
} from "@fluentui/react-components";
import moment from "moment";
import NextSvg from "./../../assets/next.svg";

import OrderSuccessSvg from "./../../assets/images/order-success.svg";

import OrderRejectSvg from "./../../assets/images/order-reject.svg";

import OrderPendingSvg from "./../../assets/images/order-pending.svg";

import useCustomNavigate from "../../hook/useCustomNavigate";
import AcceptSvg from "./../../assets/accept.svg";
import RejectSvg from "./../../assets/Dismiss.svg";

const CardItemOrderImg = (props: any) => {
  const navigate = useCustomNavigate();

  return (
    <>
      {/* <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} /> */}
      {props?.data?.map((item: any, index: number) => (
        <section
          key={index}
          onClick={() => {
            if (item?.states?.[item?.states?.length - 1]?.state !== "Create") {
              navigate(`/manage-order/${item?.id}`);
            }
          }}
        >
          <div className="bg-default-order rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage !bg-[#2E3240]"
            >
              <CardPreview
                className="div-center div-card-preview"
                onClick={() => {
                  if (
                    item?.states?.[item?.states?.length - 1]?.state !== "Create"
                  ) {
                    navigate(`/manage-order/${item?.id}`);
                  }
                }}
              >
                <Tooltip
                  content={item?.states?.[item?.states?.length - 1]?.state}
                  relationship="label"
                >
                  <img
                    src={
                      item?.states?.[item?.states?.length - 1]?.state ===
                      "Accept"
                        ? OrderSuccessSvg
                        : item?.states?.[item?.states?.length - 1]?.state ===
                          "Submit"
                        ? OrderPendingSvg
                        : OrderRejectSvg
                    }
                    alt="img item"
                    className="img-card-item-catalog"
                  />
                </Tooltip>
              </CardPreview>

              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      {item?.user?.traits?.name?.last &&
                      item?.user?.traits?.name?.first ? (
                        <Tooltip
                          relationship="description"
                          // @ts-ignore
                          content={
                            item?.user?.traits?.name?.last +
                            " " +
                            item?.user?.traits?.name?.first
                          }
                        >
                          <p className="ellipsis-text-one-line">
                            {item?.user?.traits?.name?.last ?? "--"}{" "}
                            {item?.user?.traits?.name?.first ?? "--"}
                          </p>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          relationship="description"
                          // @ts-ignore
                          content={item?.user?.traits?.email}
                        >
                          <p className="ellipsis-text-one-line">
                            {item?.user?.traits?.email ?? "--"}
                          </p>
                        </Tooltip>
                      )}

                      <Tooltip
                        relationship="description"
                        // @ts-ignore
                        content={
                          moment(item?.modified).format("DD/MM/YYYY HH:mm:ss") +
                          " | " +
                          item?.items?.length +
                          " item"
                        }
                      >
                        <p className="mt-1 text-des ellipsis-text-one-line">
                          {moment(item?.modified).format("DD/MM/YYYY HH:mm:ss")}{" "}
                          | {item?.items?.length} item
                          {item?.items?.length > 0 ? "s" : ""}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                }
                onClick={() => {
                  if (
                    item?.states?.[item?.states?.length - 1]?.state !== "Create"
                  ) {
                    navigate(`/manage-order/${item?.id}`);
                  }
                }}
              />

              <CardFooter className="footer-card-action">
                {item?.states?.[item?.states?.length - 1]?.state ===
                  "Submit" && (
                  <div className="div-action-card">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        props?.handleAccpet(item);
                      }}
                    >
                      <img
                        className="img-svg-icon mr-6"
                        src={AcceptSvg}
                        alt="Info Circle"
                      />
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        props?.handleReject(item);
                      }}
                    >
                      <img
                        className="img-svg-icon mr-3"
                        src={RejectSvg}
                        alt="Dismiss"
                      />
                    </div>
                  </div>
                )}

                {item?.states?.[item?.states?.length - 1]?.state !==
                  "Create" && (
                  <div onClick={() => navigate(`/manage-order/${item?.id}`)}>
                    <img
                      className="img-svg-icon"
                      src={NextSvg}
                      alt="Info Circle"
                    />
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItemOrderImg;
