import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimePicker } from "@fluentui/react-timepicker-compat";
import * as React from "react";

export const TimePickerWithDatePicker = (props: any) => {
  const onSelectDate = (date: any) => {
    props?.onSelectDate(date);
  };

  const onFormatDate = (date?: any): string => {
    return !date
      ? ""
      : (date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate()) +
          "/" +
          (date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1) +
          "/" +
          date?.getFullYear();
  };

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = props?.selectedDate || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [props?.selectedDate]
  );

  return (
    <div className="!w-full grid grid-cols-2 gap-2">
      <TimePicker
        className="mb-2 div-dropdown minWidthCus"
        placeholder="hh:mm:ss"
        freeform
        dateAnchor={props?.selectedDate ?? undefined}
        selectedTime={props?.selectedTime}
        onTimeChange={(e: any, data: any) => {
          props?.onChangeTime(data);
        }}
        value={props?.timePickerValue}
        onInput={props?.onInput()}
        clearable
        showSeconds
        startHour={0}
        endHour={24}
        hourCycle={"h23"}
      />
      <DatePicker
        value={props?.selectedDate}
        onSelectDate={(date: any) => onSelectDate(date)}
        className="div-dropdown !h-[30px]"
        formatDate={onFormatDate}
        allowTextInput
        placeholder="dd/mm/yyyy"
        parseDateFromString={onParseDateFromString}
      />
    </div>
  );
};
