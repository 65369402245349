import { useEffect, useState } from "react";

import { useToastController } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import EmptySvg from "./../../assets/nodata.svg";
import CardDatasetItemAdmin from "./../../components/ManageDataset/CardDatasetItemAdmin";
import {
  changeAllowIntersect,
  changeArrPathNextPage,
  changeCoordinates,
  changeFlagFilterItem,
  changeFlagRefeshItem,
  changeItemHover,
  changeSate,
  deleteItemCollectionsAsync,
  getFeaturedByIdAsync,
  getItemsByCollectionIdAsync,
  getItemsCollectionByPathAsync,
  getThumbnailItemDatasetAsync as getThumbnail,
  getItemCollectionByIdAsync,
  changeItemViewInfor,
  changeStateFilter,
} from "./../../store/exploreSlice";

import moment from "moment";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import ToastComp from "../../components/Toast";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  changeStateVisibleDrawerInforItemDataset,
  resetAllStateDrawer,
} from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { resetNextPage, resetState } from "../../store/pagingSlice";

const ExploreDatasetPage = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [dataItem, setDataItem] = useState([]);
  const [dataFeatured, setDataFeatured] = useState({});

  const { page, limit, indPathNext, flagPage } = useSelector(
    (state: RootState) => state.paging
  );
  const { arrPathNextPage, itemViewInfor } = useSelector(
    (state: RootState) => state.exploredataset
  );

  const { visibleDrawerInforDataset } = useSelector(
    (state: RootState) => state.drawer
  );

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const {
    start_at,
    end_at,
    cloudCoverFrom,
    cloudCoverTo,
    typeVT,
    flagFilterItem,
    flagRefeshItem,
    allowIntersect,
    coordinates,
    idSearch,
  } = useSelector((state: RootState) => state.exploredataset);

  useEffect(() => {
    (async function () {
      if (!flagFilterItem || !flagRefeshItem)
        try {
          dispatch(changeVisibleSpinner(true));

          //  get thong tin hien thi o breadcrumb
          const resReatured = await dispatch(
            // @ts-ignore
            getFeaturedByIdAsync(`${window.location.pathname.split("/")?.[2]}`)
          ).unwrap();

          if (resReatured.status === 200) {
            setDataFeatured(resReatured?.data);
          }
          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
        }
    })();
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    if (flagFilterItem) {
      getData();
    }
  }, [flagFilterItem]);

  useEffect(() => {
    if (flagRefeshItem) {
      getData();
    }
  }, [flagRefeshItem]);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));

        getData();

        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, [indPathNext]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname?.includes("manage-dataset")) {
        // detroy search intersect
        dispatch(changeAllowIntersect(false));
        dispatch(changeCoordinates([]));
        dispatch(changeItemHover(null));
      }
    };
  }, []);

  const getData = async () => {
    setLoading(true);
    dispatch(changeVisibleSpinner(true));
    let payload: any = {
      id: window.location.pathname.split("/")?.[2],
      // fields: "+properties",
      // filter: {
      //   op: "and",
      //   args: [
      //     {
      //       op: "gte",
      //       args: [{ property: "eo:cloud_cover" }, cloudCoverFrom],
      //     },
      //     { op: "lte", args: [{ property: "eo:cloud_cover" }, cloudCoverTo] },
      //   ],
      // },
      // filter: `{ "op": "and", "args": [ { "op": "gte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverFrom} ] }, { "op": "lte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverTo} ] } ] }`,
      filter:
        idSearch && idSearch !== ""
          ? `{ "op": "and", "args": [
{ "op": "and", "args": [ { "op": "gte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverFrom} ] }, { "op": "lte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverTo} ] } ] },
{ "op": "eq", "args": [ { "property": "id" },"${idSearch}" ] }
] }
`
          : `{ "op": "and", "args": [ { "op": "gte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverFrom} ] }, { "op": "lte", "args": [ { "property": "eo:cloud_cover" }, ${cloudCoverTo} ] } ] }`,
      filter_lang: "cql2-json",
    };

    if (start_at && end_at)
      payload.datetime = `${moment(start_at).toISOString()}/${moment(end_at)
        .endOf("days")
        .toISOString()}`;
    if (end_at && !start_at)
      payload.datetime = `../${moment(end_at).endOf("days").toISOString()}`;
    if (!end_at && start_at)
      payload.datetime = `${moment(start_at).toISOString()}/..`;
    if (!end_at && !start_at) payload.datetime = null;

    if (allowIntersect && coordinates && coordinates.length)
      payload.bbox = coordinates.join(",");

    let resItem;

    if (flagFilterItem || flagRefeshItem) {
      dispatch(changeArrPathNextPage([]));
      dispatch(changeFlagFilterItem(false));
      dispatch(changeFlagRefeshItem(false));
      // @ts-ignore
      dispatch(resetNextPage({}));

      let sun: any = [];

      resItem = await dispatch(
        // @ts-ignore
        getItemsByCollectionIdAsync({ ...payload, limit: 8 })
      ).unwrap();
      let gao = resItem?.data?.links?.filter(
        (item: any) => item?.rel === "prev"
      );
      if (!sun?.includes(gao?.[0]?.href)) {
        sun.push(gao?.[0]?.href);
      }
      let tun = resItem?.data?.links?.filter(
        (item: any) => item?.rel === "next"
      );

      if (!sun?.includes(tun?.[0]?.href)) {
        sun.push(tun?.[0]?.href);
      }

      dispatch(changeArrPathNextPage(sun));
    } else {
      if (indPathNext === 0) {
        resItem = await dispatch(
          // @ts-ignore
          getItemsByCollectionIdAsync({ ...payload, limit: 8 })
        ).unwrap();
      } else {
        resItem = await dispatch(
          // @ts-ignore
          getItemsCollectionByPathAsync({
            path: arrPathNextPage?.[indPathNext],

            ...payload,
          })
        ).unwrap();
        // xuwr lys doan get data tu path lay theo indPath trong arrPathNextPage
      }

      if (flagPage === "next") {
        // neu click btn next thi moi can save lai path o next
        let sun = [...arrPathNextPage];
        if (arrPathNextPage?.length === 0) {
          let gao = resItem?.data?.links?.filter(
            (item: any) => item?.rel === "prev"
          );
          if (!sun?.includes(gao?.[0]?.href)) {
            sun.push(gao?.[0]?.href);
          }
        }
        let tun = resItem?.data?.links?.filter(
          (item: any) => item?.rel === "next"
        );

        if (!sun?.includes(tun?.[0]?.href)) {
          sun.push(tun?.[0]?.href);
        }

        dispatch(changeArrPathNextPage(sun));
      }
    }

    let a: any = resItem?.data?.features;
    let b: any = [];

    for (let i = 0; i < a?.length; i++) {
      let res;
      res = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: a?.[i]?.collection,
          item_id: a?.[i]?.id,
        })
      ).unwrap();

      b.push({
        ...a?.[i],
        thumbnail: res,
      });
    }

    setDataItem(b ?? []);

    dispatch(changeVisibleSpinner(false));
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      dispatch(changeSate({}));

      if (!window.location.pathname?.includes("manage-dataset")) {
        // @ts-ignore
        dispatch(changeStateFilter({}));
      }

      // @ts-ignore
      dispatch(resetState());
      // @ts-ignore
      dispatch(resetNextPage({}));
      dispatch(changeArrPathNextPage([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-dataset-item"));
    dispatch(changeName("manage-dataset-item"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: "dataset",
          value: "Dataset",
          path: "manage-dataset",
        },
        {
          // @ts-ignore
          key: window.location.pathname.split("/")?.[2],
          // @ts-ignore
          value: window.location.pathname.split("/")?.[2],
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  const handleDeleteItem = async (id: string) => {
    dispatch(changeVisibleSpinner(true));

    try {
      const res = await dispatch(
        // @ts-ignore
        deleteItemCollectionsAsync({
          idCollection: window.location.pathname.split("/")?.[2],
          idItem: id,
        })
      ).unwrap();

      if (res?.status === 200) {
        const resItem = await dispatch(
          // @ts-ignore
          getItemsByCollectionIdAsync({
            id: `${window.location.pathname.split("/")?.[2]}`,
            page: page,
            limit: limit,
          })
        ).unwrap();

        let a: any = resItem?.data?.features;
        let b: any = [];

        for (let i = 0; i < a?.length; i++) {
          let res;
          res = await dispatch(
            // @ts-ignore
            getThumbnail({
              collection_id: a?.[i]?.collection,
              item_id: a?.[i]?.id,
            })
          ).unwrap();

          b.push({
            ...a?.[i],
            thumbnail: res,
          });
        }

        setDataItem(b ?? []);
        notify("Delete successfully", "success");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 1500);
      } else {
        notify("Delete fail", "error");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 1500);
      }
    } catch (e) {
      notify("Delete fail", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 1500);
    }
  };

  const onMouseEnterItem = (item: any) => {
    dispatch(changeItemHover(item));
  };
  const onMouseLeaveItem = (item: any) => {
    dispatch(changeItemHover(null));
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (message: any, type?: any) => {
    return dispatchToast(ToastComp({ message: message }), {
      position: "bottom",
      timeout: timeout,
      intent: type,
    });
  };

  const ViewInforItem = async (itemValue: any) => {
    // dispatch(changeVisibleSpinner(true));

    if (itemValue?.id !== itemViewInfor?.id) {
      const res = await dispatch(
        // @ts-ignore
        getItemCollectionByIdAsync({
          collectionId: itemValue?.collection,
          itemId: itemValue?.id,
        })
      ).unwrap();

      const resThumnail = await dispatch(
        // @ts-ignore
        getThumbnail({
          collection_id: itemValue?.collection,
          item_id: itemValue?.id,
        })
      ).unwrap();

      let item = {
        ...res?.data,
        thumbnail: resThumnail,
      };

      dispatch(changeItemViewInfor(item));
      dispatch(
        changeStateVisibleDrawerInforItemDataset(!visibleDrawerInforDataset)
      );
    } else {
      dispatch(changeStateVisibleDrawerInforItemDataset(false));
      dispatch(changeItemViewInfor({}));
    }

    // dispatch(changeVisibleSpinner(false));
  };

  // console.log(arrPathNextPage, "arrPathNextPage");

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {visibleSpinner && !loading && <LoadingGlobalComp />}
        {dataItem?.length === 0 && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )} 
        {dataItem?.length !== 0 && !loading && (
          <div>
            <CardDatasetItemAdmin
              data={dataItem}
              parent={dataFeatured}
              handleDeleteItem={handleDeleteItem}
              ViewInforItem={ViewInforItem}
              // for map
              onMouseEnterItem={(item: any) => onMouseEnterItem(item)}
              onMouseLeaveItem={(item: any) => onMouseLeaveItem(item)}
              // loading={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ExploreDatasetPage;
