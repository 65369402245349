import { useDispatch, useSelector } from "react-redux";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { changeAnimationPage, changeHref } from "../store/pagingSlice";
import { RootState } from "../store/store";

const useCustomNavigate = () => {
  const dispatch = useDispatch();
  const { arrHref } = useSelector((state: RootState) => state.paging);
  const naviagte = useNavigate();

  const handleData = (arrVal: any, path: any) => {
    if (arrVal.indexOf(path) === -1) {
      dispatch(changeAnimationPage(true));

      dispatch(changeHref(arrVal.concat(path)));
    } else {
      if (arrVal.indexOf(path) !== arrVal.length - 1) {
        if (arrVal[arrVal.length - 1].search(path) === 0) {
          dispatch(changeAnimationPage(false));
        }
        dispatch(
          changeHref(
            arrVal.filter(
              (_item: any, index: number) => index <= arrVal.indexOf(path)
            )
          )
        );
      }
    }
  };
  return (to: To, options?: NavigateOptions) => {
    // @ts-ignore
    if (to?.charAt(0) !== "/") {
      handleData(arrHref, to);
    } else {
      // @ts-ignore
      let b = to.replace("/", "");

      handleData(arrHref, b);
    }

    naviagte(to, options);
  };
};

export default useCustomNavigate;
