import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Spinner,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDownload } from "../../pages/[invoiceId]/utils";
import { toggleDrawerDownload } from "../../store/DrawerSlice";
import { RootState } from "../../store/store";
import NextSvg from "./../../assets/next-paging.svg";
import {} from "./../../store/pagingSlice";

const ItemDownloadFooter = (props: any) => {
  const { loadingDownload } = useSelector((state: RootState) => state.download);

  const downloadingFiles = getOrderDownload();

  const { visibleDrawerDownload } = useSelector(
    (state: RootState) => state.drawer
  );
  const dispatch = useDispatch();
  return (
    <div className="mr-3 !h-8">
      <Card
        orientation="horizontal"
        className="card-item-download-footer !h-8"
        onClick={() => {
          // @ts-ignore
          dispatch(toggleDrawerDownload(!visibleDrawerDownload));
        }}
      >
        {loadingDownload && (
          <CardPreview className="div-center w-[35px] !ml-1">
            <Spinner size="extra-tiny" appearance="inverted" />
          </CardPreview>
        )}

        <CardHeader
          className="w-4/5 div-center-row !text-start"
          header={
            <div>
              <p className="!text-[12px]">
                Downloading {downloadingFiles?.length} file
                {downloadingFiles?.length > 1 ? "s" : ""}
              </p>
              {/* <div className="text-color-des div-center-row !text-[10px]">
                <span className="flex">
                  <img
                    className="img-loading-download !mr-1"
                    src={ClockSvg}
                    alt="img item"
                  />
                  02m 40s
                </span>
                <span className="flex">
                  <img
                    className="img-loading-download !mr-1"
                    src={ArrowDownloadSvg}
                    alt="img item"
                  />
                  180.5 MB/ 2.29 GB
                </span>
              </div> */}
            </div>
          }
        />

        <CardFooter className="cardfooter">
          <div className="flex">
            <Button
              // className="divBtnPaginationAction"
              appearance="subtle"
              size="medium"
              icon={
                <img className="img-svg-icon-paging" src={NextSvg} alt="next" />
              }
              onClick={() => {
                // @ts-ignore
                dispatch(toggleDrawerDownload(!visibleDrawerDownload));
              }}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ItemDownloadFooter;
