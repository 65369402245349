import {
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import Editor from "@monaco-editor/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { ButtonComponent as Button } from "@web/react-component-ui-user";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import useCustomNavigate from "../../hook/useCustomNavigate";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import { timeout } from "../../utils/constants";
import {
  changeStateStringValidate,
  editItemCollectionsAsync,
  getItemCollectionByIdAsync,
  validateItemCollectionsAsync,
} from "./../../store/exploreSlice";

const EditItemDatasetPage = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const location = useLocation();

  const { statusValidate } = useSelector(
    (state: RootState) => state.exploredataset
  );
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const [value, setValue] = useState<any>(undefined);
  const [state, setState] = useState(!statusValidate ? "validate" : "post");

  const customTheme = {
    base: "vs-dark",
    inherit: true,
    rules: [
      {
        token: "comment",
        foreground: "#5d7988",
        fontStyle: "italic",
      },
      { token: "constant", foreground: "#e06c75" },
    ],
    colors: {
      "editor.background": "#21252b",
    },
  };
  useEffect(() => {
    // @ts-ignore
    monaco.editor.defineTheme("my-custom-theme", customTheme);
    monaco.editor.setTheme("my-custom-theme");
  }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: "Dataset",
          path: "manage-dataset",
        },
        {
          key: 1,
          value: location?.state?.parent?.id,
          path: `manage-dataset/${location?.state?.parent?.id}`,
        },
        {
          key: 2,
          value: `Edit ${location?.state?.item?.id}`,
          path: ``,
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(changeStateStringValidate());
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getItemCollectionByIdAsync({
            collectionId: location.state.item?.collection,
            itemId: location.state.item?.id,
          })
        ).unwrap();

        if (res?.status === 200) {
          setValue(JSON.stringify(res?.data, null, 2));
        }

        // dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(changePath("/manage-item-dataset/edit"));
    dispatch(changeName("manage--item-dataset/edit"));
    return function cleanup() {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });
  const handleEditorChange = (value: any, event: any) => {
    setValue(value);
    setState("validate");
    // @ts-ignore
    dispatch(changeStateStringValidate());
  };

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const notify = (result: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>
          {state === "validate" && <p>{result}</p>}
          {state === "post" && (
            <p>{result === 200 ? "Update successfully" : "Update fail"}</p>
          )}
        </ToastTitle>
      </Toast>,
      { timeout: timeout, position: "bottom", intent: type }
    );
  };

  const handleValidate = async () => {
    // @ts-ignore

    try {
      dispatch(changeVisibleSpinner(true));
      const res = await dispatch(
        // @ts-ignore
        validateItemCollectionsAsync(value)
      ).unwrap();
      if (res?.response?.status === 200) {
        setTimeout(() => {
          notify(res?.response?.data?.message, "success");
          dispatch(changeVisibleSpinner(false));
          setState("post");
        }, 1500);
      } else {
        setTimeout(() => {
          notify(res?.response?.data?.message, "error");
          dispatch(changeVisibleSpinner(false));
          setState("validate");
        }, 1500);
      }
    } catch (e) {
      setTimeout(() => {
        notify("Error validate", "error");
        dispatch(changeVisibleSpinner(false));
        setState("validate");
      }, 1500);
    }
  };

  const handleFinish = () => {
    ///change-here
    dispatch(
      // @ts-ignore
      editItemCollectionsAsync({
        idCollection: location?.state?.parent?.id,
        idItem: location?.state?.item?.id,
        dataItem: value,
      })
    )
      .then(unwrapResult)
      .then((result: any) => {
        setTimeout(() => {
          notify(result?.status, result?.status === 200 ? "success" : "error");
          // @ts-ignore
          setValue(undefined);
          setState("validate");

          if (result?.status === 200) {
            navigate(`/manage-dataset/${location?.state?.parent?.id}`);
          }
          // @ts-ignore
          dispatch(changeStateStringValidate());
          dispatch(changeVisibleSpinner(false));
        }, 1500);
      })
      .catch((error: any) => {
        setTimeout(() => {
          notify("Error", "error");
          dispatch(changeVisibleSpinner(false));
        }, 1500);
      });
  };

  return (
    <>
      <div className="px-6 py-6 !w-full !relative">
        {visibleSpinner && <LoadingGlobalComp />}

        {/* <p className="mb-4">Enter information item</p> */}
        <Editor
          height="calc(100vh - 230px)"
          defaultLanguage="json"
          theme="my-custom-theme"
          onChange={handleEditorChange}
          value={value}
          onMount={() => {
            dispatch(changeVisibleSpinner(false));
          }}
        />

        <div className="mt-4 !float-right">
          <Button
            className="btn-back"
            title="Cancel"
            onClick={() =>
              navigate(`/manage-dataset/${location?.state?.parent?.id}`)
            }
          />
          <Button
            appearance="primary"
            className="text-black !ml-3"
            title={state === "post" ? "Update" : "Validate"}
            onClick={state === "post" ? handleFinish : handleValidate}
          />
        </div>
      </div>
    </>
  );
};

export default EditItemDatasetPage;
