import { Spinner } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import BreadcrumbComp from "../../components/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";

import FormEditDatasetStorageComp from "../../components/ManageDataset/FormEditDatasetStorage";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { useDispatch } from "react-redux";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import { changeName, changePath } from "../../store/pageSlice";

const EditCollectionStoragePage = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const params = useParams();

  const dataBread = [
    {
      key: 0,
      value: "Dataset",
      path: "manage-dataset",
    },
    {
      key: 1,
      value: `Setting storage's collection ${
        location?.state?.item?.collection_id ?? params?.id
      }`,
      path: `manage-dataset/setting/${
        location?.state?.item?.collection_id ?? params?.id
      }`,
    },
    {
      key: 2,
      value: `Edit storage ${location?.state?.item?.storage?.name}`,
      path: "edit",
    },
  ];

  useEffect(() => {
    dispatch(changePath("/manage-dataset/setting/idCollection/edit"));
    dispatch(changeName("manage-dataset/setting/idCollection/edit"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  return <FormEditDatasetStorageComp />;
};

export default EditCollectionStoragePage;
